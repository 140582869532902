/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, FormItem, Input, SubmitButton } from 'formik-antd';
import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { ChangePasswordSchema } from './validation';

interface ChangePasswordFormProps {
  oldPassword: string;
  password: string;
  confirmPassword: string;
  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePassword: (action: any) => void;
  buttonLabel: string;
}

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  oldPassword,
  password,
  confirmPassword,
  handleChangeInput,
  onChangePassword,
  buttonLabel,
}) => {
  const { t } = useTranslation();
  const handleSubmit = useCallback(
    (_values: any, action: any) => {
      onChangePassword(action);
    },
    [onChangePassword]
  );

  const onHandleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleChangeInput(event);
    },
    [handleChangeInput]
  );

  return (
    <Formik
      initialValues={{ oldPassword, password, confirmPassword }}
      onSubmit={handleSubmit}
      validationSchema={ChangePasswordSchema}
    >
      {({ errors, touched }) => (
        <Form layout="vertical">
          <FormItem name="oldPassword" label={t('label.oldPassword')}>
            <Input.Password
              name="oldPassword"
              size="large"
              value={oldPassword}
              onChange={onHandleChangeInput}
              placeholder={t('label.enterOldPassword')}
            />
          </FormItem>
          <FormItem className="mb-3" help={undefined} name="password" label={t('label.newPassword')}>
            <Input.Password
              name="password"
              size="large"
              value={password}
              onChange={onHandleChangeInput}
              placeholder={t('label.enterNewPassword')}
            />
            <span
              className="mb-2"
              style={{
                color: touched.password && !!errors.password ? '#FF4D4F' : '#64748B',
              }}
            >
              Password must have at least 8 characters 1 lower case, 1 upper case, and 1 special character
            </span>
          </FormItem>
          <FormItem name="confirmPassword" label={t('label.confirmPassword')}>
            <Input.Password
              name="confirmPassword"
              size="large"
              value={confirmPassword}
              onChange={onHandleChangeInput}
              placeholder={t('label.enterConfirmPassword')}
            />
          </FormItem>
          <SubmitButton type="primary" className="mt-2" block size="large">
            {t(buttonLabel)}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};
