/* eslint-disable no-shadow, @typescript-eslint/no-explicit-any */
const notificationTemplate = {
  NEW_BULK_IMPORT_REQUEST: 'NEW_BULK_IMPORT_REQUEST',
  NEW_EMPLOYER_ONBOARDED: 'NEW_EMPLOYER_ONBOARDED',
  EDIT_COMPANY_INFORMATION: 'EDIT_COMPANY_INFORMATION',
  FAIL_OBA_CREATION: 'FAIL_OBA_CREATION',
  BULK_IMPORT_APPROVED: 'BULK_IMPORT_APPROVED',
  BULK_IMPORT_REJECTED: 'BULK_IMPORT_REJECTED',
  NEW_EMPLOYEE_ONBOARD: 'NEW_EMPLOYEE_ONBOARD',
  EDIT_INFO_APPROVED: 'EDIT_INFO_APPROVED',
  EDIT_INFO_REJECTED: 'EDIT_INFO_REJECTED',
  EMPLOYER_FAIL_REPAYMENT: 'EMPLOYER_FAIL_REPAYMENT',
  ADMIN_FAIL_REPAYMENT: 'ADMIN_FAIL_REPAYMENT',
  SUCCESSFUL_REPAYMENT: 'SUCCESSFUL_REPAYMENT',
  FAIL_REPAYMENT: 'FAIL_REPAYMENT',
};

export { notificationTemplate };
