import { combineReducers } from 'redux';
import { CountryReducer } from 'modules/public/country';
import { ChangeLanguageReducer } from 'modules/public/i18n';
import { toastReducer } from 'modules/public/toast';
import { ChangeUiThemeReducer } from 'modules/public/uiTheme';

export const publicReducer = combineReducers({
  i18n: ChangeLanguageReducer,
  uiTheme: ChangeUiThemeReducer,
  toasts: toastReducer,
  country: CountryReducer,
});
