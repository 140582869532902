/* eslint-disable no-shadow, @typescript-eslint/no-explicit-any */
enum PaymentTransactionStatus {
  SUCCESS = 'success',
  PENDING = 'pending',
  PENDING_MERCHANT = 'pending_merchant',
}

enum PaymentTransactionProductType {
  MOTORINSURANCE = 'Motor Insurance',
  TAKAFULINSURANCE = 'Takaful Insurance',
}

const paymentTransactionStatusLabel: any = {
  success: 'status.success',
  pending: 'status.pending',
  pendingMerchant: 'status.pending_merchant',
};

const paymentTransactionStatusSelect: any = [
  { label: paymentTransactionStatusLabel.success, value: PaymentTransactionStatus.SUCCESS },
  { label: paymentTransactionStatusLabel.pendingMerchant, value: PaymentTransactionStatus.PENDING_MERCHANT },
  { label: paymentTransactionStatusLabel.pending, value: PaymentTransactionStatus.PENDING },
];

export { PaymentTransactionStatus, PaymentTransactionProductType, paymentTransactionStatusSelect };
