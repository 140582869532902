import { takeEvery } from 'redux-saga/effects';
import {
  EMPLOYEE_COMPANY_INFO_FETCH,
  EMPLOYEE_COMPANY_RECORD_FETCH,
  EMPLOYEE_EKYC_RECORD_FETCH,
  EMPLOYEE_INFO_FETCH,
  EMPLOYEE_LAPSE_REPAYMENT_RECORD_FETCH,
  EMPLOYEE_RECORD_FETCH,
  EMPLOYEE_TRANSACTION_RECORD_FETCH,
  EMPLOYEE_UPDATE_COMPANY_INFO_FETCH,
  EMPLOYEE_UPDATE_COMPANY_STATUS_FETCH,
  EMPLOYEE_UPDATE_INFO_FETCH,
  EMPLOYEE_UPDATE_STATUS_FETCH,
  EMPLOYEE_WORK_HOUR_RECORD_FETCH,
} from '../constants';
// eslint-disable-next-line import/no-cycle
import {
  employeeCompanyInfoSaga,
  employeeCompanyRecordSaga,
  employeeEkycRecordSaga,
  employeeInfoSaga,
  employeeLapseRepaymentRecordSaga,
  employeeRecordSaga,
  employeeTransactionRecordSaga,
  employeeWorkHourRecordSaga,
  updateEmployeeCompanyInfoSaga,
  updateEmployeeCompanyStatusSaga,
  updateEmployeeInfoSaga,
  updateEmployeeStatusSaga,
} from './employeeSaga';

export function* rootEmployeeSaga() {
  yield takeEvery(EMPLOYEE_RECORD_FETCH, employeeRecordSaga);
  yield takeEvery(EMPLOYEE_INFO_FETCH, employeeInfoSaga);
  yield takeEvery(EMPLOYEE_UPDATE_INFO_FETCH, updateEmployeeInfoSaga);
  yield takeEvery(EMPLOYEE_UPDATE_STATUS_FETCH, updateEmployeeStatusSaga);
  yield takeEvery(EMPLOYEE_COMPANY_RECORD_FETCH, employeeCompanyRecordSaga);
  yield takeEvery(EMPLOYEE_COMPANY_INFO_FETCH, employeeCompanyInfoSaga);
  yield takeEvery(EMPLOYEE_UPDATE_COMPANY_INFO_FETCH, updateEmployeeCompanyInfoSaga);
  yield takeEvery(EMPLOYEE_UPDATE_COMPANY_STATUS_FETCH, updateEmployeeCompanyStatusSaga);
  yield takeEvery(EMPLOYEE_WORK_HOUR_RECORD_FETCH, employeeWorkHourRecordSaga);
  yield takeEvery(EMPLOYEE_TRANSACTION_RECORD_FETCH, employeeTransactionRecordSaga);
  yield takeEvery(EMPLOYEE_LAPSE_REPAYMENT_RECORD_FETCH, employeeLapseRepaymentRecordSaga);
  yield takeEvery(EMPLOYEE_EKYC_RECORD_FETCH, employeeEkycRecordSaga);
}
