export const SCHEDULER_LIST_FETCH = 'scheduler/SCHEDULER_LIST_FETCH';
export const SCHEDULER_LIST_SUCCESS = 'scheduler/SCHEDULER_LIST_SUCCESS';
export const SCHEDULER_LIST_ERROR = 'scheduler/SCHEDULER_LIST_ERROR';

export const CREATE_SCHEDULER_TASK_RESET = 'scheduler/CREATE_SCHEDULER_TASK_RESET';
export const CREATE_SCHEDULER_TASK_FETCH = 'scheduler/CREATE_SCHEDULER_TASK_FETCH';
export const CREATE_SCHEDULER_TASK_SUCCESS = 'scheduler/CREATE_SCHEDULER_TASK_SUCCESS';
export const CREATE_SCHEDULER_TASK_ERROR = 'scheduler/CREATE_SCHEDULER_TASK_ERROR';

export const UPDATE_SCHEDULER_TASK_RESET = 'scheduler/UPDATE_SCHEDULER_TASK_RESET';
export const UPDATE_SCHEDULER_TASK_FETCH = 'scheduler/UPDATE_SCHEDULER_TASK_FETCH';
export const UPDATE_SCHEDULER_TASK_SUCCESS = 'scheduler/UPDATE_SCHEDULER_TASK_SUCCESS';
export const UPDATE_SCHEDULER_TASK_ERROR = 'scheduler/UPDATE_SCHEDULER_TASK_ERROR';

export const CANCEL_SCHEDULER_TASK_RESET = 'scheduler/CANCEL_SCHEDULER_TASK_RESET';
export const CANCEL_SCHEDULER_TASK_FETCH = 'scheduler/CANCEL_SCHEDULER_TASK_FETCH';
export const CANCEL_SCHEDULER_TASK_SUCCESS = 'scheduler/CANCEL_SCHEDULER_TASK_SUCCESS';
export const CANCEL_SCHEDULER_TASK_ERROR = 'scheduler/CANCEL_SCHEDULER_TASK_ERROR';

export const WORK_STATUS_LIST_FETCH = 'scheduler/WORK_STATUS_LIST_FETCH';
export const WORK_STATUS_LIST_SUCCESS = 'scheduler/WORK_STATUS_LIST_SUCCESS';
export const WORK_STATUS_LIST_ERROR = 'scheduler/WORK_STATUS_LIST_ERROR';

export const WORK_STATUS_INFO_FETCH = 'scheduler/WORK_STATUS_INFO_FETCH';
export const WORK_STATUS_INFO_SUCCESS = 'scheduler/WORK_STATUS_INFO_SUCCESS';
export const WORK_STATUS_INFO_ERROR = 'scheduler/WORK_STATUS_INFO_ERROR';
