/* eslint-disable @typescript-eslint/no-explicit-any */
import { EmploymentStatus } from 'common';
import moment from 'moment';
import { newEmployeeRequestAction } from './actions';
import {
  NEW_EMPLOYEE_REQUEST_INFO_ERROR,
  NEW_EMPLOYEE_REQUEST_INFO_FETCH,
  NEW_EMPLOYEE_REQUEST_INFO_SUCCESS,
  NEW_EMPLOYEE_REQUEST_LIST_FETCH,
  NEW_EMPLOYEE_REQUEST_LIST_SUCCESS,
  NEW_EMPLOYEE_REQUEST_UPDATE_ERROR,
  NEW_EMPLOYEE_REQUEST_UPDATE_FETCH,
  NEW_EMPLOYEE_REQUEST_UPDATE_SUCCESS,
} from './constants';

export interface INewEmployeeRequestState {
  newEmployeeRequestList: {
    list: any;
    count: number;
    loading: boolean;
    currentPage: number;
    payload: {
      from?: moment.Moment;
      to?: moment.Moment;
      mobile?: string;
      employeeId?: string;
      name?: string;
      status?: string;
      eid?: string;
    };
  };
  newEmployeeRequestInfo: {
    createdOn: string;
    status: EmploymentStatus | undefined;
    companyName: string;
    employeeId: string;
    name: string;
    ebid: string;
    eid: string;
    uid: string;
    reason: string;
    employeeDetails: {
      id: string;
      name: string;
      mobile: string;
      companyEmailAddress: string;
      employeeId: string;
      role: string;
      idNo: string;
      idType: string;
    };
    salaryDetails: {
      salary: string;
      frequency: string | undefined;
      salaryDay: string;
      maxWithdrawalAmount: string;
    };
    scheduler?: boolean;
    checkInEnabled?: boolean;
    outlets: {
      id: string;
      name: string;
      address: string;
      isHq?: boolean;
      actId?: 'create' | 'delete';
    }[];
    bankCode?: string;
    bankAccountNumber?: string;
    hasConsented: boolean;
  };
  updateNewEmployeeRequest: {
    success?: boolean;
    error?: string;
  };
}

const initialState: INewEmployeeRequestState = {
  newEmployeeRequestList: {
    list: [],
    count: 0,
    loading: false,
    currentPage: 1,
    payload: {},
  },
  newEmployeeRequestInfo: {
    createdOn: '',
    status: undefined,
    companyName: '',
    employeeId: '',
    name: '',
    ebid: '',
    eid: '',
    uid: '',
    reason: '',
    employeeDetails: {
      id: '',
      name: '',
      mobile: '',
      companyEmailAddress: '',
      employeeId: '',
      role: '',
      idNo: '',
      idType: '',
    },
    salaryDetails: {
      salary: '',
      frequency: undefined,
      salaryDay: '',
      maxWithdrawalAmount: '',
    },
    outlets: [],
    hasConsented: false,
  },
  updateNewEmployeeRequest: {
    success: false,
  },
};

const newEmployeeRequestListReducer = (
  state: INewEmployeeRequestState['newEmployeeRequestList'],
  action: newEmployeeRequestAction
) => {
  switch (action.type) {
    case NEW_EMPLOYEE_REQUEST_LIST_FETCH:
      return {
        ...state,
        payload: action.payload,
        loading: true,
      };
    case NEW_EMPLOYEE_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.records,
        count: action.payload.count,
        currentPage: action.payload.currentPage,
        loading: false,
      };
    default:
      return state;
  }
};

const newEmployeeRequestInfoReducer = (
  state: INewEmployeeRequestState['newEmployeeRequestInfo'],
  action: newEmployeeRequestAction
) => {
  switch (action.type) {
    case NEW_EMPLOYEE_REQUEST_INFO_FETCH:
      return initialState.newEmployeeRequestInfo;
    case NEW_EMPLOYEE_REQUEST_INFO_ERROR:
      return {
        ...state,
      };
    case NEW_EMPLOYEE_REQUEST_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const updateNewEmployeeRequestReducer = (
  state: INewEmployeeRequestState['updateNewEmployeeRequest'],
  action: newEmployeeRequestAction
) => {
  switch (action.type) {
    case NEW_EMPLOYEE_REQUEST_UPDATE_FETCH:
      return {
        ...state,
        success: false,
        error: undefined,
      };
    case NEW_EMPLOYEE_REQUEST_UPDATE_SUCCESS:
      return {
        ...state,
        success: true,
        error: undefined,
      };
    case NEW_EMPLOYEE_REQUEST_UPDATE_ERROR:
      return {
        ...state,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last
export const newEmployeeRequestReducer = (state = initialState, action: newEmployeeRequestAction) => {
  switch (action.type) {
    case NEW_EMPLOYEE_REQUEST_LIST_FETCH:
    case NEW_EMPLOYEE_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        newEmployeeRequestList: newEmployeeRequestListReducer({ ...state.newEmployeeRequestList }, action),
      };

    case NEW_EMPLOYEE_REQUEST_INFO_FETCH:
    case NEW_EMPLOYEE_REQUEST_INFO_SUCCESS:
    case NEW_EMPLOYEE_REQUEST_INFO_ERROR:
      return {
        ...state,
        newEmployeeRequestInfo: newEmployeeRequestInfoReducer({ ...state.newEmployeeRequestInfo }, action),
      };

    case NEW_EMPLOYEE_REQUEST_UPDATE_FETCH:
    case NEW_EMPLOYEE_REQUEST_UPDATE_SUCCESS:
    case NEW_EMPLOYEE_REQUEST_UPDATE_ERROR:
      return {
        ...state,
        updateNewEmployeeRequest: updateNewEmployeeRequestReducer(
          {
            ...state.updateNewEmployeeRequest,
          },
          action
        ),
      };

    default:
      return state;
  }
};
