import { EMPLOYMENT_LIST_FETCH, EMPLOYMENT_LIST_SUCCESS } from './constants';

export interface IEmploymentListFetch {
  type: typeof EMPLOYMENT_LIST_FETCH;
  payload: {
    employerName?: string;
    employerId?: string;
    eid?: string;
    companyName?: string;
    outletId?: string;
    page?: number;
    scheduler?: boolean;
    statusFlag?: boolean;
  };
}

export interface IEmploymentListSuccess {
  type: typeof EMPLOYMENT_LIST_SUCCESS;
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    records: any;
    count: number;
  };
}

export type employmentAction = IEmploymentListFetch | IEmploymentListSuccess;

export const employmentListFetch = (payload: IEmploymentListFetch['payload']): IEmploymentListFetch => ({
  type: EMPLOYMENT_LIST_FETCH,
  payload,
});

export const successEmploymentList = (payload: IEmploymentListSuccess['payload']): IEmploymentListSuccess => ({
  type: EMPLOYMENT_LIST_SUCCESS,
  payload,
});
