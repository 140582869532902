/* eslint-disable @typescript-eslint/no-explicit-any, import/no-cycle */
import { EmployeeStatus, EmploymentStatus } from 'common';
import {
  EMPLOYEE_COMPANY_RECORD_FETCH,
  EMPLOYEE_COMPANY_RECORD_SUCCESS,
  EMPLOYEE_INFO_ERROR,
  EMPLOYEE_INFO_FETCH,
  EMPLOYEE_INFO_SUCCESS,
  EMPLOYEE_RECORD_FETCH,
  EMPLOYEE_RECORD_SUCCESS,
  EMPLOYEE_TRANSACTION_RECORD_FETCH,
  EMPLOYEE_TRANSACTION_RECORD_SUCCESS,
  EMPLOYEE_UPDATE_INFO_FETCH,
  EMPLOYEE_UPDATE_INFO_SUCCESS,
  EMPLOYEE_WORK_HOUR_RECORD_FETCH,
  EMPLOYEE_WORK_HOUR_RECORD_SUCCESS,
  EMPLOYEE_UPDATE_INFO_ERROR,
  EMPLOYEE_COMPANY_INFO_ERROR,
  EMPLOYEE_COMPANY_INFO_SUCCESS,
  EMPLOYEE_COMPANY_INFO_FETCH,
  EMPLOYEE_UPDATE_COMPANY_STATUS_FETCH,
  EMPLOYEE_UPDATE_COMPANY_STATUS_SUCCESS,
  EMPLOYEE_UPDATE_COMPANY_STATUS_ERROR,
  EMPLOYEE_UPDATE_COMPANY_INFO_FETCH,
  EMPLOYEE_UPDATE_COMPANY_INFO_SUCCESS,
  EMPLOYEE_UPDATE_COMPANY_INFO_ERROR,
  EMPLOYEE_LAPSE_REPAYMENT_RECORD_SUCCESS,
  EMPLOYEE_LAPSE_REPAYMENT_RECORD_FETCH,
  EMPLOYEE_EKYC_RECORD_FETCH,
  EMPLOYEE_EKYC_RECORD_SUCCESS,
  EMPLOYEE_UPDATE_COMPANY_STATUS_RESET,
  EMPLOYEE_UPDATE_STATUS_ERROR,
  EMPLOYEE_UPDATE_STATUS_SUCCESS,
  EMPLOYEE_UPDATE_STATUS_FETCH,
} from './constants';

export interface IEmployeeRecordFetch {
  type: typeof EMPLOYEE_RECORD_FETCH;
  payload: {
    name?: string;
    mobile?: string;
    eid?: string;
    staffId?: string;
    userId?: string;
    page: number;
    permission?: string;
  };
}

export interface IEmployeeRecordSuccess {
  type: typeof EMPLOYEE_RECORD_SUCCESS;
  payload: {
    records: any;
    count: number;
    currentPage: number;
  };
}

export interface IEmployeeInfoFetch {
  type: typeof EMPLOYEE_INFO_FETCH;
  payload: {
    id: string;
  };
}

export interface IEmployeeInfoSuccess {
  type: typeof EMPLOYEE_INFO_SUCCESS;
  payload: {
    email: string;
    name: string;
    mobile: string;
    address: {
      line2: string;
      line1: string;
      postcode: string;
      country: string;
      city: string;
    };
    idType: string;
    idNo: string;
    gender: string;
    nationality: string;
    countryOfBirth: string;
    dateOfBirth: string;
    maritalStatus: string;
    monthlyRepaymentRent: string;
  };
}

export interface IEmployeeInfoError {
  type: typeof EMPLOYEE_INFO_ERROR;
  payload: any;
}

export interface IUpdateEmployeeInfoFetch {
  type: typeof EMPLOYEE_UPDATE_INFO_FETCH;
  payload: {
    id: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    country: string;
    email: string;
    mobile: string;
    name: string;
    obaAccountName: string;
    obaAccountNumber: string;
    obaBankName: string;
    postcode: string;
    salaryAccountName: string;
    salaryAccountNumber: string;
    salaryBankName: string;
    state: string;
  };
}

export interface IUpdateEmployeeInfoSuccess {
  type: typeof EMPLOYEE_UPDATE_INFO_SUCCESS;
}

export interface IUpdateEmployeeInfoError {
  type: typeof EMPLOYEE_UPDATE_INFO_ERROR;
  payload: any;
}

export interface IUpdateEmployeeStatusFetch {
  type: typeof EMPLOYEE_UPDATE_STATUS_FETCH;
  payload: {
    id: string;
    status: typeof EmployeeStatus.PENDING_CLOSED | typeof EmployeeStatus.SUSPENDED;
    reason: string;
  };
  onSuccess: () => void;
  onError: () => void;
}

export interface IUpdateEmployeeStatusSuccess {
  type: typeof EMPLOYEE_UPDATE_STATUS_SUCCESS;
}

export interface IUpdateEmployeeStatusError {
  type: typeof EMPLOYEE_UPDATE_STATUS_ERROR;
  payload: any;
}

export interface IEmployeeCompanyRecordFetch {
  type: typeof EMPLOYEE_COMPANY_RECORD_FETCH;
  payload: {
    id: string;
  };
}

export interface IEmployeeCompanyRecordSuccess {
  type: typeof EMPLOYEE_COMPANY_RECORD_SUCCESS;
  payload: {
    records: any;
  };
}

export interface IEmployeeCompanyInfoFetch {
  type: typeof EMPLOYEE_COMPANY_INFO_FETCH;
  payload: {
    id: string;
    ebid: string;
    eid: string;
  };
}

export interface IEmployeeCompanyInfoSuccess {
  type: typeof EMPLOYEE_COMPANY_INFO_SUCCESS;
  payload: {
    id: string;
    status: string;
    companyName: string;
    employeeDetails: {
      employeeId: string;
      name: string;
      mobile: string;
      companyEmailAddress: string;
    };
    salaryDetails: {
      salary: string;
      frequency: string;
      salaryDay: string;
      maxWithdrawalAmount: string;
    };
  };
}

export interface IUpdateEmployeeCompanyInfoFetch {
  type: typeof EMPLOYEE_UPDATE_COMPANY_INFO_FETCH;
  payload: {
    id: string;
    ebid: string;
    employeeId?: string;
    name?: string;
    mobile?: string;
    email?: string;
    salary?: string | number;
    salaryFrequency?: string;
    salaryDay?: string | number;
    resignationDate?: string;
    workingDays?: number;
    ltv?: number;
    employeeLimitMax?: number;
    reason?: string;
    scheduler?: boolean;
    bankCode?: string;
    bankAccountNumber?: string;
    obaAccountName?: string;
    obaAccountNumber?: string;
    obaBankName?: string;
  };
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

export interface IUpdateEmployeeCompanyInfoSuccess {
  type: typeof EMPLOYEE_UPDATE_COMPANY_INFO_SUCCESS;
}

export interface IUpdateEmployeeCompanyInfoError {
  type: typeof EMPLOYEE_UPDATE_COMPANY_INFO_ERROR;
  payload: any;
}

export interface IUpdateEmployeeCompanyStatusFetch {
  type: typeof EMPLOYEE_UPDATE_COMPANY_STATUS_FETCH;
  payload: {
    id: string;
    ebid: string;
    status?: EmploymentStatus;
    reason?: string;
    resignationDate?: string;
  };
  onSuccess?: () => void;
  onError?: () => void;
}

export interface IUpdateEmployeeCompanyStatusReset {
  type: typeof EMPLOYEE_UPDATE_COMPANY_STATUS_RESET;
}

export interface IUpdateEmployeeCompanyStatusSuccess {
  type: typeof EMPLOYEE_UPDATE_COMPANY_STATUS_SUCCESS;
}

export interface IUpdateEmployeeCompanyStatusError {
  type: typeof EMPLOYEE_UPDATE_COMPANY_STATUS_ERROR;
}

export interface IEmployeeCompanyInfoError {
  type: typeof EMPLOYEE_COMPANY_INFO_ERROR;
  payload: any;
}

export interface IEmployeeWorkHourRecordFetch {
  type: typeof EMPLOYEE_WORK_HOUR_RECORD_FETCH;
  payload: {
    id: string;
    from?: string;
    to?: string;
    page: number;
  };
}

export interface IEmployeeWorkHourRecordSuccess {
  type: typeof EMPLOYEE_WORK_HOUR_RECORD_SUCCESS;
  payload: {
    records: any;
    count: number;
    currentPage: number;
  };
}

export interface IEmployeeTransactionRecordFetch {
  type: typeof EMPLOYEE_TRANSACTION_RECORD_FETCH;
  payload: {
    id: string;
    from?: string;
    to?: string;
    page: number;
  };
}

export interface IEmployeeTransactionRecordSuccess {
  type: typeof EMPLOYEE_TRANSACTION_RECORD_SUCCESS;
  payload: {
    records: any;
    count: number;
    currentPage: number;
  };
}

export interface IEmployeeLapseRepaymentRecordFetch {
  type: typeof EMPLOYEE_LAPSE_REPAYMENT_RECORD_FETCH;
  payload: {
    id: string;
    from?: string;
    to?: string;
  };
}

export interface IEmployeeLapseRepaymentRecordSuccess {
  type: typeof EMPLOYEE_LAPSE_REPAYMENT_RECORD_SUCCESS;
  payload: {
    records: any;
  };
}

export interface IEmployeeEkycRecordFetch {
  type: typeof EMPLOYEE_EKYC_RECORD_FETCH;
  payload: {
    id: string;
  };
}

export interface IEmployeeEkycRecordSuccess {
  type: typeof EMPLOYEE_EKYC_RECORD_SUCCESS;
  payload: {
    records: any;
  };
}

export type EmployeeAction =
  | IEmployeeRecordFetch
  | IEmployeeRecordSuccess
  | IEmployeeInfoFetch
  | IEmployeeInfoSuccess
  | IEmployeeInfoError
  | IUpdateEmployeeInfoFetch
  | IUpdateEmployeeInfoSuccess
  | IUpdateEmployeeInfoError
  | IUpdateEmployeeStatusFetch
  | IUpdateEmployeeStatusSuccess
  | IUpdateEmployeeStatusError
  | IEmployeeCompanyRecordFetch
  | IEmployeeCompanyRecordSuccess
  | IEmployeeCompanyInfoFetch
  | IEmployeeCompanyInfoSuccess
  | IEmployeeCompanyInfoError
  | IEmployeeWorkHourRecordFetch
  | IEmployeeWorkHourRecordSuccess
  | IEmployeeTransactionRecordFetch
  | IEmployeeTransactionRecordSuccess
  | IEmployeeLapseRepaymentRecordFetch
  | IEmployeeLapseRepaymentRecordSuccess
  | IUpdateEmployeeCompanyInfoFetch
  | IUpdateEmployeeCompanyInfoSuccess
  | IUpdateEmployeeCompanyInfoError
  | IUpdateEmployeeCompanyStatusFetch
  | IUpdateEmployeeCompanyStatusReset
  | IUpdateEmployeeCompanyStatusSuccess
  | IUpdateEmployeeCompanyStatusError
  | IEmployeeEkycRecordFetch
  | IEmployeeEkycRecordSuccess;

export const employeeRecord = (payload: IEmployeeRecordFetch['payload']): IEmployeeRecordFetch => ({
  type: EMPLOYEE_RECORD_FETCH,
  payload,
});

export const successEmployeeRecord = (payload: IEmployeeRecordSuccess['payload']): IEmployeeRecordSuccess => ({
  type: EMPLOYEE_RECORD_SUCCESS,
  payload,
});

export const employeeInfo = (payload: IEmployeeInfoFetch['payload']): IEmployeeInfoFetch => ({
  type: EMPLOYEE_INFO_FETCH,
  payload,
});

export const successEmployeeInfo = (payload: IEmployeeInfoSuccess['payload']): IEmployeeInfoSuccess => ({
  type: EMPLOYEE_INFO_SUCCESS,
  payload,
});

export const errorEmployeeInfo = (payload: IEmployeeInfoError['payload']): IEmployeeInfoError => ({
  type: EMPLOYEE_INFO_ERROR,
  payload,
});

export const updateEmployeeInfo = (payload: IUpdateEmployeeInfoFetch['payload']): IUpdateEmployeeInfoFetch => ({
  type: EMPLOYEE_UPDATE_INFO_FETCH,
  payload,
});

export const successUpdateEmployeeInfo = (): IUpdateEmployeeInfoSuccess => ({
  type: EMPLOYEE_UPDATE_INFO_SUCCESS,
});

export const errorUpdateEmployeeInfo = (payload: IUpdateEmployeeInfoError['payload']): IUpdateEmployeeInfoError => ({
  type: EMPLOYEE_UPDATE_INFO_ERROR,
  payload,
});

export const updateEmployeeStatus = (
  payload: IUpdateEmployeeStatusFetch['payload'],
  onSuccess: () => void,
  onError: () => void
): IUpdateEmployeeStatusFetch => ({
  type: EMPLOYEE_UPDATE_STATUS_FETCH,
  payload,
  onSuccess,
  onError,
});

export const employeeCompanyRecord = (
  payload: IEmployeeCompanyRecordFetch['payload']
): IEmployeeCompanyRecordFetch => ({
  type: EMPLOYEE_COMPANY_RECORD_FETCH,
  payload,
});

export const successEmployeeCompanyRecord = (
  payload: IEmployeeCompanyRecordSuccess['payload']
): IEmployeeCompanyRecordSuccess => ({
  type: EMPLOYEE_COMPANY_RECORD_SUCCESS,
  payload,
});

export const employeeCompanyInfo = (payload: IEmployeeCompanyInfoFetch['payload']): IEmployeeCompanyInfoFetch => ({
  type: EMPLOYEE_COMPANY_INFO_FETCH,
  payload,
});

export const successEmployeeCompanyInfo = (
  payload: IEmployeeCompanyInfoSuccess['payload']
): IEmployeeCompanyInfoSuccess => ({
  type: EMPLOYEE_COMPANY_INFO_SUCCESS,
  payload,
});

export const errorEmployeeCompanyInfo = (payload: IEmployeeCompanyInfoError['payload']): IEmployeeCompanyInfoError => ({
  type: EMPLOYEE_COMPANY_INFO_ERROR,
  payload,
});

export const updateEmployeeCompanyInfo = (
  payload: IUpdateEmployeeCompanyInfoFetch['payload'],
  onSuccess?: IUpdateEmployeeCompanyInfoFetch['onSuccess'],
  onError?: IUpdateEmployeeCompanyInfoFetch['onError']
): IUpdateEmployeeCompanyInfoFetch => ({
  type: EMPLOYEE_UPDATE_COMPANY_INFO_FETCH,
  payload,
  onSuccess,
  onError,
});

export const successUpdateEmployeeCompanyInfo = (): IUpdateEmployeeCompanyInfoSuccess => ({
  type: EMPLOYEE_UPDATE_COMPANY_INFO_SUCCESS,
});

export const errorUpdateEmployeeCompanyInfo = (
  payload: IUpdateEmployeeCompanyInfoError['payload']
): IUpdateEmployeeCompanyInfoError => ({
  type: EMPLOYEE_UPDATE_COMPANY_INFO_ERROR,
  payload,
});

export const resetUpdateEmployeeCompanyStatus = (): IUpdateEmployeeCompanyStatusReset => ({
  type: EMPLOYEE_UPDATE_COMPANY_STATUS_RESET,
});

export const updateEmployeeCompanyStatus = (
  payload: IUpdateEmployeeCompanyStatusFetch['payload'],
  onSuccess?: IUpdateEmployeeCompanyStatusFetch['onSuccess'],
  onError?: IUpdateEmployeeCompanyStatusFetch['onError']
): IUpdateEmployeeCompanyStatusFetch => ({
  type: EMPLOYEE_UPDATE_COMPANY_STATUS_FETCH,
  payload,
  onSuccess,
  onError,
});

export const successUpdateEmployeeCompanyStatus = (): IUpdateEmployeeCompanyStatusSuccess => ({
  type: EMPLOYEE_UPDATE_COMPANY_STATUS_SUCCESS,
});

export const errorUpdateEmployeeCompanyStatus = (): IUpdateEmployeeCompanyStatusError => ({
  type: EMPLOYEE_UPDATE_COMPANY_STATUS_ERROR,
});

export const employeeWorkHourRecord = (
  payload: IEmployeeWorkHourRecordFetch['payload']
): IEmployeeWorkHourRecordFetch => ({
  type: EMPLOYEE_WORK_HOUR_RECORD_FETCH,
  payload,
});

export const successEmployeeWorkHourRecord = (
  payload: IEmployeeWorkHourRecordSuccess['payload']
): IEmployeeWorkHourRecordSuccess => ({
  type: EMPLOYEE_WORK_HOUR_RECORD_SUCCESS,
  payload,
});

export const employeeTransactionRecord = (
  payload: IEmployeeTransactionRecordFetch['payload']
): IEmployeeTransactionRecordFetch => ({
  type: EMPLOYEE_TRANSACTION_RECORD_FETCH,
  payload,
});

export const successEmployeeTransactionRecord = (
  payload: IEmployeeTransactionRecordSuccess['payload']
): IEmployeeTransactionRecordSuccess => ({
  type: EMPLOYEE_TRANSACTION_RECORD_SUCCESS,
  payload,
});

export const employeeLapseRepaymentRecord = (
  payload: IEmployeeLapseRepaymentRecordFetch['payload']
): IEmployeeLapseRepaymentRecordFetch => ({
  type: EMPLOYEE_LAPSE_REPAYMENT_RECORD_FETCH,

  payload,
});

export const successEmployeeLapseRepaymentRecord = (
  payload: IEmployeeLapseRepaymentRecordSuccess['payload']
): IEmployeeLapseRepaymentRecordSuccess => ({
  type: EMPLOYEE_LAPSE_REPAYMENT_RECORD_SUCCESS,
  payload,
});

export const employeeEkycRecord = (payload: IEmployeeEkycRecordFetch['payload']): IEmployeeEkycRecordFetch => ({
  type: EMPLOYEE_EKYC_RECORD_FETCH,

  payload,
});

export const successEmployeeEkycRecord = (
  payload: IEmployeeEkycRecordSuccess['payload']
): IEmployeeEkycRecordSuccess => ({
  type: EMPLOYEE_EKYC_RECORD_SUCCESS,
  payload,
});
