import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { CountryCode } from 'common/util/country';
import en from './translations/en.json';
import enApi from './translations/en-api.json';
import idApi from './translations/id-api.json';
import id from './translations/id.json';

let currentCountry = CountryCode.MY;
const sessionStorageData = localStorage.getItem('persist:public');
if (sessionStorageData !== null) {
  const { country } = JSON.parse(sessionStorageData);
  const { code } = JSON.parse(country);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  currentCountry = code ?? CountryCode.MY;
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translation: { ...en, ...enApi },
      },
      in: {
        translation: { ...id, ...idApi },
      },
    },
    fallbackLng: 'en',
    lng: currentCountry === CountryCode.ID ? localStorage.getItem('i18nextLng') ?? 'en' : 'en',

    // keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  })
  .then();

export const changeLanguage = i18n.changeLanguage.bind(i18n);
export const getCurrentLanguage = (): string => i18n.language;
export const t = i18n.t.bind(i18n);

export default i18n;
