import { takeEvery } from 'redux-saga/effects';
import {
  CREATE_EMPLOYER_REQUEST_FETCH,
  EMPLOYER_FILE_RETRIEVE_FETCH,
  EMPLOYER_INFO_FETCH,
  EMPLOYER_INVENTORY_LIST_FETCH,
  EMPLOYER_LIST_FETCH,
  EMPLOYER_PREVIEW_FILES_IMAGE_FETCH,
  EMPLOYER_REQUEST_INFO_FETCH,
  EMPLOYER_REQUEST_LIST_FETCH,
  EMPLOYER_REQUEST_UPLOAD_BLOB_FETCH,
  EMPLOYER_SELECT_FETCH,
  INIT_EMPLOYER_REQUEST_FETCH,
  RESET_EMPLOYER_ADMIN_PASSWORD_FETCH,
  UPDATE_EMPLOYER_ADMIN_STATUS_FETCH,
  UPDATE_EMPLOYER_REQUEST_FETCH,
} from '../constants';
// eslint-disable-next-line import/no-cycle
import {
  createEmployerRequestSaga,
  employerFileRetrieveSaga,
  employerInfoSaga,
  employerInventoryListSaga,
  employerListSaga,
  employerPreviewFileImageSaga,
  employerRequestInfoSaga,
  employerRequestListSaga,
  employerRequestUploadBlobSaga,
  employerSelectSaga,
  initEmployerRequestSaga,
  resetEmployerAdminPasswordSaga,
  updateEmployerAdminStatusSaga,
  updateEmployerRequestSaga,
} from './employerSaga';

export function* rootEmployerSaga() {
  yield takeEvery(EMPLOYER_LIST_FETCH, employerListSaga);
  yield takeEvery(EMPLOYER_SELECT_FETCH, employerSelectSaga);
  yield takeEvery(EMPLOYER_INFO_FETCH, employerInfoSaga);
  yield takeEvery(EMPLOYER_REQUEST_LIST_FETCH, employerRequestListSaga);
  yield takeEvery(EMPLOYER_REQUEST_INFO_FETCH, employerRequestInfoSaga);
  // yield takeEvery(CREATE_EMPLOYER_REQUEST_FETCH, createEmployerRequestSaga);
  yield takeEvery(CREATE_EMPLOYER_REQUEST_FETCH, createEmployerRequestSaga);
  yield takeEvery(UPDATE_EMPLOYER_REQUEST_FETCH, updateEmployerRequestSaga);
  yield takeEvery(INIT_EMPLOYER_REQUEST_FETCH, initEmployerRequestSaga);
  yield takeEvery(EMPLOYER_PREVIEW_FILES_IMAGE_FETCH, employerPreviewFileImageSaga);
  yield takeEvery(EMPLOYER_REQUEST_UPLOAD_BLOB_FETCH, employerRequestUploadBlobSaga);
  yield takeEvery(UPDATE_EMPLOYER_ADMIN_STATUS_FETCH, updateEmployerAdminStatusSaga);
  yield takeEvery(RESET_EMPLOYER_ADMIN_PASSWORD_FETCH, resetEmployerAdminPasswordSaga);
  yield takeEvery(EMPLOYER_INVENTORY_LIST_FETCH, employerInventoryListSaga);
  yield takeEvery(EMPLOYER_FILE_RETRIEVE_FETCH, employerFileRetrieveSaga);
}
