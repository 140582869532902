/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { reportAction } from './actions';
import {
  BANK_REPORT_LIST_FETCH,
  BANK_REPORT_LIST_SUCCESS,
  EMPLOYEE_INFO_UPDATE_REPORT_LIST_FETCH,
  EMPLOYEE_INFO_UPDATE_REPORT_LIST_SUCCESS,
  EMPLOYER_REPORT_LIST_FETCH,
  EMPLOYER_REPORT_LIST_SUCCESS,
  EMPLOYER_REPORT_UPDATE_ERROR,
  EMPLOYER_REPORT_UPDATE_FETCH,
  EMPLOYER_REPORT_UPDATE_SUCCESS,
  EXCEPTIONAL_FILE_REPORT_LIST_FETCH,
  EXCEPTIONAL_FILE_REPORT_LIST_SUCCESS,
  IBG_ENQUIRY_LIST_FETCH,
  IBG_ENQUIRY_LIST_SUCCESS,
  LAPSE_REPAYMENT_REPORT_LIST_FETCH,
  LAPSE_REPAYMENT_REPORT_LIST_SUCCESS,
  LAPSE_REPAYMENT_SEND_EMAIL_FETCH,
  RECONCILIATION_REPORT_LIST_FETCH,
  RECONCILIATION_REPORT_LIST_SUCCESS,
  REPORT_LIST_RESET,
  UPDATE_LAPSE_REPAYMENT_REPORT_ERROR,
  UPDATE_LAPSE_REPAYMENT_REPORT_FETCH,
  UPDATE_LAPSE_REPAYMENT_REPORT_SUCCESS,
  UPLOAD_FILE_REPORT_ERROR,
  UPLOAD_FILE_REPORT_FETCH,
  UPLOAD_FILE_REPORT_SUCCESS,
  INVOICE_REPORT_LIST_FETCH,
  INVOICE_REPORT_LIST_FETCH_SUCCESS,
  INVOICE_REPORT_UPDATE_FETCH,
  INVOICE_REPORT_UPDATE_ERROR,
  INVOICE_REPORT_UPDATE_SUCCESS,
} from './constants';

export interface IReportState {
  reportList: {
    bankReport: {
      list: any;
      count: number;
      loading: boolean;
      payload: {
        createdDate?: string;
        updatedDate?: string;
        type: string;
      };
    };
    exceptionalFile: {
      list: any;
      count: number;
      loading: boolean;
      payload: {
        from?: number;
        to?: number;
        type: string;
      };
    };
    employeeInfoUpdate: {
      list: any;
      count: number;
      loading: boolean;
      payload: {
        from?: number;
        to?: number;
        type: string;
      };
    };
    reconciliation: {
      list: any;
      count: number;
      loading: boolean;
      payload: {
        createdDate?: string;
        type: string;
        serviceProvider?: string;
      };
    };
    lapseRepayment: {
      list: any;
      count: number;
      loading: boolean;
      payload: {
        from?: number;
        to?: number;
        eid?: string;
        status?: string;
      };
    };
    employerReport: {
      list: any;
      count: number;
      loading: boolean;
      payload: {
        from?: number;
        to?: number;
        createdDate?: moment.Moment;
        type: string;
        target?: string;
      };
    };
    ibgEnquiry: {
      list: any;
      count: number;
      loading: boolean;
      payload: {
        from?: any;
        to?: any;
        eid?: number;
        page: number;
      };
    };
    invoiceReport: {
      list: any;
      count: number;
      loading: boolean;
      payload: {
        from?: number;
        to?: number;
        page?: number;
      };
    };
  };
  uploadFileReport: {
    loading: boolean;
    success?: boolean;
    error?: string;
  };
  updateLapseRepayment: {
    loading: boolean;
    success?: boolean;
    error?: string;
  };
  employerReportUpdate: {
    loading: boolean;
    success?: boolean;
    error?: string;
  };
  invoiceReportUpdate: {
    loading: boolean;
    success?: boolean;
    error?: string;
  };
}

const initialState: IReportState = {
  reportList: {
    bankReport: {
      list: [],
      count: 0,
      loading: false,
      payload: {
        type: 'oba&payment',
      },
    },
    exceptionalFile: {
      list: [],
      count: 0,
      loading: false,
      payload: {
        type: 'exceptional',
      },
    },
    employeeInfoUpdate: {
      list: [],
      count: 0,
      loading: false,
      payload: {
        type: 'employeeInfo',
      },
    },
    reconciliation: {
      list: [],
      count: 0,
      loading: false,
      payload: {
        type: 'reconciliation',
      },
    },
    lapseRepayment: {
      list: [],
      count: 0,
      loading: false,
      payload: {},
    },
    employerReport: {
      list: [],
      count: 0,
      loading: false,
      payload: {
        type: 'all',
      },
    },
    ibgEnquiry: {
      list: [],
      count: 0,
      loading: false,
      payload: {
        page: 1,
      },
    },
    invoiceReport: {
      list: [],
      count: 0,
      loading: false,
      payload: {
        page: 1,
      },
    },
  },
  uploadFileReport: {
    loading: false,
    success: false,
  },
  updateLapseRepayment: {
    loading: false,
    success: false,
  },
  employerReportUpdate: {
    loading: false,
    success: false,
  },
  invoiceReportUpdate: {
    loading: false,
    success: false,
  },
};

const reportListReducer = (state: IReportState['reportList'], action: reportAction) => {
  switch (action.type) {
    case BANK_REPORT_LIST_FETCH:
      return {
        ...state,
        bankReport: {
          loading: true,
          payload: action.payload,
        },
      };
    case BANK_REPORT_LIST_SUCCESS:
      return {
        ...state,
        bankReport: {
          ...state.bankReport,
          list: action.payload.records,
          loading: false,
        },
      };
    case EXCEPTIONAL_FILE_REPORT_LIST_FETCH:
      return {
        ...state,
        exceptionalFile: {
          loading: true,
          payload: action.payload,
        },
      };
    case EXCEPTIONAL_FILE_REPORT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        exceptionalFile: {
          ...state.exceptionalFile,
          list: action.payload.records,
          loading: false,
        },
      };

    case EMPLOYEE_INFO_UPDATE_REPORT_LIST_FETCH:
      return {
        ...state,
        employeeInfoUpdate: {
          loading: true,
          payload: action.payload,
        },
      };
    case EMPLOYEE_INFO_UPDATE_REPORT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        employeeInfoUpdate: {
          ...state.employeeInfoUpdate,
          list: action.payload.records,
          loading: false,
        },
      };
    case RECONCILIATION_REPORT_LIST_FETCH:
      return {
        ...state,
        reconciliation: {
          loading: true,
          payload: action.payload,
        },
      };
    case RECONCILIATION_REPORT_LIST_SUCCESS:
      return {
        ...state,
        reconciliation: {
          ...state.reconciliation,
          list: action.payload.records,
          loading: false,
        },
      };
    case LAPSE_REPAYMENT_REPORT_LIST_FETCH:
      return {
        ...state,
        lapseRepayment: {
          loading: true,
          payload: action.payload,
        },
      };
    case LAPSE_REPAYMENT_REPORT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        lapseRepayment: {
          ...state.lapseRepayment,
          list: action.payload.records,
          loading: false,
        },
      };
    case EMPLOYER_REPORT_LIST_FETCH:
      return {
        ...state,
        employerReport: {
          loading: true,
          payload: action.payload,
        },
      };
    case EMPLOYER_REPORT_LIST_SUCCESS:
      return {
        ...state,
        employerReport: {
          ...state.employerReport,
          list: action.payload.records,
          loading: false,
        },
      };
    case IBG_ENQUIRY_LIST_FETCH:
      return {
        ...state,
        ibgEnquiry: {
          loading: true,
          payload: action.payload,
        },
      };
    case IBG_ENQUIRY_LIST_SUCCESS:
      return {
        ...state,
        ibgEnquiry: {
          ...state.ibgEnquiry,
          list: action.payload.records,
          loading: false,
        },
      };
    case INVOICE_REPORT_LIST_FETCH:
      return {
        ...state,
        invoiceReport: {
          loading: true,
          payload: action.payload,
        },
      };
    case INVOICE_REPORT_LIST_FETCH_SUCCESS:
      return {
        ...state,
        invoiceReport: {
          ...state.invoiceReport,
          list: action.payload.records,
          loading: false,
        },
      };
    default:
      return state;
  }
};

const uploadFileReportReducer = (state: IReportState['uploadFileReport'], action: reportAction) => {
  switch (action.type) {
    case UPLOAD_FILE_REPORT_FETCH:
      return {
        ...state,
        loading: true,
        success: false,
        error: undefined,
      };
    case UPLOAD_FILE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: undefined,
      };
    case UPLOAD_FILE_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const updateLapseRepaymentReducer = (state: IReportState['updateLapseRepayment'], action: reportAction) => {
  switch (action.type) {
    case UPDATE_LAPSE_REPAYMENT_REPORT_FETCH:
    case LAPSE_REPAYMENT_SEND_EMAIL_FETCH:
      return {
        ...state,
        loading: true,
        success: false,
        error: undefined,
      };
    case UPDATE_LAPSE_REPAYMENT_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: undefined,
      };
    case UPDATE_LAPSE_REPAYMENT_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

const employerReportUpdateReducer = (state: IReportState['employerReportUpdate'], action: reportAction) => {
  switch (action.type) {
    case EMPLOYER_REPORT_UPDATE_FETCH:
      return {
        ...state,
        loading: true,
        success: false,
        error: undefined,
      };
    case EMPLOYER_REPORT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: undefined,
      };
    case EMPLOYER_REPORT_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

const invoiceReportUpdateReducer = (state: IReportState['invoiceReportUpdate'], action: reportAction) => {
  switch (action.type) {
    case INVOICE_REPORT_UPDATE_FETCH:
      return {
        ...state,
        loading: true,
        success: false,
        error: undefined,
      };
    case INVOICE_REPORT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: undefined,
      };
    case INVOICE_REPORT_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last
export const reportReducer = (state = initialState, action: reportAction) => {
  switch (action.type) {
    case REPORT_LIST_RESET:
    case BANK_REPORT_LIST_FETCH:
    case EXCEPTIONAL_FILE_REPORT_LIST_FETCH:
    case EMPLOYEE_INFO_UPDATE_REPORT_LIST_FETCH:
    case RECONCILIATION_REPORT_LIST_FETCH:
    case BANK_REPORT_LIST_SUCCESS:
    case EXCEPTIONAL_FILE_REPORT_LIST_SUCCESS:
    case EMPLOYEE_INFO_UPDATE_REPORT_LIST_SUCCESS:
    case RECONCILIATION_REPORT_LIST_SUCCESS:
    case LAPSE_REPAYMENT_REPORT_LIST_FETCH:
    case LAPSE_REPAYMENT_REPORT_LIST_SUCCESS:
    case EMPLOYER_REPORT_LIST_FETCH:
    case EMPLOYER_REPORT_LIST_SUCCESS:
    case IBG_ENQUIRY_LIST_FETCH:
    case IBG_ENQUIRY_LIST_SUCCESS:
      return {
        ...state,
        reportList: reportListReducer({ ...state.reportList }, action),
      };
    case UPLOAD_FILE_REPORT_FETCH:
    case UPLOAD_FILE_REPORT_SUCCESS:
    case UPLOAD_FILE_REPORT_ERROR:
      return {
        ...state,
        uploadFileReport: uploadFileReportReducer({ ...state.uploadFileReport }, action),
      };
    case UPDATE_LAPSE_REPAYMENT_REPORT_FETCH:
    case LAPSE_REPAYMENT_SEND_EMAIL_FETCH:
    case UPDATE_LAPSE_REPAYMENT_REPORT_SUCCESS:
    case UPDATE_LAPSE_REPAYMENT_REPORT_ERROR:
      return {
        ...state,
        updateLapseRepayment: updateLapseRepaymentReducer({ ...state.updateLapseRepayment }, action),
      };
    case EMPLOYER_REPORT_UPDATE_FETCH:
    case EMPLOYER_REPORT_UPDATE_SUCCESS:
    case EMPLOYER_REPORT_UPDATE_ERROR:
      return {
        ...state,
        employerReportUpdate: employerReportUpdateReducer({ ...state.employerReportUpdate }, action),
      };
    case INVOICE_REPORT_LIST_FETCH:
    case INVOICE_REPORT_LIST_FETCH_SUCCESS:
      return {
        ...state,
        reportList: reportListReducer({ ...state.reportList }, action),
      };
    case INVOICE_REPORT_UPDATE_FETCH:
    case INVOICE_REPORT_UPDATE_SUCCESS:
    case INVOICE_REPORT_UPDATE_ERROR:
      return {
        ...state,
        invoiceReportUpdate: invoiceReportUpdateReducer({ ...state.employerReportUpdate }, action),
      };
    default:
      return state;
  }
};
