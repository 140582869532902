import { call, put } from 'redux-saga/effects';
import { API } from 'api';
import { AppInfoType } from 'common';
import { toastPush } from 'modules';
import {
  IGetConfigFetch,
  IUpdateBankConfigSettings,
  getWithdrawalConfigSuccess,
  getBankListConfigSuccess,
  successUpdateBankConfigSettings,
  getCountryUtilSuccess,
  getFeeListConfigSuccess,
} from '../actions';

export function* getConfigSaga(action: IGetConfigFetch) {
  try {
    const { id } = action.payload;
    const { data } = yield call(API.get(), `/api/admin/app/info/${id}`, {});
    switch (id) {
      case AppInfoType.WITHDRAWAL_LIMIT:
        yield put(getWithdrawalConfigSuccess(data));
        break;
      case AppInfoType.FEES_LIST:
        yield put(getFeeListConfigSuccess(data));
        break;
      case AppInfoType.BANK_INFO_LIST:
        yield put(getBankListConfigSuccess({ list: data.duitNow }));
        break;
      case AppInfoType.COUNTRY_UTIL:
        yield put(getCountryUtilSuccess({ ...data }));
        break;
      default:
        break;
    }
  } catch (error) {
    // Empty
  }
}

export function* updateBankConfigSettingsSaga(action: IUpdateBankConfigSettings) {
  try {
    const { data } = yield call(API.put(), `/api/admin/sys/bank/config`, action.payload);
    yield put(
      toastPush({
        code: 200,
        type: 'success',
        title: 'SUCCESS',
        body: 'toastPush.updatedBankConfig',
      })
    );
    yield put(successUpdateBankConfigSettings({ ...data }));
  } catch (error) {
    // Empty
  }
}
