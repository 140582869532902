/* eslint-disable @typescript-eslint/no-explicit-any, import/no-extraneous-dependencies */
import { TreeSelect } from 'antd';
import { FormLabelAlign } from 'antd/lib/form/interface';
import { BaseOptionType, ChangeEventExtra } from 'rc-tree-select/lib/TreeSelect';
import React, { ReactNode } from 'react';
import { FormItem } from 'formik-antd';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

export interface TreeSelectInputProp {
  placeholder?: string;
  label?: string | ReactNode;
  id: string;
  name: string;
  tooltip?: ReactNode;
  labelCol?: object; // ant design Col object
  wrapperCol?: object; // ant design Col object
  labelAlign?: FormLabelAlign; // default: right
  required?: boolean;
  noStyle?: boolean;
  allowClear?: boolean;
  disabled?: boolean;
  showArrow?: boolean;
  treeCheckable?: boolean;
  rules?: any;
  value?: string[];
  onChange?: (newVal: any[], labelList?: ReactNode[], extra?: ChangeEventExtra) => void;
  treeData?: BaseOptionType[];
}

const { SHOW_PARENT } = TreeSelect;

export const TreeSelectInput: React.FC<TreeSelectInputProp> = ({
  id,
  label,
  name,
  tooltip,
  labelCol,
  wrapperCol,
  labelAlign,
  required,
  rules,
  noStyle,
  treeData,
  treeCheckable,
  placeholder,
  onChange,
  value,
  disabled,
  showArrow,
  allowClear,
}) => {
  const { t } = useTranslation();
  const [, meta] = useField({ name });

  return (
    <FormItem
      label={label}
      name={name}
      tooltip={tooltip}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      labelAlign={labelAlign}
      required={required}
      rules={rules}
      noStyle={noStyle}
      help={meta.touched && meta.error ? t(`${meta.error}`) : undefined}
    >
      <TreeSelect
        id={id}
        treeData={treeData}
        treeCheckable={treeCheckable}
        showCheckedStrategy={SHOW_PARENT}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        disabled={disabled}
        showArrow={showArrow}
        allowClear={allowClear}
      />
    </FormItem>
  );
};

TreeSelectInput.defaultProps = {
  allowClear: true,
  showArrow: true,
  labelAlign: 'left',
};
