/* eslint-disable @typescript-eslint/no-explicit-any, import/no-cycle */
import { ekycAction } from './actions';
import {
  EKYC_LIST_FETCH,
  EKYC_LIST_SUCCESS,
  EKYC_INFO_FETCH,
  EKYC_INFO_SUCCESS,
  EKYC_KYC_FILE_RETRIEVE_FETCH,
  EKYC_KYC_FILE_RETRIEVE_SUCCESS,
} from './constants';
import { initEkycInfoState, IEkycInfoState } from './types';

export interface IEkycState {
  ekycList: {
    list: any;
    payload: {
      uid?: string;
      employeeName?: string;
      status?: string;
      mobileNo?: string;
      createdDate?: string;
    };
  };
  ekycInfo: IEkycInfoState;
  ekycFileRetrieve: {
    data: Blob | undefined;
    key?: string;
  };
}

const initialState: IEkycState = {
  ekycList: {
    list: [],
    payload: {},
  },
  ekycInfo: initEkycInfoState,
  ekycFileRetrieve: {
    data: undefined,
  },
};

const ekycListReducer = (state: IEkycState['ekycList'], action: ekycAction) => {
  switch (action.type) {
    case EKYC_LIST_FETCH:
      return {
        ...state,
        payload: action.payload,
      };
    case EKYC_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.records,
      };
    default:
      return state;
  }
};

const ekycInfoReducer = (state: IEkycState['ekycInfo'], action: ekycAction) => {
  switch (action.type) {
    case EKYC_INFO_FETCH:
      return {
        ...state,
        loading: true,
      };
    case EKYC_INFO_SUCCESS:
      return {
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

const ekycFileRetrieveReducer = (state: IEkycState['ekycFileRetrieve'], action: ekycAction) => {
  switch (action.type) {
    case EKYC_KYC_FILE_RETRIEVE_FETCH:
      return {
        ...state,
        blob: undefined,
      };
    case EKYC_KYC_FILE_RETRIEVE_SUCCESS:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last
export const ekycReducer = (state = initialState, action: ekycAction) => {
  switch (action.type) {
    case EKYC_LIST_FETCH:
    case EKYC_LIST_SUCCESS:
      return {
        ...state,
        ekycList: ekycListReducer({ ...state.ekycList }, action),
      };
    case EKYC_INFO_FETCH:
    case EKYC_INFO_SUCCESS:
      return {
        ...state,
        ekycInfo: ekycInfoReducer({ ...state.ekycInfo }, action),
      };
    case EKYC_KYC_FILE_RETRIEVE_FETCH:
    case EKYC_KYC_FILE_RETRIEVE_SUCCESS:
      return {
        ...state,
        ekycFileRetrieve: ekycFileRetrieveReducer({ ...state.ekycFileRetrieve }, action),
      };
    default:
      return state;
  }
};
