import { Button, Dropdown, Menu } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useCookies } from 'react-cookie';
import { ButtonType } from 'antd/lib/button/button';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CountryCode, getCookie, getDomainName, isUndefinedOrNullOrEmpty, setCookie } from 'common';
import { changeCountryCode, employerSelect, RootState, switchEmployerAdminAuth } from 'modules';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';

export interface EmployerListDropdownProp {
  type?: ButtonType;
  btnClassName?: string;
}

export const EmployerListDropdown: React.FC<EmployerListDropdownProp> = ({ btnClassName, type }) => {
  const dispatch = useDispatch();
  const [, setRefreshCookie] = useCookies(['refreshToken']);
  const [, setAccessTokenCookie] = useCookies(['accessToken']);
  const [, setExpiredOnCookie] = useCookies(['expiredOn']);
  const [, setEmployerIdCookie] = useCookies(['employerId']);
  const { employers } = useSelector((state: RootState) => state.user);
  const [updatedEid, setUpdatedEid] = useState<string>(getCookie('employerId'));

  const switchEmployer = useCallback(
    (values: { eid: string; name: string; country: CountryCode }) => {
      if (updatedEid !== values.eid) {
        dispatch(
          switchEmployerAdminAuth(
            { eid: values.eid, countryCode: values.country },
            (data: { refreshToken: string; expiredOn: number; accessToken: string; permissions: unknown }) => {
              dispatch(employerSelect());
              setRefreshCookie('refreshToken', data.refreshToken, {
                domain: getDomainName(),
                expires: moment.unix(data.expiredOn).toDate(),
              });
              setAccessTokenCookie('accessToken', data.accessToken, {
                domain: getDomainName(),
                expires: moment.unix(data.expiredOn).toDate(),
              });
              setExpiredOnCookie('expiredOn', data.expiredOn, {
                domain: getDomainName(),
                expires: moment.unix(data.expiredOn).toDate(),
              });
              setEmployerIdCookie('employerId', values.eid, {
                domain: getDomainName(),
              });
              setCookie('permissions', JSON.stringify(data.permissions || []));
              setUpdatedEid(values.eid);
            }
          )
        );
      }
    },
    [dispatch, updatedEid, setAccessTokenCookie, setEmployerIdCookie, setExpiredOnCookie, setRefreshCookie]
  );

  const getCompanyName = useMemo(() => {
    const result = employers.find((item) => item.eid === updatedEid);
    if (isUndefinedOrNullOrEmpty(result)) return '-';

    dispatch(changeCountryCode({ code: result?.country || 'my' }));
    return result?.name ?? '-';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employers, updatedEid]);

  const employerListMenu = (
    <Menu selectedKeys={[updatedEid]}>
      {employers.map((item) => (
        <Menu.Item
          key={item.eid}
          onClick={() => switchEmployer({ eid: item.eid, name: item.name, country: item.country })}
        >
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown trigger={['click']} overlay={employerListMenu}>
      <Button shape="round" className={btnClassName} type={type}>
        {getCompanyName} <DownOutlined />
      </Button>
    </Dropdown>
  );
};
