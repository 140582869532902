import { ITransactionState } from './interfaces';

export const initialState: ITransactionState = {
  transactionRecord: {
    list: [],
    count: 0,
    loading: false,
    payload: {
      page: 1,
    },
  },
  transactionInfo: {
    id: '',
    createdOn: '',
    name: '',
    mobile: '',
    type: '',
    description: '',
    remark: '',
    status: '',
    amount: 0,
    bankName: '',
    approvedAmount: 0,
    approvedLimit: 0,
    maturityDate: '',
    interestRate: 0,
  },
};
