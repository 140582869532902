export const UPLOAD_FILE_REPORT_FETCH = 'report/UPLOAD_FILE_REPORT_FETCH';
export const UPLOAD_FILE_REPORT_SUCCESS = 'report/UPLOAD_FILE_REPORT_SUCCESS';
export const UPLOAD_FILE_REPORT_ERROR = 'report/UPLOAD_FILE_REPORT_ERROR';

export const REPORT_LIST_RESET = 'report/REPORT_LIST_RESET';
export const BANK_REPORT_LIST_FETCH = 'report/BANK_REPORT_LIST_FETCH';
export const BANK_REPORT_LIST_SUCCESS = 'report/BANK_REPORT_LIST_SUCCESS';

export const EXCEPTIONAL_FILE_REPORT_LIST_FETCH = 'report/EXCEPTIONAL_FILE_REPORT_LIST_FETCH';
export const EXCEPTIONAL_FILE_REPORT_LIST_SUCCESS = 'report/EXCEPTIONAL_FILE_REPORT_LIST_SUCCESS';

export const EMPLOYEE_INFO_UPDATE_REPORT_LIST_FETCH = 'report/EMPLOYEE_INFO_UPDATE_REPORT_LIST_FETCH';
export const EMPLOYEE_INFO_UPDATE_REPORT_LIST_SUCCESS = 'report/EMPLOYEE_INFO_UPDATE_REPORT_LIST_SUCCESS';

export const RECONCILIATION_REPORT_LIST_FETCH = 'report/RECONCILIATION_REPORT_LIST_FETCH';
export const RECONCILIATION_REPORT_LIST_SUCCESS = 'report/RECONCILIATION_REPORT_LIST_SUCCESS';

export const LAPSE_REPAYMENT_REPORT_LIST_FETCH = 'report/LAPSE_REPAYMENT_REPORT_LIST_FETCH';
export const LAPSE_REPAYMENT_REPORT_LIST_SUCCESS = 'report/LAPSE_REPAYMENT_REPORT_LIST_SUCCESS';

export const EMPLOYER_REPORT_LIST_FETCH = 'report/EMPLOYER_REPORT_LIST_FETCH';
export const EMPLOYER_REPORT_LIST_SUCCESS = 'report/EMPLOYER_REPORT_LIST_SUCCESS';

export const UPDATE_LAPSE_REPAYMENT_REPORT_FETCH = 'report/UPDATE_LAPSE_REPAYMENT_REPORT_FETCH';
export const UPDATE_LAPSE_REPAYMENT_REPORT_SUCCESS = 'report/UPDATE_LAPSE_REPAYMENT_REPORT_SUCCESS';
export const UPDATE_LAPSE_REPAYMENT_REPORT_ERROR = 'report/UPDATE_LAPSE_REPAYMENT_REPORT_ERROR';

export const LAPSE_REPAYMENT_SEND_EMAIL_FETCH = 'report/LAPSE_REPAYMENT_SEND_EMAIL_FETCH';

export const EMPLOYER_REPORT_UPDATE_FETCH = 'report/EMPLOYER_REPORT_UPDATE_FETCH';
export const EMPLOYER_REPORT_UPDATE_SUCCESS = 'report/EMPLOYER_REPORT_UPDATE_SUCCESS';
export const EMPLOYER_REPORT_UPDATE_ERROR = 'report/EMPLOYER_REPORT_UPDATE_ERROR';

export const EMPLOYER_REPORT_UPLOAD_FILE_FETCH = 'report/EMPLOYER_REPORT_UPLOAD_FILE_FETCH';

export const IBG_ENQUIRY_LIST_FETCH = 'report/IBG_ENQUIRY_LIST_FETCH';
export const IBG_ENQUIRY_LIST_SUCCESS = 'report/IBG_ENQUIRY_LIST_SUCCESS';

export const INVOICE_REPORT_LIST_FETCH = 'report/INVOICE_REPORT_LIST_FETCH';
export const INVOICE_REPORT_LIST_FETCH_SUCCESS = 'report/INVOICE_REPORT_LIST_FETCH_SUCCESS';
export const INVOICE_REPORT_UPDATE_FETCH = 'report/INVOICE_REPORT_UPDATE_FETCH';
export const INVOICE_REPORT_UPDATE_SUCCESS = 'report/INVOICE_REPORT_UPDATE_SUCCESS';
export const INVOICE_REPORT_UPDATE_ERROR = 'report/INVOICE_REPORT_UPDATE_ERROR';
export const INVOICE_REPORT_UPLOAD_FILE_FETCH = 'report/INVOICE_REPORT_UPLOAD_FILE_FETCH';

export const REPORT_FILE_RETRIEVE_FETCH = 'report/REPORT_FILE_RETRIEVE_FETCH';
