/* eslint-disable @typescript-eslint/no-explicit-any, import/no-cycle */
import moment from 'moment';
import {
  DASHBOARD_STATISTIC_FETCH,
  DASHBOARD_STATISTIC_SUCCESS,
  DASHBOARD_EMPLOYEE_ATTENDANCE_FETCH,
  DASHBOARD_EMPLOYEE_ATTENDANCE_SUCCESS,
  DASHBOARD_DOWNLOAD_ATTENDANCE_FETCH,
  DASHBOARD_DOWNLOAD_ATTENDANCE_ERROR,
  DASHBOARD_DOWNLOAD_ATTENDANCE_SUCCESS,
} from './constants';

export interface IDashboardStatisticFetch {
  type: typeof DASHBOARD_STATISTIC_FETCH;
  payload: {
    dateFrom: number;
    dateTo: number;
    type: string;
    eid: string;
  };
}

export interface IDashboardStatisticSuccess {
  type: typeof DASHBOARD_STATISTIC_SUCCESS;
  payload: {
    data: {
      avgLoanSize: number;
      date: string;
      withdrawalAmount: number;
      withdrawalRequestCount: number;
      withdrawalUserCount: number;
    }[];
    avgLoanSize: number;
    withdrawalAmount: number;
    withdrawalRequestCount: number;
    withdrawalUserCount: number;
  };
}

export interface IDashboardAttendanceFetch {
  type: typeof DASHBOARD_EMPLOYEE_ATTENDANCE_FETCH;
  payload: {
    page: number;
    dateFrom: number;
    dateTo: number;
    type: string;
    date?: moment.Moment;
    eid?: string;
    name?: string;
  };
}

export interface IDashboardAttendanceSuccess {
  type: typeof DASHBOARD_EMPLOYEE_ATTENDANCE_SUCCESS;
  payload: {
    records: any;
    count: number;
  };
}
export interface IDashboardDownloadAttendanceFetch {
  type: typeof DASHBOARD_DOWNLOAD_ATTENDANCE_FETCH;
  payload: {
    page: number;
    dateFrom: number;
    dateTo: number;
    type: string;
    outletId?: string;
    date: moment.Moment;
    eid?: string;
    name?: string;
  };
}
export interface IDashboardDownloadAttendanceSuccess {
  type: typeof DASHBOARD_DOWNLOAD_ATTENDANCE_SUCCESS;
}
export interface IDashboardDownloadAttendanceError {
  type: typeof DASHBOARD_DOWNLOAD_ATTENDANCE_ERROR;
}

export type dashboardAction =
  | IDashboardStatisticFetch
  | IDashboardStatisticSuccess
  | IDashboardAttendanceFetch
  | IDashboardAttendanceSuccess
  | IDashboardDownloadAttendanceFetch
  | IDashboardDownloadAttendanceSuccess
  | IDashboardDownloadAttendanceError;

export const dashboardStatisticFetch = (payload: IDashboardStatisticFetch['payload']): IDashboardStatisticFetch => ({
  type: DASHBOARD_STATISTIC_FETCH,
  payload,
});

export const successDashboardStatistic = (
  payload: IDashboardStatisticSuccess['payload']
): IDashboardStatisticSuccess => ({
  type: DASHBOARD_STATISTIC_SUCCESS,
  payload,
});

export const dashboardAttendanceFetch = (payload: IDashboardAttendanceFetch['payload']): IDashboardAttendanceFetch => ({
  type: DASHBOARD_EMPLOYEE_ATTENDANCE_FETCH,
  payload,
});

export const dashboardAttendanceSuccess = (
  payload: IDashboardAttendanceSuccess['payload']
): IDashboardAttendanceSuccess => ({
  type: DASHBOARD_EMPLOYEE_ATTENDANCE_SUCCESS,
  payload,
});

export const dashboardDownloadAttendanceFetch = (
  payload: IDashboardDownloadAttendanceFetch['payload']
): IDashboardDownloadAttendanceFetch => ({
  type: DASHBOARD_DOWNLOAD_ATTENDANCE_FETCH,
  payload,
});

export const dashboardDownloadAttendanceSuccess = (): IDashboardDownloadAttendanceSuccess => ({
  type: DASHBOARD_DOWNLOAD_ATTENDANCE_SUCCESS,
});

export const dashboardDownloadAttendanceError = (): IDashboardDownloadAttendanceError => ({
  type: DASHBOARD_DOWNLOAD_ATTENDANCE_ERROR,
});
