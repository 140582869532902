import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikContextType, FormikProvider } from 'formik';
import { Row, Col, ColProps, Button, Typography, Collapse, Divider } from 'antd';
import { TextInput, TextInputProp } from 'components/TextInput';
import { SelectInput, SelectInputProp } from 'components/SelectInput';
import { DatePickerInput, DatePickerInputProp } from 'components/DatePickerInput';
import { RangePickerInput, RangePickerInputProp } from 'components/RangePickerInput';
import { Colors } from 'common/styles';
import './style.less';

const { Panel } = Collapse;

const { Link } = Typography;

interface ISearchbarTextInputProps {
  type: 'text';
  colProps?: ColProps;
  inputProps: TextInputProp;
}

interface ISearchbarSelectInputProps {
  type: 'select';
  colProps?: ColProps;
  inputProps: SelectInputProp;
}

interface ISearchbarDateInputProps {
  type: 'date';
  colProps?: ColProps;
  inputProps: DatePickerInputProp;
}

interface ISearchbarDateRangeInputProps {
  type: 'date-range';
  colProps?: ColProps;
  inputProps: RangePickerInputProp;
}

interface ISearchbarEmptyInputProps {
  type: 'empty-col';
  colProps?: ColProps;
}

export type ISearchbarInputProps =
  | ISearchbarTextInputProps
  | ISearchbarSelectInputProps
  | ISearchbarDateInputProps
  | ISearchbarDateRangeInputProps
  | ISearchbarEmptyInputProps;

export interface ISearchbarProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikContextType<any>;
  fields: Array<ISearchbarInputProps>;
  advanceFields?: Array<ISearchbarInputProps>;
  displaySearchButton?: boolean;
  displayClearAllButton?: boolean;
  handleSearch?: React.MouseEventHandler;
  handleClearAll?: React.MouseEventHandler;
}

export const Searchbar: React.FC<ISearchbarProps> = (props) => {
  const { formik, fields, advanceFields, displaySearchButton, displayClearAllButton, handleSearch, handleClearAll } =
    props;
  const { t } = useTranslation();
  const [enableAdvanceFilters, setEnableAdvanceFilters] = useState<boolean>(false);

  // Function
  const toggleAdvanceFilters = () => {
    setEnableAdvanceFilters((previousState) => !previousState);
  };

  const renderInputField = (searchbarInputProps: ISearchbarInputProps) => {
    let field: React.ReactNode;
    switch (searchbarInputProps.type) {
      case 'text':
        field = (
          <Col key={null} {...searchbarInputProps.colProps}>
            <TextInput {...searchbarInputProps.inputProps} />
          </Col>
        );
        break;
      case 'select':
        field = (
          <Col key={null} {...searchbarInputProps.colProps}>
            <SelectInput {...searchbarInputProps.inputProps} />
          </Col>
        );
        break;
      case 'date':
        field = (
          <Col key={null} {...searchbarInputProps.colProps}>
            <DatePickerInput {...searchbarInputProps.inputProps} />
          </Col>
        );
        break;
      case 'date-range':
        field = (
          <Col key={null} {...searchbarInputProps.colProps}>
            <RangePickerInput {...searchbarInputProps.inputProps} />
          </Col>
        );
        break;
      case 'empty-col':
        field = <Col key={null} {...searchbarInputProps.colProps} />;
        break;
      default:
        break;
    }
    return field;
  };

  const fieldsComponents: Array<React.ReactNode> = fields.map((fieldProps: ISearchbarInputProps) =>
    renderInputField(fieldProps)
  );

  const fieldSearchButton = displaySearchButton ? (
    <Col span={3}>
      <Button
        type="primary"
        style={{ color: '#1E293B', width: '100%' }}
        loading={formik.isSubmitting}
        onClick={(e) => (handleSearch ? handleSearch(e) : formik.handleSubmit())}
        ghost
      >
        {t('label.search')}
      </Button>
    </Col>
  ) : undefined;

  const fieldAdvanceFilterButton = advanceFields?.length ? (
    <Col
      span={3}
      style={{
        textAlign: 'right',
        marginBottom: 8,
        borderRight: displayClearAllButton ? '1px solid rgba(0, 0, 0, 0.06)' : undefined,
      }}
    >
      <Link style={{ width: '100%' }} onClick={() => toggleAdvanceFilters()}>
        {enableAdvanceFilters ? t('label.hideAdvanceFilter') : t('label.advanceFilter')}
      </Link>
    </Col>
  ) : undefined;

  const fieldClearAllButton = displayClearAllButton ? (
    <Col span={3} style={{ textAlign: 'center', marginBottom: 8 }}>
      <Link
        style={{ width: '100%', color: Colors.grey700 }}
        onClick={(e) => (handleClearAll ? handleClearAll(e) : formik.resetForm())}
      >
        {t('label.clearAll')}
      </Link>
    </Col>
  ) : undefined;

  const advanceFieldsComponents: Array<React.ReactNode> | undefined = advanceFields?.map(
    (fieldProps: ISearchbarInputProps) => renderInputField(fieldProps)
  );

  return (
    <FormikProvider value={formik}>
      <Row gutter={24}>
        {fieldsComponents}
        {fieldSearchButton}
      </Row>
      <Row gutter={24} justify="end">
        {fieldAdvanceFilterButton}
        {/* <Divider type="vertical" style={{ height: '22px', marginTop }} /> */}
        {fieldClearAllButton}
      </Row>
      <Collapse className="searchbar-advance-filter-collapse" activeKey={enableAdvanceFilters ? 0 : undefined}>
        <Panel key={0} header={undefined}>
          <Row gutter={24}>{advanceFieldsComponents}</Row>
        </Panel>
      </Collapse>
    </FormikProvider>
  );
};

Searchbar.defaultProps = {
  displaySearchButton: true,
  displayClearAllButton: true,
};
