export interface IEmployerOutletDataState {
  id: string;
  eid: string;
  name: string;
  address: string;
  email: string;
  companyName: string;
  location: {
    lat: number;
    long: number;
  };
  qr: {
    qrImage: string;
    qrImageTemplate: string;
  };
  phoneNumber: string;
  employees: {
    id: string;
    name: string;
    actId?: string;
  }[];
  employerOutletId: string;
  status: string;
  isHq: boolean;
  loading: boolean;
}

export interface IEmployerOutletPayloadState {
  id?: string;
  eid: string;
  name: string;
  address: string;
  companyName: string;
  location: {
    lat: number;
    long: number;
  };
  qr: {
    qrImage: string;
    qrImageTemplate: string;
  };
  phoneNumber: string;
  employees: {
    id: string;
    name: string;
  }[];
  employerOutletId: string;
  status: string;
  loading: boolean;
}

export const employerOutletDataState: IEmployerOutletDataState = {
  id: '',
  eid: '',
  name: '',
  address: '',
  email: '',
  companyName: '',
  location: {
    lat: 0,
    long: 0,
  },
  qr: {
    qrImage: '',
    qrImageTemplate: '',
  },
  phoneNumber: '',
  employees: [],
  employerOutletId: '',
  status: '',
  isHq: false,
  loading: false,
};
