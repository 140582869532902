/* eslint-disable @typescript-eslint/no-explicit-any */
import { EmploymentStatus } from 'common';
import {
  NEW_EMPLOYEE_REQUEST_LIST_FETCH,
  NEW_EMPLOYEE_REQUEST_LIST_SUCCESS,
  NEW_EMPLOYEE_REQUEST_INFO_FETCH,
  NEW_EMPLOYEE_REQUEST_INFO_SUCCESS,
  NEW_EMPLOYEE_REQUEST_INFO_ERROR,
  NEW_EMPLOYEE_REQUEST_UPDATE_FETCH,
  NEW_EMPLOYEE_REQUEST_UPDATE_SUCCESS,
  NEW_EMPLOYEE_REQUEST_UPDATE_ERROR,
} from './constants';

export interface INewEmployeeRequestFetch {
  type: typeof NEW_EMPLOYEE_REQUEST_LIST_FETCH;
  payload: {
    from?: string;
    to?: string;
    mobile?: string;
    employeeId?: string;
    name?: string;
    status?: string;
    page: number;
  };
}

export interface INewEmployeeRequestSuccess {
  type: typeof NEW_EMPLOYEE_REQUEST_LIST_SUCCESS;
  payload: {
    records: any;
    count: number;
    currentPage: number;
  };
}

export interface INewEmployeeRequestInfoFetch {
  type: typeof NEW_EMPLOYEE_REQUEST_INFO_FETCH;
  payload: {
    ebid: string;
    eid: string;
  };
}

export interface INewEmployeeRequestInfoSuccess {
  type: typeof NEW_EMPLOYEE_REQUEST_INFO_SUCCESS;
  payload: {
    requestDate: string;
    status: EmploymentStatus;
    companyName: string;
    employeeId: string;
    mobileNumber: string;
    companyEmail: string;
    salary: string;
    salaryFrequency: string;
    salaryDay: string;
    reason: string;
  };
}

export interface INewEmployeeRequestInfoError {
  type: typeof NEW_EMPLOYEE_REQUEST_INFO_ERROR;
  payload: any;
}

export interface IUpdateNewEmployeeRequestFetch {
  type: typeof NEW_EMPLOYEE_REQUEST_UPDATE_FETCH;
  payload: {
    id: string;
    eid: string;
    ebid: string;
    status: string;
    employeeId?: string;
    name?: string;
    mobile?: string;
    email?: string;
    salary?: string;
    salaryFrequency?: string;
    salaryDay?: string;
    reason?: string;
  };
  onSuccess?: () => void;
  onError?: () => void;
}

export interface IUpdateNewEmployeeRequestSuccess {
  type: typeof NEW_EMPLOYEE_REQUEST_UPDATE_SUCCESS;
}

export interface IUpdateNewEmployeeRequestError {
  type: typeof NEW_EMPLOYEE_REQUEST_UPDATE_ERROR;
  payload: any;
}

export type newEmployeeRequestAction =
  | INewEmployeeRequestFetch
  | INewEmployeeRequestSuccess
  | INewEmployeeRequestInfoFetch
  | INewEmployeeRequestInfoSuccess
  | INewEmployeeRequestInfoError
  | IUpdateNewEmployeeRequestFetch
  | IUpdateNewEmployeeRequestSuccess
  | IUpdateNewEmployeeRequestError;

export const newEmployeeRequestList = (payload: INewEmployeeRequestFetch['payload']): INewEmployeeRequestFetch => ({
  type: NEW_EMPLOYEE_REQUEST_LIST_FETCH,
  payload,
});

export const successNewEmployeeRequestList = (
  payload: INewEmployeeRequestSuccess['payload']
): INewEmployeeRequestSuccess => ({
  type: NEW_EMPLOYEE_REQUEST_LIST_SUCCESS,
  payload,
});

export const newEmployeeRequestInfo = (
  payload: INewEmployeeRequestInfoFetch['payload']
): INewEmployeeRequestInfoFetch => ({
  type: NEW_EMPLOYEE_REQUEST_INFO_FETCH,
  payload,
});

export const successNewEmployeeRequestInfo = (
  payload: INewEmployeeRequestInfoSuccess['payload']
): INewEmployeeRequestInfoSuccess => ({
  type: NEW_EMPLOYEE_REQUEST_INFO_SUCCESS,
  payload,
});

export const errorNewEmployeeRequestInfo = (
  payload: INewEmployeeRequestInfoError['payload']
): INewEmployeeRequestInfoError => ({
  type: NEW_EMPLOYEE_REQUEST_INFO_ERROR,
  payload,
});

export const updateNewEmployeeRequest = (
  payload: IUpdateNewEmployeeRequestFetch['payload'],
  onSuccess?: IUpdateNewEmployeeRequestFetch['onSuccess'],
  onError?: IUpdateNewEmployeeRequestFetch['onError']
): IUpdateNewEmployeeRequestFetch => ({
  type: NEW_EMPLOYEE_REQUEST_UPDATE_FETCH,
  payload,
  onSuccess,
  onError,
});

export const successUpdateNewEmployeeRequest = (): IUpdateNewEmployeeRequestSuccess => ({
  type: NEW_EMPLOYEE_REQUEST_UPDATE_SUCCESS,
});

export const errorUpdateNewEmployeeRequest = (
  payload: IUpdateNewEmployeeRequestError['payload']
): IUpdateNewEmployeeRequestError => ({
  type: NEW_EMPLOYEE_REQUEST_UPDATE_ERROR,
  payload,
});
