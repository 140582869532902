import { Modal } from 'antd';
import React, { CSSProperties, ReactNode } from 'react';

export interface AntdModalProp {
  visible: boolean;
  children: ReactNode;
  style?: CSSProperties;
  maskStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
  footer?: ReactNode;
  title?: string | ReactNode;
  className?: string;
  wrapClassName?: string;
  okText?: string;
  cancelText?: string;
  zIndex?: number;
  closable?: boolean;
  centered?: boolean;
  keyboard?: boolean;
  maskClosable?: boolean;
  mask?: boolean;
  width?: string | number;
  onOk?: (e: React.MouseEvent<HTMLElement>) => void;
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
  afterClose?: () => void;
}

export const AntdModal: React.FC<AntdModalProp> = ({
  style,
  className,
  wrapClassName,
  bodyStyle,
  centered,
  closable,
  footer,
  title,
  okText,
  cancelText,
  onOk,
  onCancel,
  visible,
  width,
  zIndex,
  maskClosable,
  mask,
  maskStyle,
  afterClose,
  keyboard,
  children,
}) => {
  return (
    <Modal
      style={style}
      className={className}
      wrapClassName={wrapClassName}
      bodyStyle={bodyStyle}
      centered={centered}
      closable={closable}
      footer={footer}
      title={title}
      okText={okText}
      cancelText={cancelText}
      onOk={onOk}
      onCancel={onCancel}
      open={visible}
      width={width}
      zIndex={zIndex}
      maskClosable={maskClosable}
      mask={mask}
      maskStyle={maskStyle}
      afterClose={afterClose}
      keyboard={keyboard}
    >
      {children}
    </Modal>
  );
};
