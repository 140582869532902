/* eslint-disable @typescript-eslint/no-explicit-any */
import { employerOutletAction } from './actions';
import {
  CREATE_EMPLOYER_OUTLET_ERROR,
  CREATE_EMPLOYER_OUTLET_FETCH,
  CREATE_EMPLOYER_OUTLET_RESET,
  CREATE_EMPLOYER_OUTLET_SUCCESS,
  EMPLOYER_OUTLET_INFO_ERROR,
  EMPLOYER_OUTLET_INFO_FETCH,
  EMPLOYER_OUTLET_INFO_RESET,
  EMPLOYER_OUTLET_INFO_SUCCESS,
  EMPLOYER_OUTLET_LIST_FETCH,
  EMPLOYER_OUTLET_LIST_SUCCESS,
  UPDATE_EMPLOYER_OUTLET_ERROR,
  UPDATE_EMPLOYER_OUTLET_FETCH,
  UPDATE_EMPLOYER_OUTLET_RESET,
  UPDATE_EMPLOYER_OUTLET_SUCCESS,
} from './constants';
import { employerOutletDataState, IEmployerOutletDataState } from './types';

export interface IEmployerOutletState {
  outletList: {
    list: any;
    count: number;
    loading: boolean;
    payload: {
      eid?: string;
      name?: string;
      companyName?: string;
      page: number;
    };
  };
  outletData: IEmployerOutletDataState;
  createOutlet: {
    loading: boolean;
    success?: boolean;
  };
  updateOutlet: {
    loading: boolean;
    success?: boolean;
  };
}

const initialState: IEmployerOutletState = {
  outletList: {
    list: [],
    count: 0,
    loading: false,
    payload: {
      page: 1,
    },
  },
  outletData: employerOutletDataState,
  createOutlet: {
    loading: false,
  },
  updateOutlet: {
    loading: false,
  },
};

const employerOutletListReducer = (state: IEmployerOutletState['outletList'], action: employerOutletAction) => {
  switch (action.type) {
    case EMPLOYER_OUTLET_LIST_FETCH:
      return {
        ...state,
        loading: true,
        payload: action.payload,
      };
    case EMPLOYER_OUTLET_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.records,
        count: action.payload.count,
        loading: false,
      };
    default:
      return state;
  }
};

const employerOutletDataReducer = (state: IEmployerOutletState['outletData'], action: employerOutletAction) => {
  switch (action.type) {
    case EMPLOYER_OUTLET_INFO_RESET:
      return initialState.outletData;
    case EMPLOYER_OUTLET_INFO_FETCH:
      return {
        ...state,
        loading: true,
      };
    case EMPLOYER_OUTLET_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case EMPLOYER_OUTLET_INFO_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

const createEmployerOutletReducer = (state: IEmployerOutletState['createOutlet'], action: employerOutletAction) => {
  switch (action.type) {
    case CREATE_EMPLOYER_OUTLET_RESET:
      return initialState.createOutlet;
    case CREATE_EMPLOYER_OUTLET_FETCH:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case CREATE_EMPLOYER_OUTLET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case CREATE_EMPLOYER_OUTLET_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
      };
    default:
      return state;
  }
};

const updateEmployerOutletReducer = (state: IEmployerOutletState['updateOutlet'], action: employerOutletAction) => {
  switch (action.type) {
    case UPDATE_EMPLOYER_OUTLET_RESET:
      return initialState.createOutlet;
    case UPDATE_EMPLOYER_OUTLET_FETCH:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case UPDATE_EMPLOYER_OUTLET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case UPDATE_EMPLOYER_OUTLET_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last
export const employerOutletReducer = (state = initialState, action: employerOutletAction) => {
  switch (action.type) {
    case EMPLOYER_OUTLET_LIST_FETCH:
    case EMPLOYER_OUTLET_LIST_SUCCESS:
      return {
        ...state,
        outletList: employerOutletListReducer({ ...state.outletList }, action),
      };
    case EMPLOYER_OUTLET_INFO_RESET:
    case EMPLOYER_OUTLET_INFO_FETCH:
    case EMPLOYER_OUTLET_INFO_SUCCESS:
    case EMPLOYER_OUTLET_INFO_ERROR:
      return {
        ...state,
        outletData: employerOutletDataReducer({ ...state.outletData }, action),
      };
    case CREATE_EMPLOYER_OUTLET_RESET:
    case CREATE_EMPLOYER_OUTLET_FETCH:
    case CREATE_EMPLOYER_OUTLET_SUCCESS:
    case CREATE_EMPLOYER_OUTLET_ERROR:
      return {
        ...state,
        createOutlet: createEmployerOutletReducer({ ...state.createOutlet }, action),
      };
    case UPDATE_EMPLOYER_OUTLET_RESET:
    case UPDATE_EMPLOYER_OUTLET_FETCH:
    case UPDATE_EMPLOYER_OUTLET_SUCCESS:
    case UPDATE_EMPLOYER_OUTLET_ERROR:
      return {
        ...state,
        updateOutlet: updateEmployerOutletReducer({ ...state.updateOutlet }, action),
      };
    default:
      return state;
  }
};
