/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import {
  UPLOAD_FILE_REPORT_FETCH,
  UPLOAD_FILE_REPORT_SUCCESS,
  UPLOAD_FILE_REPORT_ERROR,
  EMPLOYEE_INFO_UPDATE_REPORT_LIST_SUCCESS,
  BANK_REPORT_LIST_SUCCESS,
  EXCEPTIONAL_FILE_REPORT_LIST_SUCCESS,
  RECONCILIATION_REPORT_LIST_SUCCESS,
  LAPSE_REPAYMENT_REPORT_LIST_FETCH,
  LAPSE_REPAYMENT_REPORT_LIST_SUCCESS,
  UPDATE_LAPSE_REPAYMENT_REPORT_ERROR,
  UPDATE_LAPSE_REPAYMENT_REPORT_SUCCESS,
  UPDATE_LAPSE_REPAYMENT_REPORT_FETCH,
  LAPSE_REPAYMENT_SEND_EMAIL_FETCH,
  REPORT_LIST_RESET,
  BANK_REPORT_LIST_FETCH,
  RECONCILIATION_REPORT_LIST_FETCH,
  EMPLOYEE_INFO_UPDATE_REPORT_LIST_FETCH,
  EXCEPTIONAL_FILE_REPORT_LIST_FETCH,
  EMPLOYER_REPORT_LIST_SUCCESS,
  EMPLOYER_REPORT_LIST_FETCH,
  EMPLOYER_REPORT_UPDATE_SUCCESS,
  EMPLOYER_REPORT_UPDATE_ERROR,
  EMPLOYER_REPORT_UPDATE_FETCH,
  EMPLOYER_REPORT_UPLOAD_FILE_FETCH,
  IBG_ENQUIRY_LIST_FETCH,
  IBG_ENQUIRY_LIST_SUCCESS,
  INVOICE_REPORT_LIST_FETCH,
  INVOICE_REPORT_LIST_FETCH_SUCCESS,
  INVOICE_REPORT_UPDATE_FETCH,
  INVOICE_REPORT_UPDATE_SUCCESS,
  INVOICE_REPORT_UPDATE_ERROR,
  INVOICE_REPORT_UPLOAD_FILE_FETCH,
  REPORT_FILE_RETRIEVE_FETCH,
} from './constants';

export interface IReportListReset {
  type: typeof REPORT_LIST_RESET;
}

export interface IReportListFetch {
  type:
    | typeof BANK_REPORT_LIST_FETCH
    | typeof EXCEPTIONAL_FILE_REPORT_LIST_FETCH
    | typeof EMPLOYEE_INFO_UPDATE_REPORT_LIST_FETCH
    | typeof RECONCILIATION_REPORT_LIST_FETCH
    | typeof EMPLOYER_REPORT_LIST_FETCH
    | typeof IBG_ENQUIRY_LIST_FETCH
    | typeof INVOICE_REPORT_LIST_FETCH;
  payload: {
    from?: number;
    to?: number;
    type?: string;
    createdDate?: string | moment.Moment;
    updatedDate?: string;
    serviceProvider?: string;
    eid?: number;
    page?: number;
  };
}

export interface IReportListSuccess {
  type:
    | typeof BANK_REPORT_LIST_SUCCESS
    | typeof EXCEPTIONAL_FILE_REPORT_LIST_SUCCESS
    | typeof EMPLOYEE_INFO_UPDATE_REPORT_LIST_SUCCESS
    | typeof RECONCILIATION_REPORT_LIST_SUCCESS
    | typeof LAPSE_REPAYMENT_REPORT_LIST_SUCCESS
    | typeof EMPLOYER_REPORT_LIST_SUCCESS
    | typeof IBG_ENQUIRY_LIST_SUCCESS
    | typeof INVOICE_REPORT_LIST_FETCH_SUCCESS;
  payload: {
    count?: number;
    records: any;
  };
}

export interface IUploadFileReportFetch {
  type: typeof UPLOAD_FILE_REPORT_FETCH;
  payload: {
    data: string;
    fileName: string;
    type: string;
  };
  onSuccess?: () => void;
  onError?: () => void;
}

export interface IUploadFileReportSuccess {
  type: typeof UPLOAD_FILE_REPORT_SUCCESS;
}

export interface IUploadFileReportError {
  type: typeof UPLOAD_FILE_REPORT_ERROR;
  payload: any;
}

export interface ILapseRepaymentReportListFetch {
  type: typeof LAPSE_REPAYMENT_REPORT_LIST_FETCH;
  payload: {
    from?: number;
    to?: number;
    eid?: string;
    status?: string;
  };
}

export interface IUpdateLapseRepaymentFetch {
  type: typeof UPDATE_LAPSE_REPAYMENT_REPORT_FETCH;
  payload: {
    id: string;
    ebid: string;
    status: string;
    reason?: string;
  };
}

export interface ILapseRepaymentSendEmailFetch {
  type: typeof LAPSE_REPAYMENT_SEND_EMAIL_FETCH;
  payload: {
    ebid: string;
  };
}

export interface IUpdateLapseRepaymentSuccess {
  type: typeof UPDATE_LAPSE_REPAYMENT_REPORT_SUCCESS;
}

export interface IUpdateLapseRepaymentError {
  type: typeof UPDATE_LAPSE_REPAYMENT_REPORT_ERROR;
}

export interface IEmployerReportUpdateFetch {
  type: typeof EMPLOYER_REPORT_UPDATE_FETCH;
  payload: {
    employerReportId?: string;
    status?: string;
  };
}

export interface IEmployerReportUpdateSuccess {
  type: typeof EMPLOYER_REPORT_UPDATE_SUCCESS;
}

export interface IEmployerReportUpdateError {
  type: typeof EMPLOYER_REPORT_UPDATE_ERROR;
}

export interface IEmployerReportUploadFileFetch {
  type: typeof EMPLOYER_REPORT_UPLOAD_FILE_FETCH;
  payload: {
    eid: string;
    fileName: string;
    data: string;
    type: string;
  };
  onSuccess?: () => void;
  onError?: () => void;
}

export interface IInvoiceReportUpdateFetch {
  type: typeof INVOICE_REPORT_UPDATE_FETCH;
  payload: {
    invoiceId?: string;
    status?: string;
  };
}

export interface IInvoiceReportUpdateSuccess {
  type: typeof INVOICE_REPORT_UPDATE_SUCCESS;
}

export interface IInvoiceReportUpdateError {
  type: typeof INVOICE_REPORT_UPDATE_ERROR;
}

export interface IInvoiceReportUploadFileFetch {
  type: typeof INVOICE_REPORT_UPLOAD_FILE_FETCH;
  payload: {
    eid: string;
    fileName: string;
    data: string;
    type: string;
  };
  onSuccess?: () => void;
  onError?: () => void;
}

export interface IReportFileRetrieveFetch {
  type: typeof REPORT_FILE_RETRIEVE_FETCH;
  payload: {
    fileId: string;
    type: string;
    bank?: string;
    eid?: string;
  };
}

export type reportAction =
  | IReportListReset
  | IReportListFetch
  | IReportListSuccess
  | IUploadFileReportFetch
  | IUploadFileReportSuccess
  | IUploadFileReportError
  | ILapseRepaymentReportListFetch
  | IUpdateLapseRepaymentFetch
  | IUpdateLapseRepaymentSuccess
  | IUpdateLapseRepaymentError
  | ILapseRepaymentSendEmailFetch
  | IEmployerReportUpdateFetch
  | IEmployerReportUpdateSuccess
  | IEmployerReportUpdateError
  | IEmployerReportUploadFileFetch
  | IInvoiceReportUpdateFetch
  | IInvoiceReportUpdateSuccess
  | IInvoiceReportUpdateError
  | IInvoiceReportUploadFileFetch
  | IReportFileRetrieveFetch;

export const reportListReset = (): IReportListReset => ({
  type: REPORT_LIST_RESET,
});

export const bankReportList = (payload: IReportListFetch['payload']): IReportListFetch => ({
  type: BANK_REPORT_LIST_FETCH,
  payload,
});

export const successBankReportList = (payload: IReportListSuccess['payload']): IReportListSuccess => ({
  type: BANK_REPORT_LIST_SUCCESS,
  payload,
});

export const exceptionalFileReportList = (payload: IReportListFetch['payload']): IReportListFetch => ({
  type: EXCEPTIONAL_FILE_REPORT_LIST_FETCH,
  payload,
});

export const successExceptionalFileReportList = (payload: IReportListSuccess['payload']): IReportListSuccess => ({
  type: EXCEPTIONAL_FILE_REPORT_LIST_SUCCESS,
  payload,
});

export const reconciliationReportList = (payload: IReportListFetch['payload']): IReportListFetch => ({
  type: RECONCILIATION_REPORT_LIST_FETCH,
  payload,
});

export const successReconciliationReportList = (payload: IReportListSuccess['payload']): IReportListSuccess => ({
  type: RECONCILIATION_REPORT_LIST_SUCCESS,
  payload,
});

export const employeeInfoUpdateReportList = (payload: IReportListFetch['payload']): IReportListFetch => ({
  type: EMPLOYEE_INFO_UPDATE_REPORT_LIST_FETCH,
  payload,
});

export const successEmployeeInfoUpdateReportList = (payload: IReportListSuccess['payload']): IReportListSuccess => ({
  type: EMPLOYEE_INFO_UPDATE_REPORT_LIST_SUCCESS,
  payload,
});

export const employerReportListFetch = (payload: IReportListFetch['payload']): IReportListFetch => ({
  type: EMPLOYER_REPORT_LIST_FETCH,
  payload,
});

export const ibgEnquiryListFetch = (payload: IReportListFetch['payload']): IReportListFetch => ({
  type: IBG_ENQUIRY_LIST_FETCH,
  payload,
});

export const ibgEnquiryListSuccess = (payload: IReportListSuccess['payload']): IReportListSuccess => ({
  type: IBG_ENQUIRY_LIST_SUCCESS,
  payload,
});

export const employerReportListSuccess = (payload: IReportListSuccess['payload']): IReportListSuccess => ({
  type: EMPLOYER_REPORT_LIST_SUCCESS,
  payload,
});

export const uploadFileReport = (
  payload: IUploadFileReportFetch['payload'],
  onSuccess?: () => void,
  onError?: () => void
): IUploadFileReportFetch => ({
  type: UPLOAD_FILE_REPORT_FETCH,
  payload,
  onSuccess,
  onError,
});

export const successUploadFileReport = (): IUploadFileReportSuccess => ({
  type: UPLOAD_FILE_REPORT_SUCCESS,
});

export const errorUploadFileReport = (payload: IUploadFileReportError['payload']): IUploadFileReportError => ({
  type: UPLOAD_FILE_REPORT_ERROR,
  payload,
});

export const lapseRepaymentReportList = (
  payload: ILapseRepaymentReportListFetch['payload']
): ILapseRepaymentReportListFetch => ({
  type: LAPSE_REPAYMENT_REPORT_LIST_FETCH,
  payload,
});

export const successLapseRepaymentReportList = (payload: IReportListSuccess['payload']): IReportListSuccess => ({
  type: LAPSE_REPAYMENT_REPORT_LIST_SUCCESS,
  payload,
});

export const updateLapseRepayment = (payload: IUpdateLapseRepaymentFetch['payload']): IUpdateLapseRepaymentFetch => ({
  type: UPDATE_LAPSE_REPAYMENT_REPORT_FETCH,
  payload,
});

export const successUpdateLapseRepayment = (): IUpdateLapseRepaymentSuccess => ({
  type: UPDATE_LAPSE_REPAYMENT_REPORT_SUCCESS,
});

export const errorUpdateLapseRepayment = (): IUpdateLapseRepaymentError => ({
  type: UPDATE_LAPSE_REPAYMENT_REPORT_ERROR,
});

export const lapseRepaymentSendEmail = (
  payload: ILapseRepaymentSendEmailFetch['payload']
): ILapseRepaymentSendEmailFetch => ({
  type: LAPSE_REPAYMENT_SEND_EMAIL_FETCH,
  payload,
});

export const employerReportUpdateFetch = (
  payload: IEmployerReportUpdateFetch['payload']
): IEmployerReportUpdateFetch => ({
  type: EMPLOYER_REPORT_UPDATE_FETCH,
  payload,
});

export const employerReportUpdateSuccess = (): IEmployerReportUpdateSuccess => ({
  type: EMPLOYER_REPORT_UPDATE_SUCCESS,
});

export const employerReportUpdateError = (): IEmployerReportUpdateError => ({
  type: EMPLOYER_REPORT_UPDATE_ERROR,
});

export const employerReportUploadFileFetch = (
  payload: IEmployerReportUploadFileFetch['payload'],
  onSuccess?: () => void,
  onError?: () => void
): IEmployerReportUploadFileFetch => ({
  type: EMPLOYER_REPORT_UPLOAD_FILE_FETCH,
  payload,
  onSuccess,
  onError,
});

export const invoiceReportListFetch = (payload: IReportListFetch['payload']): IReportListFetch => ({
  type: INVOICE_REPORT_LIST_FETCH,
  payload,
});

export const invoiceReportListFetchSuccess = (payload: IReportListSuccess['payload']): IReportListSuccess => ({
  type: INVOICE_REPORT_LIST_FETCH_SUCCESS,
  payload,
});

export const invoiceReportUpdateFetch = (payload: IInvoiceReportUpdateFetch['payload']): IInvoiceReportUpdateFetch => ({
  type: INVOICE_REPORT_UPDATE_FETCH,
  payload,
});

export const invoiceReportUpdateSuccess = (): IInvoiceReportUpdateSuccess => ({
  type: INVOICE_REPORT_UPDATE_SUCCESS,
});

export const invoiceReportUpdateError = (): IInvoiceReportUpdateError => ({
  type: INVOICE_REPORT_UPDATE_ERROR,
});

export const invoiceReportUploadFileFetch = (
  payload: IInvoiceReportUploadFileFetch['payload'],
  onSuccess?: () => void,
  onError?: () => void
): IInvoiceReportUploadFileFetch => ({
  type: INVOICE_REPORT_UPLOAD_FILE_FETCH,
  payload,
  onSuccess,
  onError,
});

export const reportFileRetrieveFetch = (payload: IReportFileRetrieveFetch['payload']): IReportFileRetrieveFetch => ({
  type: REPORT_FILE_RETRIEVE_FETCH,
  payload,
});
