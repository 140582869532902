/* eslint-disable no-shadow, @typescript-eslint/no-explicit-any */

enum ServiceProvider {
  PAYWATCHMALAYSIA = 'paywatch',
  HLB = 'hongLeongBank',
}

const serviceProviderLabel: any = {
  [ServiceProvider.PAYWATCHMALAYSIA]: 'label.paywatchMalaysia',
  [ServiceProvider.HLB]: 'label.hlb',
};

const serviceProviderSelect: { label: string; value: ServiceProvider }[] = [
  { label: serviceProviderLabel[ServiceProvider.PAYWATCHMALAYSIA], value: ServiceProvider.PAYWATCHMALAYSIA },
  { label: serviceProviderLabel[ServiceProvider.HLB], value: ServiceProvider.HLB },
];

export { ServiceProvider, serviceProviderLabel, serviceProviderSelect };
