import { CountryCode } from 'common';
import { UserAction } from './actions';
import { SET_IS_LOGGED_IN, SET_USERS_CREDENTIALS, UPDATE_USERS_CREDENTIALS } from './constants';

export interface IUserState {
  isLoggedIn: boolean;
  refreshToken: string;
  expiredOn: number;
  accessToken: string;
  userProfile: {
    email: string;
    name: string;
  };
  eid: string | undefined;
  employers: {
    eid: string;
    companyName: string;
    name: string;
    country: CountryCode;
    role: string;
  }[];
  aid: string;
  permissions: string[];
  firstTimeLogin: boolean;
}

export const initialUserState: IUserState = {
  isLoggedIn: false,
  refreshToken: '',
  expiredOn: 0,
  accessToken: '',
  userProfile: {
    email: '',
    name: '',
  },
  eid: undefined,
  employers: [],
  aid: '',
  permissions: [],
  firstTimeLogin: false,
};

// eslint-disable-next-line default-param-last
export const userReducer = (state = initialUserState, action: UserAction) => {
  switch (action.type) {
    case SET_USERS_CREDENTIALS:
      return { ...state, ...action.payload };
    case UPDATE_USERS_CREDENTIALS:
      return { ...state, ...action.payload };
    case SET_IS_LOGGED_IN:
      return { ...state, isLoggedIn: action.payload };
    default:
      return state;
  }
};
