/* eslint-disable @typescript-eslint/no-explicit-any */
import { notificationAction } from './actions';
import {
  NOTIFICATION_LIST_FETCH,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_READ_FETCH,
  NOTIFICATION_READ_SUCCESS,
  NOTIFICATION_READ_ERROR,
} from './constants';

export interface INotificationState {
  notification: {
    list: any;
  };
  notificationRead: {
    loading: boolean;
    success: boolean;
    error?: boolean;
  };
  notificationUnreadCount: number;
  bulkImportPendingCount?: number;
  employerRequestPendingCount?: number;
}

const initialState: INotificationState = {
  notification: {
    list: [],
  },
  notificationRead: {
    loading: false,
    success: false,
  },
  notificationUnreadCount: 0,
  bulkImportPendingCount: 0,
  employerRequestPendingCount: 0,
};

const notificationListReducer = (state: INotificationState['notification'], action: notificationAction) => {
  switch (action.type) {
    case NOTIFICATION_LIST_FETCH:
      return {
        ...state,
      };
    case NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        notification: {
          list: action.payload.records,
        },
        notificationUnreadCount: action.payload.count,
        bulkImportPendingCount: action.payload.bulkImportPendingCount,
        employerRequestPendingCount: action.payload.employerRequestPendingCount,
      };
    default:
      return state;
  }
};

const notificationReadReducer = (state: INotificationState['notificationRead'], action: notificationAction) => {
  switch (action.type) {
    case NOTIFICATION_READ_FETCH:
    case NOTIFICATION_READ_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last
export const notificationReducer = (state = initialState, action: notificationAction) => {
  switch (action.type) {
    case NOTIFICATION_LIST_FETCH:
    case NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        ...notificationListReducer({ ...state.notification }, action),
      };
    case NOTIFICATION_READ_FETCH:
    case NOTIFICATION_READ_SUCCESS:
    case NOTIFICATION_READ_ERROR:
      return {
        ...state,
        notificationRead: notificationReadReducer({ ...state.notificationRead }, action),
      };
    default:
      return state;
  }
};
