/* eslint-disable no-shadow, @typescript-eslint/no-explicit-any */
enum EmployerLeaveType {
  ANNUAL_LEAVE = 'Annual Leave',
  UNPAID_LEAVE = 'Unpaid Leave',
}

enum LeaveSessionType {
  FULL_SESSION = 'Full session',
  FIRST_SESSION = 'First session',
  SECOND_SESSION = 'Second session',
}

const employeeLeaveTypeSelect: any = [
  { label: 'label.annualLeave', value: EmployerLeaveType.ANNUAL_LEAVE },
  { label: 'label.unpaidLeave', value: EmployerLeaveType.UNPAID_LEAVE },
];

const leaveSessionTypeSelect: any = [
  { label: 'label.fullDay', value: LeaveSessionType.FULL_SESSION },
  { label: 'label.halfDay(AM)', value: LeaveSessionType.FIRST_SESSION },
  { label: 'label.halfDay(PM)', value: LeaveSessionType.SECOND_SESSION },
];

export { EmployerLeaveType, employeeLeaveTypeSelect, LeaveSessionType, leaveSessionTypeSelect };
