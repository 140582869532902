import { takeEvery } from 'redux-saga/effects';
import {
  BULK_UPDATE_FILENAME_SELECT_FETCH,
  BULK_UPDATE_LIST_FETCH,
  BULK_UPDATE_REQUEST_ACTION_FETCH,
  BULK_UPDATE_REQUEST_LIST_FETCH,
  CREATE_BULK_UPDATE_REQUEST_FETCH,
  UPLOAD_BULK_UPDATE_FETCH,
} from '../constants';
import {
  bulkUpdateFilenameSelectSaga,
  bulkUpdateListSaga,
  bulkUpdateRequestActionSaga,
  bulkUpdateRequestListSaga,
  createBulkUpdateRequestSaga,
  uploadBulkUpdateSaga,
} from './bulkUpdateSaga';

export function* rootBulkUpdateSaga() {
  yield takeEvery(UPLOAD_BULK_UPDATE_FETCH, uploadBulkUpdateSaga);
  yield takeEvery(CREATE_BULK_UPDATE_REQUEST_FETCH, createBulkUpdateRequestSaga);
  yield takeEvery(BULK_UPDATE_LIST_FETCH, bulkUpdateListSaga);
  yield takeEvery(BULK_UPDATE_REQUEST_LIST_FETCH, bulkUpdateRequestListSaga);
  yield takeEvery(BULK_UPDATE_REQUEST_ACTION_FETCH, bulkUpdateRequestActionSaga);
  yield takeEvery(BULK_UPDATE_FILENAME_SELECT_FETCH, bulkUpdateFilenameSelectSaga);
}
