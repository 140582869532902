/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import './style.less';
import { Col, Row, Typography, Image } from 'antd';
import { currencyFormatValue } from 'common';
import { Card } from 'components/Card';
import { blueDot, orangeDot, purpleDot, greenDot } from 'assets';

const { Text } = Typography;

export interface MainChartProp {
  propskey: string;
  data: {
    avgLoanSize: number;
    date: string;
    withdrawalAmount: number;
    withdrawalRequestCount: number;
    withdrawalUserCount: number;
  }[];
  dataTypes: {
    dataKey: string;
    strokeColor: string;
  }[];
  type: number;
  dateType: string;
}

export const MainChart: React.FC<MainChartProp> = ({ dateType, type, data, dataTypes, propskey }) => {
  const { t } = useTranslation();

  const tickFormatter = useCallback(
    (tick: any) => {
      switch (dateType) {
        case 'daily':
        case 'date-range':
          return moment(tick, 'DD-MM-YYYY').format('DD-MM');
        default:
          return tick;
      }
    },
    [dateType]
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Card className="custom-tooltip" bodyStyle={{ padding: '5px' }}>
          <p className="mx-2 my-2" style={{ fontSize: '12px' }}>
            {label}
          </p>
          {type === 1 ? (
            <div>
              <Row gutter={[8, 8]} align="middle" className="mb-2">
                <Col span={4} className="d-flex ml-2">
                  <Image preview={false} src={blueDot} />
                </Col>
                <Col className="d-flex flex-column">
                  <Text
                    strong
                    className="mb-1"
                    style={{
                      color: '#1E293B',
                      fontSize: '14px',
                      lineHeight: '16px',
                    }}
                  >
                    {payload[0].value}
                  </Text>
                  <Text
                    style={{
                      color: '#64748B',
                      fontSize: '10px',
                      lineHeight: '11.72px',
                    }}
                  >
                    Withdrawal User
                  </Text>
                </Col>
              </Row>
              <Row gutter={[8, 8]} align="middle">
                <Col span={4} className="d-flex ml-2">
                  <Image preview={false} src={orangeDot} />
                </Col>
                <Col className="d-flex flex-column">
                  <Text
                    strong
                    style={{
                      color: '#1E293B',
                      fontSize: '14px',
                      lineHeight: '16px',
                    }}
                  >
                    {payload[1].value}
                  </Text>
                  <Text
                    style={{
                      color: '#64748B',
                      fontSize: '10px',
                      lineHeight: '11.72px',
                    }}
                  >
                    Withdrawal Request
                  </Text>
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <Row gutter={[8, 8]} align="middle" className="mb-2">
                <Col span={4} className="d-flex ml-2">
                  <Image preview={false} src={purpleDot} />
                </Col>
                <Col>
                  <div className="d-flex flex-column">
                    <Text
                      strong
                      className="mb-1"
                      style={{
                        color: '#1E293B',
                        fontSize: '14px',
                        lineHeight: '16px',
                      }}
                    >
                      RM {currencyFormatValue(payload[0].value, 2)}
                    </Text>
                    <Text
                      style={{
                        color: '#64748B',
                        fontSize: '10px',
                        lineHeight: '11.72px',
                      }}
                    >
                      Withdrawal Amount
                    </Text>
                  </div>
                </Col>
              </Row>
              <Row gutter={[8, 8]} align="middle">
                <Col span={4} className="d-flex ml-2">
                  <Image preview={false} src={greenDot} />
                </Col>
                <Col>
                  <div className="d-flex flex-column">
                    <Text
                      strong
                      style={{
                        color: '#1E293B',
                        fontSize: '14px',
                        lineHeight: '16px',
                      }}
                    >
                      RM {currencyFormatValue(payload[1].value, 2)}
                    </Text>
                    <Text
                      style={{
                        color: '#64748B',
                        fontSize: '10px',
                        lineHeight: '11.72px',
                      }}
                    >
                      Averange Withdrawal
                    </Text>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Card>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={228}>
      {data.length > 0 ? (
        <AreaChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="colorwithdrawalUserCount" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#3B82F6" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#3B82F6" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorwithdrawalRequestCount" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#F97316" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#F97316" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorwithdrawalAmount" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#5D5FEF" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#5D5FEF" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="coloravgLoanSize" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#14B68B" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#14B68B" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            interval={3}
            tickFormatter={tickFormatter}
            tick={{ fill: '#64748B', fontSize: '12px' }}
          />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          {dataTypes.map((item) => (
            <Area
              type="monotone"
              key={propskey}
              dataKey={item.dataKey}
              name={t(`label.${item.dataKey}`)}
              stroke={item.strokeColor}
              fill={`url(#color${item.dataKey})`}
              strokeWidth={2}
            />
          ))}
        </AreaChart>
      ) : (
        <div className="pt-3 text-center">
          <div className="sk-spinner sk-spinner-pulse" />
        </div>
      )}
    </ResponsiveContainer>
  );
};
