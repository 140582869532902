import { takeEvery } from 'redux-saga/effects';
import {
  CREATE_PROMOTION_FETCH,
  PROMOTION_INFO_FETCH,
  PROMOTION_LIST_FETCH,
  UPDATE_PROMOTION_FETCH,
  UPDATE_PROMOTION_STATUS_FETCH,
} from '../constants';
// eslint-disable-next-line import/no-cycle
import {
  createPromotionSaga,
  promotionInfoSaga,
  promotionListSaga,
  updatePromotionSaga,
  updatePromotionStatusSaga,
} from './promotionsSaga';

export function* rootPromotionSaga() {
  yield takeEvery(PROMOTION_LIST_FETCH, promotionListSaga);
  yield takeEvery(PROMOTION_INFO_FETCH, promotionInfoSaga);
  yield takeEvery(CREATE_PROMOTION_FETCH, createPromotionSaga);
  yield takeEvery(UPDATE_PROMOTION_FETCH, updatePromotionSaga);
  yield takeEvery(UPDATE_PROMOTION_STATUS_FETCH, updatePromotionStatusSaga);
}
