export const getStatusColor = (status: string | undefined) => {
  switch (status?.toLowerCase()) {
    case 'expired':
    case 'pending_confirmation':
    case 'pending_resignation':
    case 'pending_unbind':
    case 'pending':
    case 'scheduled':
      return 'warning';
    case 'active':
    case 'approve':
    case 'approved':
    case 'manual_approved':
    case 'published':
    case 'sent':
    case 'success':
      return 'success';
    case 'closed':
    case 'employer_suspend':
    case 'fail':
    case 'inactive':
    case 'rejected':
    case 'cancelled':
    case 'resigned':
    case 'suspended':
    case 'unbinded':
      return 'error';
    default:
      return 'warning';
  }
};
