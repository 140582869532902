/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { FormLabelAlign } from 'antd/lib/form/interface';
import { useField } from 'formik';
import { FormItem, Checkbox as FormikAntdCheckbox } from 'formik-antd';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

export interface CheckboxGroupInputProp {
  id: string;
  name: string;
  options: { label: string; value: any; disabled?: boolean }[];
  label?: string | ReactNode;
  defaultValue?: Array<CheckboxValueType>;
  value?: Array<CheckboxValueType>;
  disabled?: boolean;
  onChange?: (e: Array<CheckboxValueType>) => void;
  labelAlign?: FormLabelAlign; // default: right
  labelCol?: object; // ant design Col object
  wrapperCol?: object; // ant design Col object
  tooltip?: ReactNode;
  rules?: any;
  noStyle?: boolean;
  required?: boolean;
  help?: string | ReactNode;
  errorMsg?: string | ReactNode;
  checkboxGroupClassName?: string;
}

export const CheckboxGroupInput: React.FC<CheckboxGroupInputProp> = ({
  id,
  name,
  label,
  tooltip,
  labelCol,
  wrapperCol,
  labelAlign,
  required,
  errorMsg,
  help,
  rules,
  noStyle,
  checkboxGroupClassName,
  onChange,
  defaultValue,
  value,
  options,
  disabled,
}) => {
  const { t } = useTranslation();
  const [, meta] = useField({ name });

  return (
    <FormItem
      id={id}
      name={name}
      label={label}
      tooltip={tooltip}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      labelAlign={labelAlign}
      required={required}
      help={meta.touched && meta.error ? t(`${meta.error}`) : undefined}
      rules={rules}
      noStyle={noStyle}
    >
      <FormikAntdCheckbox.Group
        className={checkboxGroupClassName}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        name={name}
        options={options}
        disabled={disabled}
      />
    </FormItem>
  );
};

CheckboxGroupInput.defaultProps = {
  labelAlign: 'left',
};
