export interface SchedulerPayloadState {
  id?: string;
  eid: string;
  title: string;
  from: number;
  to: number;
  jobType: string;
  location: string;
  workers: string[];
  desc: string;
  bgColor: string;
  products: {
    id: string;
    name: string;
    category: string;
    subCategory?: string;
    price: number;
  }[];
}

export interface WorkStatusInfoState {
  id: string;
  eid: string;
  ebid: string;
  uid: string;
  status: string;
  startTime: string;
  endTime: string;
  description: string;
  outlet: {
    id: string;
    name: string;
  };
  taskScheduleId: string;
  createdOn: string;
  name: string;
  employeeName: string;
  type: string;
  tags: {
    id: string;
    name: string;
  }[];
  bgColor: string;
  images: {
    image: string;
    date: string;
  }[];
  products: {
    id?: string;
    name: string;
    category: string;
    subCategory?: string;
    price: number;
    commission?: number;
  }[];
  commission: number;
  amount: number;
  transactionId: string;
  workRecordId: string;
}

export const initWorkStatusInfo: WorkStatusInfoState = {
  id: '',
  eid: '',
  ebid: '',
  uid: '',
  status: '',
  startTime: '',
  endTime: '',
  description: '',
  outlet: {
    id: '',
    name: '',
  },
  taskScheduleId: '',
  createdOn: '',
  name: '',
  employeeName: '',
  type: '',
  tags: [],
  bgColor: '',
  images: [],
  products: [],
  commission: 0,
  amount: 0,
  transactionId: '',
  workRecordId: '',
};
