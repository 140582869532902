/* eslint-disable func-names */
import * as Yup from 'yup';

const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old password is required'),
  password: Yup.string()
    .min(8, `Password has to be at least ${8} characters!`)
    .matches(
      /(?=.*\d)(?=.*[!@#$%^&*,.?`~/])(?=.*[a-z])(?=.*[A-Z]).{8,}/,
      'Password must contain: numbers, uppercase, lowercase and special letters'
    )
    .required('Password is required'),
  confirmPassword: Yup.string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    })
    .required('Password confirmation is required'),
});

export { ChangePasswordSchema };
