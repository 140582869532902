/* eslint-disable @typescript-eslint/no-explicit-any */
import fileDownload from 'js-file-download';
import { call, put } from 'redux-saga/effects';
import { API } from 'api';
import { blobToString, downloadFileByBase64Str, employerRequestStatus, isBase64 } from 'common';
import { toastPush } from 'modules';
import {
  employerDownloadFileImageSuccess,
  employerInventoryListSuccess,
  employerRequestUploadBlobSuccess,
  errorCreateEmployerRequest,
  ICreateEmployerRequestFetch,
  IEmployerDownloadFileImageFetch,
  IEmployerFileRetrieveFetch,
  IEmployerInfoFetch,
  IEmployerListFetch,
  IEmployerRequestUploadBlobFetch,
  IInitEmployerRequestFetch,
  IResetEmployerAdminPasswordFetch,
  IUpdateEmployerAdminStatusFetch,
  IUpdateEmployerRequestFetch,
  successCreateEmployerRequest,
  successEmployerInfo,
  successEmployerList,
  successEmployerRequestInfo,
  successEmployerRequestList,
  successEmployerSelect,
  successInitEditEmployerRequest,
  successInitNewEmployerRequest,
} from '../actions';

export function* employerListSaga(action: IEmployerListFetch) {
  try {
    const { data } = yield call(API.get(), '/api/employer/all', action.payload);
    yield put(successEmployerList({ records: data.data, count: data.count }));
  } catch (error) {
    // Empty
  }
}

export function* employerSelectSaga() {
  try {
    const { data } = yield call(API.get(), '/api/employer/select', {});
    yield put(successEmployerSelect({ records: data }));
  } catch (error) {
    // Empty
  }
}

export function* employerInfoSaga(action: IEmployerInfoFetch) {
  try {
    const { eid } = action.payload;
    const { data } = yield call(API.get(), `/api/employer/${eid}`, {});
    yield put(successEmployerInfo(data));
  } catch (error) {
    // Empty
  }
}

export function* employerRequestListSaga(action: IEmployerListFetch) {
  try {
    const { data } = yield call(API.get(), '/api/admin/employerRequest/all', action.payload);
    yield put(successEmployerRequestList({ records: data.data, count: data.count }));
  } catch (error) {
    // Empty
  }
}

export function* employerRequestInfoSaga(action: IEmployerInfoFetch) {
  try {
    const { eid } = action.payload;
    const { data } = yield call(API.get(), `/api/admin/employerRequest/${eid}`, {});
    yield put(successEmployerRequestInfo(data));
  } catch (error) {
    // Empty
  }
}

export function* initEmployerRequestSaga(action: IInitEmployerRequestFetch) {
  try {
    const { type } = action.payload;
    const { data } = yield call(API.post(), '/api/admin/employerRequestInit', {
      type,
    });
    switch (type) {
      case 'new':
        yield put(successInitNewEmployerRequest({ erid: data.id }));
        break;
      case 'edit':
        yield put(successInitEditEmployerRequest({ erid: data.id }));
        break;
      default:
        break;
    }
  } catch (error) {
    // Empty
  }
}

export function* createEmployerRequestSaga(action: ICreateEmployerRequestFetch) {
  try {
    const params = JSON.parse(JSON.stringify(action.payload));

    delete params.data.attachments.hlbApplicationForm?.source;
    delete params.data.attachments.auditedFinancialStatements?.source;
    delete params.data.attachments.businessRegistrationDocument?.source;
    delete params.data.attachments.letterOfAuthorisation?.source;
    delete params.data.attachments.signedAgreement?.source;
    delete params.data.attachments.ctosReport?.source;
    delete params.data.attachments.others?.source;
    delete params.data.attachments.hlbApplicationForm?.fileList;
    delete params.data.attachments.auditedFinancialStatements?.fileList;
    delete params.data.attachments.businessRegistrationDocument?.fileList;
    delete params.data.attachments.letterOfAuthorisation?.fileList;
    delete params.data.attachments.signedAgreement?.fileList;
    delete params.data.attachments.ctosReport?.fileList;
    delete params.data.attachments.others?.fileList;

    yield call(API.post(), '/api/admin/employerRequest', params);
    // if (action.payload.type === 'new') {
    //   yield put(toastPush({ code: 200, type: 'success', title: 'SUCCESS', body: 'Success Created Employer Request' }));
    // } else {
    //   yield put(toastPush({ code: 200, type: 'success', title: 'SUCCESS', body: 'Success Updated Employer Request' }));
    // }
    yield put(successCreateEmployerRequest());
    // yield put(push(`/employer/list`));
  } catch (error: any) {
    yield put(
      errorCreateEmployerRequest({
        message: error.message,
        messageId: error.messageId,
      })
    );
  }
}

export function* updateEmployerRequestSaga(action: IUpdateEmployerRequestFetch) {
  const { onSuccess, onError, payload } = action;
  try {
    const { eid } = payload;
    yield call(API.put(), `/api/admin/employerRequest/${eid}`, payload);
    if (action.payload.status === employerRequestStatus.approve.value) {
      yield put(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.successApprovedEmployerRequest',
        })
      );
    } else {
      yield put(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.successRejectedEmployerRequest',
        })
      );
    }
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    if (onError) {
      onError();
    }
  }
}

export function* employerPreviewFileImageSaga(action: IEmployerDownloadFileImageFetch) {
  try {
    const { data } = yield call(API.get(), `/api/admin/download/file`, action.payload);
    yield put(
      employerDownloadFileImageSuccess({
        source: data,
        key: action.payload.key,
      })
    );
  } catch (error) {
    // Empty
  }
}

export function* employerRequestUploadBlobSaga(action: IEmployerRequestUploadBlobFetch) {
  const { onSuccess, onError, payload } = action;
  try {
    const { prefix, filename } = payload;
    const { data } = yield call(API.post(), '/api/admin/employerRequest/upload', action.payload);
    if (onSuccess) {
      onSuccess({ prefix, objectId: data, filename });
    }
    yield put(employerRequestUploadBlobSuccess({ prefix, objectId: data, filename }));
  } catch (error) {
    if (onError) {
      onError();
    }
  }
}

export function* updateEmployerAdminStatusSaga(action: IUpdateEmployerAdminStatusFetch) {
  const { onSuccess, onError, payload } = action;
  try {
    yield call(API.put(), '/api/admin/status', payload);
    if (onSuccess) {
      onSuccess(payload.aid, payload.status);
    }
    yield put(
      toastPush({
        code: 200,
        type: 'success',
        title: 'SUCCESS',
        body: 'toastPush.updatedSuccessful',
      })
    );
  } catch (error) {
    if (onError) {
      onError();
    }
  }
}

export function* resetEmployerAdminPasswordSaga(action: IResetEmployerAdminPasswordFetch) {
  const { onSuccess, onError, payload } = action;
  try {
    yield call(API.post(), '/api/admin/reset/pwd', payload);
    if (onSuccess) {
      onSuccess();
    }
    yield put(
      toastPush({
        code: 200,
        type: 'success',
        title: 'SUCCESS',
        body: 'toastPush.resetPwdSuccessful',
      })
    );
  } catch (error) {
    if (onError) {
      onError();
    }
  }
}

export function* employerInventoryListSaga() {
  try {
    const { data } = yield call(API.get(), '/api/employer/inventory/list');
    yield put(employerInventoryListSuccess({ data: data.items }));
  } catch (error) {
    // Empty
  }
}

export async function* employerFileRetrieveSaga(action: IEmployerFileRetrieveFetch) {
  try {
    const { data } = yield call(API.getBlob(), '/api/employer/file/retrieve', action.payload);
    // console.log('data', data);
    // console.log('hello world', (data.blob()).then((blob: any) => window.open(URL.createObjectURL(blob))));
    // const test = Buffer.from(data, "base64");
    // console.log('test', test.toString());
    // yield put(employerFileRetrieveSuccess({ data: data }));
    const result = await blobToString(data.data);
    if (isBase64(result) || result.includes('https://')) {
      downloadFileByBase64Str(result, `${action.payload.objectId}.png`);
      return;
    }
    fileDownload(data, `${action.payload.objectId}.png`);
  } catch (error) {
    // Empty
  }
}
