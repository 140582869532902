import { call, put } from 'redux-saga/effects';
import { API } from 'api';
import {
  cancelSchedulerError,
  cancelSchedulerSuccess,
  createSchedulerError,
  createSchedulerSuccess,
  ICancelSchedulerTaskFetch,
  ICreateSchedulerTaskFetch,
  ISchedulerListFetch,
  IUpdateSchedulerTaskFetch,
  IWorkStatusInfoFetch,
  IWorkStatusListFetch,
  schedulerListSuccess,
  updateSchedulerError,
  updateSchedulerSuccess,
  workStatusInfoError,
  workStatusInfoSuccess,
  workStatusListSuccess,
} from '../actions';

export function* schedulerListSaga(action: ISchedulerListFetch) {
  try {
    const { data } = yield call(API.get(), `/api/employer/task/scheduler`, action.payload);
    yield put(schedulerListSuccess({ ...data.data, count: data.count }));
  } catch (error) {
    yield put(createSchedulerError());
  }
}

export function* createSchedulerTaskSaga(action: ICreateSchedulerTaskFetch) {
  const { onSuccess, onError, payload } = action;
  try {
    yield call(API.post(), `/api/employer/task/create`, payload);
    yield put(createSchedulerSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put(createSchedulerError());
    if (onError) {
      onError();
    }
  }
}

export function* updateSchedulerTaskSaga(action: IUpdateSchedulerTaskFetch) {
  const { onSuccess, onError, payload } = action;
  try {
    yield call(API.put(), `/api/employer/task/edit/${payload.id}`, payload);
    yield put(updateSchedulerSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put(updateSchedulerError());
    if (onError) {
      onError();
    }
  }
}

export function* cancelSchedulerTaskSaga(action: ICancelSchedulerTaskFetch) {
  const { onSuccess, onError, payload } = action;
  try {
    yield call(API.put(), `/api/employer/task/delete/${payload.id}`, payload);
    yield put(cancelSchedulerSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put(cancelSchedulerError());
    if (onError) {
      onError();
    }
  }
}

export function* workStatusListSaga(action: IWorkStatusListFetch) {
  try {
    const params = {
      ...action.payload,
    };

    delete params.date;
    const { data } = yield call(API.get(), `/api/employer/task/status`, params);
    yield put(workStatusListSuccess({ records: data.data, count: data.count }));
  } catch (error) {
    yield put(createSchedulerError());
  }
}

export function* workStatusInfoSaga(action: IWorkStatusInfoFetch) {
  try {
    const { data } = yield call(API.get(), `/api/employer/task/details/${action.payload.id}`);
    yield put(workStatusInfoSuccess(data));
  } catch (error) {
    yield put(workStatusInfoError());
  }
}
