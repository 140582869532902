import { Button, Dropdown, Tooltip, Space } from 'antd';
import { ButtonType } from 'antd/lib/button/button';
import { LanguageCode, getCookie, languageCodeList } from 'common';
import React, { useCallback, useMemo } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, changeLanguageCode, selectCurrentLanguage } from 'modules';

export interface languageDropdownProp {
  type?: ButtonType;
  btnClassName?: string;
}

export const LanguageDropdown: React.FC<languageDropdownProp> = ({ type, btnClassName }) => {
  const dispatch = useDispatch();
  const language = useSelector((state: RootState) => selectCurrentLanguage(state));
  const languageCodeCookie = getCookie('locale').split('-')[0] || language;

  const onClickLocalizationMenu = useCallback(
    (e: { key: string }) => {
      dispatch(changeLanguageCode(e.key));
    },
    [dispatch]
  );

  const languageMenu = () => {
    return languageCodeList.map((item: { code: LanguageCode; name: string }) => ({
      label: (
        <Tooltip title={item.code}>
          <span>{item.name.toUpperCase()}</span>
        </Tooltip>
      ),
      key: item.code,
    }));
  };

  const langaugeCode = useMemo(() => {
    const lang = languageCodeList.find((item) => item.code === languageCodeCookie);
    return lang?.name;
  }, [languageCodeCookie]);

  return (
    <Dropdown
      trigger={['click']}
      placement="bottomRight"
      overlayClassName="language-dropdown"
      menu={{
        items: languageMenu(),
        onClick: onClickLocalizationMenu,
        selectedKeys: [languageCodeCookie],
        className: 'language-menu',
      }}
    >
      <Button className={btnClassName}>
        <Space className="align-items-baseline">
          <span>{langaugeCode ? langaugeCode.toUpperCase() : 'EN'}</span>
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
