import { CHANGE_DARK_MODE, TOGGLE_SIDEBAR, TOGGLE_ASIDE } from './constants';

export interface ChangeDarkModeAction {
  type: string;
  payload: boolean;
}

export interface ToggleSidebarAction {
  type: string;
  payload: boolean | string;
}

export interface ToggleAsideAction {
  type: string;
  payload: boolean;
}

export const changeDarkMode = (payload: boolean): ChangeDarkModeAction => ({
  type: CHANGE_DARK_MODE,
  payload,
});

export const toggleSidebarAct = (payload: boolean | string): ToggleSidebarAction => ({
  type: TOGGLE_SIDEBAR,
  payload,
});

export const toggleAside = (payload: boolean): ToggleAsideAction => ({
  type: TOGGLE_ASIDE,
  payload,
});
