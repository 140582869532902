/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  EMPLOYER_OUTLET_LIST_FETCH,
  EMPLOYER_OUTLET_LIST_SUCCESS,
  EMPLOYER_OUTLET_INFO_FETCH,
  EMPLOYER_OUTLET_INFO_RESET,
  EMPLOYER_OUTLET_INFO_SUCCESS,
  EMPLOYER_OUTLET_INFO_ERROR,
  CREATE_EMPLOYER_OUTLET_FETCH,
  CREATE_EMPLOYER_OUTLET_ERROR,
  CREATE_EMPLOYER_OUTLET_SUCCESS,
  CREATE_EMPLOYER_OUTLET_RESET,
  UPDATE_EMPLOYER_OUTLET_RESET,
  UPDATE_EMPLOYER_OUTLET_ERROR,
  UPDATE_EMPLOYER_OUTLET_SUCCESS,
  UPDATE_EMPLOYER_OUTLET_FETCH,
} from './constants';
import { IEmployerOutletDataState, IEmployerOutletPayloadState } from './types';

export interface IEmployerOutletListFetch {
  type: typeof EMPLOYER_OUTLET_LIST_FETCH;
  payload: {
    employerName?: string;
    name?: string;
    employerId?: string;
    eid?: string;
    companyName?: string;
    page?: number;
    scheduler?: boolean;
  };
}

export interface IEmployerOutletListSuccess {
  type: typeof EMPLOYER_OUTLET_LIST_SUCCESS;
  payload: {
    records: any;
    count: number;
  };
}

export interface IEmployerOutletInfoReset {
  type: typeof EMPLOYER_OUTLET_INFO_RESET;
}

export interface IEmployerOutletInfoFetch {
  type: typeof EMPLOYER_OUTLET_INFO_FETCH;
  payload: {
    id: string;
  };
}

export interface IEmployerOutletInfoSuccess {
  type: typeof EMPLOYER_OUTLET_INFO_SUCCESS;
  payload: IEmployerOutletDataState;
}

export interface IEmployerOutletInfoError {
  type: typeof EMPLOYER_OUTLET_INFO_ERROR;
}

export interface ICreateEmployerOutletReset {
  type: typeof CREATE_EMPLOYER_OUTLET_RESET;
}

export interface ICreateEmployerOutletFetch {
  type: typeof CREATE_EMPLOYER_OUTLET_FETCH;
  payload: IEmployerOutletPayloadState;
  onSuccess?: () => void;
  onError?: () => void;
}

export interface ICreateEmployerOutletSuccess {
  type: typeof CREATE_EMPLOYER_OUTLET_SUCCESS;
}

export interface ICreateEmployerOutletError {
  type: typeof CREATE_EMPLOYER_OUTLET_ERROR;
}

export interface IUpdateEmployerOutletReset {
  type: typeof UPDATE_EMPLOYER_OUTLET_RESET;
}

export interface IUpdateEmployerOutletFetch {
  type: typeof UPDATE_EMPLOYER_OUTLET_FETCH;
  payload: IEmployerOutletPayloadState;
  onSuccess?: () => void;
  onError?: () => void;
}
export interface IUpdateEmployerOutletSuccess {
  type: typeof UPDATE_EMPLOYER_OUTLET_SUCCESS;
}
export interface IUpdateEmployerOutletError {
  type: typeof UPDATE_EMPLOYER_OUTLET_ERROR;
}

export type employerOutletAction =
  | IEmployerOutletListFetch
  | IEmployerOutletListSuccess
  | IEmployerOutletInfoReset
  | IEmployerOutletInfoFetch
  | IEmployerOutletInfoSuccess
  | IEmployerOutletInfoError
  | ICreateEmployerOutletReset
  | ICreateEmployerOutletFetch
  | ICreateEmployerOutletSuccess
  | ICreateEmployerOutletError
  | IUpdateEmployerOutletReset
  | IUpdateEmployerOutletFetch
  | IUpdateEmployerOutletSuccess
  | IUpdateEmployerOutletError;

export const employerOutletListFetch = (payload: IEmployerOutletListFetch['payload']): IEmployerOutletListFetch => ({
  type: EMPLOYER_OUTLET_LIST_FETCH,
  payload,
});

export const successEmployerOutletList = (
  payload: IEmployerOutletListSuccess['payload']
): IEmployerOutletListSuccess => ({
  type: EMPLOYER_OUTLET_LIST_SUCCESS,
  payload,
});

export const employerOutletInfoReset = (): IEmployerOutletInfoReset => ({
  type: EMPLOYER_OUTLET_INFO_RESET,
});

export const employerOutletInfoFetch = (payload: IEmployerOutletInfoFetch['payload']): IEmployerOutletInfoFetch => ({
  type: EMPLOYER_OUTLET_INFO_FETCH,
  payload,
});

export const successEmployerOutletInfo = (
  payload: IEmployerOutletInfoSuccess['payload']
): IEmployerOutletInfoSuccess => ({
  type: EMPLOYER_OUTLET_INFO_SUCCESS,
  payload,
});

export const errorEmployerOutletInfo = (): IEmployerOutletInfoError => ({
  type: EMPLOYER_OUTLET_INFO_ERROR,
});

export const createEmployerOutletReset = (): ICreateEmployerOutletReset => ({
  type: CREATE_EMPLOYER_OUTLET_RESET,
});

export const createEmployerOutletFetch = (
  payload: ICreateEmployerOutletFetch['payload'],
  onSuccess?: ICreateEmployerOutletFetch['onSuccess'],
  onError?: ICreateEmployerOutletFetch['onError']
): ICreateEmployerOutletFetch => ({
  type: CREATE_EMPLOYER_OUTLET_FETCH,
  payload,
  onSuccess,
  onError,
});

export const successCreateEmployerOutlet = (): ICreateEmployerOutletSuccess => ({
  type: CREATE_EMPLOYER_OUTLET_SUCCESS,
});

export const errorCreateEmployerOutlet = (): ICreateEmployerOutletError => ({
  type: CREATE_EMPLOYER_OUTLET_ERROR,
});

export const updateEmployerOutletReset = (): IUpdateEmployerOutletReset => ({
  type: UPDATE_EMPLOYER_OUTLET_RESET,
});

export const updateEmployerOutletFetch = (
  payload: IUpdateEmployerOutletFetch['payload'],
  onSuccess?: IUpdateEmployerOutletFetch['onSuccess'],
  onError?: IUpdateEmployerOutletFetch['onError']
): IUpdateEmployerOutletFetch => ({
  type: UPDATE_EMPLOYER_OUTLET_FETCH,
  payload,
  onSuccess,
  onError,
});

export const successUpdateEmployerOutlet = (): IUpdateEmployerOutletSuccess => ({
  type: UPDATE_EMPLOYER_OUTLET_SUCCESS,
});

export const errorUpdateEmployerOutlet = (): IUpdateEmployerOutletError => ({
  type: UPDATE_EMPLOYER_OUTLET_ERROR,
});
