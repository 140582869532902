import { TFunction } from 'i18next';

declare global {
  interface Array<T> {
    translateOptionLabel: (t: TFunction) => { label: string; value: string }[];
  }
}

// eslint-disable-next-line no-extend-native, func-names
Array.prototype.translateOptionLabel = function (t: TFunction): { label: string; value: string }[] {
  // Implement your translation logic here
  // For demonstration purposes, let's just return the label itself
  return this.map((item) => ({ label: t(item.label), value: item.value }));
};

export {};
