export interface IEkycInfoState {
  id: string;
  uid: string;
  createdOn: number;
  createdDate: string;
  ekycStatus: string;
  sdkToken: string;
  facialSimilarityStatus: string;
  documentStatus: string;
  documentFailureReason: string;
  facialSimilarityFailureReason: string;
  failureReason: string;
  userSubmitInfo: {
    name: string;
    dateOfBirth: string;
    address: {
      line1: string;
      line2: string;
      postcode: string;
      city: string;
      state: string;
      country: string;
    };
    idType: string;
    idNo: string;
  };
  iso2CountryCode: string;
  ocrInformation: {
    name: string;
    dateOfBirth: string;
    address: {
      line1: string;
      line2: string;
      postcode: string;
      city: string;
      state: string;
      country: string;
    };
    idType: string;
    idNo: string;
  };
  registerInfo: {
    fullName: string;
    email: string;
    mobile: string;
    numberOfEkycAttempts: string;
  };
  frontDocument: string;
  backDocument: string;
  frontDocumentId: string;
  backDocumentId: string;
  videoId: string;
  video: string;
  loading: boolean;
}

export const initEkycInfoState: IEkycInfoState = {
  id: '',
  uid: '',
  createdOn: 0,
  createdDate: '',
  ekycStatus: '',
  sdkToken: '',
  facialSimilarityStatus: '',
  documentStatus: '',
  userSubmitInfo: {
    name: '',
    dateOfBirth: '',
    address: {
      line1: '',
      line2: '',
      postcode: '',
      city: '',
      state: '',
      country: '',
    },
    idType: '',
    idNo: '',
  },
  iso2CountryCode: '',
  ocrInformation: {
    name: '',
    dateOfBirth: '',
    address: {
      line1: '',
      line2: '',
      postcode: '',
      city: '',
      state: '',
      country: '',
    },
    idType: '',
    idNo: '',
  },
  failureReason: '',
  documentFailureReason: '',
  facialSimilarityFailureReason: '',
  registerInfo: {
    fullName: '',
    email: '',
    mobile: '',
    numberOfEkycAttempts: '',
  },
  frontDocument: '',
  backDocument: '',
  frontDocumentId: '',
  backDocumentId: '',
  videoId: '',
  video: '',
  loading: false,
};
