import fileDownload from 'js-file-download';
import { call, delay, put } from 'redux-saga/effects';
import { API } from 'api';
import { blobToString, downloadFileByBase64Str, isBase64 } from 'common';
import { toastPush } from '../../public/toast';
import {
  employerReportListSuccess,
  employerReportUpdateError,
  employerReportUpdateSuccess,
  IEmployerReportUpdateFetch,
  IEmployerReportUploadFileFetch,
  ILapseRepaymentReportListFetch,
  ILapseRepaymentSendEmailFetch,
  IReportListFetch,
  IUpdateLapseRepaymentFetch,
  IUploadFileReportFetch,
  IInvoiceReportUpdateFetch,
  IInvoiceReportUploadFileFetch,
  successBankReportList,
  successEmployeeInfoUpdateReportList,
  successExceptionalFileReportList,
  successLapseRepaymentReportList,
  successReconciliationReportList,
  successUpdateLapseRepayment,
  ibgEnquiryListSuccess,
  invoiceReportListFetchSuccess,
  invoiceReportUpdateSuccess,
  invoiceReportUpdateError,
  IReportFileRetrieveFetch,
} from '../actions';

export function* uploadFileReportSaga(action: IUploadFileReportFetch) {
  const { onSuccess, onError, payload } = action;
  try {
    yield call(API.post(), 'api/admin/financeFiles/HLBB', payload);
    yield put(
      toastPush({
        code: 200,
        type: 'success',
        title: 'SUCCESS',
        body: 'toastPush.uploadSucess',
      })
    );
    delay(1);
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    if (onError) {
      onError();
    }
  }
}

export function* reportListSaga(action: IReportListFetch) {
  try {
    const { type } = action.payload;
    const { data } = yield call(API.get(), '/api/admin/financeFiles/HLBB', action.payload);
    switch (type) {
      case 'oba':
      case 'payment':
      case 'repayment':
      case 'repaymentExceptional':
      case 'oba&payment':
        yield put(successBankReportList({ records: data }));
        break;
      case 'exceptional':
        yield put(successExceptionalFileReportList({ records: data }));
        break;
      case 'employeeInfo':
        yield put(successEmployeeInfoUpdateReportList({ records: data }));
        break;
      case 'reconciliation':
        yield put(successReconciliationReportList({ records: data }));
        break;
      case 'deduction':
      case 'commission':
      case 'deduction&commission':
        yield put(employerReportListSuccess({ records: data }));
        break;
      default:
        yield put(successReconciliationReportList({ records: data }));
        break;
    }
  } catch (error) {
    // Empty
  }
}

export function* employerReportListSaga(action: IReportListFetch) {
  try {
    const { data } = yield call(API.get(), '/api/admin/financeFiles/employerReport', action.payload);
    yield put(employerReportListSuccess({ records: data }));
  } catch (error) {
    // Empty
  }
}

export function* ibgEnquiryListSaga(action: IReportListFetch) {
  try {
    const { data } = yield call(API.get(), '/api/admin/transactions/ibg', action.payload);
    yield put(ibgEnquiryListSuccess({ records: data }));
  } catch (error) {
    // Empty
  }
}

export function* lapseRepaymentReportListSaga(action: ILapseRepaymentReportListFetch) {
  try {
    const { data } = yield call(API.get(), '/api/admin/lapsepayment', action.payload);
    yield put(successLapseRepaymentReportList({ records: data }));
  } catch (error) {
    // Empty
  }
}

export function* updateLapseRepaymentSaga(action: IUpdateLapseRepaymentFetch) {
  try {
    const { id, ebid } = action.payload;
    yield call(API.put(), `/api/employee/${id}/company/${ebid}`, action.payload);
    yield put(
      toastPush({
        code: 200,
        type: 'success',
        title: 'SUCCESS',
        body: 'toastPush.unsuspendSucessful',
      })
    );
    yield put(successUpdateLapseRepayment());
  } catch (error) {
    // Empty
  }
}

export function* lapseRepaymentSendEmailSaga(action: ILapseRepaymentSendEmailFetch) {
  try {
    const { ebid } = action.payload;
    // yield call(API.put(), `/api/employee/${id}/company/${ebid}`, action.payload)
    yield put(
      toastPush({
        code: 200,
        type: 'success',
        title: 'SUCCESS',
        body: `Success Sent Email ${ebid}`,
      })
    );
    yield put(successUpdateLapseRepayment());
  } catch (error) {
    // Empty
  }
}

export function* employerReportUpdateSaga(action: IEmployerReportUpdateFetch) {
  try {
    const { status, employerReportId } = action.payload;
    yield call(API.put(), '/api/admin/financeFiles/employerReport/update', {
      status,
      employerReportId,
    });
    yield put(
      toastPush({
        code: 200,
        type: 'success',
        title: 'SUCCESS',
        body: 'toastPush.updatedEmployerReportStatus',
      })
    );
    yield put(employerReportUpdateSuccess());
  } catch (error) {
    yield put(employerReportUpdateError());
  }
}

export function* employerReportUploadFileSaga(action: IEmployerReportUploadFileFetch) {
  const { onSuccess, onError, payload } = action;
  try {
    const { eid, fileName, data, type } = payload;
    yield call(API.post(), '/api/admin/financeFiles/employerReport/upload', {
      eid,
      fileName,
      data,
      type,
    });
    yield put(
      toastPush({
        code: 200,
        type: 'success',
        title: 'SUCCESS',
        body: 'toastPush.uploadEmployerReportFileSuccessful',
      })
    );
    if (onSuccess) onSuccess();
  } catch (error) {
    if (onError) onError();
  }
}

export function* invoiceReportListSaga(action: IReportListFetch) {
  try {
    const { data } = yield call(API.get(), '/api/admin/invoices/retrieve', action.payload);
    yield put(invoiceReportListFetchSuccess({ count: data.count, records: data.data }));
  } catch (error) {
    // Empty
  }
}

export function* invoiceReportUpdateSaga(action: IInvoiceReportUpdateFetch) {
  try {
    const { status, invoiceId } = action.payload;
    yield call(API.put(), '/api/admin/invoices/update', { status, invoiceId });
    yield put(
      toastPush({
        code: 200,
        type: 'success',
        title: 'SUCCESS',
        body: 'toastPush.uploadEmployerReportFileSuccessful',
      })
    );
    yield put(invoiceReportUpdateSuccess());
  } catch (error) {
    yield put(invoiceReportUpdateError());
  }
}

export function* invoiceReportUploadFileSaga(action: IInvoiceReportUploadFileFetch) {
  const { onSuccess, onError, payload } = action;
  try {
    const { eid, fileName, data, type } = payload;
    yield call(API.post(), '/api/admin/invoices/upload', {
      eid,
      fileName,
      data,
      type,
    });
    yield put(
      toastPush({
        code: 200,
        type: 'success',
        title: 'SUCCESS',
        body: 'toastPush.uploadEmployerReportFileSuccessful',
      })
    );
    if (onSuccess) onSuccess();
  } catch (error) {
    if (onError) onError();
  }
}

export async function* reportFileRetrieveFetchSaga(action: IReportFileRetrieveFetch) {
  try {
    const { data } = yield call(API.get(), '/api/admin/files/retrieve', action.payload);
    const result = await blobToString(data.data);
    if (isBase64(result) || result.includes('https://')) {
      downloadFileByBase64Str(result, action.payload.fileId);
      return;
    }
    fileDownload(data, action.payload.fileId);
  } catch (error) {
    // Empty
  }
}
