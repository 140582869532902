/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { SchedulerAction } from './actions';
import {
  CREATE_SCHEDULER_TASK_RESET,
  CREATE_SCHEDULER_TASK_FETCH,
  CREATE_SCHEDULER_TASK_SUCCESS,
  CREATE_SCHEDULER_TASK_ERROR,
  SCHEDULER_LIST_ERROR,
  SCHEDULER_LIST_FETCH,
  SCHEDULER_LIST_SUCCESS,
  UPDATE_SCHEDULER_TASK_ERROR,
  UPDATE_SCHEDULER_TASK_RESET,
  UPDATE_SCHEDULER_TASK_SUCCESS,
  UPDATE_SCHEDULER_TASK_FETCH,
  CANCEL_SCHEDULER_TASK_SUCCESS,
  CANCEL_SCHEDULER_TASK_ERROR,
  CANCEL_SCHEDULER_TASK_RESET,
  CANCEL_SCHEDULER_TASK_FETCH,
  WORK_STATUS_LIST_FETCH,
  WORK_STATUS_LIST_ERROR,
  WORK_STATUS_LIST_SUCCESS,
  WORK_STATUS_INFO_SUCCESS,
  WORK_STATUS_INFO_ERROR,
  WORK_STATUS_INFO_FETCH,
} from './constants';
import { initWorkStatusInfo, WorkStatusInfoState } from './types';

export interface ISchedulerState {
  schedulerList: {
    resources: any[];
    events: any[];
    count: number;
    loading: boolean;
    payload: {
      name?: string;
      dateFrom?: number;
      dateTo?: number;
      type?: string;
      eid: string;
      page: number;
    };
  };
  createSchedulerTask: {
    loading: boolean;
    success: boolean;
    error?: boolean;
  };
  updateSchedulerTask: {
    loading: boolean;
    success: boolean;
    error?: boolean;
  };
  cancelSchedulerTask: {
    loading: boolean;
    success: boolean;
    error?: boolean;
  };
  workStatusList: {
    list: any[];
    count: number;
    loading: boolean;
    payload: {
      name?: string;
      date: moment.Moment;
      dateFrom?: number;
      dateTo?: number;
      page: number;
    };
  };
  workStatusInfo: WorkStatusInfoState;
}

const initialState: ISchedulerState = {
  schedulerList: {
    resources: [],
    events: [],
    count: 0,
    loading: false,
    payload: {
      eid: '',
      dateFrom: moment().startOf('week').unix(),
      dateTo: moment().endOf('week').unix(),
      page: 1,
    },
  },
  createSchedulerTask: {
    loading: false,
    success: false,
  },
  updateSchedulerTask: {
    loading: false,
    success: false,
  },
  cancelSchedulerTask: {
    loading: false,
    success: false,
  },
  workStatusList: {
    list: [],
    count: 0,
    loading: false,
    payload: {
      date: moment(),
      dateFrom: moment().startOf('day').unix(),
      dateTo: moment().endOf('day').unix(),
      page: 1,
    },
  },
  workStatusInfo: initWorkStatusInfo,
};

const schedulerListReducer = (state: ISchedulerState['schedulerList'], action: SchedulerAction) => {
  switch (action.type) {
    case SCHEDULER_LIST_FETCH:
      return {
        ...state,
        loading: true,
        payload: action.payload,
      };
    case SCHEDULER_LIST_SUCCESS:
      return {
        ...state,
        resources: action.payload.resources,
        events: action.payload.events,
        count: action.payload.count,
        loading: false,
      };
    case SCHEDULER_LIST_ERROR:
      return {
        ...state,
        list: [],
        count: 0,
        loading: false,
      };
    default:
      return state;
  }
};

const createSchedulerTaskReducer = (state: ISchedulerState['createSchedulerTask'], action: SchedulerAction) => {
  switch (action.type) {
    case CREATE_SCHEDULER_TASK_RESET:
      return initialState.createSchedulerTask;
    case CREATE_SCHEDULER_TASK_FETCH:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case CREATE_SCHEDULER_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case CREATE_SCHEDULER_TASK_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
      };
    default:
      return state;
  }
};

const updateSchedulerTaskReducer = (state: ISchedulerState['updateSchedulerTask'], action: SchedulerAction) => {
  switch (action.type) {
    case UPDATE_SCHEDULER_TASK_RESET:
      return initialState.updateSchedulerTask;
    case UPDATE_SCHEDULER_TASK_FETCH:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case UPDATE_SCHEDULER_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case UPDATE_SCHEDULER_TASK_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
      };
    default:
      return state;
  }
};

const cancelSchedulerTaskReducer = (state: ISchedulerState['cancelSchedulerTask'], action: SchedulerAction) => {
  switch (action.type) {
    case CANCEL_SCHEDULER_TASK_RESET:
      return initialState.cancelSchedulerTask;
    case CANCEL_SCHEDULER_TASK_FETCH:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case CANCEL_SCHEDULER_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case CANCEL_SCHEDULER_TASK_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
      };
    default:
      return state;
  }
};

const workStatusListReducer = (state: ISchedulerState['workStatusList'], action: SchedulerAction) => {
  switch (action.type) {
    case WORK_STATUS_LIST_FETCH:
      return {
        ...state,
        loading: true,
        payload: action.payload,
      };
    case WORK_STATUS_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.records,
        count: action.payload.count,
        loading: false,
      };
    case WORK_STATUS_LIST_ERROR:
      return {
        ...state,
        list: [],
        count: 0,
        loading: false,
      };
    default:
      return state;
  }
};

const workStatusInfoReducer = (state: ISchedulerState['workStatusInfo'], action: SchedulerAction) => {
  switch (action.type) {
    case WORK_STATUS_INFO_FETCH:
      return initWorkStatusInfo;
    case WORK_STATUS_INFO_SUCCESS:
      return {
        ...action.payload,
      };
    case WORK_STATUS_INFO_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last
export const schedulerReducer = (state = initialState, action: SchedulerAction) => {
  switch (action.type) {
    case SCHEDULER_LIST_FETCH:
    case SCHEDULER_LIST_SUCCESS:
    case SCHEDULER_LIST_ERROR:
      return {
        ...state,
        schedulerList: schedulerListReducer({ ...state.schedulerList }, action),
      };
    case CREATE_SCHEDULER_TASK_RESET:
    case CREATE_SCHEDULER_TASK_FETCH:
    case CREATE_SCHEDULER_TASK_SUCCESS:
    case CREATE_SCHEDULER_TASK_ERROR:
      return {
        ...state,
        createSchedulerTask: createSchedulerTaskReducer({ ...state.createSchedulerTask }, action),
      };
    case UPDATE_SCHEDULER_TASK_RESET:
    case UPDATE_SCHEDULER_TASK_FETCH:
    case UPDATE_SCHEDULER_TASK_SUCCESS:
    case UPDATE_SCHEDULER_TASK_ERROR:
      return {
        ...state,
        updateSchedulerTask: updateSchedulerTaskReducer({ ...state.updateSchedulerTask }, action),
      };
    case CANCEL_SCHEDULER_TASK_RESET:
    case CANCEL_SCHEDULER_TASK_FETCH:
    case CANCEL_SCHEDULER_TASK_SUCCESS:
    case CANCEL_SCHEDULER_TASK_ERROR:
      return {
        ...state,
        cancelSchedulerTask: cancelSchedulerTaskReducer({ ...state.cancelSchedulerTask }, action),
      };
    case WORK_STATUS_LIST_FETCH:
    case WORK_STATUS_LIST_SUCCESS:
    case WORK_STATUS_LIST_ERROR:
      return {
        ...state,
        workStatusList: workStatusListReducer({ ...state.workStatusList }, action),
      };
    case WORK_STATUS_INFO_FETCH:
    case WORK_STATUS_INFO_SUCCESS:
    case WORK_STATUS_INFO_ERROR:
      return {
        ...state,
        workStatusInfo: workStatusInfoReducer({ ...state.workStatusInfo }, action),
      };
    default:
      return state;
  }
};
