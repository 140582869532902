enum PaymentToBankTypeEnum {
  WITHDRAWAL = 'withdrawal',
  ADVANCE_REPAYMENT = 'advanceRepayment',
  PAYMENT = 'payment',
}

enum PaymentToBankStatusEnum {
  PENDING_START = 'pending_start',
  NEW = 'new',
  PENDING = 'pending',
  PROCESSED = 'processed',
  FAIL = 'fail',
  SUCCESS = 'success',
  PENDING_EKYC = 'pending_ekyc',
  REVERSED = 'reversed',
  PENDING_MERCHANT = 'pending_merchant',
}

const paymentToBankTypeLabel: { [key: string]: string } = {
  [PaymentToBankTypeEnum.ADVANCE_REPAYMENT]: 'label.advanceRepayment',
  [PaymentToBankTypeEnum.PAYMENT]: 'label.payment',
  [PaymentToBankTypeEnum.WITHDRAWAL]: 'label.withdrawal',
};

const paymentToBankTypeSelect: { label: string; value: string }[] = [
  {
    label: paymentToBankTypeLabel[PaymentToBankTypeEnum.ADVANCE_REPAYMENT],
    value: PaymentToBankTypeEnum.ADVANCE_REPAYMENT,
  },
  {
    label: paymentToBankTypeLabel[PaymentToBankTypeEnum.PAYMENT],
    value: PaymentToBankTypeEnum.PAYMENT,
  },
  {
    label: paymentToBankTypeLabel[PaymentToBankTypeEnum.WITHDRAWAL],
    value: PaymentToBankTypeEnum.WITHDRAWAL,
  },
];

const paymentToBankStatusLabel: { [key: string]: string } = {
  [PaymentToBankStatusEnum.PENDING_START]: 'status.pending_start',
  [PaymentToBankStatusEnum.NEW]: 'status.new',
  [PaymentToBankStatusEnum.PENDING]: 'status.pending',
  [PaymentToBankStatusEnum.PROCESSED]: 'status.processed',
  [PaymentToBankStatusEnum.FAIL]: 'status.fail',
  [PaymentToBankStatusEnum.SUCCESS]: 'status.success',
  [PaymentToBankStatusEnum.PENDING_EKYC]: 'status.pending_ekyc',
  [PaymentToBankStatusEnum.REVERSED]: 'status.reversed',
  [PaymentToBankStatusEnum.PENDING_MERCHANT]: 'status.pending_merchant',
};

export {
  PaymentToBankTypeEnum,
  PaymentToBankStatusEnum,
  paymentToBankTypeLabel,
  paymentToBankStatusLabel,
  paymentToBankTypeSelect,
};
