import { call, put } from 'redux-saga/effects';
import { API } from 'api';
import { IEmploymentListFetch, successEmploymentList } from '../actions';

export function* employmentListSaga(action: IEmploymentListFetch) {
  try {
    const { data } = yield call(API.get(), '/api/employer/employment/list', action.payload);
    yield put(successEmploymentList({ records: data.data, count: data.count }));
  } catch (error) {
    // Empty
  }
}
