export const UPLOAD_BULK_UPDATE_FETCH = 'employee/UPLOAD_BULK_UPDATE_FETCH';
export const UPLOAD_BULK_UPDATE_SUCCESS = 'employee/UPLOAD_BULK_UPDATE_SUCCESS';
export const UPLOAD_BULK_UPDATE_ERROR = 'employee/UPLOAD_BULK_UPDATE_ERROR';

export const BULK_UPDATE_LIST_FETCH = 'employee/BULK_UPDATE_LIST_FETCH';
export const BULK_UPDATE_LIST_SUCCESS = 'employee/BULK_UPDATE_LIST_SUCCESS';

export const BULK_UPDATE_REQUEST_LIST_FETCH = 'employee/BULK_UPDATE_REQUEST_LIST_FETCH';
export const BULK_UPDATE_REQUEST_LIST_SUCCESS = 'employee/BULK_UPDATE_REQUEST_LIST_SUCCESS';

export const CREATE_BULK_UPDATE_REQUEST_FETCH = 'employee/CREATE_BULK_UPDATE_REQUEST_FETCH';
export const CREATE_BULK_UPDATE_REQUEST_SUCCESS = 'employee/CREATE_BULK_UPDATE_REQUEST_SUCCESS';
export const CREATE_BULK_UPDATE_REQUEST_ERROR = 'employee/CREATE_BULK_UPDATE_REQUEST_ERROR';

export const BULK_UPDATE_REQUEST_ACTION_RESET = 'employee/BULK_UPDATE_REQUEST_ACTION_RESET';
export const BULK_UPDATE_REQUEST_ACTION_FETCH = 'employee/BULK_UPDATE_REQUEST_ACTION_FETCH';
export const BULK_UPDATE_REQUEST_ACTION_SUCCESS = 'employee/BULK_UPDATE_REQUEST_ACTION_SUCCESS';
export const BULK_UPDATE_REQUEST_ACTION_ERROR = 'employee/BULK_UPDATE_REQUEST_ACTION_ERROR';

export const BULK_UPDATE_FILENAME_SELECT_FETCH = 'employee/BULK_UPDATE_FILENAME_SELECT_FETCH';
export const BULK_UPDATE_FILENAME_SELECT_SUCCESS = 'employee/BULK_UPDATE_FILENAME_SELECT_SUCCESS';
