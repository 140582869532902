import { takeEvery } from 'redux-saga/effects';
import {
  CREATE_EMPLOYER_OUTLET_FETCH,
  EMPLOYER_OUTLET_INFO_FETCH,
  EMPLOYER_OUTLET_LIST_FETCH,
  UPDATE_EMPLOYER_OUTLET_FETCH,
} from '../constants';
import {
  createEmployerOutletSaga,
  employerOutletInfoSaga,
  employerOutletListSaga,
  updateEmployerOutletSaga,
} from './employerOutletSaga';

export function* rootEmployerOutletSaga() {
  yield takeEvery(EMPLOYER_OUTLET_LIST_FETCH, employerOutletListSaga);
  yield takeEvery(EMPLOYER_OUTLET_INFO_FETCH, employerOutletInfoSaga);
  yield takeEvery(CREATE_EMPLOYER_OUTLET_FETCH, createEmployerOutletSaga);
  yield takeEvery(UPDATE_EMPLOYER_OUTLET_FETCH, updateEmployerOutletSaga);
}
