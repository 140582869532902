import { AuthAction } from './actions';
import { SET_FIRST_LOGIN_FLAG } from './constants';

export interface IAuthState {
  firstTimeLogin: boolean;
}

export const initialAuthState: IAuthState = {
  firstTimeLogin: false,
};

// eslint-disable-next-line default-param-last
export const authReducer = (state = initialAuthState, action: AuthAction) => {
  if (action.type === SET_FIRST_LOGIN_FLAG) {
    return { ...state, firstTimeLogin: action.payload };
  }
  return state;
};
