/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppInfoType } from 'common';
import {
  BANK_LIST_CONFIG_SUCCESS,
  GET_CONFIG_FETCH,
  WITHDRAWAL_CONFIG_SUCCESS,
  UPDATE_BANK_CONFIG_SETTINGS,
  UPDATE_BANK_CONFIG_SETTINGS_SUCCESS,
  COUNTRY_UTIL_CONFIG_SUCCESS,
  FEE_LIST_CONFIG_SUCCESS,
} from './constants';
// eslint-disable-next-line import/no-cycle
import { AllISOData } from './reducer';

export interface IGetConfigFetch {
  type: typeof GET_CONFIG_FETCH;
  loading: boolean;
  payload: {
    id: AppInfoType;
  };
}

export interface IGetWithdrawalConfigSuccess {
  type: typeof WITHDRAWAL_CONFIG_SUCCESS;
  loading: boolean;
  payload: {
    employer: {
      ltv: number;
      limit: {
        min: number;
        max: number;
      };
    };
    employee: {
      ltv: number;
      defaultEligibleEarning: number;
      limit: {
        min: number;
        max: number;
      };
    };
  };
}
export interface IGetFeeListConfigSuccess {
  type: typeof FEE_LIST_CONFIG_SUCCESS;
  payload: {
    withdrawalFee: number;
    withdrawalFeeType: string;
  };
}

export interface IGetCountryUtilSuccess {
  type: typeof COUNTRY_UTIL_CONFIG_SUCCESS;
  loading: boolean;
  payload: {
    countryListWithAllISOData: AllISOData[];
    sanctionCountries: string[];
  };
}

export interface IUpdateBankConfigSettings {
  type: typeof UPDATE_BANK_CONFIG_SETTINGS;
  loading: boolean;
  payload: {
    bankStatus: any[];
  };
}

export interface IGetBankListConfigSuccess {
  type: typeof BANK_LIST_CONFIG_SUCCESS;
  loading: boolean;
  payload: {
    list: any[];
  };
}

export interface IUpdateBankConfigSettingsSuccess {
  type: typeof UPDATE_BANK_CONFIG_SETTINGS_SUCCESS;
  loading: boolean;
  payload: any;
}

export type ConfigAction =
  | IGetConfigFetch
  | IGetWithdrawalConfigSuccess
  | IGetFeeListConfigSuccess
  | IGetCountryUtilSuccess
  | IGetBankListConfigSuccess
  | IUpdateBankConfigSettings
  | IUpdateBankConfigSettingsSuccess;

export const getConfigSetting = (payload: IGetConfigFetch['payload']): IGetConfigFetch => ({
  type: GET_CONFIG_FETCH,
  payload,
  loading: true,
});

export const getWithdrawalConfigSuccess = (
  payload: IGetWithdrawalConfigSuccess['payload']
): IGetWithdrawalConfigSuccess => ({
  type: WITHDRAWAL_CONFIG_SUCCESS,
  payload,
  loading: false,
});

export const getFeeListConfigSuccess = (payload: IGetFeeListConfigSuccess['payload']): IGetFeeListConfigSuccess => ({
  type: FEE_LIST_CONFIG_SUCCESS,
  payload,
});

export const getCountryUtilSuccess = (payload: IGetCountryUtilSuccess['payload']): IGetCountryUtilSuccess => ({
  type: COUNTRY_UTIL_CONFIG_SUCCESS,
  payload,
  loading: false,
});

export const getBankListConfigSuccess = (payload: IGetBankListConfigSuccess['payload']): IGetBankListConfigSuccess => ({
  type: BANK_LIST_CONFIG_SUCCESS,
  payload,
  loading: false,
});

export const updateBankConfigSettings = (payload: IUpdateBankConfigSettings['payload']): IUpdateBankConfigSettings => ({
  type: UPDATE_BANK_CONFIG_SETTINGS,
  payload,
  loading: true,
});

export const successUpdateBankConfigSettings = (
  payload: IUpdateBankConfigSettingsSuccess['payload']
): IUpdateBankConfigSettingsSuccess => ({
  type: UPDATE_BANK_CONFIG_SETTINGS_SUCCESS,
  payload,
  loading: false,
});
