import React, { ReactNode } from 'react';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';

export interface AntTooltipProp {
  title: ReactNode | string;
  key?: string;
  color?: string;
  frontColor?: string;
  children?: ReactNode;
  placement?: TooltipPlacement;
}

export const AntTooltip: React.FC<AntTooltipProp> = ({ title, key, color, frontColor, children, placement }) => (
  <Tooltip overlayInnerStyle={{ color: frontColor }} color={color} key={key} title={title} placement={placement}>
    {children || null}
  </Tooltip>
);

AntTooltip.defaultProps = {
  color: '#FFFFFF',
  frontColor: '#000000',
};
