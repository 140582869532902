/* eslint-disable @typescript-eslint/no-explicit-any */
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { FormLabelAlign } from 'antd/lib/form/interface';
import React, { CSSProperties, FocusEventHandler, ReactNode } from 'react';
import { FormItem, Input as FormikAdtdInput } from 'formik-antd';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

export interface TextPasswordInputProp {
  placeholder: string;
  label?: string | ReactNode;
  id: string;
  name: string;
  value?: string | number;
  allowClear?: boolean;
  required?: boolean;
  bordered?: boolean;
  noStyle?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  size?: SizeType;
  tooltip?: ReactNode;
  children?: ReactNode;
  labelAlign?: FormLabelAlign; // default: right
  labelCol?: object; // ant design Col object
  wrapperCol?: object; // ant design Col object
  rules?: any;
  style?: CSSProperties;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
}

export const TextPasswordInput: React.FC<TextPasswordInputProp> = ({
  label,
  name,
  tooltip,
  labelCol,
  wrapperCol,
  labelAlign,
  required,
  rules,
  noStyle,
  style,
  id,
  value,
  bordered,
  placeholder,
  maxLength,
  size,
  allowClear,
  disabled,
  onFocus,
  onBlur,
  readOnly,
  children,
}) => {
  const { t } = useTranslation();
  const [, meta] = useField({ name, value });

  return (
    <FormItem
      label={label}
      name={name}
      tooltip={tooltip}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      labelAlign={labelAlign}
      required={required}
      rules={rules}
      help={meta.touched && meta.error ? t(`${meta.error}`) : undefined}
      noStyle={noStyle}
    >
      {/* <Input.Group compact> */}
      <FormikAdtdInput.Password
        style={style}
        id={id}
        name={name}
        value={value}
        bordered={bordered}
        placeholder={placeholder}
        maxLength={maxLength}
        size={size}
        allowClear={allowClear}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={readOnly}
      />
      {children}
      {/* </Input.Group> */}
    </FormItem>
  );
};

TextPasswordInput.defaultProps = {
  allowClear: true,
  labelAlign: 'left',
};
