enum AdminStatus {
  ACTIVE = 'active',
  SUSPEND = 'suspend',
}

const adminStatusSelect: { label: string; value: AdminStatus }[] = [
  { label: 'status.activate', value: AdminStatus.ACTIVE },
  { label: 'label.deactivate', value: AdminStatus.SUSPEND },
];

export { AdminStatus, adminStatusSelect };
