enum State {
  SELANGOR = 'SGR',
  PERAK = 'PRK',
  TERENGGANU = 'TRG',
  SABAH = 'SBH',
  SARAWAK = 'SWK',
  KUALALUMPUR = 'KUL',
  LABUAN = 'LBN',
  PUTRAJAYA = 'PJY',
  JOHOR = 'JHR',
  KEDAH = 'KDH',
  KELANTAN = 'KTN',
  MELAKA = 'MLK',
  NEGERISEMBILAN = 'NSN',
  PAHANG = 'PHG',
  PULAUPINANG = 'PNG',
  PERLIS = 'PLS',
}

const stateLabel: { [x: string]: string } = {
  [State.SELANGOR]: 'state.selangor',
  [State.PERAK]: 'state.perak',
  [State.TERENGGANU]: 'state.terengganu',
  [State.SABAH]: 'state.sabah',
  [State.SARAWAK]: 'state.sarawak',
  [State.KUALALUMPUR]: 'state.kualaLumpur',
  [State.LABUAN]: 'state.labuan',
  [State.PUTRAJAYA]: 'state.putrajaya',
  [State.JOHOR]: 'state.johor',
  [State.KEDAH]: 'state.kedah',
  [State.KELANTAN]: 'state.kelantan',
  [State.MELAKA]: 'state.melaka',
  [State.NEGERISEMBILAN]: 'state.negeriSembilan',
  [State.PAHANG]: 'state.pahang',
  [State.PULAUPINANG]: 'state.pulauPinang',
  [State.PERLIS]: 'state.perlis',
};

const stateSelect: { label: string; value: State }[] = [
  { label: stateLabel[State.KUALALUMPUR], value: State.KUALALUMPUR },
  { label: stateLabel[State.SELANGOR], value: State.SELANGOR },
  { label: stateLabel[State.JOHOR], value: State.JOHOR },
  { label: stateLabel[State.KEDAH], value: State.KEDAH },
  { label: stateLabel[State.KELANTAN], value: State.KELANTAN },
  { label: stateLabel[State.LABUAN], value: State.LABUAN },
  { label: stateLabel[State.MELAKA], value: State.MELAKA },
  { label: stateLabel[State.NEGERISEMBILAN], value: State.NEGERISEMBILAN },
  { label: stateLabel[State.PAHANG], value: State.PAHANG },
  { label: stateLabel[State.PERAK], value: State.PERAK },
  { label: stateLabel[State.PERLIS], value: State.PERLIS },
  { label: stateLabel[State.PULAUPINANG], value: State.PULAUPINANG },
  { label: stateLabel[State.PUTRAJAYA], value: State.PUTRAJAYA },
  { label: stateLabel[State.SABAH], value: State.SABAH },
  { label: stateLabel[State.SARAWAK], value: State.SARAWAK },
  { label: stateLabel[State.TERENGGANU], value: State.TERENGGANU },
];

export { State, stateSelect, stateLabel };
