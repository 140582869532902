export const EMPLOYER_OUTLET_LIST_FETCH = 'employer/EMPLOYER_OUTLET_LIST_FETCH';
export const EMPLOYER_OUTLET_LIST_SUCCESS = 'employer/EMPLOYER_OUTLET_LIST_SUCCESS';

export const EMPLOYER_OUTLET_INFO_RESET = 'employer/EMPLOYER_OUTLET_INFO_RESET';
export const EMPLOYER_OUTLET_INFO_FETCH = 'employer/EMPLOYER_OUTLET_INFO_FETCH';
export const EMPLOYER_OUTLET_INFO_SUCCESS = 'employer/EMPLOYER_OUTLET_INFO_SUCCESS';
export const EMPLOYER_OUTLET_INFO_ERROR = 'employer/EMPLOYER_OUTLET_INFO_ERROR';

export const CREATE_EMPLOYER_OUTLET_RESET = 'employer/CREATE_EMPLOYER_OUTLET_RESET';
export const CREATE_EMPLOYER_OUTLET_FETCH = 'employer/CREATE_EMPLOYER_OUTLET_FETCH';
export const CREATE_EMPLOYER_OUTLET_SUCCESS = 'employer/CREATE_EMPLOYER_OUTLET_SUCCESS';
export const CREATE_EMPLOYER_OUTLET_ERROR = 'employer/CREATE_EMPLOYER_OUTLET_ERROR';

export const UPDATE_EMPLOYER_OUTLET_RESET = 'employer/UPDATE_EMPLOYER_OUTLET_RESET';
export const UPDATE_EMPLOYER_OUTLET_FETCH = 'employer/UPDATE_EMPLOYER_OUTLET_FETCH';
export const UPDATE_EMPLOYER_OUTLET_SUCCESS = 'employer/UPDATE_EMPLOYER_OUTLET_SUCCESS';
export const UPDATE_EMPLOYER_OUTLET_ERROR = 'employer/UPDATE_EMPLOYER_OUTLET_ERROR';
