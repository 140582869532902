import { TransactionAction } from './actions';
import { TRANSACTION_RECORD_FETCH, TRANSACTION_RECORD_SUCCESS, TRANSACTION_INFO_SUCCESS } from './constants';
import { ITransactionState } from './interfaces';
import { initialState } from './store';

// eslint-disable-next-line default-param-last
export const transactionReducer = (state = initialState, action: TransactionAction) => {
  switch (action.type) {
    case TRANSACTION_RECORD_FETCH:
    case TRANSACTION_RECORD_SUCCESS:
      return {
        ...state,
        transactionRecord: transactionRecordReducer({ ...state.transactionRecord }, action),
      };
    case TRANSACTION_INFO_SUCCESS:
      return {
        ...state,
        transactionInfo: transactionInfoReducer({ ...state.transactionInfo }, action),
      };
    default:
      return state;
  }
};

const transactionRecordReducer = (state: ITransactionState['transactionRecord'], action: TransactionAction) => {
  switch (action.type) {
    case TRANSACTION_RECORD_FETCH:
      return {
        ...state,
        loading: true,
        payload: action.payload,
      };
    case TRANSACTION_RECORD_SUCCESS:
      return {
        ...state,
        list: action.payload.records,
        count: action.payload.count,
        loading: false,
      };
    default:
      return state;
  }
};

const transactionInfoReducer = (state: ITransactionState['transactionInfo'], action: TransactionAction) => {
  if (action.type === TRANSACTION_INFO_SUCCESS) {
    return {
      ...action.payload,
      loading: false,
    };
  }
  return state;
};
