/* eslint-disable @typescript-eslint/no-explicit-any */
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FormLabelAlign } from 'antd/lib/form/interface';
import { useField } from 'formik';
import { FormItem, Checkbox as FormikAntdCheckbox } from 'formik-antd';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export interface CheckboxInputProp {
  id: string;
  name: string;
  label?: string | ReactNode;
  checkboxLabel?: string | ReactNode;
  value?: any;
  disabled?: boolean;
  onChange?: (e: CheckboxChangeEvent) => void;
  labelAlign?: FormLabelAlign; // default: right
  labelCol?: object; // ant design Col object
  wrapperCol?: object; // ant design Col object
  tooltip?: ReactNode;
  rules?: any;
  noStyle?: boolean;
  checked?: boolean;
  required?: boolean;
  className?: string;
  checkboxClassName?: string;
}

export const CheckboxInput: React.FC<CheckboxInputProp> = ({
  id,
  name,
  label,
  tooltip,
  labelCol,
  wrapperCol,
  labelAlign,
  required,
  rules,
  noStyle,
  className,
  checkboxClassName,
  onChange,
  checked,
  value,
  disabled,
  checkboxLabel,
}) => {
  const { t } = useTranslation();
  const [, meta] = useField({ name });

  return (
    <FormItem
      id={id}
      name={name}
      className={className}
      label={label}
      tooltip={tooltip}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      labelAlign={labelAlign}
      required={required}
      rules={rules}
      noStyle={noStyle}
      help={meta.touched && meta.error ? t(`${meta.error}`) : undefined}
    >
      <FormikAntdCheckbox
        className={checkboxClassName}
        onChange={onChange}
        checked={checked}
        value={value}
        name={name}
        disabled={disabled}
      >
        {checkboxLabel}
      </FormikAntdCheckbox>
    </FormItem>
  );
};

CheckboxInput.defaultProps = {
  labelAlign: 'left',
};
