import { takeEvery } from 'redux-saga/effects';
import {
  NEW_EMPLOYEE_REQUEST_LIST_FETCH,
  NEW_EMPLOYEE_REQUEST_INFO_FETCH,
  NEW_EMPLOYEE_REQUEST_UPDATE_FETCH,
} from '../constants';
import {
  newEmployeeRequestInfoSaga,
  newEmployeeRequestSaga,
  updateNewEmployeeRequestSaga,
} from './newRequestEmployeeSaga';

export function* rootNewEmployeeRequestSaga() {
  yield takeEvery(NEW_EMPLOYEE_REQUEST_LIST_FETCH, newEmployeeRequestSaga);
  yield takeEvery(NEW_EMPLOYEE_REQUEST_INFO_FETCH, newEmployeeRequestInfoSaga);
  yield takeEvery(NEW_EMPLOYEE_REQUEST_UPDATE_FETCH, updateNewEmployeeRequestSaga);
}
