/* eslint-disable no-shadow, @typescript-eslint/no-explicit-any */

enum ProductType {
  PAYMENT = 'payment',
  WITHDRAWAL = 'withdrawal',
}

enum MerchantRequestStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
  APPROVE = 'approve',
  REJECT = 'reject',
}

const productTypeLabel: any = {
  [ProductType.PAYMENT]: 'label.payment',
  [ProductType.WITHDRAWAL]: 'label.withdrawal',
};

const merchantRequestStatusLabel: any = {
  [MerchantRequestStatus.PENDING]: 'status.pending',
  [MerchantRequestStatus.ACTIVE]: 'status.active',
  [MerchantRequestStatus.APPROVE]: 'status.approved',
  [MerchantRequestStatus.REJECT]: 'status.rejected',
};

const merchantRequestStatusColor: any = {
  [MerchantRequestStatus.PENDING]: 'warning',
  [MerchantRequestStatus.ACTIVE]: 'success',
  [MerchantRequestStatus.APPROVE]: 'success',
  [MerchantRequestStatus.REJECT]: 'danger',
};

const productTypeSelect: { label: string; value: ProductType }[] = [
  { label: productTypeLabel[ProductType.PAYMENT], value: ProductType.PAYMENT },
  { label: productTypeLabel[ProductType.WITHDRAWAL], value: ProductType.WITHDRAWAL },
];

const merchantRequestStatusSelect: { label: string; value: MerchantRequestStatus }[] = [
  { label: merchantRequestStatusLabel[MerchantRequestStatus.PENDING], value: MerchantRequestStatus.PENDING },
  { label: merchantRequestStatusLabel[MerchantRequestStatus.APPROVE], value: MerchantRequestStatus.APPROVE },
  { label: merchantRequestStatusLabel[MerchantRequestStatus.REJECT], value: MerchantRequestStatus.REJECT },
];

export {
  ProductType,
  MerchantRequestStatus,
  productTypeLabel,
  merchantRequestStatusLabel,
  merchantRequestStatusColor,
  productTypeSelect,
  merchantRequestStatusSelect,
};
