/* eslint-disable @typescript-eslint/no-explicit-any */
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { FormLabelAlign } from 'antd/lib/form/interface';
import { RadioChangeEvent, RadioGroupButtonStyle, RadioGroupOptionType } from 'antd/lib/radio/interface';
import { useField } from 'formik';
import { FormItem, Radio as FormikAntdRadio } from 'formik-antd';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export interface RadioGroupInputProp {
  id: string;
  name: string;
  options: { label: string; value: any; disabled?: boolean }[];
  label?: string | ReactNode;
  defaultValue?: any;
  value?: any;
  disabled?: boolean;
  size?: SizeType;
  buttonStyle?: RadioGroupButtonStyle;
  optionType?: RadioGroupOptionType;
  onChange?: (e: RadioChangeEvent) => void;
  labelAlign?: FormLabelAlign; // default: right
  labelCol?: object; // ant design Col object
  wrapperCol?: object; // ant design Col object
  tooltip?: ReactNode;
  rules?: any;
  noStyle?: boolean;
  required?: boolean;
  radioGroupClassName?: string;
  extra?: string | ReactNode;
}

export const RadioGroupInput: React.FC<RadioGroupInputProp> = ({
  name,
  label,
  tooltip,
  labelCol,
  wrapperCol,
  labelAlign,
  required,
  rules,
  noStyle,
  id,
  radioGroupClassName,
  onChange,
  defaultValue,
  value,
  options,
  optionType,
  size,
  disabled,
  buttonStyle,
  extra,
}) => {
  const { t } = useTranslation();
  const [, meta] = useField({ name });

  return (
    <FormItem
      name={name}
      label={label}
      tooltip={tooltip}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      labelAlign={labelAlign}
      required={required}
      rules={rules}
      noStyle={noStyle}
      extra={extra}
      help={meta.touched && meta.error ? t(`${meta.error}`) : undefined}
    >
      <FormikAntdRadio.Group
        id={id}
        className={radioGroupClassName}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        name={name}
        options={options}
        optionType={optionType}
        size={size}
        disabled={disabled}
        buttonStyle={buttonStyle}
      />
    </FormItem>
  );
};

RadioGroupInput.defaultProps = {
  labelAlign: 'left',
};
