/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import {
  CREATE_SCHEDULER_TASK_RESET,
  CREATE_SCHEDULER_TASK_FETCH,
  CREATE_SCHEDULER_TASK_SUCCESS,
  CREATE_SCHEDULER_TASK_ERROR,
  SCHEDULER_LIST_ERROR,
  SCHEDULER_LIST_FETCH,
  SCHEDULER_LIST_SUCCESS,
  UPDATE_SCHEDULER_TASK_RESET,
  UPDATE_SCHEDULER_TASK_FETCH,
  UPDATE_SCHEDULER_TASK_SUCCESS,
  UPDATE_SCHEDULER_TASK_ERROR,
  CANCEL_SCHEDULER_TASK_SUCCESS,
  CANCEL_SCHEDULER_TASK_ERROR,
  CANCEL_SCHEDULER_TASK_RESET,
  CANCEL_SCHEDULER_TASK_FETCH,
  WORK_STATUS_LIST_FETCH,
  WORK_STATUS_LIST_ERROR,
  WORK_STATUS_LIST_SUCCESS,
  WORK_STATUS_INFO_SUCCESS,
  WORK_STATUS_INFO_ERROR,
  WORK_STATUS_INFO_FETCH,
} from './constants';
import { SchedulerPayloadState, WorkStatusInfoState } from './types';

export interface ISchedulerListFetch {
  type: typeof SCHEDULER_LIST_FETCH;
  payload: {
    dateFrom?: number;
    dateTo?: number;
    name?: string;
    type?: string;
    eid: string;
    page: number;
  };
}

export interface ISchedulerListSuccess {
  type: typeof SCHEDULER_LIST_SUCCESS;
  payload: {
    resources: any[];
    events: any[];
    count: number;
  };
}

export interface ISchedulerListError {
  type: typeof SCHEDULER_LIST_ERROR;
}

export interface ICreateSchedulerTaskReset {
  type: typeof CREATE_SCHEDULER_TASK_RESET;
}

export interface ICreateSchedulerTaskFetch {
  type: typeof CREATE_SCHEDULER_TASK_FETCH;
  payload: SchedulerPayloadState;
  onSuccess?: () => void;
  onError?: () => void;
}

export interface ICreateSchedulerTaskSuccess {
  type: typeof CREATE_SCHEDULER_TASK_SUCCESS;
}

export interface ICreateSchedulerTaskError {
  type: typeof CREATE_SCHEDULER_TASK_ERROR;
}

export interface IUpdateSchedulerTaskReset {
  type: typeof UPDATE_SCHEDULER_TASK_RESET;
}

export interface IUpdateSchedulerTaskFetch {
  type: typeof UPDATE_SCHEDULER_TASK_FETCH;
  payload: SchedulerPayloadState;
  onSuccess?: () => void;
  onError?: () => void;
}

export interface IUpdateSchedulerTaskSuccess {
  type: typeof UPDATE_SCHEDULER_TASK_SUCCESS;
}

export interface IUpdateSchedulerTaskError {
  type: typeof UPDATE_SCHEDULER_TASK_ERROR;
}

export interface ICancelSchedulerTaskReset {
  type: typeof CANCEL_SCHEDULER_TASK_RESET;
}

export interface ICancelSchedulerTaskFetch {
  type: typeof CANCEL_SCHEDULER_TASK_FETCH;
  payload: {
    id: string;
    type: 'child' | 'parent';
  };
  onSuccess?: () => void;
  onError?: () => void;
}

export interface ICancelSchedulerTaskSuccess {
  type: typeof CANCEL_SCHEDULER_TASK_SUCCESS;
}

export interface ICancelSchedulerTaskError {
  type: typeof CANCEL_SCHEDULER_TASK_ERROR;
}

export interface IWorkStatusListFetch {
  type: typeof WORK_STATUS_LIST_FETCH;
  payload: {
    dateFrom?: number;
    dateTo?: number;
    date?: moment.Moment;
    name?: string;
    page: number;
  };
}

export interface IWorkStatusListSuccess {
  type: typeof WORK_STATUS_LIST_SUCCESS;
  payload: {
    records: any[];
    count: number;
  };
}

export interface IWorkStatusListError {
  type: typeof WORK_STATUS_LIST_ERROR;
}

export interface IWorkStatusInfoFetch {
  type: typeof WORK_STATUS_INFO_FETCH;
  payload: {
    id: string;
  };
}

export interface IWorkStatusInfoSuccess {
  type: typeof WORK_STATUS_INFO_SUCCESS;
  payload: WorkStatusInfoState;
}

export interface IWorkStatusInfoError {
  type: typeof WORK_STATUS_INFO_ERROR;
}

export type SchedulerAction =
  | ISchedulerListFetch
  | ISchedulerListSuccess
  | ISchedulerListError
  | ICreateSchedulerTaskReset
  | ICreateSchedulerTaskFetch
  | ICreateSchedulerTaskSuccess
  | ICreateSchedulerTaskError
  | IUpdateSchedulerTaskReset
  | IUpdateSchedulerTaskFetch
  | IUpdateSchedulerTaskSuccess
  | IUpdateSchedulerTaskError
  | ICancelSchedulerTaskReset
  | ICancelSchedulerTaskFetch
  | ICancelSchedulerTaskSuccess
  | ICancelSchedulerTaskError
  | IWorkStatusListFetch
  | IWorkStatusListSuccess
  | IWorkStatusListError
  | IWorkStatusInfoFetch
  | IWorkStatusInfoSuccess
  | IWorkStatusInfoError;

export const schedulerListFetch = (payload: ISchedulerListFetch['payload']): ISchedulerListFetch => ({
  type: SCHEDULER_LIST_FETCH,
  payload,
});

export const schedulerListSuccess = (payload: ISchedulerListSuccess['payload']): ISchedulerListSuccess => ({
  type: SCHEDULER_LIST_SUCCESS,
  payload,
});

export const schedulerListError = (): ISchedulerListError => ({
  type: SCHEDULER_LIST_ERROR,
});

export const createSchedulerReset = (): ICreateSchedulerTaskReset => ({
  type: CREATE_SCHEDULER_TASK_RESET,
});

export const createSchedulerFetch = (
  payload: ICreateSchedulerTaskFetch['payload'],
  onSuccess?: () => void,
  onError?: () => void
): ICreateSchedulerTaskFetch => {
  return {
    type: CREATE_SCHEDULER_TASK_FETCH,
    payload,
    onSuccess,
    onError,
  };
};

export const createSchedulerSuccess = (): ICreateSchedulerTaskSuccess => ({
  type: CREATE_SCHEDULER_TASK_SUCCESS,
});

export const createSchedulerError = (): ICreateSchedulerTaskError => ({
  type: CREATE_SCHEDULER_TASK_ERROR,
});

export const updateSchedulerReset = (): IUpdateSchedulerTaskReset => ({
  type: UPDATE_SCHEDULER_TASK_RESET,
});

export const updateSchedulerFetch = (
  payload: IUpdateSchedulerTaskFetch['payload'],
  onSuccess?: () => void,
  onError?: () => void
): IUpdateSchedulerTaskFetch => ({
  type: UPDATE_SCHEDULER_TASK_FETCH,
  payload,
  onSuccess,
  onError,
});

export const updateSchedulerSuccess = (): IUpdateSchedulerTaskSuccess => ({
  type: UPDATE_SCHEDULER_TASK_SUCCESS,
});

export const updateSchedulerError = (): IUpdateSchedulerTaskError => ({
  type: UPDATE_SCHEDULER_TASK_ERROR,
});

export const cancelSchedulerReset = (): ICancelSchedulerTaskReset => ({
  type: CANCEL_SCHEDULER_TASK_RESET,
});

export const cancelSchedulerFetch = (
  payload: ICancelSchedulerTaskFetch['payload'],
  onSuccess?: () => void,
  onError?: () => void
): ICancelSchedulerTaskFetch => ({
  type: CANCEL_SCHEDULER_TASK_FETCH,
  payload,
  onSuccess,
  onError,
});

export const cancelSchedulerSuccess = (): ICancelSchedulerTaskSuccess => ({
  type: CANCEL_SCHEDULER_TASK_SUCCESS,
});

export const cancelSchedulerError = (): ICancelSchedulerTaskError => ({
  type: CANCEL_SCHEDULER_TASK_ERROR,
});

export const workStatusListFetch = (payload: IWorkStatusListFetch['payload']): IWorkStatusListFetch => ({
  type: WORK_STATUS_LIST_FETCH,
  payload,
});

export const workStatusListSuccess = (payload: IWorkStatusListSuccess['payload']): IWorkStatusListSuccess => ({
  type: WORK_STATUS_LIST_SUCCESS,
  payload,
});

export const workStatusListError = (): IWorkStatusListError => ({
  type: WORK_STATUS_LIST_ERROR,
});

export const workStatusInfoFetch = (payload: IWorkStatusInfoFetch['payload']): IWorkStatusInfoFetch => ({
  type: WORK_STATUS_INFO_FETCH,
  payload,
});

export const workStatusInfoSuccess = (payload: IWorkStatusInfoSuccess['payload']): IWorkStatusInfoSuccess => ({
  type: WORK_STATUS_INFO_SUCCESS,
  payload,
});

export const workStatusInfoError = (): IWorkStatusInfoError => ({
  type: WORK_STATUS_INFO_ERROR,
});
