/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CREATE_PROMOTION_ERROR,
  CREATE_PROMOTION_FETCH,
  CREATE_PROMOTION_RESET,
  CREATE_PROMOTION_SUCCESS,
  PROMOTION_INFO_FETCH,
  PROMOTION_INFO_RESET,
  PROMOTION_INFO_SUCCESS,
  PROMOTION_LIST_FETCH,
  PROMOTION_LIST_SUCCESS,
  UPDATE_PROMOTION_ERROR,
  UPDATE_PROMOTION_FETCH,
  UPDATE_PROMOTION_RESET,
  UPDATE_PROMOTION_STATUS_ERROR,
  UPDATE_PROMOTION_STATUS_FETCH,
  UPDATE_PROMOTION_STATUS_RESET,
  UPDATE_PROMOTION_STATUS_SUCCESS,
  UPDATE_PROMOTION_SUCCESS,
} from './constants';
import { IBannerState, IInsightState, IPromotionInfoState } from './types';

export interface IPromotionListFetch {
  type: typeof PROMOTION_LIST_FETCH;
  payload: {
    from?: number;
    to?: number;
    status?: string;
    type?: string;
    page: number;
  };
}

export interface IPromotionListSuccess {
  type: typeof PROMOTION_LIST_SUCCESS;
  payload: {
    records: any;
    count: number;
  };
}

export interface IPromotionInfoReset {
  type: typeof PROMOTION_INFO_RESET;
}
export interface IPromotionInfoFetch {
  type: typeof PROMOTION_INFO_FETCH;
  payload: {
    id: string;
  };
}

export interface IPromotionInfoSuccess {
  type: typeof PROMOTION_INFO_SUCCESS;
  payload: IPromotionInfoState;
}

export interface ICreatePromotionReset {
  type: typeof CREATE_PROMOTION_RESET;
}

export interface ICreatePromotionFetch {
  type: typeof CREATE_PROMOTION_FETCH;
  payload: IBannerState | IInsightState;
}

export interface ICreatePromotionSuccess {
  type: typeof CREATE_PROMOTION_SUCCESS;
}

export interface ICreatePromotionError {
  type: typeof CREATE_PROMOTION_ERROR;
}

export interface IUpdatePromotionReset {
  type: typeof UPDATE_PROMOTION_RESET;
}

export interface IUpdatePromotionFetch {
  type: typeof UPDATE_PROMOTION_FETCH;
  payload: IBannerState | IInsightState;
}

export interface IUpdatePromotionSuccess {
  type: typeof UPDATE_PROMOTION_SUCCESS;
}

export interface IUpdatePromotionError {
  type: typeof UPDATE_PROMOTION_ERROR;
}

export interface IUpdatePromotionStatusReset {
  type: typeof UPDATE_PROMOTION_STATUS_RESET;
}

export interface IUpdatePromotionStatusFetch {
  type: typeof UPDATE_PROMOTION_STATUS_FETCH;
  payload: {
    pid: string;
    paused: boolean;
    startDate?: number;
    endDate?: number;
  };
}

export interface IUpdatePromotionStatusSuccess {
  type: typeof UPDATE_PROMOTION_STATUS_SUCCESS;
}

export interface IUpdatePromotionStatusError {
  type: typeof UPDATE_PROMOTION_STATUS_ERROR;
}

export type promotionAction =
  | IPromotionListFetch
  | IPromotionListSuccess
  | IPromotionInfoReset
  | IPromotionInfoFetch
  | IPromotionInfoSuccess
  | ICreatePromotionReset
  | ICreatePromotionFetch
  | ICreatePromotionSuccess
  | ICreatePromotionError
  | IUpdatePromotionReset
  | IUpdatePromotionFetch
  | IUpdatePromotionSuccess
  | IUpdatePromotionError
  | IUpdatePromotionStatusReset
  | IUpdatePromotionStatusFetch
  | IUpdatePromotionStatusSuccess
  | IUpdatePromotionStatusError;

export const promotionList = (payload: IPromotionListFetch['payload']): IPromotionListFetch => ({
  type: PROMOTION_LIST_FETCH,
  payload,
});

export const successPromotionList = (payload: IPromotionListSuccess['payload']): IPromotionListSuccess => ({
  type: PROMOTION_LIST_SUCCESS,
  payload,
});

export const resetPromotionInfo = (): IPromotionInfoReset => ({
  type: PROMOTION_INFO_RESET,
});

export const promotionInfoFetch = (payload: IPromotionInfoFetch['payload']): IPromotionInfoFetch => ({
  type: PROMOTION_INFO_FETCH,
  payload,
});

export const successPromotionInfo = (payload: IPromotionInfoSuccess['payload']): IPromotionInfoSuccess => ({
  type: PROMOTION_INFO_SUCCESS,
  payload,
});

export const resetCreatePromotion = (): ICreatePromotionReset => ({
  type: CREATE_PROMOTION_RESET,
});

export const createPromotionFetch = (payload: ICreatePromotionFetch['payload']): ICreatePromotionFetch => ({
  type: CREATE_PROMOTION_FETCH,
  payload,
});

export const successCreatePromotion = (): ICreatePromotionSuccess => ({
  type: CREATE_PROMOTION_SUCCESS,
});

export const errorCreatePromotion = (): ICreatePromotionError => ({
  type: CREATE_PROMOTION_ERROR,
});

export const resetUpdatePromotion = (): IUpdatePromotionReset => ({
  type: UPDATE_PROMOTION_RESET,
});

export const updatePromotionFetch = (payload: IUpdatePromotionFetch['payload']): IUpdatePromotionFetch => ({
  type: UPDATE_PROMOTION_FETCH,
  payload,
});

export const successUpdatePromotion = (): IUpdatePromotionSuccess => ({
  type: UPDATE_PROMOTION_SUCCESS,
});

export const errorUpdatePromotion = (): IUpdatePromotionError => ({
  type: UPDATE_PROMOTION_ERROR,
});

export const resetUpdatePromotionStatus = (): IUpdatePromotionStatusReset => ({
  type: UPDATE_PROMOTION_STATUS_RESET,
});

export const updatePromotionStatusFetch = (
  payload: IUpdatePromotionStatusFetch['payload']
): IUpdatePromotionStatusFetch => ({
  type: UPDATE_PROMOTION_STATUS_FETCH,
  payload,
});

export const successUpdatePromotionStatus = (): IUpdatePromotionStatusSuccess => ({
  type: UPDATE_PROMOTION_STATUS_SUCCESS,
});

export const errorUpdatePromotionStatus = (): IUpdatePromotionStatusError => ({
  type: UPDATE_PROMOTION_STATUS_ERROR,
});
