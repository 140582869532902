/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryClient } from 'react-query';

export const getLatestQuery = (arrOfQueries: Array<any>, queryClient: QueryClient) => {
  const queryParamsWithTimeStamps = arrOfQueries?.map((x) => extractParamsWithTimeStamps(x, queryClient));
  return extractLatestQuery(queryParamsWithTimeStamps);
};

const extractParamsWithTimeStamps = (queryData: any, queryClient: QueryClient) => {
  const queryKey = queryData[0][0];
  const queryParams = queryData[0][1];
  const queryState = queryClient.getQueryState([queryKey, queryParams]);
  return { queryKey, queryParams, updated: queryState?.dataUpdatedAt };
};

const extractLatestQuery = (queriesWithTime: Array<any>): { queryKey: any; queryParams: any; updated: any } => {
  if (queriesWithTime.length <= 0) {
    return { queryKey: undefined, queryParams: undefined, updated: undefined };
  }

  return queriesWithTime?.reduce((a, b) => (a.updated > b.updated ? a : b));
};
