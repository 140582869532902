/* eslint-disable no-shadow, @typescript-eslint/no-explicit-any */

const schedulerViewType: any = {
  0: 'date',
  1: 'week',
  2: 'month',
};

const schedulerViewTypeApi: any = {
  0: 'daily',
  1: 'weekly',
  2: 'monthly',
};

const attendanceViewType: any = {
  daily: 'date',
  weekly: 'week',
  monthly: 'month',
};

const daysType: any = {
  mon: { label: 'label.monday', value: 'mon' },
  tue: { label: 'label.tuesday', value: 'tue' },
  wed: { label: 'label.wednesday', value: 'wed' },
  thu: { label: 'label.thursday', value: 'thu' },
  fri: { label: 'label.friday', value: 'fri' },
};

const daysTypeSelect = [daysType.mon, daysType.tue, daysType.wed, daysType.thu, daysType.fri];

export { attendanceViewType, schedulerViewType, schedulerViewTypeApi, daysType, daysTypeSelect };
