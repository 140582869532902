import {
  NOTIFICATION_LIST_FETCH,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_READ_FETCH,
  NOTIFICATION_READ_SUCCESS,
  NOTIFICATION_READ_ERROR,
} from './constants';

export interface INotificationListFetch {
  type: typeof NOTIFICATION_LIST_FETCH;
}

export interface INotificationListSuccess {
  type: typeof NOTIFICATION_LIST_SUCCESS;
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    records: any;
    count: number;
    bulkImportPendingCount?: number;
    employerRequestPendingCount?: number;
  };
}

export interface INotificationReadFetch {
  type: typeof NOTIFICATION_READ_FETCH;
  payload: {
    id: string;
  };
}

export interface INotificationReadSuccess {
  type: typeof NOTIFICATION_READ_SUCCESS;
}

export interface INotificationReadError {
  type: typeof NOTIFICATION_READ_ERROR;
}

export type notificationAction =
  | INotificationListFetch
  | INotificationListSuccess
  | INotificationReadFetch
  | INotificationReadSuccess
  | INotificationReadError;

export const notificationListFetch = (): INotificationListFetch => ({
  type: NOTIFICATION_LIST_FETCH,
});

export const notificationListSuccess = (payload: INotificationListSuccess['payload']): INotificationListSuccess => ({
  type: NOTIFICATION_LIST_SUCCESS,
  payload,
});

export const notificationReadFetch = (payload: INotificationReadFetch['payload']): INotificationReadFetch => ({
  type: NOTIFICATION_READ_FETCH,
  payload,
});

export const notificationReadSuccess = (): INotificationReadSuccess => ({
  type: NOTIFICATION_READ_SUCCESS,
});

export const notificationReadError = (): INotificationReadError => ({
  type: NOTIFICATION_READ_ERROR,
});
