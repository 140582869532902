/* eslint-disable @typescript-eslint/no-explicit-any */
import { promotionAction } from './actions';
import {
  CREATE_PROMOTION_ERROR,
  CREATE_PROMOTION_FETCH,
  CREATE_PROMOTION_RESET,
  CREATE_PROMOTION_SUCCESS,
  PROMOTION_INFO_FETCH,
  PROMOTION_INFO_RESET,
  PROMOTION_INFO_SUCCESS,
  PROMOTION_LIST_FETCH,
  PROMOTION_LIST_SUCCESS,
  UPDATE_PROMOTION_ERROR,
  UPDATE_PROMOTION_FETCH,
  UPDATE_PROMOTION_RESET,
  UPDATE_PROMOTION_STATUS_ERROR,
  UPDATE_PROMOTION_STATUS_FETCH,
  UPDATE_PROMOTION_STATUS_RESET,
  UPDATE_PROMOTION_STATUS_SUCCESS,
  UPDATE_PROMOTION_SUCCESS,
} from './constants';
import { IPromotionInfoState, initPromotionInfoState } from './types';

export interface IPromotionState {
  promotionList: {
    payload: {
      from?: number;
      to?: number;
      status?: string;
      type?: string;
      page: number;
    };
    list: any;
    count: number;
    loading: boolean;
  };
  promotionInfo: IPromotionInfoState;
  createPromotion: {
    loading: boolean;
    success: boolean;
    error?: boolean;
  };
  updatePromotion: {
    loading: boolean;
    success: boolean;
    error?: boolean;
  };
  updatePromotionStatus: {
    loading: boolean;
    success: boolean;
    error?: boolean;
  };
}

const initialState: IPromotionState = {
  promotionList: {
    payload: {
      page: 1,
      // from: null,
      // to: null
    },
    list: [],
    count: 0,
    loading: false,
  },
  promotionInfo: initPromotionInfoState,
  createPromotion: {
    loading: false,
    success: false,
    error: false,
  },
  updatePromotion: {
    loading: false,
    success: false,
    error: false,
  },
  updatePromotionStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

const promotionListReducer = (state: IPromotionState['promotionList'], action: promotionAction) => {
  switch (action.type) {
    case PROMOTION_LIST_FETCH:
      return {
        ...state,
        payload: action.payload,
        loading: true,
      };
    case PROMOTION_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.records,
        count: action.payload.count,
        loading: false,
      };
    default:
      return state;
  }
};

const promotionInfoReducer = (state: IPromotionState['promotionInfo'], action: promotionAction) => {
  switch (action.type) {
    case PROMOTION_INFO_RESET:
      return initialState.promotionInfo;
    case PROMOTION_INFO_FETCH:
      return {
        ...state,
        loading: true,
      };
    case PROMOTION_INFO_SUCCESS:
      return {
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

const createPromotion = (state: IPromotionState['createPromotion'], action: promotionAction) => {
  switch (action.type) {
    case CREATE_PROMOTION_RESET:
      return initialState.createPromotion;
    case CREATE_PROMOTION_FETCH:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case CREATE_PROMOTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case CREATE_PROMOTION_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
      };
    default:
      return state;
  }
};

const updatePromotion = (state: IPromotionState['updatePromotion'], action: promotionAction) => {
  switch (action.type) {
    case UPDATE_PROMOTION_RESET:
      return initialState.updatePromotion;
    case UPDATE_PROMOTION_FETCH:
      return {
        loading: true,
        success: false,
      };
    case UPDATE_PROMOTION_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case UPDATE_PROMOTION_ERROR:
      return {
        loading: false,
        success: false,
      };
    default:
      return state;
  }
};

const updatePromotionStatus = (state: IPromotionState['updatePromotionStatus'], action: promotionAction) => {
  switch (action.type) {
    case UPDATE_PROMOTION_STATUS_RESET:
      return initialState.updatePromotionStatus;
    case UPDATE_PROMOTION_STATUS_FETCH:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case UPDATE_PROMOTION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case UPDATE_PROMOTION_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last
export const promotionReducer = (state = initialState, action: promotionAction) => {
  switch (action.type) {
    case PROMOTION_LIST_FETCH:
    case PROMOTION_LIST_SUCCESS:
      return {
        ...state,
        promotionList: promotionListReducer({ ...state.promotionList }, action),
      };
    case PROMOTION_INFO_RESET:
    case PROMOTION_INFO_FETCH:
    case PROMOTION_INFO_SUCCESS:
      return {
        ...state,
        promotionInfo: promotionInfoReducer({ ...state.promotionInfo }, action),
      };
    case CREATE_PROMOTION_RESET:
    case CREATE_PROMOTION_FETCH:
    case CREATE_PROMOTION_SUCCESS:
    case CREATE_PROMOTION_ERROR:
      return {
        ...state,
        createPromotion: createPromotion({ ...state.createPromotion }, action),
      };
    case UPDATE_PROMOTION_RESET:
    case UPDATE_PROMOTION_FETCH:
    case UPDATE_PROMOTION_SUCCESS:
    case UPDATE_PROMOTION_ERROR:
      return {
        ...state,
        updatePromotion: updatePromotion({ ...state.updatePromotion }, action),
      };
    case UPDATE_PROMOTION_STATUS_RESET:
    case UPDATE_PROMOTION_STATUS_FETCH:
    case UPDATE_PROMOTION_STATUS_SUCCESS:
    case UPDATE_PROMOTION_STATUS_ERROR:
      return {
        ...state,
        updatePromotionStatus: updatePromotionStatus({ ...state.updatePromotionStatus }, action),
      };
    default:
      return state;
  }
};
