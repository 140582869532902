/* eslint-disable @typescript-eslint/no-explicit-any, import/no-cycle */
import {
  EKYC_LIST_FETCH,
  EKYC_LIST_SUCCESS,
  EKYC_INFO_FETCH,
  EKYC_INFO_SUCCESS,
  EKYC_KYC_FILE_RETRIEVE_FETCH,
  EKYC_KYC_FILE_RETRIEVE_SUCCESS,
} from './constants';
import { IEkycInfoState } from './types';

export interface IEkycListFetch {
  type: typeof EKYC_LIST_FETCH;
  payload: {
    from?: string;
    to?: string;
    status?: string;
    type?: string;
    mobileNo?: string;
    employeeName?: string;
    ekycDate?: string;
    uid?: string;
  };
}

export interface IEkycListSuccess {
  type: typeof EKYC_LIST_SUCCESS;
  payload: {
    records: any;
  };
}

export interface IEkycInfoFetch {
  type: typeof EKYC_INFO_FETCH;
  payload: {
    id: string;
  };
}

export interface IEkycInfoSuccess {
  type: typeof EKYC_INFO_SUCCESS;
  payload: IEkycInfoState;
}

export interface IEkycFileRetrieveFetch {
  type: typeof EKYC_KYC_FILE_RETRIEVE_FETCH;
  payload: {
    id: string;
    key: string;
  };
}

export interface IEkycFileRetrieveSuccess {
  type: typeof EKYC_KYC_FILE_RETRIEVE_SUCCESS;
  payload: {
    data: Blob | undefined;
    key: string;
  };
}

export type ekycAction =
  | IEkycListFetch
  | IEkycListSuccess
  | IEkycInfoFetch
  | IEkycInfoSuccess
  | IEkycFileRetrieveFetch
  | IEkycFileRetrieveSuccess;

export const ekycListFetch = (payload: IEkycListFetch['payload']): IEkycListFetch => ({
  type: EKYC_LIST_FETCH,
  payload,
});

export const successEkycList = (payload: IEkycListSuccess['payload']): IEkycListSuccess => ({
  type: EKYC_LIST_SUCCESS,
  payload,
});

export const ekycInfoFetch = (payload: IEkycInfoFetch['payload']): IEkycInfoFetch => ({
  type: EKYC_INFO_FETCH,
  payload,
});

export const successEkycInfo = (payload: IEkycInfoSuccess['payload']): IEkycInfoSuccess => ({
  type: EKYC_INFO_SUCCESS,
  payload,
});

export const ekycFileRetrieveFetch = (payload: IEkycFileRetrieveFetch['payload']): IEkycFileRetrieveFetch => ({
  type: EKYC_KYC_FILE_RETRIEVE_FETCH,
  payload,
});

export const ekycFileRetrieveSuccess = (payload: IEkycFileRetrieveSuccess['payload']): IEkycFileRetrieveSuccess => ({
  type: EKYC_KYC_FILE_RETRIEVE_SUCCESS,
  payload,
});
