/* eslint-disable no-shadow, @typescript-eslint/no-explicit-any */

enum EmployerReportStatus {
  SENT = 'sent',
  PENDING = 'pending',
  REJECTED = 'rejected',
}

enum InvoiceReportStatus {
  SENT = 'sent',
  PENDING = 'pending',
  REJECTED = 'rejected',
}

enum ReportFileType {
  ALL = 'all',
  OBA_FILE = 'oba',
  PAYMENT_FILE = 'payment',
  REPAYMENT_FILE = 'repayment',
  REPAYMENT_EXCEPTIONAL_FILE = 'repaymentExceptional',
  OBA_EXCEPTIONAL_FILE = 'oba_exception',
  WITHDRAWAL_REVERSAL_FILE = 'withdrawalReversal',
  EXCEPTIONAL = 'exceptional',
  EMPLOYEE_INFO_FILE = 'employeeInfo',
}

enum LaspeRepaymentStatus {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
}

const reportFileTypeSelection: any = {
  all: { label: 'label.all', value: ReportFileType.ALL },
  obaFile: { label: 'label.obaFile', value: ReportFileType.OBA_FILE },
  paymentFile: { label: 'label.paymentFile', value: ReportFileType.PAYMENT_FILE },
  repaymentFile: { label: 'label.repaymentFile', value: ReportFileType.REPAYMENT_FILE },
  repaymentExceptionalFile: {
    label: 'label.repaymentExceptionalFile',
    value: ReportFileType.REPAYMENT_EXCEPTIONAL_FILE,
  },
  obaExceptionalFile: { label: 'label.obaExceptionalFile', value: ReportFileType.OBA_EXCEPTIONAL_FILE },
  reverseWithdrawalFile: { label: 'label.withdrawalReversal', value: ReportFileType.WITHDRAWAL_REVERSAL_FILE },
  employeeInfoFile: { label: 'label.employeeInfo', value: ReportFileType.EMPLOYEE_INFO_FILE },
};

const reportHLBBTypeSelect = [
  reportFileTypeSelection.obaFile,
  reportFileTypeSelection.paymentFile,
  reportFileTypeSelection.repaymentFile,
  reportFileTypeSelection.repaymentExceptionalFile,
];

const reportExceptionalTypeSelect = [reportFileTypeSelection.exceptionalFile];

const reportEmployeeInfoTypeSelect = [reportFileTypeSelection.employeeInfoFile];

const lapseRepaymentStatusSelect = [
  { label: 'status.active', value: LaspeRepaymentStatus.ACTIVE },
  { label: 'status.suspended', value: LaspeRepaymentStatus.SUSPENDED },
];

const exceptionalReportTypeSelect: any = [
  { label: 'label.allExceptional', value: ReportFileType.EXCEPTIONAL },
  { label: 'label.obaExceptional', value: ReportFileType.OBA_EXCEPTIONAL_FILE },
  { label: 'label.withdrawalReversal', value: ReportFileType.WITHDRAWAL_REVERSAL_FILE },
];

const reportTypeConvertName: any = {
  [ReportFileType.OBA_EXCEPTIONAL_FILE]: 'label.obaExceptional',
  [ReportFileType.WITHDRAWAL_REVERSAL_FILE]: 'label.withdrawalReversal',
  [ReportFileType.EXCEPTIONAL]: 'label.exceptional',
};

export {
  ReportFileType,
  EmployerReportStatus,
  InvoiceReportStatus,
  reportHLBBTypeSelect,
  reportExceptionalTypeSelect,
  reportEmployeeInfoTypeSelect,
  lapseRepaymentStatusSelect,
  exceptionalReportTypeSelect,
  reportTypeConvertName,
};
