/* eslint-disable @typescript-eslint/no-explicit-any, import/no-cycle */
import { EmployeeStatus, EmploymentStatus } from 'common';
import { EmployeeAction } from './actions';
import {
  EMPLOYEE_COMPANY_INFO_ERROR,
  EMPLOYEE_COMPANY_INFO_FETCH,
  EMPLOYEE_COMPANY_INFO_SUCCESS,
  EMPLOYEE_COMPANY_RECORD_FETCH,
  EMPLOYEE_COMPANY_RECORD_SUCCESS,
  EMPLOYEE_EKYC_RECORD_FETCH,
  EMPLOYEE_EKYC_RECORD_SUCCESS,
  EMPLOYEE_INFO_ERROR,
  EMPLOYEE_INFO_FETCH,
  EMPLOYEE_INFO_SUCCESS,
  EMPLOYEE_LAPSE_REPAYMENT_RECORD_FETCH,
  EMPLOYEE_LAPSE_REPAYMENT_RECORD_SUCCESS,
  EMPLOYEE_RECORD_FETCH,
  EMPLOYEE_RECORD_SUCCESS,
  EMPLOYEE_TRANSACTION_RECORD_FETCH,
  EMPLOYEE_TRANSACTION_RECORD_SUCCESS,
  EMPLOYEE_UPDATE_COMPANY_INFO_ERROR,
  EMPLOYEE_UPDATE_COMPANY_INFO_FETCH,
  EMPLOYEE_UPDATE_COMPANY_INFO_SUCCESS,
  EMPLOYEE_UPDATE_COMPANY_STATUS_ERROR,
  EMPLOYEE_UPDATE_COMPANY_STATUS_FETCH,
  EMPLOYEE_UPDATE_COMPANY_STATUS_RESET,
  EMPLOYEE_UPDATE_COMPANY_STATUS_SUCCESS,
  EMPLOYEE_WORK_HOUR_RECORD_FETCH,
  EMPLOYEE_WORK_HOUR_RECORD_SUCCESS,
} from './constants';

export interface IEmployeeState {
  employeeRecord: {
    list: any;
    count: number;
    loading: boolean;
    payload: {
      name?: string;
      mobile?: string;
      eid?: string;
      staffId?: string;
      userId?: string;
      page: number;
    };
  };
  employeeInfo: {
    emailVerified: boolean;
    profile: {
      email: string;
      name: string;
      mobile: string;
      address: {
        line2: string;
        line1: string;
        postcode: string;
        country: string;
        state: string;
        city: string;
      };
      idType: string;
      idNo: string;
      gender: string;
      nationality: string;
      countryOfBirth: string;
      dateOfBirth: string;
      maritalStatus: string;
      monthlyRepaymentRent: string;
    };
    status: EmployeeStatus;
    userProfile: string;
    salaryBankInfo: {
      bankName: string;
      accountName: string;
      accountNumber: string;
    };
    obaBankInfo: {
      ebid: string;
      eid: string;
      employerName: string;
      bankName: string;
      accountName: string;
      accountNumber: string;
    }[];
    walletSummary: {
      totalBalance: number;
      totalCredit: number;
      totalEarning: number;
      totalWithdrawn: number;
      totalFee: number;
    };
  };
  employeeCompanyRecord: {
    list: any;
  };
  employeeCompanyInfo: {
    id: string;
    eid: string;
    status: EmploymentStatus | '';
    reason: string;
    companyName: string;
    employeeDetails: {
      employeeId: string;
      name: string;
      mobile: string;
      companyEmailAddress: string;
      role: string;
    };
    salaryDetails: {
      salary: number;
      frequency: string;
      salaryDay: number;
      maxWithdrawalAmount: number;
      workingDays: number;
    };
    withdrawal: {
      ltv: number;
      eligibleEarning: number;
      limit: {
        min: number;
        max: number;
      };
    };
    oba: {
      bankName?: string;
      accountName?: string;
      accountNumber?: string;
    };
    bindDate: string;
    createdOn?: number;
    resignationDate: any;
    scheduler?: boolean;
    checkInEnabled: boolean;
    outlets: {
      id: string;
      name: string;
      address: string;
      isHq?: boolean;
      actId?: 'create' | 'delete';
    }[];
    commissions: any[];
    leaves: any[];
    loading: boolean;
  };
  employeeWorkHourRecord: {
    list: any;
    count: number;
    currentPage: number;
    loading: boolean;
  };
  employeeTransactionRecord: {
    list: any;
    count: number;
    currentPage: number;
    loading: boolean;
  };
  employeeLapseRepaymentRecord: {
    list: any;
  };
  employeeEkyctRecord: {
    list: any;
  };
  updateEmployeeCompanyInfo: {
    success?: boolean;
    error?: string;
  };
  updateEmployeeCompanyStatus: {
    loading: boolean;
    success?: boolean;
    error?: boolean;
  };
}

const initialState: IEmployeeState = {
  employeeRecord: {
    list: [],
    count: 0,
    loading: false,
    payload: {
      page: 1,
    },
  },
  employeeInfo: {
    emailVerified: false,
    profile: {
      email: '',
      name: '',
      mobile: '',
      address: {
        line2: '',
        line1: '',
        postcode: '',
        state: '',
        country: '',
        city: '',
      },
      idType: '',
      idNo: '',
      gender: '',
      nationality: '',
      countryOfBirth: '',
      dateOfBirth: '',
      maritalStatus: '',
      monthlyRepaymentRent: '',
    },
    status: EmployeeStatus.BASIC,
    userProfile: '',
    salaryBankInfo: {
      bankName: '',
      accountName: '',
      accountNumber: '',
    },
    obaBankInfo: [],
    walletSummary: {
      totalBalance: 0,
      totalCredit: 0,
      totalEarning: 0,
      totalWithdrawn: 0,
      totalFee: 0,
    },
  },
  employeeCompanyRecord: {
    list: [],
  },
  employeeCompanyInfo: {
    id: '',
    eid: '',
    status: '',
    reason: '',
    companyName: '',
    employeeDetails: {
      employeeId: '',
      name: '',
      mobile: '',
      companyEmailAddress: '',
      role: '',
    },
    salaryDetails: {
      salary: 0,
      frequency: '',
      salaryDay: 0,
      maxWithdrawalAmount: 0,
      workingDays: 0,
    },
    withdrawal: {
      ltv: 50,
      eligibleEarning: 1500,
      limit: {
        min: 0,
        max: 2000,
      },
    },
    oba: {},
    bindDate: '',
    resignationDate: '',
    checkInEnabled: true,
    outlets: [],
    commissions: [],
    leaves: [],
    loading: false,
  },
  employeeWorkHourRecord: {
    list: [],
    count: 0,
    currentPage: 1,
    loading: false,
  },
  employeeTransactionRecord: {
    list: [],
    count: 0,
    currentPage: 1,
    loading: false,
  },
  employeeLapseRepaymentRecord: {
    list: [],
  },
  employeeEkyctRecord: {
    list: [],
  },
  updateEmployeeCompanyInfo: {
    success: false,
  },
  updateEmployeeCompanyStatus: {
    loading: false,
    success: false,
  },
};

const employeeRecordReducer = (state: IEmployeeState['employeeRecord'], action: EmployeeAction) => {
  switch (action.type) {
    case EMPLOYEE_RECORD_FETCH:
      return {
        ...state,
        loading: true,
        payload: action.payload,
      };
    case EMPLOYEE_RECORD_SUCCESS:
      return {
        ...state,
        list: action.payload.records,
        count: action.payload.count,
        currentPage: action.payload.currentPage,
        loading: false,
      };
    default:
      return state;
  }
};

const employeeInfoReducer = (state: IEmployeeState['employeeInfo'], action: EmployeeAction) => {
  switch (action.type) {
    case EMPLOYEE_INFO_FETCH:
      return {
        ...state,
      };
    case EMPLOYEE_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case EMPLOYEE_INFO_ERROR:
      return initialState.employeeInfo;
    default:
      return state;
  }
};

const employeeCompanyRecordReducer = (state: IEmployeeState['employeeCompanyRecord'], action: EmployeeAction) => {
  switch (action.type) {
    case EMPLOYEE_COMPANY_RECORD_FETCH:
      return {
        ...state,
      };
    case EMPLOYEE_COMPANY_RECORD_SUCCESS:
      return {
        ...state,
        list: action.payload.records,
      };
    default:
      return state;
  }
};

const employeeCompanyInfoReducer = (state: IEmployeeState['employeeCompanyInfo'], action: EmployeeAction) => {
  switch (action.type) {
    case EMPLOYEE_COMPANY_INFO_FETCH:
      return {
        ...state,
        loading: true,
      };
    case EMPLOYEE_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case EMPLOYEE_COMPANY_INFO_ERROR:
      return {
        ...state,
        // employeeInfo: action.payload
      };
    default:
      return state;
  }
};

const updateEmployeeCompanyInfoReducer = (
  state: IEmployeeState['updateEmployeeCompanyInfo'],
  action: EmployeeAction
) => {
  switch (action.type) {
    case EMPLOYEE_UPDATE_COMPANY_INFO_FETCH:
      return {
        ...state,
        success: false,
        error: undefined,
      };
    case EMPLOYEE_UPDATE_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        success: true,
        error: undefined,
      };
    case EMPLOYEE_UPDATE_COMPANY_INFO_ERROR:
      return {
        ...state,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const updateEmployeeCompanyStatusReducer = (
  state: IEmployeeState['updateEmployeeCompanyStatus'],
  action: EmployeeAction
) => {
  switch (action.type) {
    case EMPLOYEE_UPDATE_COMPANY_STATUS_RESET:
      return initialState.updateEmployeeCompanyStatus;
    case EMPLOYEE_UPDATE_COMPANY_STATUS_FETCH:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      };
    case EMPLOYEE_UPDATE_COMPANY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case EMPLOYEE_UPDATE_COMPANY_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    default:
      return state;
  }
};

const employeeWorkHourRecordReducer = (state: IEmployeeState['employeeWorkHourRecord'], action: EmployeeAction) => {
  switch (action.type) {
    case EMPLOYEE_WORK_HOUR_RECORD_FETCH:
      return {
        ...state,
        loading: true,
      };
    case EMPLOYEE_WORK_HOUR_RECORD_SUCCESS:
      return {
        ...state,
        list: action.payload.records,
        count: action.payload.count,
        currentPage: action.payload.currentPage,
        loading: false,
      };
    default:
      return state;
  }
};

const employeeTransactionRecordReducer = (
  state: IEmployeeState['employeeTransactionRecord'],
  action: EmployeeAction
) => {
  switch (action.type) {
    case EMPLOYEE_TRANSACTION_RECORD_FETCH:
      return {
        ...state,
        loading: true,
      };
    case EMPLOYEE_TRANSACTION_RECORD_SUCCESS:
      return {
        ...state,
        list: action.payload.records,
        count: action.payload.count,
        currentPage: action.payload.currentPage,
        loading: false,
      };
    default:
      return state;
  }
};

const employeeLapseRepaymentRecordReducer = (
  state: IEmployeeState['employeeLapseRepaymentRecord'],
  action: EmployeeAction
) => {
  switch (action.type) {
    case EMPLOYEE_LAPSE_REPAYMENT_RECORD_FETCH:
      return {
        ...state,
      };
    case EMPLOYEE_LAPSE_REPAYMENT_RECORD_SUCCESS:
      return {
        ...state,
        list: action.payload.records,
      };
    default:
      return state;
  }
};

const employeeEkycRecordReducer = (state: IEmployeeState['employeeEkyctRecord'], action: EmployeeAction) => {
  switch (action.type) {
    case EMPLOYEE_EKYC_RECORD_FETCH:
      return {
        ...state,
      };
    case EMPLOYEE_EKYC_RECORD_SUCCESS:
      return {
        ...state,
        list: action.payload.records,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last
export const employeeReducer = (state = initialState, action: EmployeeAction) => {
  switch (action.type) {
    case EMPLOYEE_RECORD_FETCH:
    case EMPLOYEE_RECORD_SUCCESS:
      return {
        ...state,
        employeeRecord: employeeRecordReducer({ ...state.employeeRecord }, action),
      };
    case EMPLOYEE_INFO_FETCH:
    case EMPLOYEE_INFO_SUCCESS:
    case EMPLOYEE_INFO_ERROR:
      return {
        ...state,
        employeeInfo: employeeInfoReducer({ ...state.employeeInfo }, action),
      };
    case EMPLOYEE_COMPANY_RECORD_FETCH:
    case EMPLOYEE_COMPANY_RECORD_SUCCESS:
      return {
        ...state,
        employeeCompanyRecord: employeeCompanyRecordReducer({ ...state.employeeCompanyRecord }, action),
      };
    case EMPLOYEE_COMPANY_INFO_FETCH:
    case EMPLOYEE_COMPANY_INFO_SUCCESS:
    case EMPLOYEE_COMPANY_INFO_ERROR:
      return {
        ...state,
        employeeCompanyInfo: employeeCompanyInfoReducer({ ...state.employeeCompanyInfo }, action),
      };
    case EMPLOYEE_UPDATE_COMPANY_INFO_FETCH:
    case EMPLOYEE_UPDATE_COMPANY_INFO_SUCCESS:
    case EMPLOYEE_UPDATE_COMPANY_INFO_ERROR:
      return {
        ...state,
        updateEmployeeCompanyInfo: updateEmployeeCompanyInfoReducer(
          {
            ...state.updateEmployeeCompanyInfo,
          },
          action
        ),
      };
    case EMPLOYEE_UPDATE_COMPANY_STATUS_RESET:
    case EMPLOYEE_UPDATE_COMPANY_STATUS_FETCH:
    case EMPLOYEE_UPDATE_COMPANY_STATUS_SUCCESS:
    case EMPLOYEE_UPDATE_COMPANY_STATUS_ERROR:
      return {
        ...state,
        updateEmployeeCompanyStatus: updateEmployeeCompanyStatusReducer(
          {
            ...state.updateEmployeeCompanyStatus,
          },
          action
        ),
      };
    case EMPLOYEE_WORK_HOUR_RECORD_FETCH:
    case EMPLOYEE_WORK_HOUR_RECORD_SUCCESS:
      return {
        ...state,
        employeeWorkHourRecord: employeeWorkHourRecordReducer({ ...state.employeeWorkHourRecord }, action),
      };
    case EMPLOYEE_TRANSACTION_RECORD_FETCH:
    case EMPLOYEE_TRANSACTION_RECORD_SUCCESS:
      return {
        ...state,
        employeeTransactionRecord: employeeTransactionRecordReducer(
          {
            ...state.employeeTransactionRecord,
          },
          action
        ),
      };
    case EMPLOYEE_LAPSE_REPAYMENT_RECORD_FETCH:
    case EMPLOYEE_LAPSE_REPAYMENT_RECORD_SUCCESS:
      return {
        ...state,
        employeeLapseRepaymentRecord: employeeLapseRepaymentRecordReducer(
          {
            ...state.employeeLapseRepaymentRecord,
          },
          action
        ),
      };
    case EMPLOYEE_EKYC_RECORD_FETCH:
    case EMPLOYEE_EKYC_RECORD_SUCCESS:
      return {
        ...state,
        employeeEkyctRecord: employeeEkycRecordReducer({ ...state.employeeEkyctRecord }, action),
      };
    default:
      return state;
  }
};
