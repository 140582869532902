/* eslint-disable @typescript-eslint/no-explicit-any, import/no-extraneous-dependencies, func-names */
import { FormLabelAlign } from 'antd/lib/form/interface';
import { Rule } from 'rc-field-form/lib/interface';
import { FormItem } from 'formik-antd';
import { useField } from 'formik';
import React, { ReactNode, useEffect } from 'react';
import './style.css';
import { useDispatch } from 'react-redux';
import { toastPush } from 'modules';
import { Editor } from '@tinymce/tinymce-react';
import { useTranslation } from 'react-i18next';

export interface EditorTextareaProp {
  label?: string | ReactNode;
  id: string;
  name: string;
  value?: string;
  noStyle?: boolean;
  tooltip?: ReactNode;
  labelAlign?: FormLabelAlign; // default: right
  labelCol?: object; // ant design Col object
  rules?: Rule[];
  wrapperCol?: object; // ant design Col object
  required?: boolean;
  handleChangeInput?: (value: any, editor: any) => void;
  menubar?: boolean;
  height?: number;
  readonly?: boolean;
  maxLength?: number;
}

export const EditorTextarea: React.FC<EditorTextareaProp> = ({
  name,
  handleChangeInput,
  id,
  label,
  tooltip,
  labelCol,
  wrapperCol,
  labelAlign,
  required,
  rules,
  noStyle,
  value,
  readonly,
  height,
  menubar,
  maxLength,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [field, meta, helper] = useField({ name });
  const isInvalid = meta.touched && !!meta.error;

  const charCount = (editor: any) => editor.getContent({ format: 'text' }).replace(/\n/g, '').length;

  const handleChange = (valueData: string, editor: any) => {
    const cCount = charCount(editor);
    if (maxLength && cCount > maxLength) return;
    if (handleChangeInput) handleChangeInput(valueData, editor);
    helper.setValue(valueData);
  };

  const handleBeforeAddUndo = (evt: any, editor: any) => {
    if (maxLength && charCount(editor) > maxLength) {
      evt.preventDefault();
    }
  };

  useEffect(() => {
    if (isInvalid) {
      document?.getElementById(id)?.classList.add('is-invalid');
      document?.getElementsByClassName('tox tox-tinymce')?.item(0)?.classList?.add('is-invalid');
    } else {
      document?.getElementById(id)?.classList?.remove('is-invalid');
      document?.getElementsByClassName('tox tox-tinymce')?.item(0)?.classList?.remove('is-invalid');
    }
    // eslint-disable-next-line
  }, [isInvalid]);

  return (
    <FormItem
      label={label}
      name={field.name}
      tooltip={tooltip}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      labelAlign={labelAlign}
      required={required}
      rules={rules}
      noStyle={noStyle}
      help={meta.touched && meta.error ? t(`${meta.error}`) : undefined}
    >
      <Editor
        id={id}
        apiKey="qa40a5s9knwhzobyih81o6ti6xyio56xn26jgh2cg5x65gva"
        value={value}
        textareaName={field.name}
        onEditorChange={handleChange}
        onBeforeAddUndo={handleBeforeAddUndo}
        init={{
          readonly,
          setup(editor) {
            editor.on('end', function () {
              // eslint-disable-next-line no-param-reassign
              editor.getContainer().className += ' with-border';
            });
          },
          height,
          menubar,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount textcolor',
          ],
          toolbar:
            'undo redo | formatselect | image media code code' +
            'bold italic forecolor backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent',
          file_picker_types: 'image media',
          /* we override default upload handler to simulate successful upload */
          file_picker_callback(cb, _value, _meta) {
            const input = document.createElement('input');
            let acceptExtension = '';
            if (_meta.filetype === 'image') {
              acceptExtension = 'image/jpeg,image/jpg,image/png';
            } else if (_meta.filetype === 'media') {
              acceptExtension = 'video/*';
            }
            input.setAttribute('type', 'file');
            input.setAttribute('accept', acceptExtension);
            // eslint-disable-next-line consistent-return
            input.onchange = function () {
              // @ts-ignore
              const file = this.files[0];
              if (_meta.filetype === 'image' && ['image/jpeg', 'image/jpg', 'image/png'].indexOf(file.type) === -1) {
                return dispatch(
                  toastPush({
                    code: 400,
                    type: 'error',
                    title: 'Error',
                    body: 'toastPush.onlyAcceptImageExtension',
                  })
                );
              }
              // @ts-ignore
              const url = URL.createObjectURL(this.files[0]);
              cb(url, { title: file.name });
            };
            input.click();
          },
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
    </FormItem>
  );
};

EditorTextarea.defaultProps = {
  height: 300,
  menubar: false,
  labelAlign: 'left',
  readonly: false,
};
