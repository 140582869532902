// ------------------- DESIGN SYSTEM  -------------------  //

export const white = '#FFFFFF';
export const black = '#000000';
export const transparent = '#FFFFFF00';

export const primary50 = '#ecfbf8';
export const primary100 = '#e3f9f4';
export const primary200 = '#c5f3e8';
export const primary300 = '#45d8b4';
export const primary400 = '#3ec2a2';
export const primary500 = '#37ad90';
export const primary600 = '#34a287';
export const primary700 = '#29826c';
export const primary800 = '#1f6151';
export const primary900 = '#184c3f';

export const yellow50 = '#fffae6';
export const yellow100 = '#fff0b0';
export const yellow200 = '#ffe98a';
export const yellow300 = '#ffdf54';
export const yellow400 = '#ffd933';
export const yellow500 = '#ffcf00';
export const yellow600 = '#e8bc00';
export const yellow700 = '#b59300';
export const yellow800 = '#8c7200';
export const yellow900 = '#6b5700';

export const blue50 = '#eaefff';
export const blue100 = '#dfe7ff';
export const blue200 = '#bccdff';
export const blue300 = '#285eff';
export const blue400 = '#2455e6';
export const blue500 = '#204bcc';
export const blue600 = '#1e47bf';
export const blue700 = '#183899';
export const blue800 = '#122a73';
export const blue900 = '#0e2159';

export const red50 = '#ffecef';
export const red100 = '#ffe2e7';
export const red200 = '#ffc3cc';
export const red300 = '#ff3f5c';
export const red400 = '#e63953';
export const red500 = '#cc324a';
export const red600 = '#bf2f45';
export const red700 = '#992637';
export const red800 = '#731c29';
export const red900 = '#591620';

export const grey50 = '#fdfdfd';
export const grey100 = '#f8f8f8';
export const grey200 = '#f5f5f5';
export const grey300 = '#f0f0f1';
export const grey400 = '#ededee';
export const grey500 = '#e9e9ea';
export const grey600 = '#d4d4d5';
export const grey700 = '#a5a5a6';
export const grey800 = '#808081';
export const grey900 = '#626262';

export const black50 = '#e9e9ea';
export const black100 = '#b9bcbe';
export const black200 = '#989c9f';
export const black300 = '#686e73';
export const black400 = '#4b5258';
export const black500 = '#1E272E';
export const black600 = '#1b232a';
export const black700 = '#151c21';
export const black800 = '#111519';
export const black900 = '#0d1013';
