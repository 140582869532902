/* eslint-disable @typescript-eslint/no-explicit-any */
import { API } from 'api';
import { all, call, put } from 'redux-saga/effects';
import { isUndefinedOrNullOrEmpty } from 'common';
import { toastPush } from 'modules';
import {
  bulkUpdateFilenameSelectSuccess,
  errorCreateBulkUpdateRequest,
  IBulkUpdateFilenameSelectFetch,
  IBulkUpdateRequestActionFetch,
  IBulkUpdateRequestListFetch,
  ICreateBulkUpdateRequestFetch,
  IUploadBulkUpdateFetch,
  successBulkUpdateList,
  successBulkUpdateRequestAction,
  successBulkUpdateRequestList,
  successCreateBulkUpdateRequest,
  successUploadBulkUpdate,
} from 'modules/bulkUpdate/actions';

export function* uploadBulkUpdateSaga(action: IUploadBulkUpdateFetch): any {
  try {
    const { json } = action.payload;
    const bulkInfo = yield all(
      json.map(async (item: any) => {
        return {
          refNo: item.no,
          eid: item.employerId,
          type: item.type,
          employeeId: item.employeeId,
          employeeName: item.employeeName,
          mobile: item.mobileNumber,
          email: item.companyEmailAddress,
          role: item.role,
          salary: item.salary,
          salaryFrequency: item.salaryFrequency,
          salaryDay: item.salaryDay,
          workingDays: item.workingDays,
        };
      })
    );
    yield call(API.post(), '/api/employer/bulk/update', { bulkInfo });
    yield put(
      toastPush({
        code: 200,
        type: 'success',
        title: 'SUCCESS',
        body: 'toastPush.uploadSucess',
      })
    );
    yield put(successUploadBulkUpdate());
  } catch (error) {
    // empty
  }
}

export function* createBulkUpdateRequestSaga(action: ICreateBulkUpdateRequestFetch) {
  const { onSuccess, onError } = action;
  try {
    const formData = new FormData();
    formData.append('fileData', action.payload.fileData);
    formData.append('eid', action.payload.eid ?? '');
    formData.append('type', action.payload.type ?? '');

    const { data } = yield call(API.post(), '/api/employer/bulkUpdateRequest', formData, {
      'Content-Type': 'multipart/form-data',
    });
    // yield put(toastPush({ code: 200, type: 'success', title: 'SUCCESS', body: 'Success Created Bulk Update Request' }));
    if (onSuccess) {
      onSuccess(data);
    }
    yield put(successCreateBulkUpdateRequest());
  } catch (error) {
    if (onError) {
      onError();
    }
    yield put(errorCreateBulkUpdateRequest('error'));
  }
}

export function* bulkUpdateListSaga(action: IUploadBulkUpdateFetch) {
  try {
    const { data } = yield call(API.get(), '/api/employer/bulk/update', action.payload);
    yield put(successBulkUpdateList({ records: data.records }));
  } catch (error) {
    // empty
  }
}

export function* bulkUpdateRequestListSaga(action: IBulkUpdateRequestListFetch) {
  try {
    const { data } = yield call(API.get(), '/api/employer/bulkUpdateRequest', action.payload);
    yield put(successBulkUpdateRequestList({ records: data.data, count: data.count }));
  } catch (error) {
    // empty
  }
}

export function* bulkUpdateRequestActionSaga(action: IBulkUpdateRequestActionFetch) {
  const { onSuccess, onError, payload } = action;
  try {
    const { id } = payload;
    yield call(API.put(), `/api/employer/bulkUpdate/${id}`, payload);
    yield put(successBulkUpdateRequestAction());
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    if (isUndefinedOrNullOrEmpty(error)) {
      yield put(successBulkUpdateRequestAction());
      if (onSuccess) {
        onSuccess();
      }
      return;
    }

    if (onError) {
      onError();
    }
  }
}

export function* bulkUpdateFilenameSelectSaga(action: IBulkUpdateFilenameSelectFetch) {
  try {
    const { data } = yield call(API.get(), '/api/employer/bulk/update', action.payload);
    yield put(bulkUpdateFilenameSelectSuccess(data.records));
  } catch (error) {
    // empty
  }
}
