/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, select } from 'redux-saga/effects';
import {
  CountryCode,
  deleteAllCookies,
  isUndefinedOrNullOrEmpty,
  sagaRedirectToEmployerPortalDasboard,
  setCookie,
} from 'common';
import {
  changeCountryCode,
  changeLanguageCode,
  ISwitchEmployerAdminAuth,
  RootState,
  selectCurrentCountry,
  selectCurrentLanguage,
  toastPush,
} from 'modules';
import { initialUserState, setIsLoggedIn, setUserCredential } from 'modules/user';
import { API } from 'api';
import { push } from 'redux-first-history';
import { history } from 'store';
import { IApiError, IForgetPassword, ILogin, ISetupPassword, logout, setFirstLogin } from '../actions';

const getEmployers = (state: RootState) => state.user.employers;
const getUser = (state: RootState) => state.user;
const getCountryCode = (state: RootState) => selectCurrentCountry(state);
const getLanguageCode = (state: RootState) => selectCurrentLanguage(state);

export function* loginSaga(action: ILogin): any {
  const { onSuccess, onError } = action;
  try {
    yield put(setUserCredential(initialUserState));
    const { data } = yield call(API.post(), '/api/admin/pub/authorisation', action.payload);

    if (data.twoFa) {
      localStorage.setItem('2fa', JSON.stringify(data));
      yield put(push(`/2fa${history.location.search}`));
      return;
    }

    const country = data.employers?.[0]?.country || 'my';
    const language = localStorage.getItem('i18nextLng') ?? 'en';

    yield put(setUserCredential(data));
    if (!isUndefinedOrNullOrEmpty(data.employers) && data.employers.length > 0) {
      yield put(changeCountryCode({ code: country }));
    }
    yield put(changeLanguageCode(language));
    yield put(setIsLoggedIn(true));
    if (onSuccess) {
      onSuccess(data);
    }

    if (data.firstTimeLogin) {
      yield put(push('/setup-password'));
      return;
    }

    if (!isUndefinedOrNullOrEmpty(data.employers) && data.employers.length > 0) {
      // if multiple employer redirect to select page
      if (data.employers.length > 1) {
        yield put(push(`/employer-company${history.location.search}`));
        return;
      }

      yield sagaRedirectToEmployerPortalDasboard(data);
      return;
    }

    const countryCode = yield select(getCountryCode);
    if (countryCode === CountryCode.HK) {
      yield put(push('/employer/list'));
      return;
    }

    if (action.payload.redirectUrl) {
      if (action.payload.redirectUrl.indexOf('http') > -1) {
        window.location.href = action.payload.redirectUrl;
        return;
      }

      yield put(push(action.payload.redirectUrl));
      return;
    }

    yield sagaRedirectToEmployerPortalDasboard(data);
    return;
  } catch (error) {
    if (onError) {
      onError(error);
    }
    // yield put(toastPush({ code: error.status, type: 'error', title: 'ERROR', body: error.data.message }));
  }
}

export function* setupPasswordSaga(action: ISetupPassword): any {
  const { onSuccess, onError } = action;
  try {
    const param = {
      pw: action.payload.password,
      oldPw: action.payload.oldPassword,
    };

    yield call(API.post(), '/api/admin/setup/password', param);
    yield put(setFirstLogin(true));
    if (onSuccess) {
      onSuccess();
    }
    const employers = yield select(getEmployers);
    const user = yield select(getUser);

    if (!isUndefinedOrNullOrEmpty(employers)) {
      if (employers.length > 1) {
        yield put(push('/employer-company'));
        return;
      }
    }

    if (!isUndefinedOrNullOrEmpty(user?.eid)) {
      window.location.href = `${process.env.REACT_APP_EMPLOYER_PORTAL_URL}/dashboard`;
      return;
    }

    yield put(push('/dashboard'));
  } catch (error) {
    if (onError) {
      onError();
    }
    // yield put(toastPush({ code: error.status, type: 'error', title: 'ERROR', body: error.data.message }));
  }
}

export function* forgetPasswordSaga(action: IForgetPassword) {
  const { onSuccess, onError, payload } = action;
  try {
    yield call(API.post(), '/api/admin/pub/reset/pwd', payload);
    if (onSuccess) {
      onSuccess();
    }
  } catch (error: any) {
    if (onError) {
      onError();
    }
    yield put(
      toastPush({
        code: error.statusCode,
        type: 'error',
        title: 'ERROR',
        body: error.message,
      })
    );
  }
}

export function* switchEmployerAdminAuthSaga(action: ISwitchEmployerAdminAuth): any {
  const { onSuccess, onError, payload } = action;
  try {
    const { data } = yield call(API.post(), '/api/admin/switch/employer/authorisation', payload);
    if (onSuccess) {
      onSuccess(data);
    }
    const userInfo = yield select(getUser);
    const newUserCredential = {
      ...userInfo,
      ...data,
      eid: payload.eid,
    };
    yield put(changeCountryCode({ code: payload.countryCode }));
    yield put(setUserCredential(newUserCredential));
    if (action.payload.redirectUrl) {
      yield put(push(action.payload.redirectUrl));
      return;
    }

    let language = yield select(getLanguageCode);
    if (payload.countryCode !== CountryCode.ID) {
      language = 'en';
    }
    const locale = `${language}-${payload.countryCode?.toUpperCase()}`;
    setCookie('locale', locale);

    yield sagaRedirectToEmployerPortalDasboard(payload);
  } catch (error) {
    if (onError) {
      onError();
    }
  }
}

export function* logoutSaga() {
  deleteAllCookies();
  yield put(setIsLoggedIn(false));
  yield put(setUserCredential(initialUserState));
  yield put(changeLanguageCode('en'));
  if (history.location.pathname === '/login') {
    yield put(push('/login'));
    return;
  }
  yield put(push(`/login?redirectUrl=${history.location.pathname}${history.location.search}`));
}

export function* handleApiErrorSaga(action: IApiError) {
  switch (action.payload.errorCode) {
    case 401:
      yield put(
        toastPush({
          code: action.payload.errorCode,
          type: 'error',
          title: 'ERROR',
          body: 'toastPush.unauthorized',
        })
      );
      yield put(logout());
      break;
    case 406:
      yield put(push('/403'));
      break;
    default:
      break;
  }
  // yield put();
}

export function* impersonateSaga(action: any): any {
  try {
    const { payload } = action;
    const result = yield call(API.post(), '/api/admin/auth/impersonate', { eid: payload.eid });
    const { data } = result;
    const user = yield select(getUser);
    user.accessToken = data.accessToken;
    user.refreshToken = data.refreshToken;
    user.expiredOn = data.expiredOn;
    user.eid = payload.eid;
    user.employers = data.employers;
    user.permissions = data.permissions;
    yield put(setUserCredential(user));
    setCookie('accessToken', data.accessToken, data.expiredOn);
    setCookie('refreshToken', data.refreshToken, data.expiredOn);
    setCookie('expiredOn', data.expiredOn, data.expiredOn);
    setCookie('employerId', payload.eid); // not compulsory expired
    setCookie('employerProfiles', JSON.stringify(data.employers)); // not compulsory expired
    setCookie('permissions', JSON.stringify(data.permissions)); // not compulsory expired
    setCookie('isImpersonate', 'true', data.expiredOn);
    yield sagaRedirectToEmployerPortalDasboard({ eid: payload.eid });
  } catch (error) {
    // console.log('impersonate error', error);
  }
}

export function* cancelImpersonateSaga(): any {
  try {
    const result = yield call(API.post(), '/api/admin/auth/impersonate/cancel', {});
    const { data } = result;
    const user = yield select(getUser);
    user.accessToken = data.accessToken;
    user.refreshToken = data.refreshToken;
    user.expiredOn = data.expiredOn;
    user.eid = undefined;
    user.employers = data.employers;
    user.permissions = data.permissions;
    yield put(setUserCredential(user));
    setCookie('accessToken', data.accessToken, data.expiredOn);
    setCookie('refreshToken', data.refreshToken, data.expiredOn);
    setCookie('expiredOn', data.expiredOn, data.expiredOn);
    setCookie('employerId', ''); // not compulsory expired
    setCookie('employerProfiles', JSON.stringify([])); // not compulsory expired
    setCookie('permissions', JSON.stringify(data.permissions)); // not compulsory expired
    setCookie('isImpersonate', 'false', data.expiredOn);
    yield put(push('/dashboard'));
  } catch (error) {
    // console.log('cancel impersonate error', error);
  }
}
