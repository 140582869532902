/* eslint-disable no-shadow, @typescript-eslint/no-explicit-any */
import { CalenderType } from './common';

enum EmployerRole {
  EMPLOYER_OWNER = 'EMPLOYEROWNER',
  EMPLOYER_STAFF = 'EMPLOYERSTAFF',
}

enum EmployerAdminStatus {
  ACTIVE = 'active',
  SUSPEND = 'suspend',
}

enum EmployerServiceProviderType {
  HLB = 'hongLeongBank',
  PAYWATCH = 'paywatch',
  OCBC = 'ocbc',
  XENDIT = 'xendit',
  UOB = 'uob',
  PERMATA = 'permata',
}

const employeeMovementFileType = {
  DAILY: CalenderType.DAILY,
  WEEKLY: CalenderType.WEEKLY,
  MONTHLY: CalenderType.MONTHLY,
};

const employerRequestStatus: any = {
  active: { label: 'status.active', value: 'active', color: 'success' },
  pending: { label: 'status.pending', value: 'pending', color: 'warning' },
  approve: { label: 'status.approved', value: 'approve', color: 'success' },
  reject: { label: 'status.rejected', value: 'reject', color: 'danger' },
  churn: { label: 'status.churn', value: 'churn', color: 'danger' },
};

const employerRequestStatusSelect = [
  employerRequestStatus.pending,
  employerRequestStatus.approve,
  employerRequestStatus.reject,
];

const bnmCategory = [
  { label: 'label.accommodation&hotel', value: 'Accommodation & Hotel' },
  { label: 'label.amusement&entertainment', value: 'Amusement & Entertainment' },
  { label: 'label.food&restaurant', value: 'Food & Restaurant' },
  { label: 'label.professional&commercialServices', value: 'Professional & Commercial Services' },
  { label: 'label.retail', value: 'Retail' },
  { label: 'label.transportation', value: 'Transportation' },
  { label: 'label.otherTypeOfMerchant', value: 'Other Type of Merchant' },
  { label: 'label.financialServices', value: 'Financial Services' },
  { label: 'label.governmentServices', value: 'Government Services' },
  { label: 'label.mail&telephoneOrder', value: 'Mail & Telephone Order' },
  { label: 'label.fuelPetrol', value: 'Fuel/Petrol' },
  { label: 'label.utilities', value: 'Utilities' },
  { label: 'label.insurance', value: 'Insurance' },
];

const accommodationHotel = [
  { label: 'label.hotel', value: 'Hotel' },
  { label: 'label.motel', value: 'Motel' },
  { label: 'label.hostel', value: 'Hostel' },
  { label: 'label.others', value: 'Others' },
];

const accommodationEntertainment = [
  { label: 'label.karaoke', value: 'Karaoke' },
  { label: 'label.cinema', value: 'Cinema' },
  { label: 'label.arcade', value: 'Arcade' },
  { label: 'label.bowling', value: 'Bowling' },
  { label: 'label.recreationalSport', value: 'Recreational Sport' },
  { label: 'label.musicStudio', value: 'Music Studio' },
  { label: 'label.museum', value: 'Museum' },
  { label: 'label.others', value: 'Others' },
];

const foodRestaurant = [
  { label: 'label.restaurantAndCafe', value: 'Restaurant and Cafe' },
  { label: 'label.bakeryAndCake', value: 'Bakery and Cake' },
  { label: 'label.foodStallAndHawker', value: 'Food Stall and Hawker' },
  { label: 'label.foodTruck', value: 'Food Truck' },
  { label: 'label.foodCourt', value: 'Food Court' },
  { label: 'label.mobileFoodStore', value: 'Mobile Food Store' },
  { label: 'label.caterers', value: 'Caterers' },
  { label: 'label.others', value: 'Others' },
];

const profCommercialService = [
  { label: 'label.clinic', value: 'Clinic' },
  { label: 'label.dentist', value: 'Dentist' },
  { label: 'label.legalLawFirm', value: 'Legal/Law Firm' },
  { label: 'label.auditFirm', value: 'Audit Firm' },
  { label: 'label.accountant', value: 'Accountant' },
  { label: 'label.advertisingServices', value: 'Advertising Services' },
  { label: 'label.otherBusinessServices', value: 'Other Business Services' },
  { label: 'label.medicalLaboratory', value: 'Medical Laboratory' },
  { label: 'label.otherMedicalServices', value: 'Other Medical Services' },
  { label: 'label.otherLaboratory', value: 'Other Laboratory' },
  { label: 'label.veterinary', value: 'Veterinary' },
  { label: 'label.others', value: 'Others' },
];

const retail = [
  { label: 'label.hardwareShop', value: 'Hardware Shop' },
  { label: 'label.fashionAndClothing', value: 'Fashion and Clothing' },
  { label: 'label.convenientShop', value: 'Convenient Shop' },
  { label: 'label.carWashAndAutoDetail', value: 'Car Wash and Auto Detail' },
  { label: 'label.autoServices', value: 'Auto Services' },
  { label: 'label.autoAccessoriesShop', value: 'Auto Accessories Shop' },
  { label: 'label.opticalShop', value: 'Optical Shop' },
  { label: 'label.sportingGoodsStores', value: 'Sporting Goods Stores' },
  { label: 'label.beautyAndSpa', value: 'Beauty and Spa' },
  { label: 'label.telecommunicationProducts', value: 'Telecommunication Products' },
  { label: 'label.computerSalesAndServices', value: 'Computer Sales and Services' },
  { label: 'label.petShop', value: 'Pet Shop' },
  { label: 'label.saloonAndBarber', value: 'Saloon and Barber' },
  { label: 'label.furnitureAndHomeFurnishing', value: 'Furniture and Home Furnishing' },
  { label: 'label.pasarMalam', value: 'Pasar Malam' },
  { label: 'label.pasarPagi', value: 'Pasar Pagi' },
  { label: 'label.wetMarket', value: 'Wet Market' },
  { label: 'label.fleaMarketBazar', value: 'Flea Market / Bazar' },
  { label: 'label.florist', value: 'Florist' },
  { label: 'label.printingServices', value: 'Printing Services' },
  { label: 'label.pharmacyAndDrugStores', value: 'Pharmacy and Drug Stores' },
  { label: 'label.bookAndStationeryShop', value: 'Book and Stationery Shop' },
  { label: 'label.jewelleryAndWatchShop', value: 'Jewellery and Watch Shop' },
  { label: 'label.electricalShop', value: 'Electrical Shop' },
  { label: 'label.giftAndToysShop', value: 'Gift and Toys Shop' },
  { label: 'label.musicalInstrumentStore', value: 'Musical Instrument Store' },
  { label: 'label.photoStudio', value: 'Photo Studio' },
  { label: 'label.tailoringShop', value: 'Tailoring Shop' },
  { label: 'label.laundry', value: 'Laundry' },
  { label: 'label.cosmetics', value: 'Cosmetics' },
  { label: 'label.miscellaneousStores', value: 'Miscellaneous Stores' },
  { label: 'label.bikeShop', value: 'Bike Shop' },
  { label: 'label.plantNursery', value: 'Plant Nursery' },
  { label: 'label.carDealer', value: 'Car Dealer' },
  { label: 'label.freshProduce', value: 'Fresh Produce' },
  { label: 'label.homeAppliances', value: 'Home Appliances' },
  { label: 'label.wholesalers', value: 'Wholesalers' },
  { label: 'label.others', value: 'Others' },
];

const transportation = [
  { label: 'label.taxi', value: 'Taxi' },
  { label: 'label.ferryServices', value: 'Ferry Services' },
  { label: 'label.parking', value: 'Parking' },
  { label: 'label.towingServices', value: 'Towing Services' },
  { label: 'label.travelAgencyAndTourOperators', value: 'Travel Agency and Tour Operators' },
  { label: 'label.others', value: 'Others' },
];

const otherTypeMerchant = [
  { label: 'label.eventManagement', value: 'Event Management' },
  { label: 'label.educational', value: 'Educational' },
  { label: 'label.onlineBusiness', value: 'Online Business' },
  { label: 'label.drivingSchool', value: 'Driving School' },
  { label: 'label.constructionRenovationInteriorDesign', value: 'Construction Renovation Interior Design' },
  { label: 'label.carRentalServices', value: 'Car Rental Services' },
  { label: 'label.courierServices', value: 'Courier Services' },
  { label: 'label.placeOfWorship', value: 'Place of Worship' },
  { label: 'label.internetServiceProvider', value: 'Internet Service Provider' },
  { label: 'label.buildingMaintenanceAndManagement', value: 'Building Maintenance and Management' },
  { label: 'label.cyberCafe', value: 'Cyber Cafe' },
  { label: 'label.gym', value: 'Gym' },
  { label: 'label.factory', value: 'Factory' },
  { label: 'label.confinementCentre', value: 'Confinement Centre' },
  { label: 'label.others', value: 'Others' },
];

const financialServices = [{ label: 'label.others', value: 'Others' }];

const governmentServices = [{ label: 'label.others', value: 'Others' }];

const mailTelephoneOrder = [
  { label: 'label.telemarketing', value: 'Telemarketing' },
  { label: 'label.directMarketing', value: 'Direct Marketing' },
  { label: 'label.catalogueMerchant', value: 'Catalogue Merchant' },
  { label: 'label.others', value: 'Others' },
];

const fuelPetrol = [
  { label: 'label.petronas', value: 'Petronas' },
  { label: 'label.others', value: 'Others' },
];

const utilities = [{ label: 'label.others', value: 'Others' }];

const insurance = [
  { label: 'label.insuranceServices', value: 'Insurance Services' },
  { label: 'label.others', value: 'Others' },
];

const getSubCategory = (key: string) => {
  switch (key) {
    case 'Accommodation & Hotel':
      return accommodationHotel;
    case 'Amusement & Entertainment':
      return accommodationEntertainment;
    case 'Food & Restaurant':
      return foodRestaurant;
    case 'Professional & Commercial Services':
      return profCommercialService;
    case 'Retail':
      return retail;
    case 'Transportation':
      return transportation;
    case 'Government Services':
      return governmentServices;
    case 'Other Type of Merchant':
      return otherTypeMerchant;
    case 'Financial Services':
      return financialServices;
    case 'Mail & Telephone Order':
      return mailTelephoneOrder;
    case 'Fuel/Petrol':
      return fuelPetrol;
    case 'Utilities':
      return utilities;
    case 'Insurance':
      return insurance;
    default:
      return [];
  }
};

const employerRoleOption: any = {
  owner: { label: 'label.owner', value: EmployerRole.EMPLOYER_OWNER },
  staff: { label: 'label.staff', value: EmployerRole.EMPLOYER_STAFF },
};

const employerRoleSelect = [employerRoleOption.owner, employerRoleOption.staff];

const employerAdminStatusOption: any = {
  active: { label: 'status.active', value: EmployerAdminStatus.ACTIVE },
  suspend: { label: 'status.deactivated', value: EmployerAdminStatus.SUSPEND },
};

const employerAdminStatusSelect = [employerAdminStatusOption.active, employerAdminStatusOption.suspend];

const employeeMovementFileTypeSelect = [
  { label: 'label.daily', value: employeeMovementFileType.DAILY },
  { label: 'label.weekly', value: employeeMovementFileType.WEEKLY },
  { label: 'label.monthly', value: employeeMovementFileType.MONTHLY },
];

const employerServiceProviderTypeLabel: any = {
  [EmployerServiceProviderType.HLB]: 'label.hlb',
  [EmployerServiceProviderType.PAYWATCH]: 'label.paywatch',
};

const EmployerServiceProviderTypeSelection = [
  { label: employerServiceProviderTypeLabel[EmployerServiceProviderType.HLB], value: EmployerServiceProviderType.HLB },
  {
    label: employerServiceProviderTypeLabel[EmployerServiceProviderType.PAYWATCH],
    value: EmployerServiceProviderType.PAYWATCH,
  },
];

export {
  employerRequestStatus,
  bnmCategory,
  getSubCategory,
  employerRequestStatusSelect,
  EmployerRole,
  employerRoleSelect,
  EmployerAdminStatus,
  employerAdminStatusOption,
  employerAdminStatusSelect,
  employeeMovementFileType,
  employeeMovementFileTypeSelect,
  EmployerServiceProviderType,
  employerServiceProviderTypeLabel,
  EmployerServiceProviderTypeSelection,
};
