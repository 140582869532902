/* eslint-disable @typescript-eslint/no-explicit-any, import/no-extraneous-dependencies */
import React, { useCallback, useMemo, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { AutoComplete, Typography } from 'antd';
import './style.css';
import { useTranslation } from 'react-i18next';
import { geocodeByAddress, geocodeByLatLng } from 'react-google-places-autocomplete';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useSelector } from 'react-redux';
import { RootState } from 'modules';

const { Paragraph } = Typography;
let marker: any;

export interface GoogleMapProp {
  handleChangeLatLng?: (event: google.maps.MapMouseEvent) => void;
  handleChangeLocation?: (location: google.maps.GeocoderResult) => void;
  lat: number;
  long: number;
}

export const GoogleMap: React.FC<GoogleMapProp> = ({ lat, long, handleChangeLatLng, handleChangeLocation }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const countryCode = useSelector((state: RootState) => state.public.country.code);
  const { placePredictions, getPlacePredictions } = useGoogle({
    // @ts-ignore
    options: {
      componentRestrictions: {
        country: [countryCode],
      },
    },
  });
  const [position, setPosition] = useState({
    lat,
    long,
  });
  const defaultPosition = { lat: 3.139, long: 101.6869 };

  const loadMap = (map: google.maps.Map, maps: any) => {
    marker = new maps.Marker({
      position: {
        lat: position.lat === 0 ? defaultPosition.lat : position.lat,
        lng: position.long === 0 ? defaultPosition.long : position.long,
      },
      map,
      draggable: true,
    });

    marker.addListener('dragend', (event: google.maps.MapMouseEvent) => {
      if (handleChangeLatLng) handleChangeLatLng(event);
      return (
        geocodeByLatLng({
          lat: event.latLng?.lat() ?? defaultPosition.lat,
          lng: event.latLng?.lng() ?? defaultPosition.long,
        })
          .then((results: google.maps.GeocoderResult[]) => {
            const getFirstLocation = results[0];
            setValue(getFirstLocation.formatted_address);
            if (handleChangeLocation) handleChangeLocation(getFirstLocation);
          })
          // eslint-disable-next-line no-console
          .catch((error) => console.error(error))
      );
    });
  };

  const handleChange = useCallback(
    (address: any) => {
      return (
        geocodeByAddress(address)
          .then((results: google.maps.GeocoderResult[]) => {
            const getFirstLocation = { ...results[0], formatted_address: address };
            marker.setPosition(
              new google.maps.LatLng(getFirstLocation.geometry.location.lat(), getFirstLocation.geometry.location.lng())
            );
            setPosition({
              lat: getFirstLocation.geometry.location.lat(),
              long: getFirstLocation.geometry.location.lng(),
            });
            if (handleChangeLocation) handleChangeLocation(getFirstLocation);
          })
          // eslint-disable-next-line no-console
          .catch((error) => console.error(error))
      );
    },
    [handleChangeLocation]
  );

  const onHandlePlacePredictions = useCallback(
    (_value: string) => {
      getPlacePredictions({ input: _value });
      setValue(_value);
    },
    [getPlacePredictions]
  );

  const onHandleSelectChange = useCallback((selectValue: any) => handleChange(selectValue), [handleChange]);

  const googlePlacePredictions = useMemo(() => {
    return placePredictions.map((item) => ({
      value: item.description,
      placeId: item.place_id,
    }));
  }, [placePredictions]);

  return (
    <div>
      <div className="mb-3">
        <Paragraph className="mb-2">{t('label.location')}</Paragraph>
        <AutoComplete
          placeholder={t('label.searchFromGoogleMap')}
          value={value}
          style={{ width: '100%' }}
          options={googlePlacePredictions}
          onChange={onHandlePlacePredictions}
          onSelect={onHandleSelectChange}
        />
      </div>
      <div style={{ height: '400px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: '' }}
          center={{
            lat: position.lat === 0 ? defaultPosition.lat : position.lat,
            lng: position.long === 0 ? defaultPosition.long : position.long,
          }}
          defaultCenter={{
            lat: position.lat === 0 ? defaultPosition.lat : position.lat,
            lng: position.long === 0 ? defaultPosition.long : position.long,
          }}
          defaultZoom={15}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
        />
      </div>
    </div>
  );
};
