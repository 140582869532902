/* eslint-disable no-console, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, func-names */
import { isNaN } from 'formik';
import moment, { Moment, MomentInput } from 'moment';
import React from 'react';
import * as CryptoJS from 'crypto-js';
import { formatValue } from 'react-currency-input-field';
import range from 'lodash/range';
import { intersection } from 'lodash';
import { RootState, selectCurrentCountry, toastPush } from 'modules';
import { store } from 'store';
import { t } from 'locale/i18n';
import { currencyMap, HistoryActType, PermissionId, LeaveSessionType, CountryCode, countryCodeTimezone } from 'common';
import XLSX from 'xlsx';
import { MenuProps } from 'antd';
import { push } from 'redux-first-history';

export const getCurrentCountryCode = (state: RootState) => selectCurrentCountry(state);
const development: boolean = process.env.REACT_APP_BASE_URL === 'https://myapi.paywatchglobal.com';

export const handleChangeInputTool = (
  event: React.ChangeEvent<HTMLInputElement>,
  setState: React.Dispatch<React.SetStateAction<any>>
) => {
  const { name, value } = event.target;
  const regex = /^[ A-Za-z0-9_!$,%^=?;:'"*~`()@./#&+-]*$/;
  if (regex.exec(value) || value === '') {
    setState((prevState: React.Dispatch<React.SetStateAction<any>>) => ({
      ...prevState,
      [name]: value,
    }));
  }
};

export const handleCustomChangeInputTool = (
  name: string,
  value: any,
  setState: React.Dispatch<React.SetStateAction<any>>
) => {
  setState((prevState: React.Dispatch<React.SetStateAction<any>>) => ({
    ...prevState,
    [name]: value,
  }));
};

const createOption = (label: string, value: string | number) => ({
  label,
  value,
});
export type MyOption = ReturnType<typeof createOption>;

const getEncKey = (_countryCode: string) => {
  if (isUndefinedOrNullOrEmpty(process.env.REACT_APP_ENC_KEY)) console.error('missing encryption key');

  return process.env.REACT_APP_ENC_KEY;
};

export const encrypt = (payload: object, countryCode?: string) => {
  const applicationKey = getEncKey(countryCode ?? '') ?? '';
  const randomiv = generateRandomKey(16);
  const randomIvEncoded = CryptoJS.enc.Utf8.parse(randomiv); // encodedWord Array object
  const base64encodeIV = CryptoJS.enc.Base64.stringify(randomIvEncoded); // string: 'NzUzMjI1NDE='

  const iv = CryptoJS.enc.Base64.parse(base64encodeIV);
  const key = CryptoJS.enc.Base64.parse(applicationKey);
  const encryptedMessage = CryptoJS.AES.encrypt(JSON.stringify(payload), key, {
    iv,
  });

  return `${encryptedMessage}.${base64encodeIV}`;
};

export const decrypt = (payload: string, countryCode?: string) => {
  if (isUndefinedOrNullOrEmpty(payload)) return undefined;

  const [encryptedMessage, base64iv] = payload.split('.');
  const base64key = getEncKey(countryCode ?? '') ?? '';
  const iv = CryptoJS.enc.Base64.parse(base64iv);
  const key = CryptoJS.enc.Base64.parse(base64key);

  const decryptedMessage = CryptoJS.AES.decrypt(encryptedMessage, key, {
    iv,
  });

  const result = decryptedMessage.toString(CryptoJS.enc.Utf8);

  return checkIsJson(result) ? JSON.parse(result) : result;
};

export const generateRandomKey = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const checkIsJson = (item: string | null) => {
  // eslint-disable-next-line no-param-reassign
  item = typeof item !== 'string' ? JSON.stringify(item) : item;

  try {
    // eslint-disable-next-line no-param-reassign
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  return typeof item === 'object' && item !== null;
};

export const isUndefinedOrNull = (v: any): boolean => {
  return undefined === v || v === null || (typeof v === 'number' && isNaN(v));
};

export const isUndefinedOrNullOrEmpty = (v: any): boolean => {
  if (isUndefinedOrNull(v)) {
    return true;
  }

  if (typeof v === 'string' || Array.isArray(v)) {
    if (v.length <= 0) {
      return true;
    }
  } else if (typeof v === 'object') {
    if (Object.keys(v).length <= 0) {
      return true;
    }
  } else {
    return false;
  }
  return false;
};

export const IsJsonString = (str: string): boolean => {
  try {
    JSON.parse(str, function (k, v) {
      if (k === '' && typeof v === 'number') {
        throw new Error('Invalid JSON');
      }
      return v;
    });
    return true;
  } catch (e) {
    return false;
  }
};

export const isDuplicate = (array: any, key: string) => {
  if (!Array.isArray(array)) {
    return false;
  }

  const valueArr = array.map(function (item) {
    return item[key];
  });
  return valueArr.some(function (item, idx) {
    return valueArr.indexOf(item) !== idx;
  });
};

export const isNotStringOrNumber = (v: any): boolean => {
  if (isUndefinedOrNull(v)) {
    return true;
  }

  if (typeof v === 'string') {
    if (v.length <= 0) {
      return true;
    }

    const regex = /^[ A-Za-z0-9_@./#&+-]*$/;
    if (!v.match(regex)) {
      return true;
    }

    if (IsJsonString(v)) {
      return true;
    }
  }
  return false;
};

export const getEnumKeyByEnumValue = (myEnum: any, enumValue: string) => {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : '';
};

export const checkIsEmail = (string: any): boolean => {
  if (typeof string === 'string') {
    const re =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    // eslint-disable-next-line no-param-reassign
    string = string.toLowerCase();
    if (re.test(string)) {
      return true;
    }
  }
  return false;
};

export const csvConvertToJson = (csv: any) => {
  const lines = csv.split('|');
  const result = [];

  const headers = lines[0].split(':');

  for (let i = 1; i < lines.length; i += 1) {
    const obj: any = {};

    const currentline = lines[i].split(':');

    for (let j = 0; j < headers.length; j += 1) {
      if (isUndefinedOrNullOrEmpty(currentline[j])) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const key = camelCase(headers[j]).replace(/\s/g, '');

      if (key === 'outletId') {
        if (!isUndefinedOrNullOrEmpty(currentline[j]) && currentline[j].search('"') !== -1) {
          const { dispatch } = store;
          dispatch(
            toastPush({
              code: 400,
              type: 'error',
              title: 'ERROR',
              body: 'toastPush.outletIdOnlyAllowUseSemecolonSeparate',
            })
          );
          return undefined;
        }
        currentline[j] = currentline[j].replace(/\s/g, '');
      }

      obj[key] = currentline[j];
    }
    result.push(obj);
  }

  // return result; //JavaScript object
  return result.filter((value) => Object.keys(value).length !== 0); // JSON
};

export const camelCase = (str: string) => {
  if (isUndefinedOrNullOrEmpty(str)) return '';

  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_m, chr) => chr.toUpperCase());
};

export const capitalizeFirstLetter = (str?: string, defaultValue?: string) => {
  if (str === undefined) return defaultValue ?? '';

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeWords = (str: string): string => {
  return str.replace(/\b\w/g, (c) => c.toUpperCase());
};

export const capitalizeFirstLetterOnly = (str?: string, defaultValue?: string) => {
  if (str === undefined || isUndefinedOrNullOrEmpty(str)) return defaultValue ?? '';

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const roundDownDecimal = (num: number, decimal = 2) => {
  return num.toFixed(decimal);
};

export const dateTimeFormat = (
  dateTime: any,
  datetimeFormat = 'DD-MM-YYYY HH:mm:ss',
  emptyVal = '-',
  showCountryTimezone = true,
  showUtcDateTime = false
) => {
  if (isUndefinedOrNullOrEmpty(dateTime)) return emptyVal;
  if (showCountryTimezone && moment.unix(dateTime).isValid())
    return moment
      .unix(dateTime)
      .tz(countryCodeTimezone[getCurrentCountryCode(store.getState())])
      .format(datetimeFormat);
  if (moment.unix(dateTime).isValid()) return moment.unix(dateTime).format(datetimeFormat);
  if (!moment(dateTime).isValid()) return emptyVal;

  if (showUtcDateTime) return moment(dateTime).utc().format(datetimeFormat);
  return moment(dateTime).format(datetimeFormat);
};

export const convertRangeDateToDateFormat = (state: { startDate: moment.MomentInput; endDate: moment.MomentInput }) => {
  const params = { from: undefined, to: undefined };
  if (moment(state.startDate, 'L').isValid()) {
    params.from = moment(state.startDate).format('DD-MM-YYYY') as any;
  }
  if (moment(state.endDate, 'L').isValid()) {
    params.to = moment(state.endDate).format('DD-MM-YYYY') as any;
  }

  return params;
};

export const convertRangeDate = (state: { startDate: moment.MomentInput; endDate: moment.MomentInput }) => {
  const params = { from: undefined, to: undefined };
  if (moment(state.startDate, 'L').isValid()) {
    params.from = moment(String(state.startDate)).startOf('day').unix().toString() as any;
  }
  if (moment(state.endDate, 'L').isValid()) {
    params.to = moment(String(state.endDate)).endOf('day').unix().toString() as any;
  }

  return params;
};

export const convertRangeDateIsoString = (state: { startDate: moment.MomentInput; endDate: moment.MomentInput }) => {
  const params = { from: undefined, to: undefined };
  if (moment(state.startDate, 'L').isValid()) {
    params.from = moment(String(state.startDate)).startOf('day').toISOString() as any;
  }
  if (moment(state.endDate, 'L').isValid()) {
    params.to = moment(String(state.endDate)).endOf('day').toISOString() as any;
  }

  return params;
};

export const convertRangeDateNumber = (state: {
  startDate: moment.MomentInput;
  endDate: moment.MomentInput;
}): { startDate: number; endDate: number } => {
  const params = { startDate: 0, endDate: 0 };
  if (moment(state.startDate, 'L').isValid()) {
    params.startDate = moment(String(state.startDate)).unix();
  }
  if (moment(state.endDate, 'L').isValid()) {
    params.endDate = moment(String(state.endDate)).unix();
  }

  return params;
};

export const mobileValidation = (mobileInput: string): boolean => {
  if (isNaN(Number(mobileInput))) {
    return false;
  }
  const mobileRegexp = /601\[0-9]{6,9}$/;
  return mobileRegexp.test(mobileInput);
};

export const displayWorkDuration = (time?: number): string => {
  let hours = 0;
  let minutes = 0;

  if (time) {
    hours = Math.floor(time / 3600);
    minutes = Math.floor((time % 3600) / 60);
  }

  if (hours < 0) {
    hours = 0;
    minutes = 0;
  }

  return `${hours} ${t('label.hours')} ${minutes} ${t('label.minutes')}`;
};

export const castToOrdinalSuffix = (input: number): string => {
  const lastDigit = input % 10;
  const lastTwoDigit = input % 100;

  if (lastDigit === 1 && lastTwoDigit !== 11) {
    return `${input}st`;
  }
  if (lastDigit === 2 && lastTwoDigit !== 12) {
    return `${input}nd`;
  }
  if (lastDigit === 3 && lastTwoDigit !== 13) {
    return `${input}rd`;
  }
  return `${input}th`;
};

export const castToDayOrdinalSuffix = (input: number, label = 'day'): string => {
  const lastDigit = input % 10;
  if (lastDigit <= 1) {
    return `${input} ${label}`;
  }
  return `${input} ${!isUndefinedOrNullOrEmpty(label) ? `${label}s` : ''}`;
};

export const dateRange = (from: number, to: number): { label: string; value: string | number }[] => {
  return range(from, to).map((index) => ({
    label: castToOrdinalSuffix(index),
    value: index,
  }));
};

export const dayRange = (from: number, to: number): { label: string; value: string | number }[] => {
  return range(from, to).map((index) => ({
    label: castToDayOrdinalSuffix(index),
    value: index,
  }));
};

export const readUploadFileAsBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    reader.onload = () => {
      return resolve(reader.result as string);
    };
  });
};

export const handlePreview = (preview: string) => {
  fetch(preview)
    .then((res) => res.blob())
    .then((blob) => window.open(URL.createObjectURL(blob)));
};

export const downloadFileByBase64Str = (preview: string, fileName: string) => {
  const downloadLink = document.createElement('a');
  document.body.appendChild(downloadLink);
  downloadLink.href = preview;
  downloadLink.target = '_self';
  downloadLink.download = fileName;
  downloadLink.click();
  downloadLink.remove();
};

export const reverseArr = (input: any[]) => {
  const ret: any[] = [];
  for (let i = input.length - 1; i >= 0; i -= 1) {
    ret.push(input[i]);
  }
  return ret;
};

export const currencyFormatValue = (
  value?: number,
  decimalScale?: number,
  prefix?: string,
  suffix?: string,
  disableGroupSeparators?: boolean,
  roundUp?: boolean
) => {
  if (value === undefined || isUndefinedOrNullOrEmpty(value)) {
    return '-';
  }

  if (isNaN(value)) {
    // eslint-disable-next-line no-param-reassign
    value = 0;
  }

  if (roundUp) {
    // eslint-disable-next-line no-param-reassign
    value = parseFloat(value.toFixed(decimalScale));
  }

  return formatValue({
    value: String(value),
    prefix,
    suffix,
    decimalScale,
    disableGroupSeparators,
  });
};

export const isArrayWithLength = (arr: []) => {
  return Array.isArray(arr) && arr.length;
};

export const getAllowedRoutes = (routes: any, permissions: any, countryCode: CountryCode) => {
  // @ts-ignore
  return routes.filter(({ permission, blacklistCountry }) => {
    if (blacklistCountry && blacklistCountry.length > 0 && blacklistCountry.indexOf(countryCode) !== -1) return false;
    if (!permission) return true;
    if (!isArrayWithLength(permission)) return true;
    return intersection(permission, permissions).length;
  });
};

export const checkAccessPermission = (permissions: string[], actIds: PermissionId[]): boolean => {
  return permissions.filter((element) => actIds.indexOf(element as PermissionId) !== -1).length > 0;
};

export const getDateFormat = (date?: string, format?: string) => {
  const d = moment(date, format);
  return date && d.isValid() ? d : undefined;
};

export const isEmpty = (object: object) => {
  return Object.values(object).every((x) => isUndefinedOrNullOrEmpty(x));
};

export const getDisabledHours = (isToday: boolean) => {
  if (isToday) {
    const hours = [];
    for (let i = 0; i < moment().hour(); i += 1) {
      hours.push(i);
    }
    return hours;
  }
  return [];
};

export const getDisabledMinutes = (_selectedHour: any, _isToday: boolean) => {
  return [];
};

export const isEmptyReturnDefaultValue = (val: any, result?: any, defaultVal?: any) => {
  if (isUndefinedOrNullOrEmpty(val)) {
    return defaultVal || '-';
  }

  return result || val;
};

export const onKeyPress = (event: React.KeyboardEvent) => {
  const { which, charCode } = event;
  const regex = /^[a-zA-Z0-9-_]+$/;
  const key = String.fromCharCode(!charCode ? which : charCode);
  if (!regex.test(key)) {
    event.preventDefault();
    return false;
  }
  return true;
};

export const stringToColour = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i += 1) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xff;
    const colourCode = `00${value.toString(16)}`;
    colour += colourCode.substr(-2);
  }
  return colour;
};

const getCountryCode = (localeString: string) => {
  let components = localeString.split('_');
  if (components.length === 2) {
    return components.pop();
  }
  components = localeString.split('-');
  if (components.length === 2) {
    return components.pop();
  }
  return localeString;
};

export const getCurrency = (locale: string) => {
  const countryCode = getCountryCode(locale)?.toLowerCase() ?? 'my';
  if (countryCode in currencyMap) {
    return currencyMap[countryCode];
  }
  return null;
};

export const getParamsBaseOnHistoryAct = (initParams: any, queryParams?: any, historyAct?: string) => {
  switch (historyAct) {
    case HistoryActType.PUSH:
      return initParams;
    case HistoryActType.POP:
    case HistoryActType.REPLACE:
      return queryParams || initParams;
    default:
      return initParams;
  }
};

const dateRangeOverlaps = (aStart: number, aEnd: number, bStart: number, bEnd: number): boolean => {
  if (aStart < bStart && bStart < aEnd) return true; // b starts in a
  if (aStart < bEnd && bEnd < aEnd) return true; // b ends in a
  if (bStart < aStart && aEnd < bEnd) return true; // a in b
  return false;
};

export const checkMultipleDateRangeOverlaps = (
  timeEntries: { dateFrom: number | null; dateTo: number | null }[]
): boolean => {
  const timeIntervals = timeEntries.filter((entry) => entry.dateFrom != null && entry.dateTo != null);

  if (timeIntervals !== null && timeIntervals.length > 1) return forLoopDateRage(timeIntervals);
  return false;
};

const forLoopDateRage = (timeIntervals: any[]): boolean => {
  let j = 0;
  for (let i = 0; i < timeIntervals.length - 1; i += 1) {
    for (j = i + 1; j < timeIntervals.length; j += 1) {
      if (
        dateRangeOverlaps(
          timeIntervals[i].dateFrom ?? 0,
          timeIntervals[i].dateTo ?? 0,
          timeIntervals[j].dateFrom ?? 0,
          timeIntervals[j].dateTo ?? 0
        )
      )
        return true;
    }
  }
  return false;
};

export const calculateUnitDays = (data: {
  startDate: moment.Moment;
  endDate: moment.Moment;
  toSession: LeaveSessionType;
}): number => {
  if (data.toSession === LeaveSessionType.FIRST_SESSION || data.toSession === LeaveSessionType.SECOND_SESSION)
    return 0.5;
  return data.endDate.diff(data.startDate, 'days') + 1;
};

export const isDev = (): boolean => {
  return development;
};

export const disableAfterCurrentDate = (currentDate: moment.Moment) => currentDate > moment().endOf('day');

export const checkValidationBaseOnCurrentCountry = (value: any, countryCodeArr: CountryCode[]) => {
  if (countryCodeArr.indexOf(getCurrentCountryCode(store.getState())) !== -1) {
    return !isUndefinedOrNull(value);
  }
  return true;
};

export const generateExcel = (data: any) => {
  const worksheet = XLSX.utils.json_to_sheet(data ?? []);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
  const excelBlob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const url = URL.createObjectURL(excelBlob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'file.xlsx';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const isBase64 = (fileBase64: string) => {
  try {
    const base64Content = fileBase64?.split(',')[1];
    const decodedValue = atob(base64Content);
    if (!isUndefinedOrNullOrEmpty(decodedValue) && fileBase64.includes(';base64')) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getCountryName = (countryCode: CountryCode) => {
  if (countryCode === CountryCode.MY) return 'Malaysia';
  if (countryCode === CountryCode.PH) return 'Philippines';
  return '';
};

export const getCenterTableScroll = (breakpoint: {
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  xxl?: boolean;
}) => {
  switch (true) {
    case breakpoint.xs:
      return { x: 'auto', y: '60vh' };
    case breakpoint.xl:
      return { x: 'auto', y: '40vh' };
    default:
      return { x: 'auto', y: '60vh' };
  }
};

export const blobToString = (blob: Blob): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const text = reader.result as string;
      resolve(text);
    };

    reader.onerror = reject;

    reader.readAsText(blob);
  });
};

type MenuItem = Required<MenuProps>['items'][number];
export const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
};

export const setCookie = (cname: string, cvalue: string, expiresInUnixTimestamp?: number): any => {
  let expires = '';
  if (expiresInUnixTimestamp) {
    const expiresDate = new Date(expiresInUnixTimestamp * 1000);
    expires = `expires=${expiresDate.toUTCString()}`;
  }
  document.cookie = `${cname}=${cvalue};${expires};path=/;domain=${getDomainName()}${
    window.location.protocol === 'https:' ? ';secure' : ''
  }`;
};

export const getCookie = (cname: string) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const getDomainName = () => {
  if (window.location.hostname === 'localhost') {
    return 'localhost';
  }

  if (window.location.hostname.includes('preprod')) {
    return `.myadmin.paywatchglobal.com`;
  }

  return `.${window.location.hostname}`;
};

export const getCookies = () => {
  const pairs = document.cookie.split(';');
  const cookies: any = {};
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    cookies[`${pair[0]}`.trim()] = unescape(pair.slice(1).join('='));
  }
  return cookies;
};

export const deleteCookie = (key: string) => {
  let cookieString = `${key}=;path=/`;
  const domain = getDomainName();
  if (window.location.protocol === 'https:') {
    cookieString += `;secure`;
  }
  cookieString += `;expires=0`;
  if (domain) {
    cookieString += `;domain=${domain}`;
  }
  document.cookie = cookieString;
  return null;
};

export const deleteAllCookies = (): void => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(getCookies())) {
    deleteCookie(key);
  }
};

export const sagaRedirectToEmployerPortalDasboard = (data: any): any => {
  if (isUndefinedOrNullOrEmpty(data.eid)) {
    push('/dashboard');
    return;
  }
  window.location.href = `${process.env.REACT_APP_EMPLOYER_PORTAL_URL}/dashboard`;
  // return push(`${process.env.REACT_APP_EMPLOYER_PORTAL_URL}/dashboard`);
};

export const checkWithdrawalCycleForOverlaps = (cycles: any[], setCycleErrors: any) => {
  cycles.forEach((item, index) => {
    const { dateFrom, dateTo } = item;

    const previousCycle = cycles[index - 1];
    const nextCycle = cycles[index + 1];

    // if (previousCycle && previousCycle.dateTo) {
    //   if (
    //     (dateFrom !== null && dateFrom <= previousCycle.dateTo) ||
    //     (dateTo !== null && dateTo <= previousCycle.dateTo)
    //   ) {
    //     setCycleErrors((prevErrors: any) => ({
    //       ...prevErrors,
    //       [`withdrawalCycles[${index}].dateTo`]: 'The new date range overlaps with the previous date range. 1',
    //     }));
    //     return;
    //   }
    // }

    // if (nextCycle && nextCycle.dateFrom) {
    //   if ((dateFrom !== null && dateFrom >= nextCycle.dateFrom) || (dateTo !== null && dateTo >= nextCycle.dateFrom)) {
    //     setCycleErrors((prevErrors: any) => ({
    //       ...prevErrors,
    //       [`withdrawalCycles[${index}].dateTo`]: 'The new date range overlaps with the next date range. 2',
    //     }));
    //     return;
    //   }
    // }

    for (let i = 0; i < cycles.length; i += 1) {
      if (i !== index) {
        const cycle = cycles[i];
        if (
          (dateFrom !== null && dateFrom >= cycle.dateFrom && dateFrom <= cycle.dateTo) ||
          (dateTo !== null && dateTo >= cycle.dateFrom && dateTo <= cycle.dateTo) ||
          (dateFrom !== null && dateTo !== null && dateFrom <= cycle.dateFrom && dateTo >= cycle.dateTo)
        ) {
          setCycleErrors((prevErrors: any) => ({
            ...prevErrors,
            [`withdrawalCycles[${index}].dateTo`]: 'The new date range overlaps with an existing date range.',
          }));
          return;
        }
      }
    }
  });
};

export const isDateFormatTag = (tag: string) => {
  const pattern = /^\d{2} [A-Za-z]{3} \d{4} - \d{2} [A-Za-z]{3} \d{4}$/;
  return pattern.test(tag);
};
