import { CHANGE_DARK_MODE, TOGGLE_SIDEBAR, TOGGLE_ASIDE } from './constants';

export type UiThemeState = {
  sidebarCollapsed: boolean;
  asideShow: boolean;
  darkMode: boolean;
};

export const initialUiThemeState: UiThemeState = {
  sidebarCollapsed: false,
  asideShow: false,
  darkMode: false,
};

// eslint-disable-next-line default-param-last, @typescript-eslint/no-explicit-any
export const ChangeUiThemeReducer = (state = initialUiThemeState, action: any) => {
  switch (action.type) {
    case CHANGE_DARK_MODE:
      return { ...state, darkMode: action.payload };
    case TOGGLE_SIDEBAR:
      return { ...state, sidebarCollapsed: action.payload };
    case TOGGLE_ASIDE:
      return { ...state, asideShow: action.payload };
    // case 'set':
    //   console.log('set', state, rest);
    //   return { ...state, ...rest }
    default:
      return state;
  }
};
