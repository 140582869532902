import { useField } from 'formik';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { FormItem } from 'formik-antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useSelector } from 'react-redux';
import { RootState, selectCurrentCountry } from 'modules';
import { isUndefinedOrNullOrEmpty } from 'common';
import { useTranslation } from 'react-i18next';

export interface MobileNumberInputProp {
  id?: string;
  placeholder?: string;
  label?: string;
  country?: string | number;
  name: string;
  value?: string | null;
  onChange?: (values: string) => void;
  tooltip?: ReactNode;
  wrapperCol?: object; // ant design Col object
  labelCol?: object; // ant design Col object
  labelAlign?: 'right' | 'left';
  required?: boolean;
  countryCodeEditable?: boolean;
  enableSearch?: boolean;
  disabled?: boolean;
}

export const MobileNumberInput: React.FC<MobileNumberInputProp> = ({
  id,
  name,
  onChange,
  label,
  tooltip,
  labelCol,
  wrapperCol,
  labelAlign,
  required,
  placeholder,
  country,
  countryCodeEditable,
  enableSearch,
  value,
  disabled,
}) => {
  const { t } = useTranslation();
  const countryCode = useSelector((state: RootState) => selectCurrentCountry(state));
  // This isn't an input, so instead of using the values in 'field' and 'meta' directly,
  // we'll use 'helpers'.
  // eslint-disable-next-line
  const [, meta, helper] = useField({ name: name });

  const handleChange = useCallback(
    (valueData: string) => {
      if (onChange) onChange(valueData);
      helper.setValue(valueData);
    },
    [helper, onChange]
  );

  const inputClass = useMemo(() => {
    if (!isUndefinedOrNullOrEmpty(meta.error) && meta.touched)
      return 'ant-input custom-mobile-input show-mobile-field-error';
    return 'ant-input custom-mobile-input';
  }, [meta]);

  const buttonClass = useMemo(() => {
    if (!isUndefinedOrNullOrEmpty(meta.error) && meta.touched) return 'custom-mobile-btn show-mobile-field-error';
    return 'custom-mobile-btn';
  }, [meta]);

  return (
    <FormItem
      id={id}
      label={label}
      name={name}
      tooltip={tooltip}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      help={meta.touched && meta.error ? t(`${meta.error}`) : undefined}
      labelAlign={labelAlign}
      required={required}
      // initialValue={{ short: 'my' }}
    >
      <PhoneInput
        inputClass={inputClass}
        buttonClass={buttonClass}
        placeholder={placeholder}
        country={country ?? countryCode}
        countryCodeEditable={countryCodeEditable}
        enableSearch={enableSearch}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      />
    </FormItem>
  );
};

MobileNumberInput.defaultProps = {
  labelAlign: 'left',
  enableSearch: true,
};
