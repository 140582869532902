import { call, put } from 'redux-saga/effects';
import { API } from 'api';
import { successTransactionRecord, successTransactionInfo } from '../actions';
import { ITransactionRecordFetch, ITransactionInfoFetch } from '../interfaces';

export function* transactionRecordSaga(action: ITransactionRecordFetch) {
  try {
    const url = '/api/admin/transactions/retrieve';
    const { data } = yield call(API.get(), url, action.payload);
    yield put(
      successTransactionRecord({
        records: data.data,
        count: data.count,
        currentPage: action.payload.page,
      })
    );
  } catch (error) {
    // Empty
  }
}

export function* transactionInfoSaga(action: ITransactionInfoFetch) {
  try {
    const { id } = action.payload;
    const { data } = yield call(API.get(), `/api/admin/transactions/retrieve/${id}`);
    yield put(successTransactionInfo(data));
  } catch (error) {
    // Empty
  }
}
