import { call, put } from 'redux-saga/effects';
import { API } from 'api';
import {
  ekycFileRetrieveSuccess,
  IEkycFileRetrieveFetch,
  IEkycInfoFetch,
  IEkycListFetch,
  successEkycInfo,
  successEkycList,
} from '../actions';

export function* ekycListSaga(action: IEkycListFetch) {
  try {
    const { data } = yield call(API.get(), '/api/admin/ekyc/application', action.payload);
    yield put(successEkycList({ records: data }));
  } catch (error) {
    // Empty
  }
}

export function* ekycInfoSaga(action: IEkycInfoFetch) {
  try {
    const { id } = action.payload;
    const { data } = yield call(API.get(), `/api/admin/ekyc/application/${id}`, {});
    yield put(successEkycInfo(data));
  } catch (error) {
    // Empty
  }
}

export function* ekycFileRetrieveSaga(action: IEkycFileRetrieveFetch) {
  try {
    const { id, key } = action.payload;
    const { data } = yield call(API.getBlob(), '/api/admin/files/retrieve', {
      fileId: id,
      type: 'ekyc',
    });
    yield put(ekycFileRetrieveSuccess({ data, key }));
  } catch (error) {
    // Empty
  }
}
