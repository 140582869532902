export const EMPLOYEE_RECORD_FETCH = 'employee/EMPLOYEE_RECORD_FETCH';
export const EMPLOYEE_RECORD_SUCCESS = 'employee/EMPLOYEE_RECORD_SUCCESS';

export const EMPLOYEE_INFO_FETCH = 'employee/EMPLOYEE_INFO_FETCH';
export const EMPLOYEE_INFO_SUCCESS = 'employee/EMPLOYEE_INFO_SUCCESS';
export const EMPLOYEE_INFO_ERROR = 'employee/EMPLOYEE_INFO_ERROR';

export const EMPLOYEE_UPDATE_INFO_FETCH = 'employee/EMPLOYEE_UPDATE_INFO_FETCH';
export const EMPLOYEE_UPDATE_INFO_SUCCESS = 'employee/EMPLOYEE_UPDATE_INFO_SUCCESS';
export const EMPLOYEE_UPDATE_INFO_ERROR = 'employee/EMPLOYEE_UPDATE_INFO_ERROR';

export const EMPLOYEE_UPDATE_STATUS_FETCH = 'employee/EMPLOYEE_UPDATE_STATUS_FETCH';
export const EMPLOYEE_UPDATE_STATUS_SUCCESS = 'employee/EMPLOYEE_UPDATE_STATUS_SUCCESS';
export const EMPLOYEE_UPDATE_STATUS_ERROR = 'employee/EMPLOYEE_UPDATE_STATUS_ERROR';

export const EMPLOYEE_COMPANY_RECORD_FETCH = 'employee/EMPLOYEE_COMPANY_RECORD_FETCH';
export const EMPLOYEE_COMPANY_RECORD_SUCCESS = 'employee/EMPLOYEE_COMPANY_RECORD_SUCCESS';

export const EMPLOYEE_COMPANY_INFO_FETCH = 'employee/EMPLOYEE_COMPANY_INFO_FETCH';
export const EMPLOYEE_COMPANY_INFO_SUCCESS = 'employee/EMPLOYEE_COMPANY_INFO_SUCCESS';
export const EMPLOYEE_COMPANY_INFO_ERROR = 'employee/EMPLOYEE_COMPANY_INFO_ERROR';

export const EMPLOYEE_UPDATE_COMPANY_INFO_FETCH = 'employee/EMPLOYEE_UPDATE_COMPANY_INFO_FETCH';
export const EMPLOYEE_UPDATE_COMPANY_INFO_SUCCESS = 'employee/EMPLOYEE_UPDATE_COMPANY_INFO_SUCCESS';
export const EMPLOYEE_UPDATE_COMPANY_INFO_ERROR = 'employee/EMPLOYEE_UPDATE_COMPANY_INFO_ERROR';

export const EMPLOYEE_UPDATE_COMPANY_STATUS_RESET = 'employee/EMPLOYEE_UPDATE_COMPANY_STATUS_RESET';
export const EMPLOYEE_UPDATE_COMPANY_STATUS_FETCH = 'employee/EMPLOYEE_UPDATE_COMPANY_STATUS_FETCH';
export const EMPLOYEE_UPDATE_COMPANY_STATUS_SUCCESS = 'employee/EMPLOYEE_UPDATE_COMPANY_STATUS_SUCCESS';
export const EMPLOYEE_UPDATE_COMPANY_STATUS_ERROR = 'employee/EMPLOYEE_UPDATE_COMPANY_STATUS_ERROR';

export const EMPLOYEE_WORK_HOUR_RECORD_FETCH = 'employee/EMPLOYEE_WORK_HOUR_RECORD_FETCH';
export const EMPLOYEE_WORK_HOUR_RECORD_SUCCESS = 'employee/EMPLOYEE_WORK_HOUR_RECORD_SUCCESS';

export const EMPLOYEE_TRANSACTION_RECORD_FETCH = 'employee/EMPLOYEE_TRANSACTION_RECORD_FETCH';
export const EMPLOYEE_TRANSACTION_RECORD_SUCCESS = 'employee/EMPLOYEE_TRANSACTION_RECORD_SUCCESS';

export const EMPLOYEE_LAPSE_REPAYMENT_RECORD_FETCH = 'employee/EMPLOYEE_LAPSE_REPAYMENT_RECORD_FETCH';
export const EMPLOYEE_LAPSE_REPAYMENT_RECORD_SUCCESS = 'employee/EMPLOYEE_LAPSE_REPAYMENT_RECORD_SUCCESS';

export const EMPLOYEE_EKYC_RECORD_FETCH = 'employee/EMPLOYEE_EKYC_RECORD_FETCH';
export const EMPLOYEE_EKYC_RECORD_SUCCESS = 'employee/EMPLOYEE_EKYC_RECORD_SUCCESS';
