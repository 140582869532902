import { CountryCode } from 'common';
import { ChangeCountryCodeAction } from './actions';
import { CHANGE_COUNTRY_CODE } from './constants';

export interface CountryState {
  code: CountryCode;
}

export const initialChangeCountryState: CountryState = {
  code: CountryCode.MY,
};

// eslint-disable-next-line default-param-last
export const CountryReducer = (state = initialChangeCountryState, action: ChangeCountryCodeAction) => {
  if (action.type === CHANGE_COUNTRY_CODE) {
    return {
      ...state,
      code: action.payload.code,
    };
  }
  return state;
};
