import { takeEvery } from 'redux-saga/effects';
import {
  DASHBOARD_DOWNLOAD_ATTENDANCE_FETCH,
  DASHBOARD_EMPLOYEE_ATTENDANCE_FETCH,
  DASHBOARD_STATISTIC_FETCH,
} from '../constants';
import { dashboardAttendanceSaga, dashboardDownloadAttendanceSaga, dashboardStatisticSaga } from './dashboardSaga';

export function* rootDashboardSaga() {
  yield takeEvery(DASHBOARD_STATISTIC_FETCH, dashboardStatisticSaga);
  yield takeEvery(DASHBOARD_EMPLOYEE_ATTENDANCE_FETCH, dashboardAttendanceSaga);
  yield takeEvery(DASHBOARD_DOWNLOAD_ATTENDANCE_FETCH, dashboardDownloadAttendanceSaga);
}
