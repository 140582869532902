/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, select } from 'redux-saga/effects';
import { API } from 'api';
import { INotificationReadFetch, notificationListSuccess } from '../actions';

export function* notificationListSaga() {
  try {
    const { data } = yield call(API.get(), '/api/admin/notification/retrieve', {});
    yield put(notificationListSuccess({ ...data, records: data.result }));
  } catch (error) {
    // Empty
  }
}

export function* notificationReadSaga(action: INotificationReadFetch): any {
  try {
    const { id } = action.payload;
    yield call(API.post(), `/api/admin/notification/read/${id}`);
    let notificationList = yield select((state) => state.notification.notification.list);
    if (id === 'all') {
      notificationList = notificationList.map((item: any) => ({
        ...item,
        isRead: true,
      }));
      yield put(notificationListSuccess({ records: notificationList, count: 0 }));
    } else {
      notificationList = notificationList.map((item: { id: string }) =>
        item.id === id
          ? {
              ...item,
              isRead: true,
            }
          : item
      );
      yield put(
        notificationListSuccess({
          records: notificationList,
          count: notificationList.filter((item: { isRead: boolean }) => !item.isRead).length,
        })
      );
    }
  } catch (error) {
    // Empty
  }
}
