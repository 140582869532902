/* eslint-disable @typescript-eslint/no-explicit-any, import/no-extraneous-dependencies */
import moment from 'moment';
import { DisabledTimes, RangeValue } from 'rc-picker/lib/interface';
import { RangeType } from 'rc-picker/lib/RangePicker';
import React, { ReactNode, useMemo } from 'react';
import { FormItem, DatePicker } from 'formik-antd';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { isUndefinedOrNullOrEmpty } from 'common';

const { RangePicker } = DatePicker;

export interface RangePickerInputProp {
  label?: string | ReactNode;
  className?: string;
  id: string;
  name: string;
  format?: string;
  disabled?: boolean | [boolean, boolean];
  disabledDate?: (currentDate: moment.Moment) => boolean;
  disabledTime?: (date: any, type: RangeType) => DisabledTimes;
  value?: [moment.Moment | null, moment.Moment | null];
  placeholder?: [string, string];
  tooltip?: ReactNode;
  wrapperCol?: object; // ant design Col object
  labelCol?: object; // ant design Col object
  labelAlign?: 'right' | 'left';
  required?: boolean;
  allowClear?: boolean;
  showTime?: object | boolean;
  size?: 'small' | 'large';
  onChange?: (values: any, formatString: [string, string]) => void;
  ranges?: Record<string, Exclude<RangeValue<moment.Moment>, null> | (() => Exclude<RangeValue<moment.Moment>, null>)>;
  isUpdatedField?: boolean;
  help?: string;
  validateStatus?: '' | 'success' | 'warning' | 'error' | 'validating';
}

export const RangePickerInput: React.FC<RangePickerInputProp> = ({
  className,
  label,
  name,
  tooltip,
  labelCol,
  wrapperCol,
  labelAlign,
  required,
  onChange,
  size,
  value,
  id,
  disabled,
  disabledDate,
  disabledTime,
  placeholder,
  format,
  showTime,
  ranges,
  allowClear,
  isUpdatedField,
  help,
  validateStatus,
}) => {
  const { t } = useTranslation();
  const [, meta] = useField({ name });

  const onIsUpdatedField = useMemo(() => {
    if (isUpdatedField) {
      return 'is-updated-field';
    }
    return '';
  }, [isUpdatedField]);

  const displayValidateStatus = useMemo(() => {
    if (meta.touched && meta.error) return 'error';

    if (!isUndefinedOrNullOrEmpty(help)) return 'error';

    return validateStatus;
  }, [help, meta.error, meta.touched, validateStatus]);

  return (
    <FormItem
      className={className}
      label={label}
      name={name}
      tooltip={tooltip}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      labelAlign={labelAlign}
      required={required}
      help={meta.touched && meta.error ? t(`${meta.error}`) : help}
      validateStatus={displayValidateStatus}
    >
      <RangePicker
        className={onIsUpdatedField}
        onChange={onChange}
        size={size}
        value={value}
        id={id}
        name={name}
        disabled={disabled}
        disabledDate={disabledDate}
        disabledTime={disabledTime}
        placeholder={placeholder}
        style={{ width: '100%' }}
        format={format}
        showTime={showTime}
        ranges={ranges}
        allowClear={allowClear}
      />
    </FormItem>
  );
};

RangePickerInput.defaultProps = {
  allowClear: true,
  labelAlign: 'left',
};
