import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'modules';
import { initialUserState, selectIsLoggedIn, setIsLoggedIn, setUserCredential } from 'modules/user';
import { useCookies } from 'react-cookie';
import { isUndefinedOrNullOrEmpty } from 'common';

export const PublicRoute = (props: RouteProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const redirectUrl = searchParams.get('redirectUrl');
  const users = useSelector((state: RootState) => state.user);
  const isLoggedIn = useSelector<RootState, boolean>((state) => selectIsLoggedIn(state));
  const [cookies] = useCookies(['expiredOn']);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(setUserCredential(initialUserState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  if (isLoggedIn) {
    if (isUndefinedOrNullOrEmpty(cookies.expiredOn)) {
      dispatch(setIsLoggedIn(false));
      return null;
    }
    if (redirectUrl) {
      if (redirectUrl.indexOf('http') > -1) {
        window.location.assign(redirectUrl);
        return null;
      }
    }

    if (!isUndefinedOrNullOrEmpty(users.eid) && !users.firstTimeLogin) {
      window.location.href = `${process.env.REACT_APP_EMPLOYER_PORTAL_URL}/dashboard`;
      return <Route {...props} />;
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Redirect to={{ pathname: '/' }} />;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route exact {...props} />;
};
