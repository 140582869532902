export const NEW_EMPLOYEE_REQUEST_LIST_FETCH = 'employee/NEW_EMPLOYEE_REQUEST_LIST_FETCH';
export const NEW_EMPLOYEE_REQUEST_LIST_SUCCESS = 'employee/NEW_EMPLOYEE_REQUEST_LIST_SUCCESS';

export const NEW_EMPLOYEE_REQUEST_INFO_FETCH = 'employee/NEW_EMPLOYEE_REQUEST_INFO_FETCH';
export const NEW_EMPLOYEE_REQUEST_INFO_SUCCESS = 'employee/NEW_EMPLOYEE_REQUEST_INFO_SUCCESS';
export const NEW_EMPLOYEE_REQUEST_INFO_ERROR = 'employee/NEW_EMPLOYEE_REQUEST_INFO_ERROR';

export const NEW_EMPLOYEE_REQUEST_UPDATE_FETCH = 'employee/NEW_EMPLOYEE_REQUEST_UPDATE_FETCH';
export const NEW_EMPLOYEE_REQUEST_UPDATE_SUCCESS = 'employee/NEW_EMPLOYEE_REQUEST_UPDATE_SUCCESS';
export const NEW_EMPLOYEE_REQUEST_UPDATE_ERROR = 'employee/NEW_EMPLOYEE_REQUEST_UPDATE_ERROR';
