/* eslint-disable no-shadow, @typescript-eslint/no-explicit-any */
import { CountryCode } from './country';

const maritalStatusType: any = {
  single: { label: 'label.single', value: 'single' },
  married: { label: 'label.married', value: 'married' },
  divorced: { label: 'label.divorced', value: 'divorced' },
  separated: { label: 'label.separated', value: 'separated' },
  widowed: { label: 'label.widowed', value: 'widowed' },
  other: { label: 'label.other', value: 'other' },
};

const genderType: any = {
  male: { label: 'label.male', value: 'male' },
  female: { label: 'label.female', value: 'female' },
  prefernottosay: { label: 'label.prefernottosay', value: 'prefernottosay' },
};

const kycIdType: any = {
  HkId: { label: 'idType.hkId', value: 'hkId' },
  MyKad: { label: 'idType.mykad', value: 'MyKad' },
  MyTentera: { label: 'idType.mytentera', value: 'MyTentera' },
  Passport: { label: 'idType.passport', value: 'Passport' },
  BindingNumber: { label: 'idType.bindingNumber', value: 'BindingNumber' },
  ktp: { label: 'idType.ktp', value: 'ktp' },
  KTPId: { label: 'idType.ktp', value: 'KTPId' },
};

const employmentKycIdType: any = {
  mykad: { label: 'idType.mykad', value: 'mykad' },
  mytentera: { label: 'idType.mytentera', value: 'mytentera' },
  passport: { label: 'idType.passport', value: 'passport' },
  sssnumber: { label: 'idType.sssNumber', value: 'sss number' },
  'sss number': { label: 'idType.sssNumber', value: 'sss number' },
  ktp: { label: 'idType.ktp', value: 'ktp' },
  ktpid: { label: 'idType.ktp', value: 'KTPId' },
};

const kycStatus: any = {
  approved: { label: 'status.approve', value: 'Approve' },
  pending: { label: 'status.pending', value: 'Pending' },
  rejected: { label: 'status.rejected', value: 'Rejected' },
  manualApproved: { label: 'status.manual_approved', value: 'Manual_Approved' },
};

const maritalStatusSelect = [
  maritalStatusType.single,
  maritalStatusType.married,
  maritalStatusType.divorced,
  maritalStatusType.separated,
  maritalStatusType.widowed,
  maritalStatusType.other,
];

const genderTypeSelect = [genderType.male, genderType.female, genderType.prefernottosay];
const hkKycIdTypeSelect = [kycIdType.HkId];

const kycIdTypeSelect = (countryCode: CountryCode) => {
  if (countryCode === CountryCode.MY) return [kycIdType.MyKad, kycIdType.MyTentera, kycIdType.Passport];
  if (countryCode === CountryCode.PH) return [kycIdType.BindingNumber];
  if (countryCode === CountryCode.ID) return [kycIdType.KTPId, kycIdType.Passport];
  return [];
};

const employmentKycIdTypeSelect = (countryCode: CountryCode) => {
  if (countryCode === CountryCode.MY)
    return [employmentKycIdType.mykad, employmentKycIdType.mytentera, employmentKycIdType.passport];
  if (countryCode === CountryCode.PH) return [employmentKycIdType.sssnumber];
  if (countryCode === CountryCode.ID) return [employmentKycIdType.ktp, employmentKycIdType.passport];
  return [];
};

const getNationalityByCountryCode = (countryCode: CountryCode) => {
  if (countryCode === CountryCode.HK) return 'Hongkonger';
  if (countryCode === CountryCode.PH) return 'Philippineser';
  if (countryCode === CountryCode.ID) return 'Indonesian';
  return 'Malaysian';
};

const kycStatusSelect = [kycStatus.pending, kycStatus.approved, kycStatus.rejected, kycStatus.manualApproved];

export {
  kycIdType,
  kycIdTypeSelect,
  kycStatus,
  hkKycIdTypeSelect,
  kycStatusSelect,
  genderType,
  genderTypeSelect,
  maritalStatusType,
  maritalStatusSelect,
  employmentKycIdTypeSelect,
  getNationalityByCountryCode,
  employmentKycIdType,
};
