import { push } from 'redux-first-history';
import { call, put } from 'redux-saga/effects';
import { API } from 'api';
import { toastPush } from '../../public/toast';
import {
  INewEmployeeRequestFetch,
  INewEmployeeRequestInfoFetch,
  successNewEmployeeRequestList,
  successNewEmployeeRequestInfo,
  IUpdateNewEmployeeRequestFetch,
  successUpdateNewEmployeeRequest,
} from '../actions';

export function* newEmployeeRequestSaga(action: INewEmployeeRequestFetch) {
  try {
    const { data } = yield call(API.get(), '/api/employment/pending/all', action.payload);
    yield put(
      successNewEmployeeRequestList({
        records: data.data,
        count: data.count,
        currentPage: action.payload.page,
      })
    );
  } catch (error) {
    // Empty
  }
}

export function* newEmployeeRequestInfoSaga(action: INewEmployeeRequestInfoFetch) {
  try {
    const { ebid } = action.payload;
    const { data } = yield call(API.get(), `/api/employment/pending/${ebid}`, {
      eid: action.payload.eid,
    });
    yield put(successNewEmployeeRequestInfo(data));
  } catch (error) {
    // Empty
  }
}

export function* updateNewEmployeeRequestSaga(action: IUpdateNewEmployeeRequestFetch) {
  const { onSuccess, onError, payload } = action;
  try {
    const { id, ebid, eid } = payload;
    yield call(API.put(), `/api/employee/company/${ebid}`, payload);
    yield put(successUpdateNewEmployeeRequest());
    yield put(
      toastPush({
        code: 200,
        type: 'success',
        title: 'SUCCESS',
        body: 'toastPush.updatedEmployeeEmploymentInfo',
      })
    );
    yield put(push(`/employee/${id}/new-employee-request/${ebid}/${eid}`));
    if (onSuccess) onSuccess();
  } catch (error) {
    if (onError) onError();
  }
}
