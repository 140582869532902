enum LanguageCode {
  EN = 'en',
  IND = 'ind',
  PH = 'ph',
  ID = 'id',
  IN = 'in',
}

const languageCodeList = [
  {
    code: LanguageCode.EN,
    name: 'EN',
  },
  {
    code: LanguageCode.IN,
    name: 'ID',
  },
];

export { LanguageCode, languageCodeList };
