/* eslint-disable @typescript-eslint/no-explicit-any, import/no-cycle */
import { employerAction } from './actions';
import {
  CREATE_EMPLOYER_REQUEST_ERROR,
  CREATE_EMPLOYER_REQUEST_FETCH,
  CREATE_EMPLOYER_REQUEST_RESET,
  CREATE_EMPLOYER_REQUEST_SUCCESS,
  EMPLOYER_FILE_RETRIEVE_FETCH,
  EMPLOYER_FILE_RETRIEVE_RESET,
  EMPLOYER_FILE_RETRIEVE_SUCCESS,
  EMPLOYER_INFO_FETCH,
  EMPLOYER_INFO_RESET,
  EMPLOYER_INFO_SUCCESS,
  EMPLOYER_INVENTORY_LIST_FETCH,
  EMPLOYER_INVENTORY_LIST_SUCCESS,
  EMPLOYER_LIST_FETCH,
  EMPLOYER_LIST_SUCCESS,
  EMPLOYER_PREVIEW_FILES_IMAGE_ERROR,
  EMPLOYER_PREVIEW_FILES_IMAGE_FETCH,
  EMPLOYER_PREVIEW_FILES_IMAGE_RESET,
  EMPLOYER_PREVIEW_FILES_IMAGE_SUCCESS,
  EMPLOYER_REQUEST_INFO_FETCH,
  EMPLOYER_REQUEST_INFO_RESET,
  EMPLOYER_REQUEST_INFO_SUCCESS,
  EMPLOYER_REQUEST_LIST_FETCH,
  EMPLOYER_REQUEST_LIST_SUCCESS,
  EMPLOYER_REQUEST_UPLOAD_BLOB_ERROR,
  EMPLOYER_REQUEST_UPLOAD_BLOB_FETCH,
  EMPLOYER_REQUEST_UPLOAD_BLOB_SUCCESS,
  EMPLOYER_SELECT_FETCH,
  EMPLOYER_SELECT_SUCCESS,
  INIT_EDIT_EMPLOYER_REQUEST_SUCCESS,
  INIT_EMPLOYER_REQUEST_ERROR,
  INIT_EMPLOYER_REQUEST_FETCH,
  INIT_NEW_EMPLOYER_REQUEST_SUCCESS,
} from './constants';
import {
  initEmployerRequestInfoState,
  IEmployerInfoState,
  IEmployerRequestInfoState,
  initEmployerInfoState,
} from './types';

export interface IEmployerState {
  employerList: {
    list: any;
    count: number;
    loading: boolean;
    payload: {
      employerName?: string;
      employerId?: string;
      eid?: string;
      companyNameEid?: string;
      companyName?: string;
      page: number;
    };
  };
  employerSelect: {
    list: any;
  };
  employerInfo: IEmployerInfoState;
  employerRequestList: {
    list: any;
    count: number;
    loading: boolean;
    payload: {
      erid?: string;
      eid?: string;
      from?: number;
      to?: number;
      employerId?: string;
      employerName?: string;
      companyNameEid?: string;
      companyName?: string;
      status?: string;
      page: number;
    };
  };
  createEmployerRequest: {
    loading: boolean;
    success: boolean;
    error?: {
      message?: string;
      messageId?: string;
    };
  };
  employerRequestInfo: IEmployerRequestInfoState;
  initEmployerRequest: {
    newErid: string;
    editErid: string;
  };
  previewEmployerFileImage: {
    source: string;
    key: string;
  };
  employerRequestUploadBlob: {
    objectId: string;
    filename: string;
    prefix: string;
    loading: boolean;
    success: boolean;
    error: boolean;
  };
  employerInventoryList: {
    list: any[];
  };
  employerFileRetrieve: {
    data: string;
    success: boolean;
  };
}

const initialState: IEmployerState = {
  employerList: {
    list: [],
    count: 0,
    loading: false,
    payload: {
      page: 1,
    },
  },
  employerSelect: {
    list: [],
  },
  employerInfo: initEmployerInfoState,
  employerRequestList: {
    list: [],
    count: 0,
    loading: false,
    payload: {
      page: 1,
      status: 'pending',
    },
  },
  employerRequestInfo: initEmployerRequestInfoState,
  createEmployerRequest: {
    loading: false,
    success: false,
    error: undefined,
  },
  initEmployerRequest: {
    newErid: '',
    editErid: '',
  },
  previewEmployerFileImage: {
    source: '',
    key: '',
  },
  employerRequestUploadBlob: {
    objectId: '',
    filename: '',
    prefix: '',
    loading: false,
    success: false,
    error: false,
  },
  employerInventoryList: {
    list: [],
  },
  employerFileRetrieve: {
    data: '',
    success: false,
  },
};

const employerListReducer = (state: IEmployerState['employerList'], action: employerAction) => {
  switch (action.type) {
    case EMPLOYER_LIST_FETCH:
      return {
        ...state,
        loading: true,
        payload: action.payload,
      };
    case EMPLOYER_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.records,
        count: action.payload.count,
        loading: false,
      };
    default:
      return state;
  }
};

const employerSelectReducer = (state: IEmployerState['employerSelect'], action: employerAction) => {
  switch (action.type) {
    case EMPLOYER_SELECT_FETCH:
      return {
        ...state,
      };
    case EMPLOYER_SELECT_SUCCESS:
      return {
        ...state,
        list: action.payload.records,
      };
    default:
      return state;
  }
};

const employerInfoReducer = (state: IEmployerState['employerInfo'], action: employerAction) => {
  switch (action.type) {
    case EMPLOYER_INFO_RESET:
      return initialState.employerInfo;
    case EMPLOYER_INFO_FETCH:
      return {
        ...state,
      };
    case EMPLOYER_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const createEmployerRequest = (state: IEmployerState['createEmployerRequest'], action: employerAction) => {
  switch (action.type) {
    case CREATE_EMPLOYER_REQUEST_RESET:
      return initialState.createEmployerRequest;
    case CREATE_EMPLOYER_REQUEST_FETCH:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case CREATE_EMPLOYER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case CREATE_EMPLOYER_REQUEST_ERROR:
      return {
        loading: false,
        success: false,
        error: {
          message: action.payload.message,
          messageId: action.payload.messageId,
        },
      };
    default:
      return state;
  }
};

const initEmployerRequest = (state: IEmployerState['initEmployerRequest'], action: employerAction) => {
  switch (action.type) {
    case INIT_EMPLOYER_REQUEST_FETCH:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case INIT_NEW_EMPLOYER_REQUEST_SUCCESS:
      return {
        ...state,
        newErid: action.payload.erid,
        loading: false,
        success: true,
      };
    case INIT_EDIT_EMPLOYER_REQUEST_SUCCESS:
      return {
        ...state,
        editErid: action.payload.erid,
        loading: false,
        success: true,
      };
    case INIT_EMPLOYER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
      };
    default:
      return state;
  }
};

const employerRequestListReducer = (state: IEmployerState['employerRequestList'], action: employerAction) => {
  switch (action.type) {
    case EMPLOYER_REQUEST_LIST_FETCH:
      return {
        ...state,
        loading: true,
        payload: action.payload,
      };
    case EMPLOYER_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.records,
        count: action.payload.count,
        loading: false,
      };
    default:
      return state;
  }
};

const employerRequestInfoReducer = (state: IEmployerState['employerRequestInfo'], action: employerAction) => {
  switch (action.type) {
    case EMPLOYER_REQUEST_INFO_RESET:
      return initialState.employerRequestInfo;
    case EMPLOYER_REQUEST_INFO_FETCH:
      return {
        ...state,
      };
    case EMPLOYER_REQUEST_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const previewEmployerFileImageReducer = (state: IEmployerState['previewEmployerFileImage'], action: employerAction) => {
  switch (action.type) {
    case EMPLOYER_PREVIEW_FILES_IMAGE_RESET:
      return initialState.previewEmployerFileImage;
    case EMPLOYER_PREVIEW_FILES_IMAGE_FETCH:
    case EMPLOYER_PREVIEW_FILES_IMAGE_ERROR:
      return {
        ...state,
      };
    case EMPLOYER_PREVIEW_FILES_IMAGE_SUCCESS:
      return {
        ...state,
        source: action.payload.source,
        key: action.payload.key,
      };
    default:
      return state;
  }
};

const employerRequestUploadBlobReducer = (
  state: IEmployerState['employerRequestUploadBlob'],
  action: employerAction
) => {
  switch (action.type) {
    case EMPLOYER_REQUEST_UPLOAD_BLOB_FETCH:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case EMPLOYER_REQUEST_UPLOAD_BLOB_SUCCESS:
      return {
        ...state,
        objectId: action.payload.objectId,
        prefix: action.payload.prefix,
        filename: action.payload.filename,
        loading: false,
        success: true,
      };
    case EMPLOYER_REQUEST_UPLOAD_BLOB_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
      };
    default:
      return state;
  }
};

const employerInventoryListReducer = (state: IEmployerState['employerInventoryList'], action: employerAction) => {
  switch (action.type) {
    case EMPLOYER_INVENTORY_LIST_FETCH:
      return {
        ...state,
      };
    case EMPLOYER_INVENTORY_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.data,
      };
    default:
      return state;
  }
};
const employerFileRetrieveStateReducer = (state: IEmployerState['employerFileRetrieve'], action: employerAction) => {
  switch (action.type) {
    case EMPLOYER_FILE_RETRIEVE_RESET:
      return initialState.employerFileRetrieve;
    case EMPLOYER_FILE_RETRIEVE_FETCH:
      return {
        data: '',
        success: false,
      };
    case EMPLOYER_FILE_RETRIEVE_SUCCESS:
      return {
        data: action.payload.data,
        success: true,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last
export const employerReducer = (state = initialState, action: employerAction) => {
  switch (action.type) {
    case EMPLOYER_LIST_FETCH:
    case EMPLOYER_LIST_SUCCESS:
      return {
        ...state,
        employerList: employerListReducer({ ...state.employerList }, action),
      };
    case EMPLOYER_SELECT_FETCH:
    case EMPLOYER_SELECT_SUCCESS:
      return {
        ...state,
        employerSelect: employerSelectReducer({ ...state.employerSelect }, action),
      };
    case EMPLOYER_INFO_RESET:
    case EMPLOYER_INFO_FETCH:
    case EMPLOYER_INFO_SUCCESS:
      return {
        ...state,
        employerInfo: employerInfoReducer({ ...state.employerInfo }, action),
      };
    case EMPLOYER_REQUEST_LIST_FETCH:
    case EMPLOYER_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        employerRequestList: employerRequestListReducer({ ...state.employerRequestList }, action),
      };
    case EMPLOYER_REQUEST_INFO_RESET:
    case EMPLOYER_REQUEST_INFO_FETCH:
    case EMPLOYER_REQUEST_INFO_SUCCESS:
      return {
        ...state,
        employerRequestInfo: employerRequestInfoReducer({ ...state.employerRequestInfo }, action),
      };
    case CREATE_EMPLOYER_REQUEST_RESET:
    case CREATE_EMPLOYER_REQUEST_FETCH:
    case CREATE_EMPLOYER_REQUEST_SUCCESS:
    case CREATE_EMPLOYER_REQUEST_ERROR:
      return {
        ...state,
        createEmployerRequest: createEmployerRequest({ ...state.createEmployerRequest }, action),
      };
    case INIT_EMPLOYER_REQUEST_FETCH:
    case INIT_NEW_EMPLOYER_REQUEST_SUCCESS:
    case INIT_EDIT_EMPLOYER_REQUEST_SUCCESS:
    case INIT_EMPLOYER_REQUEST_ERROR:
      return {
        ...state,
        initEmployerRequest: initEmployerRequest({ ...state.initEmployerRequest }, action),
        createEmployerRequest: initialState.createEmployerRequest,
      };
    case EMPLOYER_PREVIEW_FILES_IMAGE_RESET:
    case EMPLOYER_PREVIEW_FILES_IMAGE_FETCH:
    case EMPLOYER_PREVIEW_FILES_IMAGE_SUCCESS:
    case EMPLOYER_PREVIEW_FILES_IMAGE_ERROR:
      return {
        ...state,
        previewEmployerFileImage: previewEmployerFileImageReducer({ ...state.previewEmployerFileImage }, action),
      };
    case EMPLOYER_REQUEST_UPLOAD_BLOB_FETCH:
    case EMPLOYER_REQUEST_UPLOAD_BLOB_SUCCESS:
    case EMPLOYER_REQUEST_UPLOAD_BLOB_ERROR:
      return {
        ...state,
        employerRequestUploadBlob: employerRequestUploadBlobReducer(
          {
            ...state.employerRequestUploadBlob,
          },
          action
        ),
      };
    case EMPLOYER_INVENTORY_LIST_FETCH:
    case EMPLOYER_INVENTORY_LIST_SUCCESS:
      return {
        ...state,
        employerInventoryList: employerInventoryListReducer({ ...state.employerInventoryList }, action),
      };
    case EMPLOYER_FILE_RETRIEVE_RESET:
    case EMPLOYER_FILE_RETRIEVE_FETCH:
    case EMPLOYER_FILE_RETRIEVE_SUCCESS:
      return {
        ...state,
        employerFileRetrieve: employerFileRetrieveStateReducer({ ...state.employerFileRetrieve }, action),
      };
    default:
      return state;
  }
};
