/* eslint-disable no-shadow, @typescript-eslint/no-explicit-any */
enum PermissionId {
  BANK_CONFIG_READ = 'BANK_CONFIG_READ',
  BANK_CONFIG_UPDATE = 'BANK_CONFIG_UPDATE',
  BANK_REPORTING_READ = 'BANK_REPORTING_READ',
  BULK_IMPORT_HISTORY_READ = 'BULK_IMPORT_HISTORY_READ',
  BULK_UPDATE_READ = 'BULK_UPDATE_READ',
  BULK_UPDATE_REQUEST_READ = 'BULK_UPDATE_REQUEST_READ',
  DASHBOARD_STATISTIC_READ = 'DASHBOARD_STATISTIC_READ',
  EKYC_APPLICATION_READ = 'EKYC_APPLICATION_READ',
  EKYC_APPLICATION_UPDATE = 'EKYC_APPLICATION_UPDATE',
  EMPLOYEE_ATTENDANCE_UPLOAD_READ = 'EMPLOYEE_ATTENDANCE_UPLOAD_READ',
  EMPLOYEE_ATTENDANCE_UPLOAD_WRITE = 'EMPLOYEE_ATTENDANCE_UPLOAD_WRITE',
  EMPLOYEE_COMPANY_INFO_READ = 'EMPLOYEE_COMPANY_INFO_READ',
  EMPLOYEE_COMPANY_INFO_UPDATE = 'EMPLOYEE_COMPANY_INFO_UPDATE',
  EMPLOYEE_INFO_UPDATE_READ = 'EMPLOYEE_INFO_UPDATE_READ',
  EMPLOYEE_LIST_READ = 'EMPLOYEE_LIST_READ',
  EMPLOYEE_LIST_UPDATE = 'EMPLOYEE_LIST_UPDATE',
  EMPLOYEE_TRANSACTION_READ = 'EMPLOYEE_TRANSACTION_READ',
  EMPLOYER_ADMIN_STATUS_READ = 'EMPLOYER_ADMIN_STATUS_READ',
  EMPLOYER_ADMIN_STATUS_UPDATE = 'EMPLOYER_ADMIN_STATUS_UPDATE',
  EMPLOYER_LIST_READ = 'EMPLOYER_LIST_READ',
  EMPLOYER_LIST_UPDATE = 'EMPLOYER_LIST_UPDATE',
  EMPLOYER_OUTLET_READ = 'EMPLOYER_OUTLET_READ',
  EMPLOYER_OUTLET_UPDATE = 'EMPLOYER_OUTLET_UPDATE',
  EMPLOYER_OUTLET_WRITE = 'EMPLOYER_OUTLET_WRITE',
  EMPLOYER_REPORT_READ = 'EMPLOYER_REPORT_READ',
  EMPLOYER_REPORT_UPDATE = 'EMPLOYER_REPORT_UPDATE',
  EMPLOYER_DEDUCTION_REPORT_READ = 'EMPLOYER_DEDUCTION_REPORT_READ',
  EMPLOYER_REQUEST_READ = 'EMPLOYER_REQUEST_READ',
  EMPLOYER_REQUEST_UPDATE = 'EMPLOYER_REQUEST_UPDATE',
  EMPLOYER_REQUEST_WRITE = 'EMPLOYER_REQUEST_WRITE',
  EXCEPTIONAL_FILE_READ = 'EXCEPTIONAL_FILE_READ',
  IBG_ENQUIRY_READ = 'IBG_ENQUIRY_READ',
  INSIGHT_CATEGORY_READ = 'INSIGHT_CATEGORY_READ',
  INSIGHT_CATEGORY_UPDATE = 'INSIGHT_CATEGORY_UPDATE',
  INSIGHT_CATEGORY_WRITE = 'INSIGHT_CATEGORY_WRITE',
  INVOICE_READ = 'INVOICE_READ',
  INVOICE_UPDATE = 'INVOICE_UPDATE',
  LAPSE_REPAYMENT_READ = 'LAPSE_REPAYMENT_READ',
  MERCHANT_LIST_READ = 'MERCHANT_LIST_READ',
  MERCHANT_LIST_UPDATE = 'MERCHANT_LIST_UPDATE',
  MERCHANT_LIST_WRITE = 'MERCHANT_LIST_WRITE',
  MERCHANT_REQUEST_READ = 'MERCHANT_REQUEST_READ',
  MERCHANT_REQUEST_UPDATE = 'MERCHANT_REQUEST_UPDATE',
  NEW_EMPLOYEE_REQUEST_READ = 'NEW_EMPLOYEE_REQUEST_READ',
  NEW_EMPLOYEE_REQUEST_UPDATE = 'NEW_EMPLOYEE_REQUEST_UPDATE',
  PAYMENT_TRANSACTION_READ = 'PAYMENT_TRANSACTION_READ',
  PAYMENT_TRANSACTION_UPDATE = 'PAYMENT_TRANSACTION_UPDATE',
  PAYOUT_STATUS_READ = 'PAYOUT_STATUS_READ',
  PAYOUT_STATUS_WRITE = 'PAYOUT_STATUS_WRITE',
  PROMOTION_READ = 'PROMOTION_READ',
  PROMOTION_UPDATE = 'PROMOTION_UPDATE',
  PROMOTION_WRITE = 'PROMOTION_WRITE',
  RECONCILIATION_READ = 'RECONCILIATION_READ',
  SEND_RESET_PASSWORD = 'SEND_RESET_PASSWORD',
  SETTING_CONFIG_READ = 'SETTING_CONFIG_READ',
  SETTING_CONFIG_UPDATE = 'SETTING_CONFIG_UPDATE',
  SETTING_FEES_CHARGES_READ = 'SETTING_FEES_CHARGES_READ',
  SETTING_FEES_CHARGES_UPDATE = 'SETTING_FEES_CHARGES_UPDATE',
  SETTING_ROLES_READ = 'SETTING_ROLES_READ',
  SETTING_ROLES_UPDATE = 'SETTING_ROLES_UPDATE',
  SETTING_ROLES_WRITE = 'SETTING_ROLES_WRITE',
  SETTING_USER_ACCESS_READ = 'SETTING_USER_ACCESS_READ',
  SETTING_USER_ACCESS_UPDATE = 'SETTING_USER_ACCESS_UPDATE',
  SETTING_USER_ACCESS_WRITE = 'SETTING_USER_ACCESS_WRITE',
  SETTING_GLOBAL_CUT_OFF_READ = 'SETTING_GLOBAL_CUT_OFF_READ',
  SETTING_GLOBAL_CUT_OFF_WRITE = 'SETTING_GLOBAL_CUT_OFF_WRITE',
  SETTING_GLOBAL_CUT_OFF_UPDATE = 'SETTING_GLOBAL_CUT_OFF_UPDATE',
  UPDATE_ADMIN_STATUS = 'UPDATE_ADMIN_STATUS',
  EMPLOYEE_EMPLOYMENT_PROFILE_READ = 'EMPLOYEE_EMPLOYMENT_PROFILE_READ',
  EMPLOYEE_EMPLOYMENT_PROFILE_UPDATE = 'EMPLOYEE_EMPLOYMENT_PROFILE_UPDATE',
  EMPLOYEE_EMPLOYMENT_PROFILE_DOWNLOAD = 'EMPLOYEE_EMPLOYMENT_PROFILE_DOWNLOAD',
  HRMS_PARTNERS_READ = 'HRMS_PARTNERS_READ',
  HRMS_PROCESSES_READ = 'HRMS_PROCESSES_READ',
  HRMS_SD_REQUESTS_READ = 'HRMS_SD_REQUESTS_READ',
  SAAS_FEE_CONFIG_READ = 'SAAS_FEE_CONFIG_READ',
  STATEMENT_OF_ACCOUNT_READ = 'STATEMENT_OF_ACCOUNT_READ',
  BULK_UPDATE_AGENT_READ = 'BULK_UPDATE_AGENT_READ',
  RULE_ENGINE_READ = 'RULE_ENGINE_READ',
  RULE_ENGINE_WRITE = 'RULE_ENGINE_WRITE',
  RULE_ENGINE_ADD = 'RULE_ENGINE_ADD',
  EMPLOYEE_LIST_SALARY_CREDIT_TAB_READ = 'EMPLOYEE_LIST_SALARY_CREDIT_TAB_READ',
  TEMP_FILE_UPLOAD_READ = 'TEMP_FILE_UPLOAD_READ',
  TEMP_FILE_UPLOAD_WRITE = 'TEMP_FILE_UPLOAD_WRITE',
  TEMP_FILE_UPLOAD_EDIT = 'TEMP_FILE_UPLOAD_EDIT',
  UPLOAD_STATUS_READ = 'UPLOAD_STATUS_READ',
  UPLOAD_STATUS_WRITE = 'UPLOAD_STATUS_WRITE',
  UPLOAD_STATUS_EDIT = 'UPLOAD_STATUS_EDIT',
  FEATURES_AND_SERVICES_READ = 'FEATURES_AND_SERVICES_READ',
  TRANSACTIONS_READ = 'TRANSACTIONS_READ',
  EP_EMPLOYEE_INFORMATION_VIEW = 'EP_EMPLOYEE_INFORMATION_VIEW',
  EP_EMPLOYEE_INFORMATION_EDIT = 'EP_EMPLOYEE_INFORMATION_EDIT',
  EP_EMPLOYEE_LEAVE_INFORMATION_EDIT = 'EP_EMPLOYEE_LEAVE_INFORMATION_EDIT',
  EP_EMPLOYEE_LEAVE_INFORMATION_VIEW = 'EP_EMPLOYEE_LEAVE_INFORMATION_VIEW',
  EP_EMPLOYEE_LEAVE_INFORMATION_ADD = 'EP_EMPLOYEE_LEAVE_INFORMATION_ADD',
  EP_EMPLOYEE_LIST_VIEW = 'EP_EMPLOYEE_LIST_VIEW',
  EP_EMPLOYEE_LIST_EDIT = 'EP_EMPLOYEE_LIST_EDIT',
  EP_EMPLOYEE_SALARY_INFORMATION_EDIT = 'EP_EMPLOYEE_SALARY_INFORMATION_EDIT',
  EP_EMPLOYEE_SALARY_INFORMATION_VIEW = 'EP_EMPLOYEE_SALARY_INFORMATION_VIEW',
  EP_EMPLOYEE_TRANSACTION_HISTORY_VIEW = 'EP_EMPLOYEE_TRANSACTION_HISTORY_VIEW',
  POLL_READ = 'POLL_READ',
  POLL_WRITE = 'POLL_WRITE',
  POLL_EDIT = 'POLL_EDIT',
  IMPERSONATE = 'IMPERSONATE',
}

export { PermissionId };
