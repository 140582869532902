import { takeEvery } from 'redux-saga/effects';
import {
  UPLOAD_FILE_REPORT_FETCH,
  LAPSE_REPAYMENT_REPORT_LIST_FETCH,
  UPDATE_LAPSE_REPAYMENT_REPORT_FETCH,
  LAPSE_REPAYMENT_SEND_EMAIL_FETCH,
  BANK_REPORT_LIST_FETCH,
  RECONCILIATION_REPORT_LIST_FETCH,
  EMPLOYEE_INFO_UPDATE_REPORT_LIST_FETCH,
  EXCEPTIONAL_FILE_REPORT_LIST_FETCH,
  EMPLOYER_REPORT_LIST_FETCH,
  IBG_ENQUIRY_LIST_FETCH,
  EMPLOYER_REPORT_UPDATE_FETCH,
  EMPLOYER_REPORT_UPLOAD_FILE_FETCH,
  INVOICE_REPORT_LIST_FETCH,
  INVOICE_REPORT_UPDATE_FETCH,
  INVOICE_REPORT_UPLOAD_FILE_FETCH,
  REPORT_FILE_RETRIEVE_FETCH,
} from '../constants';
// eslint-disable-next-line import/no-cycle
import {
  employerReportListSaga,
  employerReportUpdateSaga,
  lapseRepaymentReportListSaga,
  lapseRepaymentSendEmailSaga,
  reportListSaga,
  updateLapseRepaymentSaga,
  uploadFileReportSaga,
  employerReportUploadFileSaga,
  ibgEnquiryListSaga,
  invoiceReportListSaga,
  invoiceReportUpdateSaga,
  invoiceReportUploadFileSaga,
  reportFileRetrieveFetchSaga,
} from './reportSaga';

export function* rootReportSaga() {
  yield takeEvery(BANK_REPORT_LIST_FETCH, reportListSaga);
  yield takeEvery(EXCEPTIONAL_FILE_REPORT_LIST_FETCH, reportListSaga);
  yield takeEvery(EMPLOYEE_INFO_UPDATE_REPORT_LIST_FETCH, reportListSaga);
  yield takeEvery(RECONCILIATION_REPORT_LIST_FETCH, reportListSaga);
  yield takeEvery(EMPLOYER_REPORT_LIST_FETCH, employerReportListSaga);
  yield takeEvery(IBG_ENQUIRY_LIST_FETCH, ibgEnquiryListSaga);
  yield takeEvery(LAPSE_REPAYMENT_REPORT_LIST_FETCH, lapseRepaymentReportListSaga);
  yield takeEvery(UPLOAD_FILE_REPORT_FETCH, uploadFileReportSaga);
  yield takeEvery(UPDATE_LAPSE_REPAYMENT_REPORT_FETCH, updateLapseRepaymentSaga);
  yield takeEvery(LAPSE_REPAYMENT_SEND_EMAIL_FETCH, lapseRepaymentSendEmailSaga);
  yield takeEvery(EMPLOYER_REPORT_UPDATE_FETCH, employerReportUpdateSaga);
  yield takeEvery(EMPLOYER_REPORT_UPLOAD_FILE_FETCH, employerReportUploadFileSaga);
  yield takeEvery(INVOICE_REPORT_LIST_FETCH, invoiceReportListSaga);
  yield takeEvery(INVOICE_REPORT_UPDATE_FETCH, invoiceReportUpdateSaga);
  yield takeEvery(INVOICE_REPORT_UPLOAD_FILE_FETCH, invoiceReportUploadFileSaga);
  yield takeEvery(REPORT_FILE_RETRIEVE_FETCH, reportFileRetrieveFetchSaga);
}
