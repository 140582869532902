/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormLabelAlign } from 'antd/lib/form/interface';
import { useField } from 'formik';
import React, { CSSProperties, ReactNode, useMemo } from 'react';
import { FormItem, Switch as FormikAdtdSwitch } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { SwitchChangeEventHandler } from 'antd/lib/switch';

export interface SwitchInputProp {
  label?: string | ReactNode;
  checkedChildren?: string | ReactNode;
  unCheckedChildren?: string | ReactNode;
  id: string;
  name: string;
  className?: string;
  value?: string | number;
  // requiredMark?: any;
  required?: boolean;
  noStyle?: boolean;
  disabled?: boolean;
  tooltip?: ReactNode;
  children?: ReactNode;
  help?: ReactNode;
  labelAlign?: FormLabelAlign; // default: right
  labelCol?: object; // ant design Col object
  wrapperCol?: object; // ant design Col object
  rules?: any;
  style?: CSSProperties;
  validateStatus?: '' | 'success' | 'warning' | 'error' | 'validating';
  isUpdatedField?: boolean;
  defaultChecked?: boolean;
  checked?: boolean;
  loading?: boolean;
  onChange?: SwitchChangeEventHandler;
}

export const SwitchInput: React.FC<SwitchInputProp> = (props) => {
  const {
    className,
    label,
    checkedChildren,
    unCheckedChildren,
    name,
    tooltip,
    labelCol,
    wrapperCol,
    labelAlign,
    required,
    rules,
    noStyle,
    style,
    id,
    value,
    disabled,
    children,
    help,
    validateStatus,
    isUpdatedField,
    defaultChecked,
    checked,
    loading,
    onChange,
  } = props;
  const [field, meta] = useField({ name, value });
  const { t } = useTranslation();

  const displayValidateStatus = useMemo(() => {
    if (meta.touched && meta.error) return 'error';

    return validateStatus;
  }, [meta.error, meta.touched, validateStatus]);

  const onIsUpdatedField = useMemo(() => {
    if (isUpdatedField) {
      return 'is-updated-field';
    }
    return '';
  }, [isUpdatedField]);

  return (
    <FormItem
      className={className}
      label={label}
      name={field.name}
      tooltip={tooltip}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      labelAlign={labelAlign}
      required={required}
      rules={rules}
      noStyle={noStyle}
      help={help ?? (meta.touched && meta.error) ? t(`${meta.error}`) : undefined}
      validateStatus={displayValidateStatus}
    >
      <FormikAdtdSwitch
        style={style}
        id={id}
        name={field.name}
        className={onIsUpdatedField}
        disabled={disabled}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        loading={loading}
      />
      {children}
    </FormItem>
  );
};

SwitchInput.defaultProps = {
  labelAlign: 'left',
};
