import { notification } from 'antd';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'modules';
import Icon, { CloseCircleOutlined } from '@ant-design/icons';
import { Trans, useTranslation } from 'react-i18next';
import { isUndefinedOrNullOrEmpty } from 'common';

const successSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18457 2.99721 7.13633 4.39828 5.49707C5.79935 3.85782 7.69279 2.71538 9.79619 2.24015C11.8996 1.76491 14.1003 1.98234 16.07 2.86"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M22 4L12 14.01L9 11.01" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SuccessIcon = (props: any) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Icon component={successSvg} {...props} />;
};

export const Toaster = () => {
  const { t } = useTranslation();
  const data = useSelector((state: RootState) => state.public.toasts);

  useEffect(() => {
    if (data.toasts.length <= 0) {
      return;
    }

    let icon;
    let style;
    if (data.toasts[0].type === 'success') {
      style = { color: '#FFF', backgroundColor: '#14B68B' };
      icon = <SuccessIcon />;
    } else if (data.toasts[0].type === 'error') {
      style = { color: '#FFF', backgroundColor: '#EB5757' };
      icon = <CloseCircleOutlined />;
    }

    notification[data.toasts[0].type]({
      message: (
        <p className="text-white mb-0">
          {!isUndefinedOrNullOrEmpty(data.toasts[0]?.params) && typeof data.toasts[0]?.body === 'string' ? (
            <Trans i18nKey={data.toasts[0]?.body} values={data.toasts[0]?.params} />
          ) : (
            <div>{typeof data.toasts[0]?.body === 'string' ? t(data.toasts[0]?.body) : data.toasts[0]?.body}</div>
          )}
        </p>
      ),
      description: null,
      style,
      icon,
      duration: 6,
    });
  }, [data, t]);

  return <div />;
};
