/* eslint-disable no-shadow, @typescript-eslint/no-explicit-any */
enum WorkStatusType {
  PENDING_EMPLOYER = 'pending_employer',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

const workStatusTypeColor: any = {
  [WorkStatusType.PENDING_EMPLOYER]: 'warning',
  [WorkStatusType.APPROVED]: 'success',
  [WorkStatusType.REJECTED]: 'danger',
};

const schedulerWorkStatusType: any = {
  pending: { color: 'secondary', label: 'status.pending', value: 'pending' },
  pending_employer: { color: 'secondary', label: 'status.pending_approval', value: 'pending' },
  completed: { color: 'success', label: 'status.completed', value: 'completed' },
  approved: { color: 'success', label: 'status.approved', value: 'approved' },
  rejected: { color: 'danger', label: 'status.rejected', value: 'rejected' },
  'not done': { color: 'danger', label: 'status.notDone', value: 'not done' },
  expired: { color: 'danger', label: 'status.notDone', value: 'not done' },
  'in progress': { color: 'warning', label: 'status.inProgress', value: 'in progress' },
};

export { WorkStatusType, workStatusTypeColor, schedulerWorkStatusType };
