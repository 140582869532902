import { call, put, delay } from 'redux-saga/effects';
import { toastData, toastDelete, ToastPush } from '../actions';

export function* handleToastSaga(action: ToastPush) {
  yield call(callAlertData, action);
}

function* callAlertData(action: ToastPush) {
  yield put(toastData(action.payload));
  yield delay(parseFloat('1'));
  yield put(toastDelete());
}
