/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { CountryCode } from 'common';
import {
  BULK_UPDATE_FILENAME_SELECT_FETCH,
  BULK_UPDATE_FILENAME_SELECT_SUCCESS,
  BULK_UPDATE_LIST_FETCH,
  BULK_UPDATE_LIST_SUCCESS,
  BULK_UPDATE_REQUEST_ACTION_ERROR,
  BULK_UPDATE_REQUEST_ACTION_FETCH,
  BULK_UPDATE_REQUEST_ACTION_RESET,
  BULK_UPDATE_REQUEST_ACTION_SUCCESS,
  BULK_UPDATE_REQUEST_LIST_FETCH,
  BULK_UPDATE_REQUEST_LIST_SUCCESS,
  CREATE_BULK_UPDATE_REQUEST_ERROR,
  CREATE_BULK_UPDATE_REQUEST_FETCH,
  CREATE_BULK_UPDATE_REQUEST_SUCCESS,
  UPLOAD_BULK_UPDATE_ERROR,
  UPLOAD_BULK_UPDATE_FETCH,
  UPLOAD_BULK_UPDATE_SUCCESS,
} from './constants';

export type BulkImportType = 'onboarding' | 'offboarding' | 'leaveManagement';

export interface IBulkUpdateListFetch {
  type: typeof BULK_UPDATE_LIST_FETCH;
  payload: {
    from?: string;
    to?: string;
    buqid?: string;
    // fileName?: string,
  };
}

export interface IBulkUpdateListSuccess {
  type: typeof BULK_UPDATE_LIST_SUCCESS;
  payload: {
    records: any;
  };
}

export interface IUploadBulkUpdateFetch {
  type: typeof UPLOAD_BULK_UPDATE_FETCH;
  payload: {
    json: any;
  };
}

export interface IUploadBulkUpdateSuccess {
  type: typeof UPLOAD_BULK_UPDATE_SUCCESS;
}

export interface IUploadBulkUpdateError {
  type: typeof UPLOAD_BULK_UPDATE_ERROR;
  payload: any;
}

export interface IBulkUpdateRequestListFetch {
  type: typeof BULK_UPDATE_REQUEST_LIST_FETCH;
  payload: {
    from?: moment.Moment;
    to?: moment.Moment;
    eid?: string;
    status?: string;
    page: number;
  };
}

export interface IBulkUpdateRequestListSuccess {
  type: typeof BULK_UPDATE_REQUEST_LIST_SUCCESS;
  payload: {
    records: any[];
    count: number;
  };
}

export interface IBulkUpdateRequestActionReset {
  type: typeof BULK_UPDATE_REQUEST_ACTION_RESET;
}

export interface IBulkUpdateRequestActionFetch {
  type: typeof BULK_UPDATE_REQUEST_ACTION_FETCH;
  payload: {
    id: string;
    status: string;
    reason?: string;
    countryCode?: CountryCode;
  };
  onSuccess?: () => void;
  onError?: () => void;
}

export interface ICreateBulkUpdateRequestFetch {
  type: typeof CREATE_BULK_UPDATE_REQUEST_FETCH;
  payload: {
    eid?: string;
    fileData: File;
    fileName: string;
    type?: BulkImportType;
    countryCode?: CountryCode;
  };
  onSuccess?: (response: { bulkImportApprovalFlag: boolean }) => void;
  onError?: () => void;
}

export interface ICreateBulkUpdateRequestSuccess {
  type: typeof CREATE_BULK_UPDATE_REQUEST_SUCCESS;
}

export interface ICreateBulkUpdateRequestError {
  type: typeof CREATE_BULK_UPDATE_REQUEST_ERROR;
  payload: any;
}

export interface IBulkUpdateRequestActionSuccess {
  type: typeof BULK_UPDATE_REQUEST_ACTION_SUCCESS;
}

export interface IBulkUpdateRequestActionError {
  type: typeof BULK_UPDATE_REQUEST_ACTION_ERROR;
  payload: any;
}

export interface IBulkUpdateFilenameSelectFetch {
  type: typeof BULK_UPDATE_FILENAME_SELECT_FETCH;
  payload: {
    fileName?: string;
    type: 'fileName';
  };
}

export interface IBulkUpdateFilenameSelectSuccess {
  type: typeof BULK_UPDATE_FILENAME_SELECT_SUCCESS;
  payload: any;
}

export type bulkUpdateAction =
  | IBulkUpdateListFetch
  | IBulkUpdateListSuccess
  | IUploadBulkUpdateFetch
  | IUploadBulkUpdateSuccess
  | IUploadBulkUpdateError
  | IBulkUpdateRequestListFetch
  | IBulkUpdateRequestListSuccess
  | IBulkUpdateRequestActionReset
  | IBulkUpdateRequestActionFetch
  | IBulkUpdateRequestActionSuccess
  | IBulkUpdateRequestActionError
  | ICreateBulkUpdateRequestFetch
  | ICreateBulkUpdateRequestSuccess
  | ICreateBulkUpdateRequestError
  | IBulkUpdateFilenameSelectFetch
  | IBulkUpdateFilenameSelectSuccess;

export const bulkUpdateList = (payload: IBulkUpdateListFetch['payload']): IBulkUpdateListFetch => ({
  type: BULK_UPDATE_LIST_FETCH,
  payload,
});

export const successBulkUpdateList = (payload: IBulkUpdateListSuccess['payload']): IBulkUpdateListSuccess => ({
  type: BULK_UPDATE_LIST_SUCCESS,
  payload,
});

export const uploadBulkUpdate = (payload: IUploadBulkUpdateFetch['payload']): IUploadBulkUpdateFetch => ({
  type: UPLOAD_BULK_UPDATE_FETCH,
  payload,
});

export const successUploadBulkUpdate = (): IUploadBulkUpdateSuccess => ({
  type: UPLOAD_BULK_UPDATE_SUCCESS,
});

export const errorUploadBulkUpdate = (payload: IUploadBulkUpdateError['payload']): IUploadBulkUpdateError => ({
  type: UPLOAD_BULK_UPDATE_ERROR,
  payload,
});

export const bulkUpdateRequestList = (
  payload: IBulkUpdateRequestListFetch['payload']
): IBulkUpdateRequestListFetch => ({
  type: BULK_UPDATE_REQUEST_LIST_FETCH,
  payload,
});

export const successBulkUpdateRequestList = (
  payload: IBulkUpdateRequestListSuccess['payload']
): IBulkUpdateRequestListSuccess => ({
  type: BULK_UPDATE_REQUEST_LIST_SUCCESS,
  payload,
});

export const createBulkUpdateRequestFetch = (
  payload: ICreateBulkUpdateRequestFetch['payload'],
  onSuccess?: (response: { bulkImportApprovalFlag: boolean }) => void,
  onError?: () => void
): ICreateBulkUpdateRequestFetch => ({
  type: CREATE_BULK_UPDATE_REQUEST_FETCH,
  payload,
  onSuccess,
  onError,
});

export const successCreateBulkUpdateRequest = (): ICreateBulkUpdateRequestSuccess => ({
  type: CREATE_BULK_UPDATE_REQUEST_SUCCESS,
});

export const errorCreateBulkUpdateRequest = (
  payload: ICreateBulkUpdateRequestError['payload']
): ICreateBulkUpdateRequestError => ({
  type: CREATE_BULK_UPDATE_REQUEST_ERROR,
  payload,
});

export const bulkUpdateRequestActionReset = (): IBulkUpdateRequestActionReset => ({
  type: BULK_UPDATE_REQUEST_ACTION_RESET,
});

export const bulkUpdateRequestAction = (
  payload: IBulkUpdateRequestActionFetch['payload'],
  onSuccess?: () => void,
  onError?: () => void
): IBulkUpdateRequestActionFetch => ({
  type: BULK_UPDATE_REQUEST_ACTION_FETCH,
  payload,
  onSuccess,
  onError,
});

export const successBulkUpdateRequestAction = (): IBulkUpdateRequestActionSuccess => ({
  type: BULK_UPDATE_REQUEST_ACTION_SUCCESS,
});

export const errorBulkUpdateRequestAction = (
  payload: IBulkUpdateRequestActionError['payload']
): IBulkUpdateRequestActionError => ({
  type: BULK_UPDATE_REQUEST_ACTION_ERROR,
  payload,
});

export const bulkUpdateFilenameSelectFetch = (
  payload: IBulkUpdateFilenameSelectFetch['payload']
): IBulkUpdateFilenameSelectFetch => ({
  type: BULK_UPDATE_FILENAME_SELECT_FETCH,
  payload,
});

export const bulkUpdateFilenameSelectSuccess = (
  payload: IBulkUpdateFilenameSelectSuccess['payload']
): IBulkUpdateFilenameSelectSuccess => ({
  type: BULK_UPDATE_FILENAME_SELECT_SUCCESS,
  payload,
});
