export interface IBannerState {
  type: 'banner';
  name: string;
  position: number;
  startDate: number;
  endDate: number;
  image: string;
  link: string;
  pid?: string;
}

export interface IInsightState {
  type: 'insight';
  name: string;
  position: number;
  startDate: number;
  endDate: number;
  thumbnailImage: string;
  image: string;
  category: string;
  link: string;
  featured: boolean;
  description: string;
  shortDescription: string;
  title: string;
  template: string;
  buttonText?: string;
  pid?: string;
}

export interface IPromotionInfoState {
  name: string;
  buttonText: string;
  category: string;
  createdOn: number;
  ctaLabel: string;
  description: string;
  shortDescription: string;
  featured: boolean;
  id: string;
  image: string;
  largeImage: string;
  link: string;
  position: string;
  startDate: number;
  endDate: number;
  status: string;
  thumbnailImage: string;
  title: string;
  type: string;
  template: string;
  loading: boolean;
}

export const initPromotionInfoState: IPromotionInfoState = {
  name: '',
  buttonText: '',
  category: '',
  createdOn: 0,
  ctaLabel: '',
  description: '',
  shortDescription: '',
  endDate: 0,
  featured: false,
  id: '',
  image: '',
  largeImage: '',
  link: '',
  position: '',
  startDate: 0,
  status: '',
  thumbnailImage: '',
  title: '',
  type: '',
  template: '',
  loading: false,
};
