/* eslint-disable @typescript-eslint/no-explicit-any */
import { employmentAction } from './actions';
import { EMPLOYMENT_LIST_FETCH, EMPLOYMENT_LIST_SUCCESS } from './constants';

export interface IEmploymentState {
  employmentList: {
    list: any;
    count: number;
    loading: boolean;
    payload: {
      name?: string;
      page: number;
      scheduler: boolean;
    };
  };
}

const initialState: IEmploymentState = {
  employmentList: {
    list: [],
    count: 0,
    loading: false,
    payload: {
      page: 1,
      scheduler: false,
    },
  },
};

const employmentListReducer = (state: IEmploymentState['employmentList'], action: employmentAction) => {
  switch (action.type) {
    case EMPLOYMENT_LIST_FETCH:
      return {
        ...state,
        loading: true,
        payload: action.payload,
      };
    case EMPLOYMENT_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.records,
        count: action.payload.count,
        loading: false,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last
export const employmentReducer = (state = initialState, action: employmentAction) => {
  switch (action.type) {
    case EMPLOYMENT_LIST_FETCH:
    case EMPLOYMENT_LIST_SUCCESS:
      return {
        ...state,
        employmentList: employmentListReducer({ ...state.employmentList }, action),
      };
    default:
      return state;
  }
};
