import { CHANGE_COUNTRY_CODE } from './constants';

export interface ChangeCountryCodeAction {
  type: typeof CHANGE_COUNTRY_CODE;
  payload: {
    code: string;
  };
}

export const changeCountryCode = (payload: ChangeCountryCodeAction['payload']): ChangeCountryCodeAction => ({
  type: CHANGE_COUNTRY_CODE,
  payload,
});
