import { changeLanguage } from 'locale/i18n';
import { getCookie, isUndefinedOrNullOrEmpty, setCookie } from 'common/util';
import { CHANGE_LANGUAGE } from './constants';
// eslint-disable-next-line import/no-cycle
import { ChangeLanguageAction } from '.';

export interface LanguageState {
  lang: string;
}

export const initialChangeLanguageState: LanguageState = {
  lang: localStorage.getItem('i18nextLng') ?? 'en',
};

// eslint-disable-next-line default-param-last
export const ChangeLanguageReducer = (state = initialChangeLanguageState, action: ChangeLanguageAction) => {
  if (action.type === CHANGE_LANGUAGE) {
    const language = action.payload;

    changeLanguage(language);
    const countryCode = getCookie('locale').split('-')[1];
    if (!isUndefinedOrNullOrEmpty(countryCode)) setCookie('locale', `${language}-${countryCode?.toUpperCase()}`);
    return {
      ...state,
      lang: action.payload,
    };
  }
  return state;
};
