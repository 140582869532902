enum PayoutStatusEnum {
  PENDING = 'pending',
  SUCCESS = 'success',
  REJECTED = 'rejected',
  REVERSED = 'reversed',
}

const payoutStatusLabel: { [key: string]: string } = {
  [PayoutStatusEnum.PENDING]: 'status.pending',
  [PayoutStatusEnum.SUCCESS]: 'status.success',
  [PayoutStatusEnum.REJECTED]: 'status.rejected',
  [PayoutStatusEnum.REVERSED]: 'status.reversed',
};

const payoutStatusSelect: { label: string; value: string }[] = [
  {
    label: payoutStatusLabel[PayoutStatusEnum.PENDING],
    value: PayoutStatusEnum.PENDING,
  },
  {
    label: payoutStatusLabel[PayoutStatusEnum.SUCCESS],
    value: PayoutStatusEnum.SUCCESS,
  },
  {
    label: payoutStatusLabel[PayoutStatusEnum.REJECTED],
    value: PayoutStatusEnum.REJECTED,
  },
  {
    label: payoutStatusLabel[PayoutStatusEnum.REVERSED],
    value: PayoutStatusEnum.REVERSED,
  },
];

const reconciliationMatchStatusSelect: { label: string; value: boolean }[] = [
  {
    label: 'label.true',
    value: true,
  },
  {
    label: 'label.false',
    value: false,
  },
];

enum PayoutStatusReconciliationType {
  BANK_STATEMENT = 'bankStatement',
}

const payoutStatysReconciliationLabel: { [key: string]: string } = {
  [PayoutStatusReconciliationType.BANK_STATEMENT]: 'label.bankStatement',
};

const PayoutStatusReconciliationTypeSelect: { label: string; value: string }[] = [
  {
    label: payoutStatysReconciliationLabel[PayoutStatusReconciliationType.BANK_STATEMENT],
    value: PayoutStatusReconciliationType.BANK_STATEMENT,
  },
];

const PayoutStatusColor: { [key: string]: string | undefined } = {
  [PayoutStatusEnum.PENDING]: 'warning',
  [PayoutStatusEnum.SUCCESS]: 'success',
  [PayoutStatusEnum.REJECTED]: 'danger',
  [PayoutStatusEnum.REVERSED]: 'secondary',
  undefined,
};

export {
  PayoutStatusEnum,
  PayoutStatusColor,
  payoutStatusLabel,
  payoutStatusSelect,
  payoutStatysReconciliationLabel,
  PayoutStatusReconciliationType,
  PayoutStatusReconciliationTypeSelect,
  reconciliationMatchStatusSelect,
};
