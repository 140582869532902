// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-app-polyfill/ie11'; // For IE 11 support
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-app-polyfill/stable';
import './polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { persistor, sagaMiddleware, store } from './store';
import App from './App';
// i18next libs
import './locale/i18n';
import * as serviceWorker from './serviceWorker';

import { rootSaga } from './modules';

sagaMiddleware.run(rootSaga);
const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
