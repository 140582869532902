/* eslint-disable no-shadow, @typescript-eslint/no-explicit-any */
import malaysiaFlag from '../../assets/country/malaysia.png';
import hongKongFlag from '../../assets/country/hong-kong.png';
import indonesiaFlag from '../../assets/country/indonesia.png';
import philippinesFlag from '../../assets/country/philippines.png';

enum CountryCode {
  MY = 'my',
  HK = 'hk',
  PH = 'ph',
  ID = 'id',
}

enum CountryTimezone {
  MY = 'Asia/Kuala_Lumpur',
  PH = 'Asia/Manila',
  ID = 'Asia/Jakarta',
  HK = 'Asia/Hong_Kong',
}

enum CountryTimezoneUtcOffset {
  MY = 'GMT+8',
  PH = 'GMT+8',
  ID = 'GMT+7',
  HK = 'GMT+8',
}

type CountryCodeTimezone = {
  [key in CountryCode]: string;
};

const countryCodeTimezone: CountryCodeTimezone = {
  ph: CountryTimezone.PH,
  my: CountryTimezone.MY,
  id: CountryTimezone.ID,
  hk: CountryTimezone.HK,
};

const countryTimezoneUtcOffset: CountryCodeTimezone = {
  ph: CountryTimezoneUtcOffset.PH,
  my: CountryTimezoneUtcOffset.MY,
  id: CountryTimezoneUtcOffset.ID,
  hk: CountryTimezoneUtcOffset.HK,
};

const countryMap: any = {
  MY: CountryCode.MY,
  HK: CountryCode.HK,
  PH: CountryCode.PH,
  ID: CountryCode.ID,
};

// country name
const countryCodeConvertName: any = {
  [CountryCode.MY]: 'country.malaysia',
  [CountryCode.HK]: 'country.hongKong',
  [CountryCode.PH]: 'country.philippines',
  [CountryCode.ID]: 'country.indonesia',
};

// country flag
const countryCodeConvertImage: any = {
  [CountryCode.MY]: malaysiaFlag,
  [CountryCode.HK]: hongKongFlag,
  [CountryCode.PH]: philippinesFlag,
  [CountryCode.ID]: indonesiaFlag,
};

const countryCodeList = [
  {
    code: CountryCode.MY,
    name: countryCodeConvertName[CountryCode.MY],
    image: countryCodeConvertImage[CountryCode.MY],
    timezone: CountryTimezone.MY,
    displayUtcOffset: '',
  },
  // {
  //   code: CountryCode.HK,
  //   name: countryCodeConvertName[CountryCode.HK],
  //   image: countryCodeConvertImage[CountryCode.HK],
  // },
  {
    code: CountryCode.PH,
    name: countryCodeConvertName[CountryCode.PH],
    image: countryCodeConvertImage[CountryCode.PH],
    timezone: CountryTimezone.PH,
  },
  {
    code: CountryCode.ID,
    name: countryCodeConvertName[CountryCode.ID],
    image: countryCodeConvertImage[CountryCode.ID],
    timezone: CountryTimezone.ID,
  },
];

const checkAccessByCountry = (countryList: string[], locale: string) => {
  return countryList.filter((element: string) => element.toLowerCase() === locale.toLowerCase()).length > 0;
};

export {
  CountryCode,
  countryMap,
  countryCodeList,
  countryCodeConvertName,
  checkAccessByCountry,
  countryCodeConvertImage,
  countryCodeTimezone,
  CountryTimezone,
  countryTimezoneUtcOffset,
};
