enum BulkImportStatus {
  SUCCESS = 'success',
  FAIL = 'fail',
  EXIST = 'exist',
  PENDING = 'pending',
}

enum BulkImportFileType {
  ONBOARDING = 'onboarding',
  OFFBOARDING = 'offboarding',
  LEAVE_MANAGEMENT = 'leaveManagement',
}

enum BulkImportActionType {
  NEW = 'new',
  UPDATE = 'update',
  SUSPEND = 'suspend',
  UNSUSPEND = 'unsuspend',
  RESIGN = 'resign',
  CANCEL = 'cancel',
  REJECT = 'reject',
  PAID = 'paid',
  UNPAID = 'unpaid',
  DELETE = 'delete',
}

const bulkImportStatusLabel: { [x: string]: string } = {
  [BulkImportStatus.SUCCESS]: 'status.success',
  [BulkImportStatus.FAIL]: 'status.fail',
  [BulkImportStatus.EXIST]: 'status.exist',
  [BulkImportStatus.PENDING]: 'status.pending',
};

const bulkImportFileTypeLabel: { [x: string]: string } = {
  [BulkImportFileType.ONBOARDING]: 'label.onboarding',
  [BulkImportFileType.OFFBOARDING]: 'label.offboarding',
  [BulkImportFileType.LEAVE_MANAGEMENT]: 'label.leaveManagment',
};

const bulkImportActionTypeLabel: { [x: string]: string } = {
  [BulkImportActionType.NEW]: 'status.new',
  [BulkImportActionType.UPDATE]: 'status.update',
  [BulkImportActionType.SUSPEND]: 'status.suspend',
  [BulkImportActionType.UNSUSPEND]: 'status.unsuspend',
  [BulkImportActionType.RESIGN]: 'status.resign',
  [BulkImportActionType.CANCEL]: 'status.cancel',
  [BulkImportActionType.REJECT]: 'status.reject',
  [BulkImportActionType.PAID]: 'status.paid',
  [BulkImportActionType.UNPAID]: 'status.unpaid',
  [BulkImportActionType.DELETE]: 'status.delete',
};

const bulkImportStatusSelection: { label: string; value: string }[] = [
  { label: bulkImportStatusLabel[BulkImportStatus.SUCCESS], value: BulkImportStatus.SUCCESS },
  { label: bulkImportStatusLabel[BulkImportStatus.FAIL], value: BulkImportStatus.FAIL },
  { label: bulkImportStatusLabel[BulkImportStatus.EXIST], value: BulkImportStatus.EXIST },
  { label: bulkImportStatusLabel[BulkImportStatus.PENDING], value: BulkImportStatus.PENDING },
];

const bulkImportFileTypeSelection: { label: string; value: string }[] = [
  { label: bulkImportFileTypeLabel[BulkImportFileType.ONBOARDING], value: BulkImportFileType.ONBOARDING },
  { label: bulkImportFileTypeLabel[BulkImportFileType.OFFBOARDING], value: BulkImportFileType.OFFBOARDING },
  { label: bulkImportFileTypeLabel[BulkImportFileType.LEAVE_MANAGEMENT], value: BulkImportFileType.LEAVE_MANAGEMENT },
];

const bulkImportActionTypeSelection: { label: string; value: string }[] = [
  { label: bulkImportActionTypeLabel[BulkImportActionType.NEW], value: BulkImportActionType.NEW },
  { label: bulkImportActionTypeLabel[BulkImportActionType.UPDATE], value: BulkImportActionType.UPDATE },
  { label: bulkImportActionTypeLabel[BulkImportActionType.SUSPEND], value: BulkImportActionType.SUSPEND },
  { label: bulkImportActionTypeLabel[BulkImportActionType.UNSUSPEND], value: BulkImportActionType.UNSUSPEND },
  { label: bulkImportActionTypeLabel[BulkImportActionType.RESIGN], value: BulkImportActionType.RESIGN },
  { label: bulkImportActionTypeLabel[BulkImportActionType.CANCEL], value: BulkImportActionType.CANCEL },
  { label: bulkImportActionTypeLabel[BulkImportActionType.REJECT], value: BulkImportActionType.REJECT },
  { label: bulkImportActionTypeLabel[BulkImportActionType.PAID], value: BulkImportActionType.PAID },
  { label: bulkImportActionTypeLabel[BulkImportActionType.UNPAID], value: BulkImportActionType.UNPAID },
  { label: bulkImportActionTypeLabel[BulkImportActionType.DELETE], value: BulkImportActionType.DELETE },
];

export {
  bulkImportStatusLabel,
  bulkImportFileTypeLabel,
  bulkImportActionTypeLabel,
  bulkImportStatusSelection,
  bulkImportFileTypeSelection,
  bulkImportActionTypeSelection,
};
