import { push } from 'redux-first-history';
import { call, delay, put } from 'redux-saga/effects';
import { API } from 'api';
import { toastPush } from 'modules';
import {
  errorEmployeeInfo,
  errorUpdateEmployeeCompanyStatus,
  IEmployeeCompanyInfoFetch,
  IEmployeeEkycRecordFetch,
  IEmployeeInfoFetch,
  IEmployeeLapseRepaymentRecordFetch,
  IEmployeeRecordFetch,
  IEmployeeTransactionRecordFetch,
  IEmployeeWorkHourRecordFetch,
  IUpdateEmployeeCompanyInfoFetch,
  IUpdateEmployeeCompanyStatusFetch,
  IUpdateEmployeeInfoFetch,
  IUpdateEmployeeStatusFetch,
  successEmployeeCompanyInfo,
  successEmployeeCompanyRecord,
  successEmployeeEkycRecord,
  successEmployeeInfo,
  successEmployeeLapseRepaymentRecord,
  successEmployeeRecord,
  successEmployeeTransactionRecord,
  successEmployeeWorkHourRecord,
  successUpdateEmployeeCompanyInfo,
  successUpdateEmployeeCompanyStatus,
  successUpdateEmployeeInfo,
} from '../actions';

export function* employeeRecordSaga(action: IEmployeeRecordFetch) {
  try {
    const url = action.payload.permission === 'EMPLOYEE_LIST_READ' ? '/api/employee' : '/api/employer/employment/list';
    // eslint-disable-next-line no-param-reassign
    delete action?.payload.permission;
    const { data } = yield call(API.get(), url, action.payload);
    yield put(
      successEmployeeRecord({
        records: data.data,
        count: data.count,
        currentPage: action.payload.page,
      })
    );
  } catch (error) {
    // Empty
  }
}

export function* employeeInfoSaga(action: IEmployeeInfoFetch) {
  try {
    const { id } = action.payload;
    const { data } = yield call(API.get(), `/api/employee/${id}`);
    yield put(successEmployeeInfo(data));
  } catch (error) {
    yield put(errorEmployeeInfo({}));
  }
}

export function* updateEmployeeInfoSaga(action: IUpdateEmployeeInfoFetch) {
  try {
    const { id } = action.payload;
    yield call(API.put(), `/api/employee/${id}`, action.payload);
    yield put(successUpdateEmployeeInfo());
    yield put(
      toastPush({
        code: 200,
        type: 'success',
        title: 'SUCCESS',
        body: 'toastPush.updatedEmployeeInfo',
      })
    );
    yield put(push(`/employee/${id}`));
  } catch (error) {
    // Empty
  }
}

export function* updateEmployeeStatusSaga(action: IUpdateEmployeeStatusFetch) {
  const { onSuccess, onError, payload } = action;
  try {
    yield call(API.put(), `/api/employee/${payload.id}`, payload);
    onSuccess();
    yield put(
      toastPush({
        code: 200,
        type: 'success',
        title: 'SUCCESS',
        body: 'toastPush.updatedEmployeeStatus',
      })
    );
    yield put(push(`/employee/${payload.id}`));
  } catch (error) {
    onError();
  }
}

export function* employeeCompanyRecordSaga(action: IEmployeeWorkHourRecordFetch) {
  try {
    const { id } = action.payload;
    const { data } = yield call(API.get(), `/api/employee/${id}/company/all`);
    yield put(successEmployeeCompanyRecord({ records: data.records }));
  } catch (error) {
    // Empty
  }
}

export function* employeeCompanyInfoSaga(action: IEmployeeCompanyInfoFetch) {
  try {
    const { id, ebid, eid } = action.payload;
    const { data } = yield call(API.get(), `/api/employee/${id}/company/${ebid}`, { eid });
    yield put(successEmployeeCompanyInfo(data));
  } catch (error) {
    // Empty
  }
}

export function* updateEmployeeCompanyInfoSaga(action: IUpdateEmployeeCompanyInfoFetch) {
  const { onSuccess, onError, payload } = action;
  try {
    const { ebid } = payload;
    yield call(API.put(), `/api/employee/company/${ebid}`, payload);
    yield put(successUpdateEmployeeCompanyInfo());
    yield put(
      toastPush({
        code: 200,
        type: 'success',
        title: 'SUCCESS',
        body: 'toastPush.updatedEmployeeEmploymentInfo',
      })
    );
    if (onSuccess) onSuccess();
  } catch (error) {
    if (onError) onError(error);
  }
}

export function* updateEmployeeCompanyStatusSaga(action: IUpdateEmployeeCompanyStatusFetch) {
  const { onSuccess, onError, payload } = action;
  try {
    const { ebid } = payload;
    yield call(API.put(), `/api/employee/company/${ebid}`, payload);
    yield put(
      toastPush({
        code: 200,
        type: 'success',
        title: 'SUCCESS',
        body: 'toastPush.updatedEmployeeEmploymentInfo',
      })
    );
    yield delay(1);
    yield put(successUpdateEmployeeCompanyStatus());
    if (onSuccess) onSuccess();
  } catch (error) {
    yield put(errorUpdateEmployeeCompanyStatus());
    if (onError) onError();
  }
}

export function* employeeWorkHourRecordSaga(action: IEmployeeWorkHourRecordFetch) {
  try {
    const { id, from, to, page } = action.payload;
    const { data } = yield call(API.get(), `/api/employee/${id}/work`, {
      from,
      to,
      page,
    });
    yield put(
      successEmployeeWorkHourRecord({
        records: data.data,
        count: data.count,
        currentPage: page,
      })
    );
  } catch (error) {
    // Empty
  }
}

export function* employeeTransactionRecordSaga(action: IEmployeeTransactionRecordFetch) {
  try {
    const { id, from, to, page } = action.payload;
    const { data } = yield call(API.get(), `/api/employee/${id}/transaction`, {
      from,
      to,
      page,
    });
    yield put(
      successEmployeeTransactionRecord({
        records: data.data,
        count: data.count,
        currentPage: page,
      })
    );
  } catch (error) {
    // Empty
  }
}

export function* employeeLapseRepaymentRecordSaga(action: IEmployeeLapseRepaymentRecordFetch) {
  try {
    const { id, from, to } = action.payload;
    const { data } = yield call(API.get(), `/api/employee/${id}/lapsepayment`, {
      from,
      to,
    });
    yield put(successEmployeeLapseRepaymentRecord({ records: data.records }));
  } catch (error) {
    // Empty
  }
}

export function* employeeEkycRecordSaga(action: IEmployeeEkycRecordFetch) {
  try {
    const { id } = action.payload;
    const { data } = yield call(API.get(), `/api/ekyc/application`, {
      uid: id,
    });
    yield put(successEmployeeEkycRecord({ records: data }));
  } catch (error) {
    // Empty
  }
}
