import { Toast, ToastAction } from './actions';
import { TOAST_DATA, TOAST_DELETE, TOAST_DELETE_BY_INDEX } from './constants';

export interface ToastState {
  toasts: Toast[];
}

export const initialAlertState: ToastState = { toasts: [] };

// eslint-disable-next-line default-param-last
export const toastReducer = (state = initialAlertState, action: ToastAction) => {
  switch (action.type) {
    case TOAST_DATA:
      return {
        toasts: [...state.toasts, action.payload],
      };
    case TOAST_DELETE:
      return {
        toasts: [...state.toasts.slice(1, state.toasts.length)],
      };
    case TOAST_DELETE_BY_INDEX:
      return {
        toasts: [...state.toasts.slice(0, action.index).concat(...state.toasts.slice(action.index + 1))],
      };
    default:
      return state;
  }
};
