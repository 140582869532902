import React, { CSSProperties, ReactNode, useCallback } from 'react';
import { Row, Col, Card as AntdCard, Button, Typography, Tooltip } from 'antd';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { isUndefinedOrNullOrEmpty } from 'common';

export interface CardProps {
  children: React.ReactNode;
  className?: string;
  title?: string | React.ReactNode;
  showBackButton?: boolean;
  loading?: boolean;
  replacePath?: string;
  headStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
  rowJustify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between';
  rowAlign?: 'top' | 'middle' | 'bottom';
  smColSpan?: string | number;
  xsColSpan?: string | number;
  mdColSpan?: string | number;
  lgColSpan?: string | number;
  xlColSpan?: string | number;
  xxlColSpan?: string | number;
  newStyle?: boolean;
  actions?: Array<ReactNode>;
}

const { Text } = Typography;

export const Card: React.FC<CardProps> = ({
  newStyle,
  replacePath,
  headStyle,
  showBackButton,
  title,
  rowJustify,
  rowAlign,
  xsColSpan,
  smColSpan,
  mdColSpan,
  lgColSpan,
  xlColSpan,
  xxlColSpan,
  className,
  bodyStyle,
  loading,
  actions,
  children,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const newHeaderStyle = newStyle
    ? {
        fontSize: '28px',
        lineHeight: '32.81px',
        fontWeight: 400,
        color: '#000',
        backgroundColor: '#FFF',
      }
    : {
        fontSize: '28px',
        lineHeight: '32.81px',
        color: '#FFFFFF',
        backgroundColor: '#3399FF',
      };
  const handleOnBack = useCallback(() => {
    if (!isUndefinedOrNullOrEmpty(replacePath) && replacePath !== undefined) {
      return history.replace(replacePath);
    }
    return history.goBack();
  }, [history, replacePath]);

  const headerTitleHtml = (
    <Row align="middle" style={newHeaderStyle || headStyle}>
      {showBackButton && newStyle && (
        <Col flex="50px">
          <Tooltip placement="topLeft" title={t('label.back')}>
            <Button
              style={{ height: 30 }}
              icon={<MdKeyboardArrowLeft size="1.8rem" style={{ color: '#3399FF' }} />}
              type="link"
              onClick={handleOnBack}
              className="d-inline-flex align-items-cente"
            />
          </Tooltip>
          {/*  /!*<h5 className={'mb-0 pb-1'} style={{ 'color': '#3399FF' }}>{t('label.back')}</h5>*!/!*!/}
        {/!*</Button> */}
        </Col>
      )}
      <Col flex={5}>
        <Text style={newHeaderStyle || headStyle}>{title}</Text>
      </Col>
    </Row>
  );

  return (
    <Row justify={rowJustify} align={rowAlign}>
      <Col xs={xsColSpan} sm={smColSpan} md={mdColSpan} lg={lgColSpan} xl={xlColSpan} xxl={xxlColSpan}>
        <AntdCard
          className={className}
          bodyStyle={bodyStyle}
          headStyle={newHeaderStyle || headStyle}
          loading={loading}
          title={!isUndefinedOrNullOrEmpty(title) && headerTitleHtml}
          bordered={false}
          actions={actions}
        >
          {showBackButton && !newStyle && (
            <div>
              <Button
                type="link"
                onClick={handleOnBack}
                className="d-inline-flex align-items-center btn-link disable-hover-link p-0 mb-3"
              >
                <MdKeyboardArrowLeft size="1.8rem" />
                <h5 className="mb-1" style={{ color: '#3399FF' }}>
                  {t('label.back')}
                </h5>
              </Button>
            </div>
          )}
          {children}
        </AntdCard>
      </Col>
    </Row>
  );
};

Card.defaultProps = {
  xsColSpan: 24,
  headStyle: {
    fontSize: '28px',
    lineHeight: '32.81px',
    color: '#FFFFFF',
    backgroundColor: '#3399FF',
  },
};
