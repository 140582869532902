/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Icon from '@ant-design/icons';

export const descSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 10.0001L9 12.0001L13 8.00012M5.83474 2.69717C6.55227 2.63991 7.23346 2.35775 7.78132 1.89087C9.0598 0.801351 10.9402 0.801351 12.2187 1.89087C12.7665 2.35775 13.4477 2.63991 14.1653 2.69717C15.8397 2.83079 17.1693 4.16043 17.303 5.83486C17.3602 6.55239 17.6424 7.23358 18.1093 7.78144C19.1988 9.05993 19.1988 10.9403 18.1093 12.2188C17.6424 12.7667 17.3602 13.4479 17.303 14.1654C17.1693 15.8398 15.8397 17.1695 14.1653 17.3031C13.4477 17.3603 12.7665 17.6425 12.2187 18.1094C10.9402 19.1989 9.0598 19.1989 7.78132 18.1094C7.23346 17.6425 6.55227 17.3603 5.83474 17.3031C4.16031 17.1695 2.83067 15.8398 2.69705 14.1654C2.63979 13.4478 2.35763 12.7667 1.89075 12.2188C0.801229 10.9403 0.801229 9.05993 1.89075 7.78144C2.35763 7.23358 2.63979 6.55239 2.69705 5.83486C2.83067 4.16043 4.16031 2.83079 5.83474 2.69717Z"
      stroke="#64748B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const websiteSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 10C19 14.9706 14.9706 19 10 19M19 10C19 5.02944 14.9706 1 10 1M19 10H1M10 19C5.02944 19 1 14.9706 1 10M10 19C11.6569 19 13 14.9706 13 10C13 5.02944 11.6569 1 10 1M10 19C8.34315 19 7 14.9706 7 10C7 5.02944 8.34315 1 10 1M1 10C1 5.02944 5.02944 1 10 1"
      stroke="#64748B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const mobileSvg = () => (
  <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 16H7.01M3 19H11C12.1046 19 13 18.1046 13 17V3C13 1.89543 12.1046 1 11 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19Z"
      stroke="#64748B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const taskDescSvg = () => (
  <svg width="14" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1H17M1 7H17M1 13H8" stroke="#64748B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const briefcaseSvg = () => (
  <svg width="14" height="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 12.2554C16.2207 13.3805 13.1827 14 10 14C6.8173 14 3.7793 13.3805 1 12.2554M14 5V3C14 1.89543 13.1046 1 12 1H8C6.89543 1 6 1.89543 6 3V5M10 11H10.01M3 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H3C1.89543 5 1 5.89543 1 7V17C1 18.1046 1.89543 19 3 19Z"
      stroke="#64748B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const locationMarkerSvg = () => (
  <svg width="14" height="14" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1569 14.6569C14.2202 15.5935 12.2616 17.5521 10.9138 18.8999C10.1327 19.681 8.86768 19.6814 8.08663 18.9003C6.76234 17.576 4.84159 15.6553 3.84315 14.6569C0.718951 11.5327 0.718951 6.46734 3.84315 3.34315C6.96734 0.218951 12.0327 0.218951 15.1569 3.34315C18.281 6.46734 18.281 11.5327 15.1569 14.6569Z"
      stroke="#64748B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 9C12.5 10.6569 11.1569 12 9.5 12C7.84315 12 6.5 10.6569 6.5 9C6.5 7.34315 7.84315 6 9.5 6C11.1569 6 12.5 7.34315 12.5 9Z"
      stroke="#64748B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const unbindSvg = () => (
  <svg width="22" height="17" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99127 5.53166C9.97873 5.44181 9.95363 5.35382 9.91296 5.27002C9.90694 5.25698 9.89891 5.24534 9.89188 5.23277C9.8452 5.14711 9.78797 5.06518 9.71066 4.99395C8.99584 4.33147 8.05712 4 7.11841 4C6.1797 4 5.24098 4.33101 4.52666 4.99395L1.07149 8.19879C-0.357163 9.52374 -0.357163 11.6806 1.07149 13.0061C1.78581 13.669 2.72503 14 3.66374 14C4.60246 14 5.54117 13.669 6.256 13.0061L8.29055 11.1192C7.56719 11.0019 6.88449 10.7458 6.27959 10.3613L4.8419 11.6946C4.21292 12.2779 3.11507 12.2779 2.48608 11.6946C1.83702 11.0922 1.83702 10.1122 2.48608 9.51024L4.37656 7.75698C4.37707 7.75652 4.37757 7.75652 4.37807 7.75605L5.94176 6.3054C6.10239 6.15642 6.28813 6.04516 6.48641 5.97021C6.59032 5.93063 6.69775 5.9013 6.80668 5.88222C7.33025 5.7905 7.89247 5.9311 8.29707 6.3054C8.68762 6.6676 9.32163 6.6676 9.71117 6.3054C9.8467 6.1797 9.93254 6.02561 9.97371 5.86453C9.97471 5.86034 9.97672 5.85615 9.97772 5.85149C9.99981 5.75978 10.0043 5.6662 9.99629 5.57263C9.99429 5.55866 9.99278 5.54562 9.99127 5.53166Z"
      fill="#173142"
    />
    <path
      d="M17.8989 3.67683C17.574 2.72039 16.9228 1.96533 16.0649 1.55052C15.2075 1.13576 14.2467 1.11102 13.3596 1.48163L10.7491 2.56984C11.3193 2.98909 11.8105 3.54059 12.179 4.20431L14.0233 3.43571C14.8302 3.09933 15.7907 3.56489 16.0856 4.43342C16.2334 4.868 16.2149 5.34232 16.0346 5.7685C15.8538 6.19463 15.5323 6.52143 15.1291 6.68913L14.3165 7.02808C14.3155 7.0285 14.3144 7.02945 14.3134 7.02987L10.7003 8.53551C9.89441 8.87147 8.93332 8.4069 8.63804 7.5378C8.45522 6.99837 7.90043 6.73002 7.39981 6.93908C6.8993 7.14709 6.64205 7.75391 6.82535 8.29339C7.15016 9.24983 7.80145 10.0049 8.65885 10.4197C9.51625 10.8344 10.477 10.8592 11.3645 10.4891L14.9781 8.98353C14.9791 8.98311 14.9801 8.98321 14.9811 8.98279L15.7933 8.64326C16.6807 8.27375 17.3883 7.55353 17.786 6.61572C18.1836 5.6779 18.2241 4.63332 17.8989 3.67683Z"
      fill="#173142"
    />
  </svg>
);

export const employerSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.7021 4H9.20208V6.5H6.70208V8H9.20208V10.5H6.70208V12H8.4377C9.00454 11.2668 9.79271 10.7134 10.7021 10.44V8L13.2021 8V10.4124C14.1518 10.675 14.9755 11.2412 15.5622 12H17.7021V10.5H14.7021V8H17.7021V6.5H14.7021V4H13.2021V6.5H10.7021V4Z"
      fill="black"
      fillOpacity="0.45"
    />
    <path
      d="M4.70208 13.1515C5.44918 13.1729 6.13763 13.4284 6.69665 13.8472V3.9946H17.3999V13.7102C17.9471 13.3558 18.5995 13.15 19.3 13.15C19.3268 13.15 19.3536 13.1503 19.3804 13.1509V3.00438C19.3804 2.72593 19.285 2.48892 19.0941 2.29335C18.9032 2.09778 18.6686 2 18.3902 2H5.6923C5.41385 2 5.1792 2.09778 4.98835 2.29335C4.7975 2.48892 4.70208 2.72593 4.70208 3.00438V13.1515Z"
      fill="black"
      fillOpacity="0.45"
    />
    <path
      d="M1.72645 23C1.45188 23 1.22049 22.9059 1.03229 22.7176C0.844095 22.5292 0.75 22.2976 0.75 22.0227V21.5249C0.75 20.8251 1.10521 20.2644 1.81564 19.8429C2.52609 19.4214 3.45919 19.2107 4.61496 19.2107C4.7511 19.2107 4.89005 19.2145 5.03179 19.2222C5.17356 19.2299 5.30577 19.2368 5.42844 19.2429C5.28768 19.5504 5.17746 19.8692 5.09778 20.1991C5.01811 20.5291 4.97827 20.8805 4.97827 21.2535V23H1.72645ZM18.9599 23V21.2473C18.9599 20.8815 18.9223 20.5306 18.8473 20.1945C18.7723 19.8583 18.6597 19.5389 18.5097 19.2361C18.6324 19.2263 18.7646 19.2196 18.9063 19.2161C19.0481 19.2125 19.1877 19.2107 19.3252 19.2107C20.4714 19.2107 21.4018 19.4214 22.1163 19.8429C22.8309 20.2644 23.1881 20.8251 23.1881 21.5249V22.0227C23.1881 22.2976 23.094 22.5292 22.9059 22.7176C22.7176 22.9059 22.4863 23 22.2117 23H18.9599ZM4.61451 18.5123C4.10105 18.5123 3.66891 18.334 3.31808 17.9775C2.96723 17.6211 2.7918 17.1856 2.7918 16.6712C2.7918 16.1677 2.96873 15.738 3.3226 15.3821C3.67645 15.0263 4.10875 14.8484 4.61949 14.8484C5.13023 14.8484 5.56333 15.027 5.91881 15.3843C6.2743 15.7416 6.45205 16.1743 6.45205 16.6825C6.45205 17.1907 6.27415 17.6227 5.91836 17.9785C5.56258 18.3343 5.12796 18.5123 4.61451 18.5123ZM19.3227 18.5123C18.8093 18.5123 18.3771 18.334 18.0263 17.9775C17.6754 17.6211 17.5 17.1856 17.5 16.6712C17.5 16.1677 17.677 15.738 18.0308 15.3821C18.3847 15.0263 18.817 14.8484 19.3277 14.8484C19.8385 14.8484 20.2716 15.027 20.627 15.3843C20.9825 15.7416 21.1603 16.1743 21.1603 16.6825C21.1603 17.1907 20.9824 17.6227 20.6266 17.9785C20.2708 18.3343 19.8362 18.5123 19.3227 18.5123Z"
      fill="black"
      fillOpacity="0.45"
    />
    <path
      d="M7.38268 23C7.13447 23 6.92529 22.9172 6.75513 22.7514C6.585 22.5857 6.49993 22.3818 6.49993 22.14V21.4631C6.49993 20.472 7.00593 19.6712 8.01795 19.0609C9.02996 18.4505 10.3567 18.1454 11.9982 18.1454C13.6477 18.1454 14.977 18.4505 15.9862 19.0609C16.9953 19.6712 17.4999 20.472 17.4999 21.4631V22.14C17.4999 22.3818 17.4149 22.5857 17.2447 22.7514C17.0746 22.9172 16.8654 23 16.6172 23H7.38268ZM11.9991 19.6938C10.9221 19.6938 10.0474 19.8486 9.37494 20.1583C8.70246 20.4679 8.33263 20.8701 8.26544 21.3649V21.4517H15.7344V21.3431C15.6756 20.8629 15.3076 20.4679 14.6303 20.1583C13.9531 19.8486 13.076 19.6938 11.9991 19.6938ZM11.9999 17.5062C11.215 17.5062 10.5479 17.2385 9.99846 16.7032C9.44905 16.1679 9.17434 15.5178 9.17434 14.7531C9.17434 13.9883 9.44905 13.3383 9.99846 12.803C10.5479 12.2677 11.215 12 11.9999 12C12.7848 12 13.452 12.2677 14.0014 12.803C14.5508 13.3383 14.8255 13.9883 14.8255 14.7531C14.8255 15.5178 14.5508 16.1679 14.0014 16.7032C13.452 17.2385 12.7848 17.5062 11.9999 17.5062ZM12.0001 13.5361C11.6463 13.5361 11.3518 13.6527 11.1165 13.8859C10.8812 14.1192 10.7635 14.4081 10.7635 14.7529C10.7635 15.0976 10.8823 15.3846 11.12 15.6139C11.3577 15.8432 11.6521 15.9578 12.0035 15.9578C12.3548 15.9578 12.6502 15.842 12.8897 15.6105C13.1292 15.3789 13.2489 15.092 13.2489 14.7496C13.2489 14.4073 13.1293 14.1195 12.8899 13.8861C12.6506 13.6528 12.354 13.5361 12.0001 13.5361Z"
      fill="black"
      fillOpacity="0.45"
    />
  </svg>
);

export const safetySvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Safety">
      <g id="Vector">
        <path
          d="M12 1.5L3 4.5V13.5C3 18.4711 7.02891 22.5 12 22.5C16.9711 22.5 21 18.4711 21 13.5V4.5L12 1.5ZM19.3125 13.5C19.3125 17.5383 16.0383 20.8125 12 20.8125C7.96172 20.8125 4.6875 17.5383 4.6875 13.5V5.76562L12 3.1875L19.3125 5.76562V13.5Z"
          fill="black"
          fillOpacity="0.45"
        />
        <path
          d="M8.86763 11.1323C8.78942 11.0537 8.69647 10.9914 8.59411 10.9489C8.49175 10.9063 8.38199 10.8844 8.27114 10.8844C8.16029 10.8844 8.05054 10.9063 7.94817 10.9489C7.84581 10.9914 7.75286 11.0537 7.67466 11.1323C7.5961 11.2105 7.53376 11.3035 7.49122 11.4058C7.44869 11.5082 7.42679 11.6179 7.42679 11.7288C7.42679 11.8396 7.44869 11.9494 7.49122 12.0518C7.53376 12.1541 7.5961 12.2471 7.67466 12.3253L10.7567 15.4073C10.8307 15.4814 10.9185 15.5402 11.0152 15.5803C11.112 15.6205 11.2156 15.6411 11.3204 15.6411C11.4251 15.6411 11.5288 15.6205 11.6255 15.5803C11.7222 15.5402 11.8101 15.4814 11.884 15.4073L17.1223 10.169C17.1964 10.0951 17.2552 10.0072 17.2953 9.91047C17.3355 9.81375 17.3561 9.71007 17.3561 9.60536C17.3561 9.50064 17.3355 9.39696 17.2953 9.30024C17.2552 9.20352 17.1964 9.11566 17.1223 9.04168L17.0567 8.97606C16.9827 8.90195 16.8949 8.84315 16.7981 8.80303C16.7014 8.76292 16.5977 8.74227 16.493 8.74227C16.3883 8.74227 16.2846 8.76292 16.1879 8.80303C16.0912 8.84315 16.0033 8.90195 15.9293 8.97606L11.3192 13.5839L8.86763 11.1323Z"
          fill="black"
          fillOpacity="0.45"
        />
      </g>
    </g>
  </svg>
);

export const DescIcon = (props: JSX.IntrinsicAttributes) => {
  return <Icon component={descSvg} {...props} />;
};
export const WebsiteIcon = (props: JSX.IntrinsicAttributes) => {
  return <Icon component={websiteSvg} {...props} />;
};
export const MobileIcon = (props: any) => {
  return <Icon component={mobileSvg} {...props} />;
};
export const TaskDescIcon = (props: any) => {
  return <Icon component={taskDescSvg} {...props} />;
};
export const BriefcaseIcon = (props: any) => {
  return <Icon component={briefcaseSvg} {...props} />;
};
export const LocationIcon = (props: any) => {
  return <Icon component={locationMarkerSvg} {...props} />;
};
export const UnbindIcon = (props: any) => {
  return <Icon component={unbindSvg} {...props} />;
};

export const EmployerIcon = (props: any) => {
  return <Icon component={employerSvg} {...props} />;
};

export const SafetyIcon = (props: any) => {
  return <Icon component={safetySvg} {...props} />;
};
