import { call, put } from 'redux-saga/effects';
import { API } from 'api';
import {
  ICreateEmployerOutletFetch,
  IEmployerOutletInfoFetch,
  IEmployerOutletListFetch,
  IUpdateEmployerOutletFetch,
  successCreateEmployerOutlet,
  successEmployerOutletInfo,
  successEmployerOutletList,
  successUpdateEmployerOutlet,
} from '../actions';

export function* employerOutletListSaga(action: IEmployerOutletListFetch) {
  try {
    const { data } = yield call(API.get(), '/api/employer/outlets/list', action.payload);
    yield put(successEmployerOutletList({ records: data.data, count: data.count }));
  } catch (error) {
    // Empty
  }
}

export function* employerOutletInfoSaga(action: IEmployerOutletInfoFetch) {
  try {
    const { data } = yield call(API.get(), `/api/employer/outlet/${action.payload.id}`);
    yield put(successEmployerOutletInfo(data));
  } catch (error) {
    // Empty
  }
}

export function* createEmployerOutletSaga(action: ICreateEmployerOutletFetch) {
  const { onSuccess, onError, payload } = action;
  try {
    yield call(API.post(), `/api/employer/outlet/create`, payload);
    yield put(successCreateEmployerOutlet());

    if (onSuccess) onSuccess();
  } catch (error) {
    if (onError) onError();
  }
}

export function* updateEmployerOutletSaga(action: IUpdateEmployerOutletFetch) {
  const { onSuccess, onError, payload } = action;
  try {
    yield call(API.put(), `/api/employer/outlet/${payload.id}`, payload);
    yield put(successUpdateEmployerOutlet());

    if (onSuccess) onSuccess();
  } catch (error) {
    if (onError) onError();
  }
}
