/* eslint-disable no-shadow, @typescript-eslint/no-explicit-any */
import { CountryCode } from './country';

const promotionType: any = {
  banner: { label: 'label.banner', value: 'banner' },
  insight: { label: 'label.insight', value: 'insight' },
  ticker: { label: 'label.ticker', value: 'ticker' },
};

const promotionStatus: any = {
  scheduled: { label: 'label.scheduled', value: 'scheduled' },
  expired: { label: 'label.expired', value: 'expired' },
  published: { label: 'label.published', value: 'published' },
  paused: { label: 'label.paused', value: 'paused' },
};

const promotionInsightCategory: any = {
  workingWithPaywatch: { label: 'label.workingWithPaywatch', value: 'workingWithPaywatch' },
  manageYourFinance: { label: 'label.manageYourFinance', value: 'manageYourFinance' },
  workplaceUpdates: { label: 'label.workplaceUpdates', value: 'workplaceUpdates' },
  userTestimonials: { label: 'label.userTestimonials', value: 'userTestimonials' },
  newsOnPaywatch: { label: 'label.newsOnPaywatch', value: 'newsOnPaywatch' },
};

const promotionInsightTemplate: any = {
  externalLink: { label: 'label.externalLink', value: 'externalLink' },
  template1: { label: 'label.template1', value: 'template1' },
  template2: { label: 'label.template2', value: 'template2' },
};

const promotionInsightLanguage = {
  en: 'en',
  ms: 'ms',
  zh: 'zh',
  ind: 'ind',
};

const promotionTypeSelect = [promotionType.banner, promotionType.insight, promotionType.ticker];

const promotionStatusSelect = [promotionStatus.scheduled, promotionStatus.expired, promotionStatus.published];

const promotionInsightCategorySelect = [
  promotionInsightCategory.workingWithPaywatch,
  promotionInsightCategory.manageYourFinance,
  promotionInsightCategory.workplaceUpdates,
  promotionInsightCategory.userTestimonials,
  promotionInsightCategory.newsOnPaywatch,
];

const promotionInsightTemplateSelect = [
  promotionInsightTemplate.externalLink,
  promotionInsightTemplate.template1,
  promotionInsightTemplate.template2,
];

const promotionLanguagesAvailableMys: any = [
  promotionInsightLanguage.en,
  promotionInsightLanguage.ms,
  promotionInsightLanguage.zh,
];

const promotionLanguagesAvailableInd: any = [promotionInsightLanguage.en, promotionInsightLanguage.ind];

const promotionLanguagesAvailablePhp: any = [promotionInsightLanguage.en];

const promotionLanguagesAvailable = (countryCode: CountryCode) => {
  if (countryCode === CountryCode.ID) return promotionLanguagesAvailableInd;
  if (countryCode === CountryCode.PH) return promotionLanguagesAvailablePhp;
  return promotionLanguagesAvailableMys;
};

export {
  promotionInsightCategory,
  promotionType,
  promotionInsightTemplate,
  promotionTypeSelect,
  promotionStatus,
  promotionInsightLanguage,
  promotionLanguagesAvailableMys,
  promotionLanguagesAvailableInd,
  promotionLanguagesAvailablePhp,
  promotionStatusSelect,
  promotionInsightCategorySelect,
  promotionInsightTemplateSelect,
  promotionLanguagesAvailable,
};
