/* eslint-disable @typescript-eslint/no-explicit-any */
import { service } from './requestBuilder';

export const API = {
  get: () => (url: string, data = {}) => {
    return service({
      url,
      method: 'get',
      params: data,
    });
  },
  post: () => (url: string, data?: object | string, headers?: any) => {
    return service({
      url,
      method: 'post',
      data,
      headers
    });
  },
  put: () => (url: string, data?: object) => {
    return service({
      url,
      method: 'put',
      data,
    });
  },
  getBlob:
    () =>
    (url: string, data = {}) => {
      return service({
        url,
        method: 'get',
        params: data,
        responseType: 'blob',
        // responseType: 'blob',
      });
    },
  newGet: (url: string, data = {}, ) => service({ url, method: 'get', params: data }),
  newGetBlob: (url: string, data = {}) => service({ url, method: 'get', params: data, responseType: 'blob' }),
  newPostBlob: (url: string, data = {}) => service({ url, method: 'post', params: data, responseType: 'blob' }),
  newPost: (url: string, data?: object | string, headers?: any) => service({ url, method: 'post', data, headers }),
  newPut: (url: string, data?: object, headers?: any) => service({ url, method: 'put', data, headers }),
  newDelete: (url: string, data?: object | string) => service({ url, method: 'delete', params: data }),
};
