/* eslint-disable @typescript-eslint/no-explicit-any, import/no-extraneous-dependencies */
import { Button, Col, Row, Space } from 'antd';
import { Formik } from 'formik';
import { Form as FormikAntdForm, SubmitButton } from 'formik-antd';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RangeValue } from 'rc-picker/lib/interface';
import { disableAfterCurrentDate, isEmpty, isUndefinedOrNullOrEmpty } from 'common';
import { AntdDataTable } from '../AntdDataTable';
import { RangePickerInput } from '../RangePickerInput';

export interface FilterListByDateRangeProp {
  handleSearch?: (values: any, setSubmitting: any, page: number) => void;
  showForm?: boolean;
  rowKey?: string;
  dataSource: any[];
  columns: any[];
  tableScroll?: object;
  dataCount?: number;
  currentPage?: number;
  loading?: boolean;
  downloadReportLoading?: boolean;
  showFilterClearButton?: boolean;
  pagination?: boolean;
  state?: {
    from: moment.Moment;
    to: moment.Moment;
  };
  handleDownloadReport?: (values: any) => void;
  ranges?: Record<string, Exclude<RangeValue<moment.Moment>, null> | (() => Exclude<RangeValue<moment.Moment>, null>)>;
}

export const FilterListByDateRange: React.FC<FilterListByDateRangeProp> = ({
  handleSearch,
  handleDownloadReport,
  state,
  showForm,
  ranges,
  showFilterClearButton,
  dataSource,
  downloadReportLoading,
  rowKey,
  columns,
  dataCount,
  loading,
  currentPage,
  pagination,
  tableScroll,
}) => {
  const { t } = useTranslation();

  const onHandleSearch = useCallback(
    (values: any, { setSubmitting }: any) => {
      if (handleSearch) handleSearch(values, setSubmitting, 1);
    },
    [handleSearch]
  );

  const onHandleChangePage = (values: any, { setSubmitting }: any, page: number) => {
    if (handleSearch) handleSearch(values, setSubmitting, page);
  };

  const onHandleDownloadReport = (values: any) => {
    if (handleDownloadReport) handleDownloadReport(values);
  };

  const onHandleResetForm = (resetForm: () => void, values: any) => {
    const resetFormAction = () => {
      resetForm();
      onHandleDownloadReport(values);
    };

    return (
      <Button className="mx-3" type="primary" loading={downloadReportLoading} onClick={resetFormAction}>
        {t('label.downloadReport')}
      </Button>
    );
  };

  return (
    <Space direction="vertical">
      <Formik
        initialValues={{
          from: state?.from ? state.from : null,
          to: state?.to ? state.to : null,
        }}
        onSubmit={onHandleSearch}
      >
        {({ values, setFieldValue, setSubmitting, resetForm }) => (
          <>
            {showForm && (
              <FormikAntdForm>
                <Row gutter={24}>
                  <Col xs={12} md={8}>
                    <RangePickerInput
                      id="createdDate"
                      name="createdDate"
                      placeholder={[t('label.startDate'), t('label.endDate')]}
                      value={[values.from, values.to]}
                      disabledDate={disableAfterCurrentDate}
                      onChange={(datesValues) => {
                        setFieldValue('from', !isUndefinedOrNullOrEmpty(datesValues) && datesValues[0]);
                        setFieldValue('to', !isUndefinedOrNullOrEmpty(datesValues) && datesValues[1]);
                      }}
                      ranges={ranges}
                      format="DD-MM-YYYY"
                      allowClear={false}
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <SubmitButton className="px-5" type="primary" style={{ color: '#1E293B' }} ghost>
                      {t('label.filter')}
                    </SubmitButton>
                    {showFilterClearButton && !isEmpty(values) && (
                      <Button
                        type="link"
                        onClick={() => {
                          resetForm();
                          onHandleSearch({}, { setSubmitting });
                        }}
                        style={{ color: '#DC2626' }}
                      >
                        {t('label.clearFilter')}
                      </Button>
                    )}
                    {dataSource?.length > 0 && handleDownloadReport && onHandleResetForm(resetForm, values)}
                  </Col>
                </Row>
              </FormikAntdForm>
            )}
            <AntdDataTable
              rowKey={rowKey}
              columns={columns}
              dataSource={dataSource}
              dataCount={dataCount}
              loading={loading}
              currentPage={currentPage}
              pagination={pagination}
              scroll={tableScroll}
              onChangePage={(page) => onHandleChangePage(values, { setSubmitting }, page)}
              showSizeChanger={false}
            />
          </>
        )}
      </Formik>
    </Space>
  );
};

FilterListByDateRange.defaultProps = {
  tableScroll: { x: 'calc(700px)' },
  pagination: false,
  showFilterClearButton: true,
};
