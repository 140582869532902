/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { bulkUpdateAction } from 'modules/bulkUpdate/actions';
import {
  BULK_UPDATE_FILENAME_SELECT_FETCH,
  BULK_UPDATE_FILENAME_SELECT_SUCCESS,
  BULK_UPDATE_LIST_FETCH,
  BULK_UPDATE_LIST_SUCCESS,
  BULK_UPDATE_REQUEST_ACTION_ERROR,
  BULK_UPDATE_REQUEST_ACTION_FETCH,
  BULK_UPDATE_REQUEST_ACTION_RESET,
  BULK_UPDATE_REQUEST_ACTION_SUCCESS,
  BULK_UPDATE_REQUEST_LIST_FETCH,
  BULK_UPDATE_REQUEST_LIST_SUCCESS,
  CREATE_BULK_UPDATE_REQUEST_ERROR,
  CREATE_BULK_UPDATE_REQUEST_FETCH,
  CREATE_BULK_UPDATE_REQUEST_SUCCESS,
  UPLOAD_BULK_UPDATE_ERROR,
  UPLOAD_BULK_UPDATE_FETCH,
  UPLOAD_BULK_UPDATE_SUCCESS,
} from './constants';

export interface IBulkUpdateState {
  bulkUpdateList: {
    list: any;
    loading: boolean;
  };
  uploadBulkUpdate: {
    success?: boolean;
    error?: string;
  };
  bulkUpdateRequestList: {
    list: any[];
    count: number;
    loading: boolean;
    payload: {
      from?: moment.Moment;
      to?: moment.Moment;
      id?: string;
      eid?: string;
      status?: string;
      page: number;
    };
  };
  createBulkUpdateRequest: {
    loading: boolean;
    success?: boolean;
    error?: string;
  };
  bulkUpdateRequestAction: {
    loading: boolean;
    success?: boolean;
    error?: string;
  };
  bulkUpdateFilenameSelect: {
    list: any;
  };
}

const initialState: IBulkUpdateState = {
  bulkUpdateList: {
    list: [],
    loading: false,
  },
  uploadBulkUpdate: {
    success: false,
  },
  bulkUpdateRequestList: {
    list: [],
    count: 0,
    loading: false,
    payload: {
      page: 1,
    },
  },
  createBulkUpdateRequest: {
    loading: false,
    success: false,
  },
  bulkUpdateRequestAction: {
    loading: false,
    success: false,
  },
  bulkUpdateFilenameSelect: {
    list: [],
  },
};

const bulkUpdateListReducer = (state: IBulkUpdateState['bulkUpdateList'], action: bulkUpdateAction) => {
  switch (action.type) {
    case BULK_UPDATE_LIST_FETCH:
      return {
        ...state,
        list: [],
        loading: true,
      };
    case BULK_UPDATE_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.records,
        loading: false,
      };
    default:
      return state;
  }
};

const uploadBulkUpdateReducer = (state: IBulkUpdateState['uploadBulkUpdate'], action: bulkUpdateAction) => {
  switch (action.type) {
    case UPLOAD_BULK_UPDATE_FETCH:
      return {
        ...state,
        success: false,
        error: undefined,
      };
    case UPLOAD_BULK_UPDATE_SUCCESS:
      return {
        ...state,
        success: true,
        error: undefined,
      };
    case UPLOAD_BULK_UPDATE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

const createBulkUpdateRequestReducer = (
  state: IBulkUpdateState['createBulkUpdateRequest'],
  action: bulkUpdateAction
) => {
  switch (action.type) {
    case CREATE_BULK_UPDATE_REQUEST_FETCH:
      return {
        ...state,
        loading: true,
        success: false,
        error: undefined,
      };
    case CREATE_BULK_UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: undefined,
      };
    case CREATE_BULK_UPDATE_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const bulkUpdateActionReducer = (state: IBulkUpdateState['bulkUpdateRequestAction'], action: bulkUpdateAction) => {
  switch (action.type) {
    case BULK_UPDATE_REQUEST_ACTION_RESET:
      return initialState.bulkUpdateRequestAction;
    case BULK_UPDATE_REQUEST_ACTION_FETCH:
      return {
        ...state,
        loading: true,
        success: false,
        error: undefined,
      };
    case BULK_UPDATE_REQUEST_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: undefined,
      };
    case BULK_UPDATE_REQUEST_ACTION_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const bulkUpdateRequestListReducer = (state: IBulkUpdateState['bulkUpdateRequestList'], action: bulkUpdateAction) => {
  switch (action.type) {
    case BULK_UPDATE_REQUEST_LIST_FETCH:
      return {
        ...state,
        payload: action.payload,
        loading: true,
      };
    case BULK_UPDATE_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.records,
        count: action.payload.count,
        loading: false,
      };
    default:
      return state;
  }
};

const bulkUpdateFilenameSelectReducer = (
  state: IBulkUpdateState['bulkUpdateFilenameSelect'],
  action: bulkUpdateAction
) => {
  switch (action.type) {
    case BULK_UPDATE_FILENAME_SELECT_FETCH:
      return {
        ...state,
      };
    case BULK_UPDATE_FILENAME_SELECT_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last
export const bulkUpdateReducer = (state = initialState, action: bulkUpdateAction) => {
  switch (action.type) {
    case BULK_UPDATE_LIST_FETCH:
    case BULK_UPDATE_LIST_SUCCESS:
      return {
        ...state,
        bulkUpdateList: bulkUpdateListReducer({ ...state.bulkUpdateList }, action),
      };
    case UPLOAD_BULK_UPDATE_FETCH:
    case UPLOAD_BULK_UPDATE_SUCCESS:
    case UPLOAD_BULK_UPDATE_ERROR:
      return {
        ...state,
        uploadBulkUpdate: uploadBulkUpdateReducer({ ...state.uploadBulkUpdate }, action),
      };
    case BULK_UPDATE_REQUEST_LIST_FETCH:
    case BULK_UPDATE_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        bulkUpdateRequestList: bulkUpdateRequestListReducer({ ...state.bulkUpdateRequestList }, action),
      };
    case CREATE_BULK_UPDATE_REQUEST_FETCH:
    case CREATE_BULK_UPDATE_REQUEST_SUCCESS:
    case CREATE_BULK_UPDATE_REQUEST_ERROR:
      return {
        ...state,
        createBulkUpdateRequest: createBulkUpdateRequestReducer({ ...state.createBulkUpdateRequest }, action),
      };
    case BULK_UPDATE_REQUEST_ACTION_RESET:
    case BULK_UPDATE_REQUEST_ACTION_FETCH:
    case BULK_UPDATE_REQUEST_ACTION_SUCCESS:
    case BULK_UPDATE_REQUEST_ACTION_ERROR:
      return {
        ...state,
        bulkUpdateRequestAction: bulkUpdateActionReducer({ ...state.bulkUpdateRequestAction }, action),
      };
    case BULK_UPDATE_FILENAME_SELECT_FETCH:
    case BULK_UPDATE_FILENAME_SELECT_SUCCESS:
      return {
        ...state,
        bulkUpdateFilenameSelect: bulkUpdateFilenameSelectReducer({ ...state.bulkUpdateFilenameSelect }, action),
      };
    default:
      return state;
  }
};
