import { combineReducers } from 'redux';
import persistReducer from 'redux-persist/es/persistReducer';
import { all, call } from 'redux-saga/effects';
import storage from 'redux-persist/lib/storage';
import { publicReducer } from 'modules/app';
import { bulkUpdateReducer, IBulkUpdateState, rootBulkUpdateSaga } from 'modules/bulkUpdate';
import { configReducer, IConfigState, rootConfigSaga } from 'modules/config';
import { dashboardReducer, IDashboardState, rootDashboardSaga } from 'modules/dashboard';
import { ekycReducer, IEkycState, rootEkycSaga } from 'modules/ekyc';
import { employeeReducer, IEmployeeState, rootEmployeeSaga } from 'modules/employee';
import { transactionReducer, ITransactionState, rootTransactionSaga } from 'modules/transaction';
import { employerReducer, IEmployerState, rootEmployerSaga } from 'modules/employer';
import { employerOutletReducer, IEmployerOutletState, rootEmployerOutletSaga } from 'modules/employerOutlet';
import { employmentReducer, IEmploymentState, rootEmploymentSaga } from 'modules/employment';
import {
  INewEmployeeRequestState,
  newEmployeeRequestReducer,
  rootNewEmployeeRequestSaga,
} from 'modules/newEmployeeRequst';
import { INotificationState, notificationReducer, rootNotificationSaga } from 'modules/notification';
import { IPromotionState, promotionReducer, rootPromotionSaga } from 'modules/promotion';
import { CountryState } from 'modules/public/country';
import { LanguageState } from 'modules/public/i18n';
import { rootHandleToastSaga, ToastState } from 'modules/public/toast';
import { UiThemeState } from 'modules/public/uiTheme';
import { IReportState, reportReducer, rootReportSaga } from 'modules/report';
import { ISchedulerState, rootSchedulerSaga, schedulerReducer } from 'modules/scheduler';
import { IUserState, userReducer } from 'modules/user';
import { authReducer, IAuthState, rootAuthSaga } from 'modules/auth';

export * from 'modules/public/i18n';
export * from 'modules/public/uiTheme';
export * from 'modules/public/country';
export * from 'modules/public/toast';
export * from 'modules/auth';
export * from 'modules/dashboard';
export * from 'modules/employerOutlet';
export * from 'modules/config';
export * from 'modules/bulkUpdate';
export * from 'modules/user';
export * from 'modules/employee';
export * from 'modules/transaction';
export * from 'modules/employer';
export * from 'modules/employment';
export * from 'modules/newEmployeeRequst';
export * from 'modules/report';
export * from 'modules/promotion';
export * from 'modules/ekyc';
export * from 'modules/notification';
export * from 'modules/scheduler';

export interface RootState {
  public: {
    uiTheme: UiThemeState;
    i18n: LanguageState;
    toasts: ToastState;
    country: CountryState;
  };
  auth: IAuthState;
  bulkUpdate: IBulkUpdateState;
  config: IConfigState;
  dashboard: IDashboardState;
  ekyc: IEkycState;
  transaction: ITransactionState;
  employee: IEmployeeState;
  employer: IEmployerState;
  employerOutlet: IEmployerOutletState;
  employment: IEmploymentState;
  newEmployeeRequest: INewEmployeeRequestState;
  notification: INotificationState;
  promotion: IPromotionState;
  report: IReportState;
  scheduler: ISchedulerState;
  user: IUserState;
}

export const persistConfig = {
  key: 'user',
  storage,
  whitelist: ['user'],
};

const countryPersistConfig = {
  key: 'public',
  storage,
  whitelist: ['country'],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rootReducer = (routerReducer: any) =>
  combineReducers({
    router: routerReducer,
    public: persistReducer(countryPersistConfig, publicReducer),
    auth: authReducer,
    bulkUpdate: bulkUpdateReducer,
    config: configReducer,
    dashboard: dashboardReducer,
    ekyc: ekycReducer,
    employee: employeeReducer,
    transaction: transactionReducer,
    employer: employerReducer,
    employerOutlet: employerOutletReducer,
    employment: employmentReducer,
    newEmployeeRequest: newEmployeeRequestReducer,
    notification: notificationReducer,
    promotion: promotionReducer,
    report: reportReducer,
    scheduler: schedulerReducer,
    user: userReducer,
  });

export function* rootSaga() {
  yield all([
    // call(rootUserSaga),
    call(rootAuthSaga),
    call(rootBulkUpdateSaga),
    call(rootConfigSaga),
    call(rootDashboardSaga),
    call(rootEkycSaga),
    call(rootEmployeeSaga),
    call(rootTransactionSaga),
    call(rootEmployerSaga),
    call(rootEmployerOutletSaga),
    call(rootEmploymentSaga),
    call(rootHandleToastSaga),
    call(rootNewEmployeeRequestSaga),
    call(rootNotificationSaga),
    call(rootPromotionSaga),
    call(rootReportSaga),
    call(rootSchedulerSaga),
  ]);
}
