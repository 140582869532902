import { takeEvery } from 'redux-saga/effects';
import {
  CANCEL_SCHEDULER_TASK_FETCH,
  CREATE_SCHEDULER_TASK_FETCH,
  SCHEDULER_LIST_FETCH,
  UPDATE_SCHEDULER_TASK_FETCH,
  WORK_STATUS_INFO_FETCH,
  WORK_STATUS_LIST_FETCH,
} from '../constants';
import {
  cancelSchedulerTaskSaga,
  createSchedulerTaskSaga,
  schedulerListSaga,
  updateSchedulerTaskSaga,
  workStatusInfoSaga,
  workStatusListSaga,
} from './schedulerSaga';

export function* rootSchedulerSaga() {
  yield takeEvery(SCHEDULER_LIST_FETCH, schedulerListSaga);
  yield takeEvery(CREATE_SCHEDULER_TASK_FETCH, createSchedulerTaskSaga);
  yield takeEvery(UPDATE_SCHEDULER_TASK_FETCH, updateSchedulerTaskSaga);
  yield takeEvery(CANCEL_SCHEDULER_TASK_FETCH, cancelSchedulerTaskSaga);
  yield takeEvery(WORK_STATUS_LIST_FETCH, workStatusListSaga);
  yield takeEvery(WORK_STATUS_INFO_FETCH, workStatusInfoSaga);
}
