/* eslint-disable @typescript-eslint/no-explicit-any, import/no-cycle */
import { dashboardAction } from './actions';
import {
  DASHBOARD_DOWNLOAD_ATTENDANCE_ERROR,
  DASHBOARD_DOWNLOAD_ATTENDANCE_FETCH,
  DASHBOARD_DOWNLOAD_ATTENDANCE_SUCCESS,
  DASHBOARD_EMPLOYEE_ATTENDANCE_FETCH,
  DASHBOARD_EMPLOYEE_ATTENDANCE_SUCCESS,
  DASHBOARD_STATISTIC_FETCH,
  DASHBOARD_STATISTIC_SUCCESS,
} from './constants';

export interface IDashboardState {
  dashboardStatistic: {
    data: {
      avgLoanSize: number;
      date: string;
      withdrawalAmount: number;
      withdrawalRequestCount: number;
      withdrawalUserCount: number;
    }[];
    avgLoanSize: number;
    withdrawalAmount: number;
    withdrawalRequestCount: number;
    withdrawalUserCount: number;
  };
  dashboardAttendance: {
    loading: boolean;
    records: any;
    count: number;
  };
  dashboardDownloadAttendance: {
    loading: boolean;
    source?: Blob;
  };
}

const initialState: IDashboardState = {
  dashboardStatistic: {
    data: [],
    avgLoanSize: 0,
    withdrawalAmount: 0,
    withdrawalRequestCount: 0,
    withdrawalUserCount: 0,
  },
  dashboardAttendance: {
    loading: false,
    records: [],
    count: 0,
  },
  dashboardDownloadAttendance: {
    loading: false,
  },
};

const dashboardStatisticReducer = (state: IDashboardState['dashboardStatistic'], action: dashboardAction) => {
  switch (action.type) {
    case DASHBOARD_STATISTIC_FETCH:
      return {
        ...state,
      };
    case DASHBOARD_STATISTIC_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const dashboardAttendanceReducer = (state: IDashboardState['dashboardAttendance'], action: dashboardAction) => {
  switch (action.type) {
    case DASHBOARD_EMPLOYEE_ATTENDANCE_FETCH:
      return {
        ...state,
        loading: true,
      };
    case DASHBOARD_EMPLOYEE_ATTENDANCE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

const dashboardDownloadAttendanceReducer = (
  state: IDashboardState['dashboardDownloadAttendance'],
  action: dashboardAction
) => {
  switch (action.type) {
    case DASHBOARD_DOWNLOAD_ATTENDANCE_FETCH:
      return {
        ...state,
        loading: true,
      };
    case DASHBOARD_DOWNLOAD_ATTENDANCE_SUCCESS:
    case DASHBOARD_DOWNLOAD_ATTENDANCE_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last
export const dashboardReducer = (state = initialState, action: dashboardAction) => {
  switch (action.type) {
    case DASHBOARD_STATISTIC_FETCH:
    case DASHBOARD_STATISTIC_SUCCESS:
      return {
        ...state,
        dashboardStatistic: dashboardStatisticReducer({ ...state.dashboardStatistic }, action),
      };
    case DASHBOARD_EMPLOYEE_ATTENDANCE_FETCH:
    case DASHBOARD_EMPLOYEE_ATTENDANCE_SUCCESS:
      return {
        ...state,
        dashboardAttendance: dashboardAttendanceReducer({ ...state.dashboardAttendance }, action),
      };
    case DASHBOARD_DOWNLOAD_ATTENDANCE_FETCH:
    case DASHBOARD_DOWNLOAD_ATTENDANCE_SUCCESS:
    case DASHBOARD_DOWNLOAD_ATTENDANCE_ERROR:
      return {
        ...state,
        dashboardDownloadAttendance: dashboardDownloadAttendanceReducer(
          {
            ...state.dashboardDownloadAttendance,
          },
          action
        ),
      };
    default:
      return state;
  }
};
