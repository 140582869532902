import moment from 'moment';

const currentYear = moment().year();
const yearsAhead = 5;

const yearSelect = Array.from({ length: yearsAhead + 1 }).map((_, index) => {
  const year = currentYear + index;
  return { label: year, value: year };
});

export { yearSelect };
