import { push } from 'redux-first-history';
import { call, put } from 'redux-saga/effects';
import { API } from 'api';
import { toastPush } from 'modules';
import {
  errorCreatePromotion,
  errorUpdatePromotion,
  ICreatePromotionFetch,
  IPromotionInfoFetch,
  IPromotionListFetch,
  IUpdatePromotionFetch,
  IUpdatePromotionStatusFetch,
  resetCreatePromotion,
  resetUpdatePromotion,
  resetUpdatePromotionStatus,
  successCreatePromotion,
  successPromotionInfo,
  successPromotionList,
  successUpdatePromotion,
  successUpdatePromotionStatus,
} from '../actions';

export function* promotionListSaga(action: IPromotionListFetch) {
  try {
    const { data } = yield call(API.get(), '/api/admin/promotion/all', action.payload);
    yield put(successPromotionList({ records: data.data, count: data.count }));
  } catch (error) {
    // Empty
  }
}

export function* promotionInfoSaga(action: IPromotionInfoFetch) {
  try {
    const { id } = action.payload;
    const { data } = yield call(API.get(), `/api/admin/promotion/${id}`, {});
    yield put(successPromotionInfo(data));
  } catch (error) {
    // Empty
  }
}

export function* createPromotionSaga(action: ICreatePromotionFetch) {
  try {
    const { data } = yield call(API.post(), `api/admin/promotion`, action.payload);
    yield put(
      toastPush({
        code: 200,
        type: 'success',
        title: 'SUCCESS',
        body: 'toastPush.createdPromotionSuccessful',
      })
    );
    yield put(push(`/promotion/${data.id}`));
    yield put(successCreatePromotion());
    yield put(resetCreatePromotion());
  } catch (error) {
    yield put(errorCreatePromotion());
  }
}

export function* updatePromotionSaga(action: IUpdatePromotionFetch) {
  try {
    const { pid } = action.payload;
    const { data } = yield call(API.put(), `api/admin/promotion/${pid}`, action.payload);
    yield put(
      toastPush({
        code: 200,
        type: 'success',
        title: 'SUCCESS',
        body: 'toastPush.updatedPromotionSuccessful',
      })
    );
    yield put(push(`/promotion/${data.id}`));
    yield put(successUpdatePromotion());
    yield put(resetUpdatePromotion());
  } catch (error) {
    yield put(errorUpdatePromotion());
  }
}

export function* updatePromotionStatusSaga(action: IUpdatePromotionStatusFetch) {
  try {
    const { pid } = action.payload;
    yield call(API.put(), `api/admin/promotion/${pid}/status`, action.payload);
    yield put(successUpdatePromotionStatus());
    if (action.payload.paused) {
      yield put(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.unpublishedPromotionSuccessful',
        })
      );
    } else {
      yield put(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.publishedPromotionSuccessful',
        })
      );
    }
    yield put(resetUpdatePromotionStatus());
  } catch (error) {
    // Empty
  }
}
