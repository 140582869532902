export const EMPLOYER_LIST_FETCH = 'employer/EMPLOYER_LIST_FETCH';
export const EMPLOYER_LIST_SUCCESS = 'employer/EMPLOYER_LIST_SUCCESS';

export const EMPLOYER_SELECT_FETCH = 'employer/EMPLOYER_SELECT_FETCH';
export const EMPLOYER_SELECT_SUCCESS = 'employer/EMPLOYER_SELECT_SUCCESS';

export const EMPLOYER_INFO_INIT = 'employer/EMPLOYER_INFO_INIT';
export const EMPLOYER_INFO_RESET = 'employer/EMPLOYER_INFO_RESET';
export const EMPLOYER_INFO_FETCH = 'employer/EMPLOYER_INFO_FETCH';
export const EMPLOYER_INFO_SUCCESS = 'employer/EMPLOYER_INFO_SUCCESS';

export const EMPLOYER_REQUEST_LIST_FETCH = 'employer/EMPLOYER_REQUEST_LIST_FETCH';
export const EMPLOYER_REQUEST_LIST_SUCCESS = 'employer/EMPLOYER_REQUEST_LIST_SUCCESS';

export const EMPLOYER_REQUEST_INFO_RESET = 'employer/EMPLOYER_REQUEST_INFO_RESET';
export const EMPLOYER_REQUEST_INFO_FETCH = 'employer/EMPLOYER_REQUEST_INFO_FETCH';
export const EMPLOYER_REQUEST_INFO_SUCCESS = 'employer/EMPLOYER_REQUEST_INFO_SUCCESS';

export const CREATE_EMPLOYER_REQUEST_RESET = 'employer/CREATE_EMPLOYER_REQUEST_RESET';
export const CREATE_EMPLOYER_REQUEST_FETCH = 'employer/CREATE_EMPLOYER_REQUEST_FETCH';
export const CREATE_EMPLOYER_REQUEST_SUCCESS = 'employer/CREATE_EMPLOYER_REQUEST_SUCCESS';
export const CREATE_EMPLOYER_REQUEST_ERROR = 'employer/CREATE_EMPLOYER_REQUEST_ERROR';

export const UPDATE_EMPLOYER_REQUEST_FETCH = 'employer/UPDATE_EMPLOYER_REQUEST_FETCH';
export const UPDATE_EMPLOYER_REQUEST_SUCCESS = 'employer/UPDATE_EMPLOYER_REQUEST_SUCCESS';
export const UPDATE_EMPLOYER_REQUEST_ERROR = 'employer/UPDATE_EMPLOYER_REQUEST_ERROR';

export const INIT_EMPLOYER_REQUEST_FETCH = 'employer/INIT_EMPLOYER_REQUEST_FETCH';
export const INIT_NEW_EMPLOYER_REQUEST_SUCCESS = 'employer/INIT_NEW_EMPLOYER_REQUEST_SUCCESS';
export const INIT_EDIT_EMPLOYER_REQUEST_SUCCESS = 'employer/INIT_EDIT_EMPLOYER_REQUEST_SUCCESS';
export const INIT_EMPLOYER_REQUEST_ERROR = 'employer/INIT_EMPLOYER_REQUEST_ERROR';

export const EMPLOYER_PREVIEW_FILES_IMAGE_RESET = 'employer/EMPLOYER_PREVIEW_FILES_IMAGE_RESET';
export const EMPLOYER_PREVIEW_FILES_IMAGE_FETCH = 'employer/EMPLOYER_PREVIEW_FILES_IMAGE_FETCH';
export const EMPLOYER_PREVIEW_FILES_IMAGE_SUCCESS = 'employer/EMPLOYER_PREVIEW_FILES_IMAGE_SUCCESS';
export const EMPLOYER_PREVIEW_FILES_IMAGE_ERROR = 'employer/EMPLOYER_PREVIEW_FILES_IMAGE_ERROR';

export const EMPLOYER_REQUEST_UPLOAD_BLOB_FETCH = 'employer/EMPLOYER_REQUEST_UPLOAD_BLOB_FETCH';
export const EMPLOYER_REQUEST_UPLOAD_BLOB_SUCCESS = 'employer/EMPLOYER_REQUEST_UPLOAD_BLOB_SUCCESS';
export const EMPLOYER_REQUEST_UPLOAD_BLOB_ERROR = 'employer/EMPLOYER_REQUEST_UPLOAD_BLOB_ERROR';

export const UPDATE_EMPLOYER_ADMIN_STATUS_FETCH = 'employer/UPDATE_EMPLOYER_ADMIN_STATUS_FETCH';

export const RESET_EMPLOYER_ADMIN_PASSWORD_FETCH = 'employer/RESET_EMPLOYER_ADMIN_PASSWORD_FETCH';

export const EMPLOYER_INVENTORY_LIST_FETCH = 'employer/EMPLOYER_INVENTORY_LIST_FETCH';
export const EMPLOYER_INVENTORY_LIST_SUCCESS = 'employer/EMPLOYER_INVENTORY_LIST_SUCCeSS';

export const EMPLOYER_FILE_RETRIEVE_RESET = 'employer/EMPLOYER_FILE_RETRIEVE_RESET';
export const EMPLOYER_FILE_RETRIEVE_FETCH = 'employer/EMPLOYER_FILE_RETRIEVE_FETCH';
export const EMPLOYER_FILE_RETRIEVE_SUCCESS = 'employer/EMPLOYER_FILE_RETRIEVE_SUCCESS';
export const EMPLOYER_FILE_RETRIEVE_ERROR = 'employer/EMPLOYER_FILE_RETRIEVE_ERROR';
