/* eslint-disable @typescript-eslint/no-explicit-any, import/no-cycle */
import { ConfigAction } from './actions';
import {
  GET_CONFIG_FETCH,
  WITHDRAWAL_CONFIG_SUCCESS,
  BANK_LIST_CONFIG_SUCCESS,
  UPDATE_BANK_CONFIG_SETTINGS_SUCCESS,
  UPDATE_BANK_CONFIG_SETTINGS,
  COUNTRY_UTIL_CONFIG_SUCCESS,
  FEE_LIST_CONFIG_SUCCESS,
} from './constants';

export interface AllISOData {
  code: string;
  code3: string;
  name: string;
  number: string;
}

export interface IConfigState {
  withdrawalConfig: {
    employer: {
      ltv: number;
      dailyLtv: number;
      limit: {
        min: number;
        max: number;
      };
    };
    employee: {
      ltv: number;
      defaultEligibleEarning: number;
      limit: {
        min: number;
        max: number;
        monthly: number;
      };
    };
    monthlyLimit: number;
    monthlyLimitUsed: number;
    manualGrantingFrequencyLimit: {
      min: number;
      max: number;
    };
    maximumManualGrantingAmount: {
      min: number;
      max: number;
    };
    customGrantingTimeDayEnabled?: boolean;
    customGrantingTime?: string;
    customGrantingDay?: string;
  };
  feeListConfig: {
    withdrawalFee?: number;
    withdrawalFeeType?: string;
  };
  bankInfoConfig: {
    list: any[];
    loading: boolean;
    actionLoading: boolean;
  };
  countryUtil: {
    countryListWithAllISOData: AllISOData[];
    sanctionCountries: string[];
  };
}

const initialState: IConfigState = {
  withdrawalConfig: {
    employer: {
      ltv: 50,
      dailyLtv: 50,
      limit: {
        min: 0,
        max: 100000,
      },
    },
    employee: {
      ltv: 50,
      defaultEligibleEarning: 1500,
      limit: {
        min: 0,
        max: 100000,
        monthly: 2000,
      },
    },
    monthlyLimit: 100000,
    monthlyLimitUsed: 200,
    manualGrantingFrequencyLimit: {
      min: 0,
      max: 10,
    },
    maximumManualGrantingAmount: {
      min: 0,
      max: 1000,
    },
  },
  feeListConfig: {
    withdrawalFee: undefined,
  },
  bankInfoConfig: {
    list: [],
    loading: false,
    actionLoading: false,
  },
  countryUtil: {
    countryListWithAllISOData: [],
    sanctionCountries: [],
  },
};

const withdrawalConfigReducer = (state: IConfigState['withdrawalConfig'], action: ConfigAction) => {
  if (action.type === WITHDRAWAL_CONFIG_SUCCESS) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

const feeListConfigReducer = (state: IConfigState['feeListConfig'], action: ConfigAction) => {
  if (action.type === FEE_LIST_CONFIG_SUCCESS) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

const countryUtilConfigReducer = (state: IConfigState['countryUtil'], action: ConfigAction) => {
  if (action.type === COUNTRY_UTIL_CONFIG_SUCCESS) {
    return {
      ...state,
      countryListWithAllISOData: action.payload.countryListWithAllISOData,
      sanctionCountries: action.payload.sanctionCountries,
    };
  }
  return state;
};

const bankInfoConfigReducer = (state: IConfigState['bankInfoConfig'], action: ConfigAction) => {
  switch (action.type) {
    case BANK_LIST_CONFIG_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case UPDATE_BANK_CONFIG_SETTINGS:
      return {
        ...state,
        actionLoading: true,
      };
    case UPDATE_BANK_CONFIG_SETTINGS_SUCCESS:
      return {
        ...state,
        actionLoading: false,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last
export const configReducer = (state = initialState, action: ConfigAction) => {
  switch (action.type) {
    case GET_CONFIG_FETCH:
      return {
        ...state,
        loading: true,
      };
    case WITHDRAWAL_CONFIG_SUCCESS:
      return {
        ...state,
        withdrawalConfig: withdrawalConfigReducer({ ...state.withdrawalConfig }, action),
        loading: false,
      };
    case FEE_LIST_CONFIG_SUCCESS:
      return {
        ...state,
        feeListConfig: feeListConfigReducer({ ...state.feeListConfig }, action),
      };
    case COUNTRY_UTIL_CONFIG_SUCCESS:
      return {
        ...state,
        countryUtil: countryUtilConfigReducer({ ...state.countryUtil }, action),
      };
    case BANK_LIST_CONFIG_SUCCESS:
      return {
        ...state,
        bankInfoConfig: bankInfoConfigReducer({ ...state.bankInfoConfig }, action),
        loading: false,
      };
    case UPDATE_BANK_CONFIG_SETTINGS:
    case UPDATE_BANK_CONFIG_SETTINGS_SUCCESS:
      return {
        ...state,
        bankInfoConfig: bankInfoConfigReducer({ ...state.bankInfoConfig }, action),
      };
    default:
      return state;
  }
};
