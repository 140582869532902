/* eslint-disable @typescript-eslint/no-explicit-any, import/no-extraneous-dependencies */
import { Table } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { GetComponentProps, PanelRender } from 'rc-table/lib/interface';
import React from 'react';

declare type TablePaginationPosition =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight';

export interface AntdDataTableProp {
  id?: string;
  size?: SizeType;
  className?: string;
  rowClassName?: string;
  rowKey?: string;
  title?: PanelRender<object>;
  footer?: PanelRender<any>;
  columns: object[];
  dataSource?: object[];
  dataCount?: number;
  pageSize?: number;
  currentPage?: number;
  loading?: boolean;
  bordered?: boolean;
  pagination?: boolean;
  showHeader?: boolean;
  showSizeChanger?: boolean;
  onChangePage?: (page: number, pageSize: number) => void;
  position?: TablePaginationPosition[];
  scroll?: object;
  expandable?: any;
  paginationSize?: any;
  summary?: (data: readonly object[]) => React.ReactNode;
  onRow?: GetComponentProps<any>;
}

export const AntdDataTable: React.FC<AntdDataTableProp> = ({
  id,
  rowKey,
  className,
  rowClassName,
  size,
  title,
  columns,
  dataSource,
  loading,
  scroll,
  bordered,
  footer,
  showHeader,
  expandable,
  summary,
  onRow,
  pagination,
  pageSize,
  position,
  dataCount,
  currentPage,
  showSizeChanger,
  onChangePage,
  paginationSize,
}) => {
  return (
    <Table
      id={id}
      rowKey={rowKey ?? 'id'}
      rowClassName={rowClassName}
      className={className}
      size={size}
      title={title}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      scroll={scroll}
      bordered={bordered}
      footer={footer}
      showHeader={showHeader}
      expandable={expandable}
      summary={summary}
      onRow={onRow}
      pagination={
        pagination && {
          pageSize,
          position,
          total: dataCount,
          current: currentPage,
          showSizeChanger,
          onChange: onChangePage,
          size: paginationSize,
        }
      }
    />
  );
};

AntdDataTable.defaultProps = {
  position: ['bottomLeft'],
  pagination: true,
};
