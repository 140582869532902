import { applyMiddleware, createStore } from 'redux';
import persistReducer from 'redux-persist/es/persistReducer';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import { createReduxHistoryContext } from 'redux-first-history';
import { promiseMiddleware } from 'redux-saga-promise-actions';
import { createHashHistory } from 'history';
import { persistConfig, rootReducer } from './modules';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createHashHistory(),
});

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const middlewareEnhancer = composeWithDevTools(applyMiddleware(sagaMiddleware, routerMiddleware, promiseMiddleware));

const store = createStore(persistReducer(persistConfig, rootReducer(routerReducer)), middlewareEnhancer);
export const history = createReduxHistory(store);

const persistor = persistStore(store);

export { store, sagaMiddleware, persistor };
