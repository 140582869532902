import { Col, Row, Typography } from 'antd';
import { BaseType } from 'antd/lib/typography/Base';
import React, { useMemo } from 'react';
import './style.css';
import { isUndefinedOrNullOrEmpty } from 'common';

const { Text, Paragraph } = Typography;
type ColType = string | number | object;

interface DataStaticInputProps {
  label?: string | React.ReactNode;
  label2?: string | React.ReactNode;
  data?: string | number | React.ReactNode;
  data2?: string | number | React.ReactNode;
  defaultData?: string;
  defaultData2?: string;
  labelClassName?: string;
  label2ClassName?: string;
  isUpdatedField?: boolean;
  onIsUpdatedField2?: boolean;
  xsColLabel?: ColType;
  xsColData?: ColType;
  mdColLabel?: ColType;
  mdColData?: ColType;
  smColLabel?: ColType;
  smColData?: ColType;
  lgColLabel?: ColType;
  lgColData?: ColType;
  xsColLabel2?: ColType;
  xsColData2?: ColType;
  mdColLabel2?: ColType;
  mdColData2?: ColType;
  smColLabel2?: ColType;
  smColData2?: ColType;
  lgColLabel2?: ColType;
  lgColData2?: ColType;
  textClassName?: string;
  rowClassName?: string;
  rowJustify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between';
  dataType?: BaseType;
  data2Type?: BaseType;
  children?: React.ReactNode;
  children2?: React.ReactNode;
}

export const DataStaticInput: React.FC<DataStaticInputProps> = ({
  isUpdatedField,
  onIsUpdatedField2,
  textClassName,
  rowClassName,
  labelClassName,
  label2ClassName,
  rowJustify,
  xsColLabel,
  smColLabel,
  mdColLabel,
  lgColLabel,
  xsColLabel2,
  smColLabel2,
  mdColLabel2,
  lgColLabel2,
  xsColData,
  smColData,
  mdColData,
  lgColData,
  xsColData2,
  smColData2,
  mdColData2,
  lgColData2,
  children,
  children2,
  dataType,
  data2Type,
  label,
  label2,
  data,
  data2,
  defaultData,
  defaultData2,
}) => {
  const onIsUpdatedField = useMemo(() => {
    if (isUpdatedField) {
      return 'is-updated-field';
    }
    return '';
  }, [isUpdatedField]);

  const onTextClassName = useMemo(() => {
    if (!isUndefinedOrNullOrEmpty(textClassName)) {
      return textClassName;
    }
    return '';
  }, [textClassName]);

  return (
    <Row className={rowClassName} justify={rowJustify}>
      {label && (
        <Col xs={xsColLabel} sm={smColLabel} md={mdColLabel} lg={lgColLabel}>
          <Text className={labelClassName}>{label}</Text>
        </Col>
      )}
      <Col xs={xsColData} sm={smColData} md={mdColData} lg={lgColData}>
        {children === undefined ? (
          <Paragraph type={dataType} className={`form-control-static ${onIsUpdatedField} ${onTextClassName}`}>
            {isUndefinedOrNullOrEmpty(data) ? defaultData : data}
          </Paragraph>
        ) : (
          children
        )}
      </Col>
      {label2 ? (
        <Col xs={xsColLabel2} sm={smColLabel2} md={mdColLabel2} lg={lgColLabel2}>
          <Text className={label2ClassName}>{label2}</Text>
        </Col>
      ) : null}
      {data2 ? (
        <Col xs={xsColData2} sm={smColData2} md={mdColData2} lg={lgColData2}>
          {children === undefined ? (
            <Paragraph type={data2Type} className={`form-control-static ${onIsUpdatedField2} ${onTextClassName}`}>
              {isUndefinedOrNullOrEmpty(data2) ? defaultData2 : data2}
            </Paragraph>
          ) : (
            children2
          )}
        </Col>
      ) : null}
    </Row>
  );
};

DataStaticInput.defaultProps = {
  mdColLabel: 8,
  xsColLabel: 12,
  xsColData: 12,
  mdColData: 'auto',
  defaultData: '-',
  defaultData2: '-',
};
