import {
  TRANSACTION_RECORD_FETCH,
  TRANSACTION_RECORD_SUCCESS,
  TRANSACTION_INFO_FETCH,
  TRANSACTION_INFO_SUCCESS,
} from './constants';
import {
  ITransactionRecordFetch,
  ITransactionRecordSuccess,
  ITransactionInfoFetch,
  ITransactionInfoSuccess,
} from './interfaces';

export const transactionRecord = (payload: ITransactionRecordFetch['payload']): ITransactionRecordFetch => ({
  type: TRANSACTION_RECORD_FETCH,
  payload,
});

export const successTransactionRecord = (payload: ITransactionRecordSuccess['payload']): ITransactionRecordSuccess => ({
  type: TRANSACTION_RECORD_SUCCESS,
  payload,
});

export const transactionInfoFetch = (payload: ITransactionInfoFetch['payload']): ITransactionInfoFetch => ({
  type: TRANSACTION_INFO_FETCH,
  payload,
});

export const successTransactionInfo = (payload: ITransactionInfoSuccess['payload']): ITransactionInfoSuccess => ({
  type: TRANSACTION_INFO_SUCCESS,
  payload,
});

export type TransactionAction =
  | ITransactionRecordFetch
  | ITransactionRecordSuccess
  | ITransactionInfoFetch
  | ITransactionInfoSuccess;
