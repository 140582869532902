export const PROMOTION_LIST_FETCH = 'promotion/PROMOTION_LIST_FETCH';
export const PROMOTION_LIST_SUCCESS = 'promotion/PROMOTION_LIST_SUCCESS';
export const PROMOTION_INFO_RESET = 'promotion/PROMOTION_INFO_RESET';
export const PROMOTION_INFO_FETCH = 'promotion/PROMOTION_INFO_FETCH';
export const PROMOTION_INFO_SUCCESS = 'promotion/PROMOTION_INFO_SUCCESS';
export const CREATE_PROMOTION_RESET = 'promotion/CREATE_PROMOTION_RESET';
export const CREATE_PROMOTION_FETCH = 'promotion/CREATE_PROMOTION_FETCH';
export const CREATE_PROMOTION_SUCCESS = 'promotion/CREATE_PROMOTION_SUCCESS';
export const CREATE_PROMOTION_ERROR = 'promotion/CREATE_PROMOTION_ERROR';
export const UPDATE_PROMOTION_RESET = 'promotion/UPDATE_PROMOTION_RESET';
export const UPDATE_PROMOTION_FETCH = 'promotion/UPDATE_PROMOTION_FETCH';
export const UPDATE_PROMOTION_SUCCESS = 'promotion/UPDATE_PROMOTION_SUCCESS';
export const UPDATE_PROMOTION_ERROR = 'promotion/UPDATE_PROMOTION_ERROR';
export const UPDATE_PROMOTION_STATUS_RESET = 'promotion/UPDATE_PROMOTION_STATUS_RESET';
export const UPDATE_PROMOTION_STATUS_FETCH = 'promotion/UPDATE_PROMOTION_STATUS_FETCH';
export const UPDATE_PROMOTION_STATUS_SUCCESS = 'promotion/UPDATE_PROMOTION_STATUS_SUCCESS';
export const UPDATE_PROMOTION_STATUS_ERROR = 'promotion/UPDATE_PROMOTION_STATUS_ERROR';
