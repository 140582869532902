/* eslint-disable @typescript-eslint/no-explicit-any, import/no-cycle */
import { call, put } from 'redux-saga/effects';
import { API } from 'api';
import { toastPush } from '../../public/toast';
import {
  dashboardAttendanceSuccess,
  dashboardDownloadAttendanceError,
  dashboardDownloadAttendanceSuccess,
  IDashboardAttendanceFetch,
  IDashboardDownloadAttendanceFetch,
  IDashboardStatisticFetch,
  successDashboardStatistic,
} from '../actions';

export function* dashboardStatisticSaga(action: IDashboardStatisticFetch) {
  try {
    const { data } = yield call(API.get(), `/api/admin/statistics/employee`, action.payload);
    yield put(successDashboardStatistic(data));
  } catch (error) {
    // Empty
  }
}

export function* dashboardAttendanceSaga(action: IDashboardAttendanceFetch) {
  try {
    const params: any = {
      ...action.payload,
    };

    delete params.date;
    const { data } = yield call(API.get(), `/api/admin/attendance`, params);
    yield put(dashboardAttendanceSuccess({ records: data.data, count: data.count }));
  } catch (error) {
    // Empty
  }
}

export function* dashboardDownloadAttendanceSaga(action: IDashboardDownloadAttendanceFetch) {
  try {
    const { dateFrom, dateTo, eid, outletId } = action.payload;
    yield call(API.get(), '/api/employer/attendance/download', {
      dateFrom,
      dateTo,
      eid,
      outletId,
    });
    yield put(
      toastPush({
        code: 200,
        type: 'success',
        title: 'SUCCESS',
        body: 'toastPush.downloadReportSuccessPleaseCheckMailbox',
      })
    );
    yield put(dashboardDownloadAttendanceSuccess());
  } catch (error) {
    yield put(dashboardDownloadAttendanceError());
  }
}
